import React, { useState } from "react";
import { Grid, useTheme } from "@mui/material";
import Plot from "react-plotly.js";
import moment from "moment";
import { formatDatePlotly } from "../../../../common/utils/format";
import { getApportionment, getTotalEsiForWdpDetailed } from "../../../utils/stats";
import { subtract, multiply, divide } from "../../../../common/utils/math";
import { useDefaultConfig, useDefaultLayout, useDefaultLabelStyle, useIsLightTheme, useConvert } from "../../../utils/charts";
import { ChartTitle } from "../../../components/chartTitle";
import { roundEndDate, roundStartDate } from "../../../../common/utils/date";

export const SourceInputsByPeriodChart = ({ inputs, waterUserExtractionPoint }) => {
  const [ dynamicPlot, setDynamicPlot ] = useState(false);
  const theme = useTheme();
  const isLightTheme = useIsLightTheme();
  const defaultConfig = useDefaultConfig({ staticPlot: !dynamicPlot });
  const defaultLayout = useDefaultLayout();
  const defaultLabelStyle = useDefaultLabelStyle();
  const convert = useConvert();

  const create = (name, isVisibleByDefault, color) => ({
    x: [],
    y: [],
    name,
    type: "bar",
    marker: {
      color
    },
    hovertemplate: "Volume: <b>%{y:.2f}k.m³</b><extra></extra>",
    hoverlabel: defaultLabelStyle,
    visible: isVisibleByDefault ? true : (dynamicPlot ? "legendonly" : false)
  });

  const user = create("User", true, theme.palette.primary.main);
  const others = create("Others", false, theme.palette.primary.light);
  const shapes = [];

  for (const input of [...inputs].sort((a, b) => moment.parseZone(a.waterDeliveryPeriod.start) - moment.parseZone(b.waterDeliveryPeriod.end))) {
    const isDelivered = input.waterDeliveryPeriod.exchangeClearings.some(y => y.exchangeClearingType.isDelivered);
    const i = getTotalEsiForWdpDetailed(waterUserExtractionPoint.extractionPoint.extractionSource, input.extractionSourceInputs, input.waterDeliveryPeriod, isDelivered);
    if (i === null) {
      continue;
    }

    const io = waterUserExtractionPoint.extractionPoint.extractionSource.inputOffset || 0;
    for (const d of i.details) {
      const x = formatDatePlotly(roundStartDate(moment.parseZone(d.date).add(io, 'seconds')));
      const total = divide(d.net, waterUserExtractionPoint.lossFactor);
      const apportionment = getApportionment(waterUserExtractionPoint);
  
      user.x.push(x);
      user.y.push(multiply(total, apportionment));
      others.x.push(x);
      others.y.push(multiply(total, subtract(1, apportionment)));
    }

    const end = roundEndDate(moment.parseZone(input.waterDeliveryPeriod.end));
    if (shapes.length < inputs.length - 1) {
      shapes.push({
        type: "line",
        xref: "x",
        yref: "paper",
        x0: end.clone().add(12, "hours").format("YYYY-MM-DD HH:mm:ss"),
        y0: 0,
        x1: end.clone().add(12, "hours").format("YYYY-MM-DD HH:mm:ss"),
        y1: 1,
        line: {
          width: 4,
          color: isLightTheme ? theme.palette.grey[400] : theme.palette.grey[800],
          dash: 'dot'
        }
      });
    }
  }

  const data = [user, others];

  return (
    <Grid container item xs={12}>
      <ChartTitle checked={dynamicPlot} onChange={setDynamicPlot} title="Allowance by apportionment over time" />
      <Grid item xs={12} style={{ height: 400 }}>
        <Plot
          config={defaultConfig}
          data={data}
          layout={{
            ...defaultLayout,
            legend: {...defaultLayout.legend, traceorder: "normal"},
            barmode: "stack",
            xaxis: {
              gridcolor: undefined,
              rangemode: "nonnegative",
              tickformat: "%d/%m",
              ticks: "outside",
              title: {
                font: {
                  size: convert(theme.typography.body2.fontSize)
                },
                text: "water delivery period"
              }
            },
            yaxis: {
              gridcolor: isLightTheme ? undefined : theme.palette.grey[800],
              rangemode: "tozero",
              ticks: "outside",
              title: {
                font: {
                  size: convert(theme.typography.body2.fontSize)
                },
                text: "volume (k.m³)"
              }
            },
            shapes
          }}
          style={{ display: "block" }}
          useResizeHandler={true}
        />
      </Grid>
    </Grid>
  );
};