import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ClearingProperties } from "./ClearingProperties";

export const ClearingEditDialog = ({ open, target, onCancel, onDelete, onSave, types }) => {
  const [ type, setType ] = useState(null);

  useEffect(() => {
    setType(target ? target.exchangeClearingType.id : null);
  }, [ target, setType ]);

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Edit</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Configure the exchange clearing.
        </DialogContentText>
        <ClearingProperties type={type} setType={setType} types={types} />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={onDelete} color="error">Delete</Button>
        <Box>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={() => onSave({ type })}>Edit</Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
};