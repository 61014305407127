/**
 * @generated SignedSource<<27ebeff3ea1812f1011cb51edc581794>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type extractionSourceExtractionSourceInputsQuery$variables = {|
  catchmentId: any,
  end: any,
  extractionSourceId: any,
  start: any,
|};
export type extractionSourceExtractionSourceInputsQuery$data = {|
  +extractionSourceInputs: ?$ReadOnlyArray<?{|
    +actualGross: ?any,
    +actualNet: ?any,
    +actualSync: ?{|
      +analysis: ?any,
      +id: ?string,
      +sync: any,
    |},
    +date: any,
    +forecastGross: ?any,
    +forecastNet: ?any,
    +forecastSync: ?{|
      +analysis: ?any,
      +id: ?string,
      +sync: any,
    |},
    +id: ?string,
  |}>,
|};
export type extractionSourceExtractionSourceInputsQuery = {|
  response: extractionSourceExtractionSourceInputsQuery$data,
  variables: extractionSourceExtractionSourceInputsQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "catchmentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "extractionSourceId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "analysis",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sync",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order",
        "value": {
          "date": "ASC"
        }
      },
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "lte",
                        "variableName": "end"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "date"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.0"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "gte",
                        "variableName": "start"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "date"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.1"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "eq",
                            "variableName": "catchmentId"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "catchment"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.2"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "eq",
                            "variableName": "extractionSourceId"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "extractionSource"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.3"
              }
            ],
            "kind": "ListValue",
            "name": "and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "ExtractionSourceInput",
    "kind": "LinkedField",
    "name": "extractionSourceInputs",
    "plural": true,
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "forecastGross",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "forecastNet",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ExtractionSourceSync",
        "kind": "LinkedField",
        "name": "forecastSync",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualGross",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualNet",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ExtractionSourceSync",
        "kind": "LinkedField",
        "name": "actualSync",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "extractionSourceExtractionSourceInputsQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "extractionSourceExtractionSourceInputsQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "15eed3a96e404a3c208628a2e6e33e42",
    "id": null,
    "metadata": {},
    "name": "extractionSourceExtractionSourceInputsQuery",
    "operationKind": "query",
    "text": "query extractionSourceExtractionSourceInputsQuery(\n  $catchmentId: UUID!\n  $extractionSourceId: UUID!\n  $start: DateTime!\n  $end: DateTime!\n) {\n  extractionSourceInputs(where: {and: [{date: {lte: $end}}, {date: {gte: $start}}, {catchment: {id: {eq: $catchmentId}}}, {extractionSource: {id: {eq: $extractionSourceId}}}]}, order: {date: ASC}) {\n    id\n    date\n    forecastGross\n    forecastNet\n    forecastSync {\n      id\n      analysis\n      sync\n    }\n    actualGross\n    actualNet\n    actualSync {\n      id\n      analysis\n      sync\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "b3171e9aab0391d86326e6340a3dafc5";

module.exports = ((node/*: any*/)/*: Query<
  extractionSourceExtractionSourceInputsQuery$variables,
  extractionSourceExtractionSourceInputsQuery$data,
>*/);
