import { React } from "react";
import { Link as LinkReact } from "react-router-dom";
import { Box, Breadcrumbs, Chip, Link, Paper, Typography, useScrollTrigger, useTheme } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useResizeDetector } from "react-resize-detector";
import { formatDate } from "../../../../common/utils/format";
import { roundStartDate, roundEndDate, parseDateWithZone } from "../../../../common/utils/date";

export const MagicHeader = ({ type, waterDeliveryPeriod: wdp, waterUserExtractionPoint: wuep }) => {
  const { height: breadcrumbsHeight, ref: breadcrumbsRef } = useResizeDetector();
  const { height: chipsHeight, ref: chipsRef } = useResizeDetector();
  const { width: headerWidth, ref: headerRef } = useResizeDetector();
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 10 });
  const theme = useTheme();

  const paddingOffset = 3;
  const defaultPadding = theme.spacing(paddingOffset * 2);

  const start = parseDateWithZone(wdp.start);
  const end = parseDateWithZone(wdp.end);
  const duration = end.diff(start, 'days');

  return (
    <>
      <Paper ref={breadcrumbsRef} elevation={trigger ? 4 : 0} square sx={{ mt: -5, pt: 5, ml: -paddingOffset, pl: paddingOffset, mr: -paddingOffset, pr: paddingOffset,
        background: theme.palette.background.default, position: "fixed", width: `calc(${headerWidth}px + ${defaultPadding})`, zIndex: 1000 }}>
        <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
          <Link component={LinkReact} underline="hover" color="inherit" to="/">
            <Typography variant="body2">Overview</Typography>
          </Link>
          <Link underline="hover" color="inherit">
            <Typography variant="body2">{`${formatDate(roundStartDate(start))} - ${formatDate(roundEndDate(end))}`}</Typography>
          </Link>
          <Link component={LinkReact} underline="hover" color="inherit" to={`/delivery-periods/${wdp.id}/water-sources/${wuep.extractionPoint.extractionSource.id}`}>
            <Typography variant="body2">{wuep.extractionPoint.name}</Typography>
          </Link>
          <Link underline="hover" color="text.primary">
            <Typography variant="body2">{wuep.waterUser.name}</Typography>
          </Link>
        </Breadcrumbs>
        {trigger && <Box ref={chipsRef}>
          <Box sx={{ mr: { md: 3 }, mt: { xs: -1, md: 5 }, pb: { xs: 2, md: 0 }, position: { md: "absolute" }, right: 0, top: "-5px" }}>
            <Chip size="small" sx={{ mr: 1 }} label={type} />
            <Chip size="small" label={`${duration} days`} />
          </Box>
        </Box>}
      </Paper>
      <Box ref={headerRef} sx={{ pt: { xs: `${breadcrumbsHeight - (trigger ? chipsHeight : 0)}px`, md: `${breadcrumbsHeight}px` } }}>
        <Typography variant="h1">Clearing for {wuep.waterUser.name}</Typography>
        <Typography variant="body2" component="h2" sx={{ mb: 1, mt: 1 }}>for delivery period {formatDate(roundStartDate(start))} - {formatDate(roundEndDate(end))} ({duration} days)</Typography>
      </Box>
    </>
  );
};