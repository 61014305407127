import React, { useContext } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import "moment-timezone";
import { formatDateShort, formatVolume } from "../../../../common/utils/format";
import { calculateDeliveriesByDay } from "../../../utils/water";
import { ProfileContext } from "../../../../common/contexts/profile";

export const MeasurementsDetail = ({ measurements }) => {
  const { timeZone } = useContext(ProfileContext);

  if (!measurements || measurements.length === 0) {
    return <></>;
  }

  // Ensure measurements, which are in standard time, are adjusted to the current TZ if required
  const deliveries = calculateDeliveriesByDay(measurements.map(x => ({ ...x, date: moment.parseZone(x.date).tz(timeZone).format() })));
  const data = Object.keys(deliveries).map(x => ({ label: x, value: deliveries[x].done() }));

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            {data.map((x, i) => (<TableCell key={i}>{formatDateShort(x.label)}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ width: { xs: "10%", sm: "10%", md: "15%", xl: "20%" } }}>Total volume</TableCell>
            {data.map((x, i) => (<TableCell key={i}>{formatVolume(x.value)}</TableCell>))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};