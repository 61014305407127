import React from "react";
import { Link as LinkReact } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Subheader } from "../../../../common/components/subheader";
import { QueryPanel } from "../../../../common/components/queryPanel";

export const DeliveryPeriodsHeader = ({ onExecute }) => (
  <>
    <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
      <Link component={LinkReact} underline="hover" color="inherit" to="/">
        <Typography variant="body2">Administration</Typography>
      </Link>
      <Link underline="hover" color="text.primary">
        <Typography variant="body2">Delivery Periods</Typography>
      </Link>
    </Breadcrumbs>
    <Typography variant="h1">Delivery Periods</Typography>
    <Subheader name="Search" />
    <QueryPanel onExecute={onExecute} endOffset={28} />
  </>
);