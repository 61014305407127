/**
 * @generated SignedSource<<5e9ab6909a1bd7fb3a903148030b2218>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateOfferLineInput = {|
  price: any,
  volume: any,
  waterDeliveryPeriodId: any,
  waterUserExtractionPointId: any,
|};
export type ClearingCreateOfferLineMutation$variables = {|
  input: CreateOfferLineInput,
|};
export type ClearingCreateOfferLineMutation$data = {|
  +createOfferLine: {|
    +offerLine: ?{|
      +id: ?string,
      +offer: ?{|
        +id: ?string,
      |},
      +price: any,
      +volume: any,
    |},
  |},
|};
export type ClearingCreateOfferLineMutation = {|
  response: ClearingCreateOfferLineMutation$data,
  variables: ClearingCreateOfferLineMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateOfferLinePayload",
    "kind": "LinkedField",
    "name": "createOfferLine",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OfferLine",
        "kind": "LinkedField",
        "name": "offerLine",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "volume",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Offer",
            "kind": "LinkedField",
            "name": "offer",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClearingCreateOfferLineMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClearingCreateOfferLineMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "87f6d31ebb3dafdc858c548b13cb2afc",
    "id": null,
    "metadata": {},
    "name": "ClearingCreateOfferLineMutation",
    "operationKind": "mutation",
    "text": "mutation ClearingCreateOfferLineMutation(\n  $input: CreateOfferLineInput!\n) {\n  createOfferLine(input: $input) {\n    offerLine {\n      id\n      price\n      volume\n      offer {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "755a263ab13d1707e818b38bfed6b278";

module.exports = ((node/*: any*/)/*: Mutation<
  ClearingCreateOfferLineMutation$variables,
  ClearingCreateOfferLineMutation$data,
>*/);
