import React, { useContext, useState } from "react";
import { usePreloadedQuery } from "react-relay";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { Link as LinkReact } from "react-router-dom";
import moment from "moment";
import "moment-timezone";
import LinkIcon from "@mui/icons-material/Link";
import InfoIcon from "@mui/icons-material/Info";
import { formatCurrency, formatDate } from "../../../../common/utils/format";
import { ProfileContext } from "../../../../common/contexts/profile";
import { getReason } from "../../../utils/accounts";

const getLink = (referenceTypeName, referenceId, accountId, metadata) => {
  if (!referenceTypeName || !referenceId) {
    return null;
  }

  switch (referenceTypeName) {
    case 'ExchangeClearings':
      const wdpId = metadata.find(x => x.name === 'WaterDeliveryPeriodId')?.value;
      const wuepId = metadata.find(x => x.name === `${accountId}/WaterUserExtractionPointId`)?.value;
      return wdpId && wuepId
        ? `/delivery-periods/${wdpId}/water-users/${wuepId}`
        : null;
    default:
      return null;
  }
};

export const TransactionResults = ({ transactionResultsQueryDef, transactionResultsQueryRef }) => {
  const transactionsQuery = usePreloadedQuery(transactionResultsQueryDef, transactionResultsQueryRef);
  const { timeZone } = useContext(ProfileContext)
  const [ open, setOpen ] = useState(null);
  const theme = useTheme();

  const transactionLineItems = transactionsQuery
    && transactionsQuery.myAccounts
    && transactionsQuery.myAccounts[0]
    && transactionsQuery.myAccounts[0].transactionLineItems;

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Memo</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionLineItems && transactionLineItems.map(x => {
              const link = getLink(x.transaction.referenceTypeName, x.transaction.referenceId, transactionsQuery.myAccounts[0].id, x.transaction.transactionMetadata);
              return (
                <TableRow key={x.id}>
                  <TableCell>{formatDate(moment.parseZone(x.transaction.date).tz(timeZone).format())}</TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center">
                      {x.transaction.memo}{x.transaction.transactionLineItems && x.transaction.transactionLineItems.length > 0 && (<> ({x.transaction.transactionLineItems[0].account.name})</>)}
                      {(() => {
                        const reason = getReason(x.transaction);
                        if (reason) {
                          return (
                            <IconButton onClick={() => setOpen(reason)} size="small" sx={{ ml: 1 }}>
                              <InfoIcon fontSize="inherit" />
                            </IconButton>
                          );
                        }
                      })()}
                    </Stack>
                  </TableCell>
                  <TableCell>{formatCurrency(x.value * -1)}</TableCell>
                  <TableCell>
                    {link && <Link component={LinkReact} to={link}>
                      <LinkIcon fontSize="small" sx={{ color: theme.palette.text.primary, mr: 1, mt: 1, opacity: 0.54 }} />
                    </Link>}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {open && <Dialog open={!!open} maxWidth="md" onClose={() => setOpen(null)}>
        <DialogTitle>Reason</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ mb: 1 }}>{open}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(null)}>Close</Button>
        </DialogActions>
      </Dialog>}
    </>
  )
};