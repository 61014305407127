import React, { Suspense, useContext, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { Profile } from "./components/Profile";
import { CenteredLoading } from "../../../common/components/loading";
import { ProfileContext } from "../../../common/contexts/profile";

const ProfileQuery = graphql`
  query profileProfileQuery($catchmentId: UUID!) {
    me {
      name
      userIdentities {
        upn
        provider
      }
      userRoles {
        target
        role {
          code
          level
        }
      }
    }
    catchments(where: { id: { eq: $catchmentId } }, order: { name: ASC }) {
      name
      waterUsers {
        id
        name
      }
    }
  }
`;

export const ProfilePage = () => {
  const { catchmentId } = useContext(ProfileContext);
  const [ profileQueryRef, loadProfileQuery ] = useQueryLoader(ProfileQuery);
  useEffect(() => loadProfileQuery({ catchmentId }), [ loadProfileQuery, catchmentId ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {profileQueryRef && <Profile profileQueryDef={ProfileQuery} profileQueryRef={profileQueryRef} />}
    </Suspense>
  );
};