import React, { useContext, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { Paper, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { formatDateTimeLocal } from "../../../../common/utils/format";
import { ClearingEditDialog } from "./ClearingEditDialog";
import { NotificationContext } from "../../../../common/contexts/notification";
import { LinkCell } from "../../../../common/components/linkCell";
import { cap } from "../../../../common/utils/strings";

const UpdateExchangeClearingMutation = graphql`
  mutation ClearingsTableUpdateExchangeClearingMutation($input: UpdateExchangeClearingInput!) {
    updateExchangeClearing(input: $input) {
      exchangeClearing {
        id
        isLatest
        submissionState
        run
        exchangeClearingType {
          id
          isFinal
          name
        }
      }
    }
  }
`;

const DeleteExchangeClearingMutation = graphql`
  mutation ClearingsTableDeleteExchangeClearingMutation($input: DeleteExchangeClearingInput!) {
    deleteExchangeClearing(input: $input) {
      exchangeClearing {
        id @deleteRecord
      }
    }
  }
`;

export const updateClearings = (store, wdpId, toAdd, toRemove) => {
  const wdp = store.get(wdpId);
  const linkName = "exchangeClearings";
  const linkFilter = { order: { run: "DESC" } };

  let clearings = wdp.getLinkedRecords(linkName, linkFilter);
  if (toAdd) {
    clearings = [...clearings, toAdd];
  }
  if (toRemove) {
    clearings = clearings.filter(x => !!x);
  }

  if (clearings.length > 0) {
    clearings.sort((a, b) => moment.parseZone(b.getValue("run")).diff(moment.parseZone(a.getValue("run"))));
    clearings.forEach((clearing, index) => clearing.setValue(index === 0, "isLatest"));
  }
  wdp.setLinkedRecords(clearings, linkName, linkFilter);
};

export const ClearingsTable = ({ clearings, types, waterDeliveryPeriod }) => {
  const [ commitUpdateMutation ] = useMutation(UpdateExchangeClearingMutation);
  const [ commitDeleteMutation ] = useMutation(DeleteExchangeClearingMutation);
  const [ open, setOpen ] = useState(false);
  const [ target, setTarget ] = useState(null);
  const { setSuccess, setError } = useContext(NotificationContext);

  const onCompleted = () => setSuccess("Exchange clearing updated successfully");
  const onError = e => setError("Exchange clearing failed to update", e);

  const handleCancel = () => {
    setTarget(null);
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
    commitDeleteMutation({
      variables: { input: { exchangeClearingId: target.id } },
      onCompleted,
      onError,
      updater: store => updateClearings(store, waterDeliveryPeriod.id, null, target)
    });
  };

  const handleEdit = x => {
    setTarget(x);
    setOpen(true);
  };

  const handleSave = ({ type }) => {
    setOpen(false);
    commitUpdateMutation({
      variables: { input: { exchangeClearingId: target.id, exchangeClearingTypeId: type } },
      onCompleted,
      onError,
      updater: store => updateClearings(store, waterDeliveryPeriod.id)
    });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Run</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Is Latest?</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clearings.map(x => {
              const link = `/admin/delivery-periods/${waterDeliveryPeriod.id}/clearings/${x.id}`;
              return (
                <TableRow key={x.id}>
                  <LinkCell link={link}>{formatDateTimeLocal(x.run)}</LinkCell>
                  <LinkCell link={link}>{x.exchangeClearingType.name}</LinkCell>
                  <LinkCell link={link}>{cap(x.submissionState)}</LinkCell>
                  <LinkCell link={link}>{x.isLatest ? "Yes" : "No"}</LinkCell>
                  <TableCell>
                    {x.isLatest &&<IconButton size="small" onClick={() => handleEdit(x)} sx={{ mr: 1 }}>
                      <EditIcon fontSize="inherit" />
                    </IconButton>}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ClearingEditDialog open={open} target={target} onCancel={handleCancel} onSave={handleSave}
        onDelete={handleDelete} types={types} />
    </>
  );
};