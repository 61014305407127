import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export const Icon = ({ type }) => {
  const [ showDialog, setShowDialog ] = useState(false);

  let message = "";
  let icon = null;
  switch (type) {
    case "isMissingOffers":
      message = "No offers have been made for this water delivery period.";
      icon = <WarningAmberIcon />;
      break;
    case "isInProgress":
      message = "This water delivery period clearing is in progress. Review your offers before the final clearing is complete.";
      icon = <RotateLeftIcon />;
      break;
    default:
      break;
  }

  return (
    <>
      {message && icon && 
        <>
          <Grid sx={{ cursor: "pointer", float: "right", mt: 0.5 }} onClick={() => setShowDialog(true)}>
            {icon}
          </Grid>
          <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
            <DialogContent>
              <Typography variant="body1">{message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowDialog(false)}>Close</Button>
            </DialogActions>
          </Dialog>
        </>}
    </>
  );
};