/**
 * @generated SignedSource<<5d11120fbbeb46ab835c8be1e7e7ccf6>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type forecastAccuracyForecastAccuracyQuery$variables = {|
  catchmentId: any,
|};
export type forecastAccuracyForecastAccuracyQuery$data = {|
  +extractionSources: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
  |}>,
|};
export type forecastAccuracyForecastAccuracyQuery = {|
  response: forecastAccuracyForecastAccuracyQuery$data,
  variables: forecastAccuracyForecastAccuracyQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "catchmentId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order",
        "value": {
          "name": "ASC"
        }
      },
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "eq",
                    "variableName": "catchmentId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "catchment"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "ExtractionSource",
    "kind": "LinkedField",
    "name": "extractionSources",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "forecastAccuracyForecastAccuracyQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "forecastAccuracyForecastAccuracyQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f7a62ef069855380b09d58a6e15dfbd9",
    "id": null,
    "metadata": {},
    "name": "forecastAccuracyForecastAccuracyQuery",
    "operationKind": "query",
    "text": "query forecastAccuracyForecastAccuracyQuery(\n  $catchmentId: UUID!\n) {\n  extractionSources(where: {catchment: {id: {eq: $catchmentId}}}, order: {name: ASC}) {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "7b54a5cd832366601f7892cbbc70cabe";

module.exports = ((node/*: any*/)/*: Query<
  forecastAccuracyForecastAccuracyQuery$variables,
  forecastAccuracyForecastAccuracyQuery$data,
>*/);
