import React, { Suspense, useContext, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { ProfileContext } from "../../../common/contexts/profile";
import { CenteredLoading } from "../../../common/components/loading";
import { Financials } from "./components/Financials";

const FinancialsQuery = graphql`
  query financialsFinancialsQuery($catchmentId: UUID!, $now: DateTime!) {
    accounts(where: { and: [ { catchment: { id: { eq: $catchmentId } } }, { referenceTypeName: { eq: "WaterUsers" } } ] }) {
      id
      name
      referenceId
      accountBalance {
        value
      }
    }
    exchangeClearingsForCurrentSeason(catchmentId: $catchmentId, now: $now, where: { and: [ { exchangeClearingType: { isFinal: { eq: true } } }, { isLatest: { eq: true } } ] }, order: { waterDeliveryPeriod: { start: ASC } }) {
      id
      allocations {
        id
        changeQuantity
        totalQuantity
        spotPrice
        raceCongestionCharge
        raceCongestionCredit
        totalNetCharge
        waterUserExtractionPoint {
          id
          waterUser {
            id
          }
        }
      }
      waterDeliveryPeriod {
        id
        start
        end
      }
    }
    transactionLineItemsForCurrentSeason(catchmentId: $catchmentId, now: $now, where: { and: [ { account: { referenceTypeName: { eq: "WaterUsers" } } }, { transaction: { referenceTypeName: { eq: "ExchangeClearings" } } } ] }) {
      id
      value
      account {
        id
        referenceId
      }
      transaction {
        id
        referenceId
        transactionMetadata(where: { name: { eq: "WaterDeliveryPeriodId" } }) {
          id
          name
          value
        }
      }
    }
    waterUsers(where: { catchment: { id: { eq: $catchmentId } } }, order: { publicId: ASC }) {
      id
      name
    }
  }
`;

export const FinancialsPage = () => {
  const [ financialsQueryRef, loadFinancialsQuery ] = useQueryLoader(FinancialsQuery);
  const { catchmentId, now } = useContext(ProfileContext);

  useEffect(() => loadFinancialsQuery(
    { catchmentId, now: now({ ignoreTz: true }).format() }, { fetchPolicy: "network-only" }),
    [ loadFinancialsQuery, catchmentId, now ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {financialsQueryRef && <Financials financialsQueryDef={FinancialsQuery} financialsQueryRef={financialsQueryRef} />}
    </Suspense>
  );
};