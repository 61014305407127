import React, { useContext, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation, usePreloadedQuery } from "react-relay";
import { Navigate } from "react-router-dom";
import { Fab, Grid, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { NotificationContext } from "../../../../common/contexts/notification";
import { ExtractionSourceInputDialog } from "./ExtractionSourceInputDialog";
import { ExtractionSourceInputsTable } from "./ExtractionSourceInputsTable";
import { ExtractionSourceSyncDialog } from "./ExtractionSourceSyncDialog";

const CreateExtractionSourceInputMutation = graphql`
  mutation ExtractionSourceResultsCreateExtractionSourceInputMutation($input: CreateExtractionSourceInputInput!) {
    createExtractionSourceInput(input: $input) {
      extractionSourceInput {
        id
        date
        forecastGross
        forecastNet
        actualGross
        actualNet
      }
    }
  }
`;

const UpdateExtractionSourceInputMutation = graphql`
  mutation ExtractionSourceResultsUpdateExtractionSourceInputMutation($input: UpdateExtractionSourceInputInput!) {
    updateExtractionSourceInput(input: $input) {
      extractionSourceInput {
        id
        date
        forecastGross
        forecastNet
        actualGross
        actualNet
      }
    }
  }
`;

const DeleteExtractionSourceInputMutation = graphql`
  mutation ExtractionSourceResultsDeleteExtractionSourceInputMutation($input: DeleteExtractionSourceInputInput!) {
    deleteExtractionSourceInput(input: $input) {
      extractionSourceInput {
        id @deleteRecord
      }
    }
  }
`;

const updateInputs = (store, cId, esId, start, end, toAdd, toRemove) => {
  const linkName = "extractionSourceInputs";
  const linkFilter = { where: { and: [ { date: { lte: end.format() } }, { date: { gte: start.format() } }, { catchment: { id: { eq: cId } } }, { extractionSource: { id: { eq: esId } } } ] }, order: { date: "ASC" } };

  let inputs = store.getRoot().getLinkedRecords(linkName, linkFilter);
  if (toAdd) {
    inputs = [...inputs, toAdd];
  }
  if (toRemove) {
    inputs = inputs.filter(x => !!x);
  }
  inputs.sort((a, b) => a.getValue("date").localeCompare(b.getValue("date")));
  store.getRoot().setLinkedRecords(inputs, linkName, linkFilter);
};

export const ExtractionSourceResults = ({ extractionSourceInputsQueryDef, extractionSourceInputsQueryRef, catchmentId, extractionSourceQueryDef, extractionSourceQueryRef, start, end }) => {
  const extractionSourceInputsQuery = usePreloadedQuery(extractionSourceInputsQueryDef, extractionSourceInputsQueryRef);
  const extractionSourceQuery = usePreloadedQuery(extractionSourceQueryDef, extractionSourceQueryRef);
  const [ commitCreateExtractionSourceInput ] = useMutation(CreateExtractionSourceInputMutation);
  const [ commitUpdateExtractionSourceInput ] = useMutation(UpdateExtractionSourceInputMutation);
  const [ commitDeleteExtractionSourceInput ] = useMutation(DeleteExtractionSourceInputMutation);
  const theme = useTheme();
  const { setSuccess, setError } = useContext(NotificationContext);

  const es = extractionSourceQuery.extractionSources.length > 0
    ? extractionSourceQuery.extractionSources[0]
    : null;

  const [ editOpen, setEditOpen ] = useState(false);
  const [ viewOpen, setViewOpen ] = useState(false);
  const [ target, setTarget ] = useState(null);

  const onCompleted = e => setSuccess("Extraction source input updated successfully");
  const onError = e => setError("Extraction source input failed to update", e);

  const handleAdd = () => {
    setTarget(null);
    setEditOpen(true);
  };

  const handleEdit = item => {
    setTarget(item);
    setEditOpen(true);
  };

  const handleView = item => {
    setTarget(item);
    setViewOpen(true);
  };

  const handleSave = item => {
    setEditOpen(false);
    if (target) {
      commitUpdateExtractionSourceInput({
        variables: { input: { ...item, extractionSourceInputId: target.id } },
        onCompleted,
        onError,
        updater: store => updateInputs(store, catchmentId, es.id, start, end)
      });
    } else {
      commitCreateExtractionSourceInput({
        variables: { input: { ...item, extractionSourceId: es.id } },
        onCompleted,
        onError,
        updater: store => {
          const esi = store.getRootField("createExtractionSourceInput")
            .getLinkedRecord("extractionSourceInput");
          updateInputs(store, catchmentId, es.id, start, end, esi);
        }
      });
    }
  };

  const handleDelete = () => {
    setEditOpen(false);
    commitDeleteExtractionSourceInput({
      variables: { input: { extractionSourceInputId: target.id } },
      onCompleted,
      onError,
      updater: store => updateInputs(store, catchmentId, es.id, start, end, null, target)
    });
  };

  const handleCancel = () => setEditOpen(false);

  if (!es) {
    return (<Navigate to="/admin/sources" />);
  }

  return (
    <>
      <Grid container sx={{ mb: 2, mt: 2 }}>
        <ExtractionSourceInputsTable extractionSourceInputs={extractionSourceInputsQuery.extractionSourceInputs}
          onEdit={handleEdit} onView={handleView} extractionSourceConfig={es && es.config} />
      </Grid>
      <Fab color="primary" style={{ bottom: theme.spacing(3), position: "fixed", right: theme.spacing(3) }} onClick={handleAdd}>
        <AddIcon />
      </Fab>
      {<ExtractionSourceInputDialog open={editOpen} target={target} extractionSourceConfig={es && es.config}
        onSave={handleSave} onCancel={handleCancel} onDelete={handleDelete} />}
      {target && <ExtractionSourceSyncDialog open={viewOpen} target={target} onCancel={() => setViewOpen(false)} />}
    </>
  );
};