import { formatCurrency } from "../../common/utils/format";

export const isDebit = (line, account) => ((account || line.account).accountType.name === 'Debit' && line.value >= 0)
  || ((account || line.account).accountType.name === 'Credit' && line.value < 0);

export const isCredit = (line, account) => !isDebit(line, account);

export const formatAccountingValue = value =>
  formatCurrency(Math.abs(value));

export const getReason = txn =>
{
  if (!txn || !txn.transactionMetadata || !txn.referenceTypeName) {
    return null;
  }

  const reason = txn.transactionMetadata.find(x => x.name === "Reason");
  if (!reason) {
    return null;
  }

  switch (txn.referenceTypeName) {
    case "ExchangeClearings": {
      switch (reason.value) {
        case "Create":
          return "New exchange clearing published.";
        case "Update":
          return "Existing exchange clearing updated.";
        case "Delete":
          return "Existing exchange clearing deleted.";
        case "UpdateOfftakeMeasurements":
          return "Existing exchange clearing reconciled with offtake measurements.";
        case "UpdateWaterDeliveryPeriod":
          return "Existing exchange clearing reconciled with change to water delivery period.";
        default:
          return null;
      }
    }
    default:
      return null;
  }
}