/**
 * @generated SignedSource<<e1f7f7d35f8cd5f97b3e9583ed940b82>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateExchangeClearingInput = {|
  allocations?: ?$ReadOnlyArray<?AllocationCreateInput>,
  catchmentId: any,
  exchangeClearingTypeId: any,
  run: any,
  waterDeliveryPeriodId: any,
|};
export type AllocationCreateInput = {|
  baseQuantity: any,
  baseQuantityPreLoss: any,
  changeQuantity: any,
  clearingFee: any,
  constraint?: ?string,
  effectiveGrossPrice: any,
  effectiveNetPrice: any,
  farmCongestionPrice: any,
  farmSurcharge?: ?string,
  quantityDisposedCreditPreLoss: any,
  quantityObtainedCharge: any,
  raceCongestionCharge: any,
  raceCongestionCredit: any,
  raceCongestionPrice: any,
  spotPrice: any,
  spotPricePreLoss: any,
  totalGrossCharge: any,
  totalNetCharge: any,
  totalQuantity: any,
  totalQuantityPreLoss: any,
  waterUserExtractionPointId: any,
|};
export type ClearingWizardDialogCreateExchangeClearingMutation$variables = {|
  input: CreateExchangeClearingInput,
|};
export type ClearingWizardDialogCreateExchangeClearingMutation$data = {|
  +createExchangeClearing: {|
    +exchangeClearing: ?{|
      +allocations: ?$ReadOnlyArray<?{|
        +baseQuantity: any,
        +baseQuantityPreLoss: any,
        +changeQuantity: any,
        +clearingFee: any,
        +constraint: ?string,
        +effectiveGrossPrice: any,
        +effectiveNetPrice: any,
        +farmCongestionPrice: any,
        +farmSurcharge: ?string,
        +id: ?string,
        +quantityDisposedCreditPreLoss: any,
        +quantityObtainedCharge: any,
        +raceCongestionCharge: any,
        +raceCongestionCredit: any,
        +raceCongestionPrice: any,
        +spotPrice: any,
        +spotPricePreLoss: any,
        +totalGrossCharge: any,
        +totalNetCharge: any,
        +totalQuantity: any,
        +totalQuantityPreLoss: any,
        +waterUserExtractionPointId: any,
      |}>,
      +exchangeClearingType: ?{|
        +id: ?string,
        +isFinal: boolean,
        +name: ?string,
      |},
      +id: ?string,
      +isLatest: boolean,
      +run: any,
      +waterDeliveryPeriod: ?{|
        +id: ?string,
      |},
    |},
  |},
|};
export type ClearingWizardDialogCreateExchangeClearingMutation = {|
  response: ClearingWizardDialogCreateExchangeClearingMutation$data,
  variables: ClearingWizardDialogCreateExchangeClearingMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateExchangeClearingPayload",
    "kind": "LinkedField",
    "name": "createExchangeClearing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExchangeClearing",
        "kind": "LinkedField",
        "name": "exchangeClearing",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLatest",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "run",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExchangeClearingType",
            "kind": "LinkedField",
            "name": "exchangeClearingType",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isFinal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WaterDeliveryPeriod",
            "kind": "LinkedField",
            "name": "waterDeliveryPeriod",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Allocation",
            "kind": "LinkedField",
            "name": "allocations",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "baseQuantityPreLoss",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "baseQuantity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalQuantityPreLoss",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalQuantity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "changeQuantity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "farmSurcharge",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "spotPricePreLoss",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "spotPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "farmCongestionPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "raceCongestionPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveGrossPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveNetPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantityDisposedCreditPreLoss",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantityObtainedCharge",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "raceCongestionCharge",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "raceCongestionCredit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalGrossCharge",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalNetCharge",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clearingFee",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "constraint",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "waterUserExtractionPointId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClearingWizardDialogCreateExchangeClearingMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClearingWizardDialogCreateExchangeClearingMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7a51e2a616bfae8f19e8663dd994d6d9",
    "id": null,
    "metadata": {},
    "name": "ClearingWizardDialogCreateExchangeClearingMutation",
    "operationKind": "mutation",
    "text": "mutation ClearingWizardDialogCreateExchangeClearingMutation(\n  $input: CreateExchangeClearingInput!\n) {\n  createExchangeClearing(input: $input) {\n    exchangeClearing {\n      id\n      isLatest\n      run\n      exchangeClearingType {\n        id\n        isFinal\n        name\n      }\n      waterDeliveryPeriod {\n        id\n      }\n      allocations {\n        id\n        baseQuantityPreLoss\n        baseQuantity\n        totalQuantityPreLoss\n        totalQuantity\n        changeQuantity\n        farmSurcharge\n        spotPricePreLoss\n        spotPrice\n        farmCongestionPrice\n        raceCongestionPrice\n        effectiveGrossPrice\n        effectiveNetPrice\n        quantityDisposedCreditPreLoss\n        quantityObtainedCharge\n        raceCongestionCharge\n        raceCongestionCredit\n        totalGrossCharge\n        totalNetCharge\n        clearingFee\n        constraint\n        waterUserExtractionPointId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "bd3a135fa2c6ae8adcea022cf6ce8b25";

module.exports = ((node/*: any*/)/*: Mutation<
  ClearingWizardDialogCreateExchangeClearingMutation$variables,
  ClearingWizardDialogCreateExchangeClearingMutation$data,
>*/);
