import React from "react";
import { usePreloadedQuery } from "react-relay";
import { Link as LinkReact, Navigate } from "react-router-dom";
import { Breadcrumbs, Grid, Link, Paper, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { formatCurrency } from "../../../../common/utils/format";
import { Subheader } from "../../../../common/components/subheader";
import { GridNode } from "../../../../common/components/gridNode";
import { QueryPanel } from "../../../../common/components/queryPanel";

export const TransactionHeader = ({ transactionHeaderQueryDef, transactionHeaderQueryRef, onExecute }) => {
  const transactionsQuery = usePreloadedQuery(transactionHeaderQueryDef, transactionHeaderQueryRef);

  const account = transactionsQuery.myAccounts
    && transactionsQuery.myAccounts.length > 0
    && transactionsQuery.myAccounts[0];

  if (!account) {
    return (<Navigate to="/financials" />);
  }

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Overview</Typography>
        </Link>
        <Link component={LinkReact} underline="hover" color="inherit" to="/financials">
          <Typography variant="body2">Transactions</Typography>
        </Link>
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">{account.name}</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Transaction History</Typography>
      <Subheader name="Overview" />
      <Grid container item xs={12} component={Paper} sx={{ p: 2, pt: 1 }} rowSpacing={1}>
        <GridNode name="Balance" value={formatCurrency(account.accountBalance ? account.accountBalance.value * -1 : 0)} />
      </Grid>
      <Subheader name="Search" />
      <QueryPanel onExecute={onExecute} startOffset={-30} endOffset={0} />
    </>
  )
};