import React, { useState } from "react";
import { usePreloadedQuery } from "react-relay";
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { formatDateTime } from "../../../../common/utils/format";
import { ImportDetail } from "../../../components/import/ImportDetail";

export const ExtractionSourceMeasurementResults = ({ extractionSourceQueryDef, extractionSourceQueryRef, extractionSourceMeasurementsQueryDef, extractionSourceMeasurementsQueryRef }) => {
  const extractionSources = usePreloadedQuery(extractionSourceQueryDef, extractionSourceQueryRef);
  const extractionSourceMeasurements = usePreloadedQuery(extractionSourceMeasurementsQueryDef, extractionSourceMeasurementsQueryRef);
  const [ detail, setDetail ] = useState(null);

  const lookup = extractionSources && extractionSources.measurementQualityTypes
    ? extractionSources.measurementQualityTypes.reduce((acc, x) => ({ ...acc, [x.id]: x.name }), {})
    : {};

  return (
    <Grid container sx={{ mb: 10, mt: 2 }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>Flow Rate</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Quality</TableCell>
              <TableCell width={50}>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {extractionSourceMeasurements.extractionSourceMeasurements.map(x => (
              <TableRow key={x.id}>
                <TableCell>{formatDateTime(x.date)}</TableCell>
                <TableCell>{x.flowRate.toFixed(3)} l/s</TableCell>
                <TableCell>{x.total ? (<>{(x.total / 1000).toFixed(3)} k.m<sup>3</sup></>): ""}</TableCell>
                <TableCell>{x.measurementQualityType ? lookup[x.measurementQualityType.id] : ""}</TableCell>
                <TableCell>
                  <IconButton onClick={() => setDetail(x)}>
                    <InfoIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {detail && <ImportDetail importOperationId={detail.importOperation ? detail.importOperation.id : '00000000-0000-0000-0000-000000000000'}
        userId={detail.modifiedByUser ? detail.modifiedByUser.id : '00000000-0000-0000-0000-000000000000'}
        isOpen={!!detail} onClose={() => setDetail(null)} />}
    </Grid>
  );
};