import React, { useContext } from "react";
import { Breadcrumbs, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import { Link as LinkReact } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { ProfileContext } from "../../../common/contexts/profile";
import { HelpContext } from "../../../common/contexts/help";

const tours = [
  {
    title: "Make an offer",
    description: "See how to make an offer for water for a specific delivery period.",
    steps: [
      {
        content: "Click to open the navigation drawer.",
        target: ".nav .drawer-toggle",
        spotlightClicks: true,
        hideFooter: true,
        extensions: {
          isMobile: true
        }
      },
      {
        content: "Click to navigate to the overview page.",
        target: ".nav .overview",
        spotlightClicks: true,
        hideFooter: true,
        extensions: {
          isDesktop: true
        }
      },
      {
        content: "Click to navigate to the overview page.",
        target: ".nav-drawer .overview",
        spotlightClicks: true,
        hideFooter: true,
        extensions: {
          isMobile: true
        }
      },
      {
        content: "The overview page shows each of the water users you manage.",
        target: ".home .section h2"
      },
      {
        content: (<>In the "Next" section for each water user, the upcoming water delivery periods that you can place bids for are shown.<br /><br />Click on any of the delivery periods to drill down.</>),
        target: ".home .section .next",
        spotlightClicks: true,
        hideFooter: true
      },
      {
        content: "In the overview section, information about the current water delivery period is displayed. Only delivery periods without finalized clearings can be bid on.",
        target: ".clearing .overview",
        extensions: {
          waitForLink: /\/delivery-periods\/[\da-f-]+\/water-users\/[\da-f-]+/
        }
      },
      {
        content: "Under the results section, the most recent clearing for the delivery period is displayed. This section will be empty until the first clearing is run.",
        target: ".clearing .results"
      },
      {
        content: "Under the water availability forecast section, historical and forecast river levels are provided. The availability of water will be a key influence on the price.",
        target: ".clearing .river"
      },
      {
        content: "Under the offers section, the open offers for this water user for this water period are listed. The highest price offers will be filled first.",
        target: ".clearing .offers"
      },
      {
        content: "To add a new offer, press the + icon here. A maximum of four offers are permitted per water user, per delivery period. If this is exceeded, or the period has already been finalized, this button will be disabled.",
        target: ".clearing .offers .add-offer"
      },
      {
        content: "To edit or delete an offer, click on the pencil icon next to an existing offer.",
        target: ".clearing .offers .offers-summary"
      }
    ]
  }
];

tours.forEach(x => x.steps.forEach(y => {
  y.disableBeacon = true;
  y.hideBackButton = true;
}));

export const HelpPage = () => {
  const { profile } = useContext(ProfileContext);
  const { setIndex, setSteps } = useContext(HelpContext);

  const start = tour => {
    setSteps(tour.steps);
    setIndex(-1);
  };

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Overview</Typography>
        </Link>
        <Link underline="hover" color="inherit">
          <Typography variant="body2">Help</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Help</Typography>
      <Typography variant="body1" sx={{ pt: 2 }}>
        Click on any of the following items to start a guided tour:
      </Typography>
      <List>
        {tours
          .filter(tour => profile.isAdmin || !tour.isAdmin)
          .map((tour, index) => (
            <ListItem key={index} disablePadding component={Paper} sx={{ maxWidth: "750px" }}>
              <ListItemButton onClick={() => start(tour)}>
                <ListItemIcon>
                  <PlayCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={tour.title} secondary={tour.description} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </>
  );
};