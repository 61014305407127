import React from "react";
import { usePreloadedQuery } from "react-relay";
import { Link as LinkReact } from "react-router-dom";
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { WaterUsersTable } from "./WaterUsersTable";

export const WaterUsers = ({ waterUsersQueryDef, waterUsersQueryRef }) => {
  const waterUsersQuery = usePreloadedQuery(waterUsersQueryDef, waterUsersQueryRef);
  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Administration</Typography>
        </Link>
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">Water Users</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Water Users</Typography>
      <Grid container className="water-users" sx={{ mt: 2 }}>
        <WaterUsersTable waterUsers={waterUsersQuery.waterUsers} />
      </Grid>
    </>
  );
};