/**
 * @generated SignedSource<<71e4ba4092c624c70536f384eac36571>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type allocationsAllocationsQuery$variables = {|
  catchmentId: any,
  esId: any,
  wdpId: any,
|};
export type allocationsAllocationsQuery$data = {|
  +extractionSourceInputsForWaterDeliveryPeriodAtLatest: ?$ReadOnlyArray<?{|
    +actualGross: ?any,
    +actualNet: ?any,
    +date: any,
    +forecastGross: ?any,
    +forecastNet: ?any,
    +id: ?string,
  |}>,
  +extractionSources: ?$ReadOnlyArray<?{|
    +config: ?string,
    +id: ?string,
    +inputOffset: ?number,
    +name: ?string,
  |}>,
  +offerSummaries: ?$ReadOnlyArray<?{|
    +price: any,
    +volume: any,
  |}>,
  +waterDeliveryPeriods: ?$ReadOnlyArray<?{|
    +end: any,
    +exchangeClearings: ?$ReadOnlyArray<?{|
      +allocations: ?$ReadOnlyArray<?{|
        +clearingFee: any,
        +id: ?string,
        +spotPrice: any,
        +spotPricePreLoss: any,
        +totalNetCharge: any,
        +totalQuantity: any,
        +waterUserExtractionPoint: ?{|
          +extractionPoint: ?{|
            +id: ?string,
            +name: ?string,
          |},
          +id: ?string,
          +waterUser: ?{|
            +id: ?string,
            +name: ?string,
          |},
        |},
      |}>,
      +exchangeClearingType: ?{|
        +id: ?string,
        +isDelivered: ?boolean,
        +isFinal: boolean,
        +name: ?string,
      |},
      +id: ?string,
      +run: any,
    |}>,
    +id: ?string,
    +start: any,
  |}>,
|};
export type allocationsAllocationsQuery = {|
  response: allocationsAllocationsQuery$data,
  variables: allocationsAllocationsQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "catchmentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "esId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wdpId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = [
  {
    "kind": "Variable",
    "name": "waterDeliveryPeriodId",
    "variableName": "wdpId"
  }
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "eq",
                        "variableName": "wdpId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.0"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "eq",
                            "variableName": "catchmentId"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "catchment"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.1"
              }
            ],
            "kind": "ListValue",
            "name": "and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "WaterDeliveryPeriod",
    "kind": "LinkedField",
    "name": "waterDeliveryPeriods",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "isLatest": {
                "eq": true
              }
            }
          }
        ],
        "concreteType": "ExchangeClearing",
        "kind": "LinkedField",
        "name": "exchangeClearings",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "run",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExchangeClearingType",
            "kind": "LinkedField",
            "name": "exchangeClearingType",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isFinal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDelivered",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Allocation",
            "kind": "LinkedField",
            "name": "allocations",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalQuantity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "spotPricePreLoss",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "spotPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalNetCharge",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clearingFee",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WaterUserExtractionPoint",
                "kind": "LinkedField",
                "name": "waterUserExtractionPoint",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExtractionPoint",
                    "kind": "LinkedField",
                    "name": "extractionPoint",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WaterUser",
                    "kind": "LinkedField",
                    "name": "waterUser",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "exchangeClearings(where:{\"isLatest\":{\"eq\":true}})"
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v6/*: any*/),
    "concreteType": "OfferSummary",
    "kind": "LinkedField",
    "name": "offerSummaries",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "volume",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v6/*: any*/),
    "concreteType": "TemporalExtractionSourceInput",
    "kind": "LinkedField",
    "name": "extractionSourceInputsForWaterDeliveryPeriodAtLatest",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "forecastGross",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "forecastNet",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualGross",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualNet",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "eq",
                "variableName": "esId"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "ExtractionSource",
    "kind": "LinkedField",
    "name": "extractionSources",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "config",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "inputOffset",
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "allocationsAllocationsQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "allocationsAllocationsQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "9c9db8f15250588fca03330c04309ed1",
    "id": null,
    "metadata": {},
    "name": "allocationsAllocationsQuery",
    "operationKind": "query",
    "text": "query allocationsAllocationsQuery(\n  $catchmentId: UUID!\n  $wdpId: UUID!\n  $esId: UUID!\n) {\n  waterDeliveryPeriods(where: {and: [{id: {eq: $wdpId}}, {catchment: {id: {eq: $catchmentId}}}]}) {\n    id\n    start\n    end\n    exchangeClearings(where: {isLatest: {eq: true}}) {\n      id\n      run\n      exchangeClearingType {\n        id\n        name\n        isFinal\n        isDelivered\n      }\n      allocations {\n        id\n        totalQuantity\n        spotPricePreLoss\n        spotPrice\n        totalNetCharge\n        clearingFee\n        waterUserExtractionPoint {\n          id\n          extractionPoint {\n            id\n            name\n          }\n          waterUser {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n  offerSummaries(waterDeliveryPeriodId: $wdpId) {\n    price\n    volume\n  }\n  extractionSourceInputsForWaterDeliveryPeriodAtLatest(waterDeliveryPeriodId: $wdpId) {\n    id\n    date\n    forecastGross\n    forecastNet\n    actualGross\n    actualNet\n  }\n  extractionSources(where: {id: {eq: $esId}}) {\n    id\n    config\n    inputOffset\n    name\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "18d8b299739637059b723e8391afc3f4";

module.exports = ((node/*: any*/)/*: Query<
  allocationsAllocationsQuery$variables,
  allocationsAllocationsQuery$data,
>*/);
