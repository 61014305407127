import React, { Suspense, useContext, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { ForecastAccuracyHeader } from "./components/ForecastAccuracyHeader";
import { ForecastAccuracyResults } from "./components/ForecastAccuracyResults";
import { CenteredLoading, ResultsLoading } from "../../../../../common/components/loading";
import { ProfileContext } from "../../../../../common/contexts/profile";

const ForecastAccuracyQuery = graphql`
  query forecastAccuracyForecastAccuracyQuery($catchmentId: UUID!) {
    extractionSources(where: { catchment: { id: { eq: $catchmentId } } }, order: { name: ASC }) {
      id
      name
    }
  }
`;

const ForecastAccuracyResultsQuery = graphql`
  query forecastAccuracyForecastAccuracyResultsQuery($catchmentId: UUID!, $extractionSources: [UUID!], $end: DateTime!, $start: DateTime!) {
    extractionSourceInputHistories(where: { and: [ { catchmentId: { eq: $catchmentId } }, { extractionSourceId: { in: $extractionSources } }, { date: { gte: $start } }, { date: { lte: $end } } ] }, order: { date: ASC, periodStart: ASC }) {
      id
      originalId
      date
      extractionSourceId
      forecastGross
      forecastSync {
        id
        analysis
        sync
        reference
      }
      periodStart
      periodEnd
    }
    extractionSourceInputs(where: { and: [ { catchmentId: { eq: $catchmentId } }, { extractionSourceId: { in: $extractionSources } }, { date: { gte: $start } }, { date: { lte: $end } } ] }, order: { date: ASC }) {
      id
      date
      extractionSourceId
      actualGross
      actualSync {
        id
        analysis
        sync
        reference
      }
    }
  }
`;

export const ForecastAccuracyReportPage = () => {
  const [ forecastAccuracyQueryRef, loadForecastAccuracyQuery ] = useQueryLoader(ForecastAccuracyQuery);
  const [ forecastAccuracyResultsQueryRef, loadForecastAccuracyResultsQuery ] = useQueryLoader(ForecastAccuracyResultsQuery);
  const { catchmentId } = useContext(ProfileContext);

  useEffect(() => loadForecastAccuracyQuery({ catchmentId },
    { fetchPolicy: "network-only" }),
    [ catchmentId, loadForecastAccuracyQuery ]);

  const handleExecute = ({ start, end, extractionSources }) =>
    loadForecastAccuracyResultsQuery(
      { start: start.format(), end: end.format(), catchmentId, extractionSources },
      { fetchPolicy: "network-only" });

  return (
    <Suspense fallback={<CenteredLoading />}>
      {forecastAccuracyQueryRef && <>
        <ForecastAccuracyHeader forecastAccuracyQueryDef={ForecastAccuracyQuery} forecastAccuracyQueryRef={forecastAccuracyQueryRef}
          onExecute={handleExecute} />
        <Suspense fallback={<ResultsLoading />}>
          {forecastAccuracyResultsQueryRef &&
            <ForecastAccuracyResults forecastAccuracyResultsQueryDef={ForecastAccuracyResultsQuery} forecastAccuracyResultsQueryRef={forecastAccuracyResultsQueryRef} />}
        </Suspense>
      </>}
    </Suspense>
  );
};