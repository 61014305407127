import React from "react";
import graphql from "babel-plugin-relay/macro";
import EventIcon from "@mui/icons-material/Event";
import WaterIcon from '@mui/icons-material/Water';
import HelpIcon from "@mui/icons-material/Help";
import SettingsIcon from '@mui/icons-material/Settings';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import SavingsIcon from '@mui/icons-material/Savings';
import SummarizeIcon from '@mui/icons-material/Summarize';
import HomeIcon from "@mui/icons-material/Home";
import { HomePage } from "./pages/home";
import { ProfilePage } from "./pages/profile";
import { ClearingPage } from "./pages/clearing";
import { DeliveryPeriodsPage } from "./pages/deliveryPeriods";
import { DeliveryPeriodPage } from "./pages/deliveryPeriod";
import { ExtractionSourcesPage } from "./pages/extractionSources";
import { ExtractionSourcePage } from "./pages/extractionSource";
import { ExchangeClearingPage } from "./pages/exchangeClearing";
import { AllocationsPage } from "./pages/allocations";
import { HelpPage } from "./pages/help";
import { ConfigurationPage } from "./pages/configuration";
import { ClearingsPage } from "./pages/clearings";
import { WaterUsersPage } from "./pages/waterUsers";
import { WaterUserPage } from "./pages/waterUser";
import { AccountsPage } from "./pages/accounts";
import { AccountPage } from "./pages/account";
import { FinancialsPage } from "./pages/financials";
import { TransactionPage } from "./pages/transaction";
import { WaterUserExtractionPointPage } from "./pages/waterUserExtractionPoint";
import { ReportsPage } from "./pages/reports";
import { ActualDeliveriesReportPage } from "./pages/reports/pages/actualDeliveries";
import { ForecastAccuracyReportPage } from "./pages/reports/pages/forecastAccuracy";
import { Shell } from "../common/shell";
import { makePrivate } from "../common/utils/app";

const ProfileQuery = graphql`
  query AppProfileQuery {
    me {
      id
      name
      userIdentities {
        upn
        provider
      }
      userRoles {
        target
        role {
          code
          level
        }
      }
    }
    catchments(order: { name: ASC }) {
      id
      name
      timeZoneLinux
      nowOffset
      nowOffsetDisabled
      nowOverride
      nowOverrideDisabled
    }
  }
`;

const App = () => {
  const generalLinks = [
    { name: "Overview", link: "/", icon: <HomeIcon />, className: "overview" },
    { name: "Clearings", link: "/delivery-periods", icon: <ManageSearchIcon /> },
    { name: "Financials", link: "/financials", icon: <SavingsIcon /> }
  ];

  const adminLinks = [
    { name: "Accounts", link: "/admin/accounts", icon: <PointOfSaleIcon /> },
    { name: "Delivery Periods", link: "/admin/delivery-periods", icon: <EventIcon /> },
    { name: "Sources", link: "/admin/sources", icon: <WaterIcon /> },
    { name: "Water Users", link: "/admin/water-users", icon: <SupervisedUserCircleIcon /> },
    { name: "Reports", link: "/admin/reports", icon: <SummarizeIcon /> },
    { name: "Configuration", link: "/admin/configuration", icon: <SettingsIcon /> }
  ];

  const miscLinks = [
    { name: "Help", link: "/help", icon: <HelpIcon /> }
  ];

  const config = { title: "Wai Hā Pilot", generalLinks, adminLinks, miscLinks };

  const palette = {
    primary: {
      light: '#8cbdd8',
      main: '#035671',
      dark: '#002e44',
      contrastText: '#fff',
    },
    secondary: {
      light: '#86dffe',
      main: '#0699bf',
      dark: '#006478',
      contrastText: '#000',
    },
    error: {
      light: '#b06256',
      main: '#9d3214',
      dark: '#711f03'
    }
  };

  return (
    <Shell palette={palette} profileQuery={ProfileQuery}>
      {makePrivate("/", <HomePage />, false, null, config)}
      {makePrivate("/profile", <ProfilePage />, false, null, config)}
      {makePrivate("/delivery-periods", <ClearingsPage />, false, null, config)}
      {makePrivate("/delivery-periods/:wdpId/water-users/:wuepId", <ClearingPage />, false, null, { ...config, disableElevation: true })}
      {makePrivate("/delivery-periods/:wdpId/water-sources/:esId", <AllocationsPage />, false, null, config)}
      {makePrivate("/financials", <FinancialsPage />, false, null, config)}
      {makePrivate("/transactions/:aId", <TransactionPage />, false, null, config)}
      {makePrivate("/admin/delivery-periods", <DeliveryPeriodsPage />, true, null, config)}
      {makePrivate("/admin/delivery-periods/:wdpId", <DeliveryPeriodPage />, true, null, config)}
      {makePrivate("/admin/delivery-periods/:wdpId/clearings/:ecId", <ExchangeClearingPage />, true, null, config)}
      {makePrivate("/admin/sources", <ExtractionSourcesPage />, true, null, config)}
      {makePrivate("/admin/sources/:esId", <ExtractionSourcePage />, true, null, config)}
      {makePrivate("/admin/water-users", <WaterUsersPage />, true, null, config)}
      {makePrivate("/admin/water-users/:wuId", <WaterUserPage />, true, null, config)}
      {makePrivate("/admin/water-user-extraction-points/:wuepId", <WaterUserExtractionPointPage />, true, null, config)}
      {makePrivate("/admin/configuration", <ConfigurationPage />, true, null, config)}
      {makePrivate("/admin/accounts", <AccountsPage />, true, null, config)}
      {makePrivate("/admin/accounts/:aId", <AccountPage />, true, null, config)}
      {makePrivate("/admin/reports", <ReportsPage />, true, null, config)}
      {makePrivate("/admin/reports/actual-deliveries", <ActualDeliveriesReportPage />, true, null, config)}
      {makePrivate("/admin/reports/forecast-accuracy", <ForecastAccuracyReportPage />, true, null, config)}
      {makePrivate("/help", <HelpPage />, false, null, config)}
    </Shell>
  );
};

export default App;
