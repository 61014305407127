import React, { Suspense, useContext, useEffect, useMemo } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { Home } from "./components/Home";
import { CenteredLoading } from "../../../common/components/loading";
import { ProfileContext } from "../../../common/contexts/profile";

// TODO: only fetch the inputs required for the displayed clearings
const HomeQuery = graphql`
  query homeHomeWaterUsersQuery($catchmentId: UUID!, $start: DateTime!, $end: DateTime!, $esiStart: DateTime!, $esiEnd: DateTime!) {
    extractionSources(where: { catchment: { id: { eq: $catchmentId } } }) {
      id
      inputOffset
      extractionSourceInputs(where: { date: { gte: $esiStart, lte: $esiEnd } }) {
        id
        date
        forecastGross
        forecastNet
        actualGross
        actualNet
      }
    }
    waterDeliveryPeriods(where: { catchment: { id: { eq: $catchmentId } }, start: { lte: $end }, end: { gte: $start } }, order: { start: ASC }) {
      id
      start
      end
    }
    waterUsers(filterByCurrentUser: true, where: { catchment: { id: { eq: $catchmentId } } }, order: { publicId: ASC }) {
      id
      name
      waterUserExtractionPoints {
        id
        lossFactor
        allocations(where: { exchangeClearing: { and: [ { isLatest: { eq: true } }, { waterDeliveryPeriod: { start: { lte: $end }, end: { gte: $start } } } ] } }, order: { exchangeClearing: { run: DESC } }) {
          baseQuantity
          totalQuantity
          exchangeClearing {
            id
            exchangeClearingType {
              id
              isFinal
              isDelivered
            }
            waterDeliveryPeriod {
              id
            }
          }
        }
        extractionPoint {
          id
          name
          extractionSource {
            id
            config
            inputOffset
          }
        }
        offers(where: { waterDeliveryPeriod: { start: { lte: $end }, end: { gte: $start } } }) {
          id
          offerLines {
            id
            volume
          }
          waterDeliveryPeriod {
            id
          }
        }
        shares {
          id
          value
          apportionment {
            id
            value
          }
        }
      }
    }
  }
`;

export const HomePage = () => {
  const [ homeQueryRef, loadHomeQuery ] = useQueryLoader(HomeQuery);
  const { catchmentId, now } = useContext(ProfileContext);

  const start = useMemo(() => now({ ignoreTz: true }), [ now ]);
  const end = useMemo(() => start.clone().add(14, "days"), [ start ]);
  const esiStart = useMemo(() => start.clone().add(-7, "days"), [ start ]);
  const esiEnd = useMemo(() => end.clone().add(7, "days"), [ end ]);

  useEffect(() => loadHomeQuery(
    { catchmentId, end: end.format(), esiEnd: esiEnd.format(), esiStart: esiStart.format(), start: start.format() },
    { fetchPolicy: "network-only" }),
    [ loadHomeQuery, catchmentId, end, esiEnd, esiStart, start ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {homeQueryRef && <Home homeQueryDef={HomeQuery} homeQueryRef={homeQueryRef} now={start} />}
    </Suspense>
  );
};