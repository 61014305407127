import React, { useContext, useMemo, useState, Suspense } from "react";
import { Alert, Grid, Paper, useTheme } from "@mui/material";
import { useFeatures } from "flagged";
import Plot from "react-plotly.js";
import { GridNode } from "../../../../common/components/gridNode";
import { add, chain, divide, multiply, subtract } from "../../../../common/utils/math";
import { formatCurrency, formatVolume } from "../../../../common/utils/format";
import { useDefaultConfig, useDefaultLayout, useDefaultLabelStyle, useIsLightTheme, useConvert } from "../../../utils/charts";
import { ChartTitle } from "../../../components/chartTitle";
import { ResultUserChartDownload } from "./ResultUserChartDownload";
import { ProfileContext } from "../../../../common/contexts/profile";

export const ResultUserChart = ({ wdpId, wuepId, offerSummariesByWaterUserExtractionPoint, totalInput, lossFactor, allocation, constraint, showIntercept }) => {
  const theme = useTheme();
  const { debugCharts, debugSdChart } = useFeatures();
  const [ dynamicPlot, setDynamicPlot ] = useState(false);
  const profileContext = useContext(ProfileContext);

  const isLightTheme = useIsLightTheme();
  const defaultConfig = useDefaultConfig({ staticPlot: !dynamicPlot });
  const defaultLayout = useDefaultLayout();
  const defaultLabelStyle = useDefaultLabelStyle();
  const convert = useConvert();

  const [ data, userPrice, userVolume ] = useMemo(() => {
    const baseline = divide(totalInput, lossFactor);

    const mapToFarm = x => ({ ...x, price: multiply(x.price, lossFactor), volume: divide(x.volume, lossFactor) });
    const userSummaries = offerSummariesByWaterUserExtractionPoint.filter(x => x.isUser).map(mapToFarm) || [];
    const othersSummaries = offerSummariesByWaterUserExtractionPoint.filter(x => !x.isUser).map(mapToFarm) || [];
    const maxPrice = Math.max.apply(null, userSummaries.map(x => x.price).concat(othersSummaries.map(x => x.price)));

    if (debugCharts) {
      console.log("User summaries", userSummaries);
    }

    const userData = userSummaries.reduce((result, { price, volume }) => {
      result.push({ price, volume: result.length === 0 ? volume : add(volume, result[result.length - 1].volume) });
      return result;
    }, []);

    if (debugCharts) {
      console.log("User data", userData);
    }

    const userSeries = userData.reduce((result, { price, volume }) => {
      result.x.push(result.x.length === 0 ? 0 : result.x[result.x.length - 1]);
      result.x.push(volume);
      result.y.push(price);
      result.y.push(price);
      return result;
    }, {
      x: [],
      y: [],
      mode: "lines+markers",
      name: "User demand",
      line: {
        color: theme.palette.primary.main
      },
      hovertemplate: "Volume: <b>%{x:.2f}k.m³</b>, Price: <b>%{y:$.3f}</b><extra></extra>",
      hoverlabel: defaultLabelStyle
    });
    if (userSeries.x.length > 0) {
      userSeries.x.push(userSeries.x[userSeries.x.length - 1]);
      userSeries.y.push(0);
    }

    if (debugCharts) {
      console.log("User series", userSeries);
    }

    const totalOthersAllocation = othersSummaries.reduce((p, x) => p.add(x.volume), chain(0)).done();

    if (debugCharts) {
      console.log("Others summaries", othersSummaries);
    }

    const othersData = othersSummaries.reduce((result, { price, volume }) => {
      const newVolume = subtract(result.length === 0 ? baseline : result[result.length - 1].volume, volume);
      result.push({ price, volume: newVolume });
      return result;
    }, []);

    if (debugCharts) {
      console.log("Others data", othersData);
    }

    const othersSeries = othersData.reduce((result, { price, volume }) => {
      if (totalOthersAllocation <= 0) {
        return result;
      }

      result.x.push(result.x.length === 0 ? baseline : result.x[result.x.length - 1]);
      result.x.push(volume);
      result.y.push(price);
      result.y.push(price);
      return result;
    }, {
      x: [],
      y: [],
      mode: "lines+markers",
      name: "Others' supply",
      line: {
        color: theme.palette.primary.light
      },
      hovertemplate: "Volume: <b>%{x:.2f}k.m³</b>, Price: <b>%{y:$.3f}</b><extra></extra>",
      hoverlabel: defaultLabelStyle
    });
    if (othersSeries.x.length > 0) {
      othersSeries.x.push(othersSeries.x[othersSeries.x.length - 1]);
      othersSeries.y.push(0);
    };

    if (debugCharts) {
      console.log("Others series", othersSeries);
    }

    const availableSeries = {
      x: [ baseline, baseline ],
      y: [ 0, maxPrice ],
      mode: "lines+markers",
      name: "Available supply",
      line: {
        color: isLightTheme ? theme.palette.grey[400] : theme.palette.grey[100]
      },
      hovertemplate: "Volume: <b>%{x:.2f}k.m³</b><extra></extra>",
      hoverlabel: defaultLabelStyle
    };

    const data = [];
    let userPrice = null;
    let userVolume = null;

    data.push(availableSeries);
    if (othersSeries.x.length > 0) {
      data.push(othersSeries);
    }
    if (userSeries.x.length > 0) {
      data.push(userSeries);
    }

    if (userSeries.x.length > 0 && othersSeries.x.length > 0 && showIntercept) {
      const interceptSeries = userSeries.x.reduce((p, c, i) => {
        // Skip the last point
        if (i + 1 >= userSeries.x.length) {
          return p;
        }

        // If p is defined, then we've already identified the intercept
        if (!p) {
          const volume = c;
          const price = userSeries.y[i];
          const nextVolume = userSeries.x[i + 1];
          const nextPrice = userSeries.y[i + 1];
          let match = null;

          // If price doesn't change, then we are looking for other data to intercept vertically
          if (price === nextPrice) {
            for (let j = 0; j < othersSeries.x.length; j++) {
              if (j + 1 >= othersSeries.x.length || othersSeries.x[j] !== othersSeries.x[j + 1]) {
                continue;
              }

              if (othersSeries.y[j] > price && othersSeries.y[j + 1] < price &&
                othersSeries.x[j] > volume && othersSeries.x[j] < nextVolume) {
                match = { x: othersSeries.x[j], y: price };
                break;
              }
            }
          }
          // If volume doesn't change, then we are looking for other data to intercept horizontally
          else if (volume === nextVolume) {
            for (let j = 0; j < othersSeries.x.length; j++) {
              if (j + 1 >= othersSeries.x.length || othersSeries.y[j] !== othersSeries.y[j + 1]) {
                continue;
              }

              if (othersSeries.x[j] > volume && othersSeries.x[j + 1] < volume &&
                  othersSeries.y[j] < price && othersSeries.y[j] > nextPrice) {
                match = { x: volume, y: othersSeries.y[j] };
                break;
              }
            }
          }
          // This shouldn't happen 
          else {
            console.warn("User series is not moving as a step");
          }

          if (match) {
            userVolume = match.x;
            userPrice = match.y;
            p = {
              x: [ match.x ],
              y: [ match.y ],
              mode: "markers",
              name: "Clearing",
              marker: {
                color: theme.palette.error.main,
                size: 15,
                symbol: "star"
              },
              showlegend: false,
              hovertemplate: "Exchange clearing point (volume: <b>%{x:.2f}k.m³</b>, price: <b>%{y:$.3f}</b>)<extra></extra>",
              hoverlabel: defaultLabelStyle
            };
          }
        }

        return p;
      }, null);

      if (interceptSeries) {
        data.push(interceptSeries);
      }
    }

    return [ data, userPrice, userVolume ];
  }, [ debugCharts, defaultLabelStyle, lossFactor, offerSummariesByWaterUserExtractionPoint, totalInput, theme, isLightTheme, showIntercept ]);

  const [ download, setDownload ] = useState(false);

  const isMismatch = (userPrice > 0 && Math.abs(userPrice - allocation.spotPrice) >= 0.01)
    || (userVolume > 0 && Math.abs(userVolume - allocation.totalQuantity) >= 1);

  return (
    <>
      {constraint && !constraint.isTargetConstraint && constraint.isAnyConstrained
        && <Alert severity="warning">This chart may be misleading due to race congestion. Your price may be lower and you may get more water.</Alert>}
      {constraint && constraint.isTargetConstraint
        && <Alert severity="warning">This chart may be misleading due to race congestion. Your price may be higher and you may get less water.</Alert>}
      {constraint && !constraint.isAnyConstrained && !constraint.isTargetConstraint && isMismatch
        && <Alert severity="warning">This chart may be misleading as the cleared price and/or volume does not align. Refer to the Clearing Outputs below the chart for your actual position.</Alert>}
      <Grid container item component={Paper} xs={12} sx={{ mt: 1 }}>
        <ChartTitle checked={dynamicPlot} onChange={setDynamicPlot} title="User demand and others' supply" onDownload={profileContext.profile.isAdmin ? () => setDownload(true) : null} />
        <Grid item xs={12} style={{ height: 400 }}>
          {data &&
            <Plot
              config={defaultConfig}
              data={data}
              layout={{
                ...defaultLayout,
                xaxis: {
                  gridcolor: isLightTheme ? undefined : theme.palette.grey[800],
                  rangemode: "nonnegative",
                  ticks: "outside",
                  title: {
                    font: {
                      size: convert(theme.typography.body2.fontSize)
                    },
                    text: "volume (k.m³)"
                  }
                },
                yaxis: {
                  gridcolor: isLightTheme ? undefined : theme.palette.grey[800],
                  rangemode: "tozero",
                  ticks: "outside",
                  title: {
                    font: {
                      size: convert(theme.typography.body2.fontSize)
                    },
                    text: "price ($)"
                  }
                }
              }}
              style={{ display: "block" }}
              useResizeHandler={true}
            />}
        </Grid>
        {debugSdChart && userPrice && userVolume &&
          <Grid container item xs={12} sx={{ m: 2, mt: 1 }}>
            <GridNode name="Total to arrive" value={formatVolume(userVolume)} isDense />
            <GridNode name="Price" value={<>{formatCurrency(userPrice, 3)}/k.m<sup>3</sup></>} isDense />
          </Grid>}
      </Grid>
      {wdpId && download && <Suspense fallback={<></>}>
        <ResultUserChartDownload wdpId={wdpId} wuepId={wuepId} totalInput={totalInput} onComplete={() => setDownload(false)} />
      </Suspense>}
    </>
  );
};

ResultUserChart.defaultProps = {
  wdpId: null,
  wuepId: null
};