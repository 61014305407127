import React, { Suspense, useContext, useEffect } from "react";
import { useQueryLoader } from "react-relay";
import { useParams } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { CenteredLoading } from "../../../common/components/loading";
import { Allocations } from "./components/Allocations";
import { ProfileContext } from "../../../common/contexts/profile";

const AllocationsQuery = graphql`
  query allocationsAllocationsQuery($catchmentId: UUID!, $wdpId: UUID!, $esId: UUID!) {
    waterDeliveryPeriods(where: { and: [ { id: { eq: $wdpId } }, { catchment: { id: { eq: $catchmentId } } } ] }) {
      id
      start
      end
      exchangeClearings(where: { isLatest: { eq: true } }) {
        id
        run
        exchangeClearingType {
          id
          name
          isFinal
          isDelivered
        }
        allocations {
          id
          totalQuantity
          spotPricePreLoss
          spotPrice
          totalNetCharge
          clearingFee
          waterUserExtractionPoint {
            id
            extractionPoint {
              id
              name
            }
            waterUser {
              id
              name
            }
          }
        }
      }
    }
    offerSummaries(waterDeliveryPeriodId: $wdpId) {
      price
      volume
    }
    extractionSourceInputsForWaterDeliveryPeriodAtLatest(waterDeliveryPeriodId: $wdpId) {
      id
      date
      forecastGross
      forecastNet
      actualGross
      actualNet
    }
    extractionSources(where: { id: { eq: $esId } }) {
      id
      config
      inputOffset
      name
    }
  }
`;

export const AllocationsPage = () => {
  const { wdpId, esId } = useParams();
  const [ allocationsQueryRef, loadAllocationsQuery ] = useQueryLoader(AllocationsQuery);
  const { catchmentId } = useContext(ProfileContext);
  useEffect(() => loadAllocationsQuery({ catchmentId, wdpId, esId }, { fetchPolicy: "network-only" }),
    [ catchmentId, loadAllocationsQuery, wdpId, esId ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {allocationsQueryRef && <Allocations allocationsQueryDef={AllocationsQuery} allocationsQueryRef={allocationsQueryRef} />}
    </Suspense>
  );
};