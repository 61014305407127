import React, { Suspense, useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import moment from "moment";
import "moment-timezone";
import { formatDateTime } from "../../../common/utils/format";
import { ProfileContext } from "../../../common/contexts/profile";
import { WizardLoading } from "../../../common/components/loading";

const ImportOperationQuery = graphql`
  query ImportDetailImportOperationQuery($importOperationId: UUID!, $userId: UUID!) {
    importOperation(id: $importOperationId) {
      id
      comment
      fileName
      periodStart
    }
    user(id: $userId) {
      id
      name
    }
  }
`;

const InnerImportDetail = ({ importOperationId, userId }) => {
  const data = useLazyLoadQuery(ImportOperationQuery, { importOperationId, userId }, { fetchPolicy: "network-only" });
  const { timeZone } = useContext(ProfileContext);
  return (
    <>
      {data && data.user &&
        <>
          <Typography sx={{ mt: 1 }} variant="body1">{data.user.name}</Typography>
          <Typography variant="caption">Created by</Typography>
        </>}
      {data && data.importOperation &&
        <>
          <Typography sx={{ mt: 1 }} variant="body1">{formatDateTime(moment.parseZone(data.importOperation.periodStart).tz(timeZone))}</Typography>
          <Typography variant="caption">Created date</Typography>
          <Typography sx={{ mt: 1 }} variant="body1">{data.importOperation.fileName}</Typography>
          <Typography variant="caption">File name</Typography>
          {data.importOperation.comment &&
            <>
              <Typography sx={{ mt: 1 }} variant="body1">{data.importOperation.comment}</Typography>
              <Typography variant="caption">Comments</Typography>
            </>}
        </>}
    </>
  )
};

export const ImportDetail = ({ importOperationId, userId, isOpen, onClose }) => (
  <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
    <DialogTitle>Details</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Measurement record details.
      </DialogContentText>
      <Suspense fallback={<WizardLoading />}>
        <InnerImportDetail importOperationId={importOperationId} userId={userId} />
      </Suspense>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);