import moment from "moment";
import { chain, divide, multiply } from "../../common/utils/math";
import { calculateNet } from "./water";
import { roundStartDate, roundEndDate } from "../../common/utils/date";

export const getApportionment = (wuep) =>
  wuep.shares.reduce((p, c) => p.add(multiply(c.value, c.apportionment.value)), chain(0)).done();

export const getAllowance = (wdp, wuep, es, esi, isDelivered) => {
  const totalInput = getTotalEsiForWdp(es, esi, wdp, isDelivered);
  if (totalInput === null) {
    return null;
  }

  const lossAdjustedInput = wuep.lossFactor
    ? divide(totalInput, wuep.lossFactor)
    : totalInput;

  const totalApportionment = getApportionment(wuep);
  return multiply(totalApportionment, lossAdjustedInput);
};

export const getTotalEsiForWdpDetailed = (es, esi, wdp, isDelivered) => {
  const prepDate = x => {
    let d = moment.parseZone(x);
    if (es.inputOffset) {
      d = d.subtract(moment.duration(es.inputOffset, 'seconds'));
    }
    return d;
  };
  const result = esi
    .filter(x => moment.parseZone(x.date).isSameOrAfter(roundStartDate(prepDate(wdp.start)))
      && moment.parseZone(x.date).isSameOrBefore(roundEndDate(prepDate(wdp.end))))
    .reduce((p, x) => {
      // If it's delivered, prefer actuals.
      // If it's not yet delivered, then prefer actuals for the first day (as this will be known at final clearing time), otherwise use forecast.
      const isFirstDay = moment.parseZone(x.date).isSame(roundStartDate(prepDate(wdp.start)));
      const gross = isDelivered || isFirstDay ? (x.actualGross != null ? x.actualGross : x.forecastGross) : x.forecastGross;
      const net = isDelivered || isFirstDay ? (x.actualNet != null ? x.actualNet : x.forecastNet) : x.forecastNet;
      const calcNet = calculateNet(es.config, gross, net) || 0;
      p.net = p.net.add(calcNet);
      p.count = p.count + 1;
      p.details.push({ date: x.date, net: calcNet });
      return p;
    }, { net: chain(0), count: 0, details: [] });
  const duration = moment.parseZone(wdp.end).diff(moment.parseZone(wdp.start), 'days');
  return duration === result.count ? { ...result, net: result.net.done() } : null;
};

export const getTotalEsiForWdp = (es, esi, wdp, isDelivered) => {
  const result = getTotalEsiForWdpDetailed(es, esi, wdp, isDelivered);
  return result ? result.net : null;
};

export const getStatsItems = (allocation, offer, wdp, wuep, es, esi, isDelivered) => {
  const allowance = getAllowance(wdp, wuep, es, esi, isDelivered);
  const request = offer ? offer.offerLines.reduce((p, x) => p.add(x.volume), chain(0)).done() : null;
  const grant = allocation ? allocation.totalQuantity : null;
  return [
    { num: allowance ? allowance.toFixed(2) : allowance, label: "Impact Allowance" },
    { num: request ? request.toFixed(2) : request, label: "Total Under Offer" },
    { num: grant ? grant.toFixed(2) : grant, label: "Cleared Quantity",
      direction: allowance === grant || grant == null || allowance == null
        ? null
        : (grant >= allowance ? "up" : "down") }
  ];
};