import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { formatCurrency, formatVolume } from "../../../../common/utils/format";

export const AllocationsTable = ({ allocations }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Water User ID</TableCell>
          <TableCell>Water User Name</TableCell>
          <TableCell>Extraction Point</TableCell>
          <TableCell>Base Quantity (pre-loss)</TableCell>
          <TableCell>Base Quantity</TableCell>
          <TableCell>Total Quantity (pre-loss)</TableCell>
          <TableCell>Total Quantity</TableCell>
          <TableCell>Change Quantity</TableCell>
          <TableCell>Farm Congestion</TableCell>
          <TableCell>Quantity Disposed Credit (pre-loss)</TableCell>
          <TableCell>Quantity Obtained Charge</TableCell>
          <TableCell>Race Congestion Charge</TableCell>
          <TableCell>Race Congestion Credit</TableCell>
          <TableCell>Total Gross Charge</TableCell>
          <TableCell>Total Net Charge</TableCell>
          <TableCell>Spot Price (pre-loss)</TableCell>
          <TableCell>Spot Price</TableCell>
          <TableCell>Farm Congestion Price</TableCell>
          <TableCell>Race Congestion Price</TableCell>
          <TableCell>Effective Gross Price</TableCell>
          <TableCell>Effective Net Price</TableCell>
          <TableCell>Clearing Fee</TableCell>
          <TableCell>Constraint</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {allocations.map(x => (
          <TableRow key={x.id}>
            <TableCell>{x.waterUserExtractionPoint.waterUser.publicId}</TableCell>
            <TableCell>{x.waterUserExtractionPoint.waterUser.name}</TableCell>
            <TableCell>{x.waterUserExtractionPoint.extractionPoint.name}</TableCell>
            <TableCell>{formatVolume(x.baseQuantityPreLoss)}</TableCell>
            <TableCell>{formatVolume(x.baseQuantity)}</TableCell>
            <TableCell>{formatVolume(x.totalQuantityPreLoss)}</TableCell>
            <TableCell>{formatVolume(x.totalQuantity)}</TableCell>
            <TableCell>{formatVolume(x.changeQuantity)}</TableCell>
            <TableCell>{x.farmSurcharge}</TableCell>
            <TableCell>{formatCurrency(x.quantityDisposedCreditPreLoss)}</TableCell>
            <TableCell>{formatCurrency(x.quantityObtainedCharge)}</TableCell>
            <TableCell>{formatCurrency(x.raceCongestionCharge)}</TableCell>
            <TableCell>{formatCurrency(x.raceCongestionCredit)}</TableCell>
            <TableCell>{formatCurrency(x.totalGrossCharge)}</TableCell>
            <TableCell>{formatCurrency(x.totalNetCharge)}</TableCell>
            <TableCell>{formatCurrency(x.spotPricePreLoss, 3)}</TableCell>
            <TableCell>{formatCurrency(x.spotPrice, 3)}</TableCell>
            <TableCell>{formatCurrency(x.farmCongestionPrice, 3)}</TableCell>
            <TableCell>{formatCurrency(x.raceCongestionPrice, 3)}</TableCell>
            <TableCell>{formatCurrency(x.effectiveGrossPrice, 3)}</TableCell>
            <TableCell>{formatCurrency(x.effectiveNetPrice, 3)}</TableCell>
            <TableCell>{formatCurrency(x.clearingFee)}</TableCell>
            <TableCell>{x.constraint}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);