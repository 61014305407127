import React, { useCallback, useEffect, useMemo } from "react";
import { usePreloadedQuery } from "react-relay";
import { Alert, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import moment from "moment";
import { getAllowance, getTotalEsiForWdp } from "../../../utils/stats";
import { multiply } from "../../../../common/utils/math";

const maxOfferLines = 4;

export const ClearingOffersTable = ({ autoBid, clearingOffersQueryDef, clearingOffersQueryRef, onProgressChange, type, types }) => {
  const clearingOffersQuery = usePreloadedQuery(clearingOffersQueryDef, clearingOffersQueryRef);
  useEffect(() => onProgressChange(false), [ clearingOffersQuery, onProgressChange ]);

  const isDelivered = useMemo(() => {
    const t = types.find(x => x.id === type);
    return t && t.isDelivered;
  }, [ type, types ]);

  const minimumBidPrice = useMemo(() => clearingOffersQuery.catchments && clearingOffersQuery.catchments.length > 0
    ? clearingOffersQuery.catchments[0].minimumBidPrice
    : 0, [ clearingOffersQuery ]);
    
  const maxLossFactor = useMemo(() => clearingOffersQuery.waterUserExtractionPointsAt && clearingOffersQuery.waterUserExtractionPointsAt.length > 0
    ? clearingOffersQuery.waterUserExtractionPointsAt
      .filter(x => !x.waterUser.isRecurringDefaultBidder && !x.waterUser.isDemandOnly && !x.waterUser.isSystemActor)
      .reduce((p, x) => x.lossFactor > p ? x.lossFactor : p, 0)
    : 1, [ clearingOffersQuery ]);

  const maximumBidPrice = useMemo(() => multiply(
    clearingOffersQuery.waterUserExtractionPointsAt
      .reduce((p, x) => {
        if (x.offers && x.offers.length > 0 && x.offers[0].offerLines && x.offers[0].offerLines.length > 0) {
          const max = x.offers[0].offerLines.reduce((q, c) => c.price > q ? c.price : q, 0);
          if (max > p) {
            p = max;
          }
        }
        return p;
      }, 0),
      maxLossFactor),
      [ clearingOffersQuery, maxLossFactor ]);

  const wdp = useMemo(() => clearingOffersQuery.waterDeliveryPeriods && clearingOffersQuery.waterDeliveryPeriods.length > 0
    ? clearingOffersQuery.waterDeliveryPeriods[0]
    : null, [ clearingOffersQuery ]);

  const inputs = useMemo(() => {
    const es = clearingOffersQuery.extractionSourcesAt;
    const esi = clearingOffersQuery.extractionSourceInputsForWaterDeliveryPeriodAt;
    if (!es || !esi) {
      return [];
    }

    const groupedEsi = esi.reduce((p, c) => {
      if (c.extractionSource) {
        const currentEs = c.extractionSource.id;
        if (!p[currentEs]) {
          p[currentEs] = [];
        }

        p[currentEs].push(c);
      }

      return p;
    }, {});

    return Object.keys(groupedEsi).reduce((p, c) => {
      const match = es.find(x => x.id.split('_')[0] === c);
      if (match) {
        const currentCount = groupedEsi[c].length;
        const f = getTotalEsiForWdp(match, groupedEsi[c], wdp, false);
        const a = getTotalEsiForWdp(match, groupedEsi[c], wdp, true);
        p[c] = {
          forecast: f === null ? "" : f.toFixed(2),
          actual: a === null ? "" : a.toFixed(2),
          count: currentCount
        };
      }

      return p;
    }, {});
  }, [ clearingOffersQuery, wdp ]);

  const isMissingInputs = useMemo(() => {
    if (wdp) {
      const start = moment.parseZone(wdp.start);
      const end = moment.parseZone(wdp.end);
      const duration = end.diff(start, "days");
      return Object.keys(inputs).some(x => inputs[x].count < duration);
    }

    return false;
  }, [ wdp, inputs ]);

  const getFirstFourOfferLines = useCallback((wuep) => {
    const offers = wuep.offers;
    const wu = wuep.waterUser;
    const ep = wuep.extractionPoint;
    let lines = [];
    if (offers && offers.length > 0 && offers[0].offerLines.length > 0) {
      lines = offers[0].offerLines;
    } else if (wu && wu.isRecurringDefaultBidder) {
      lines = [ { volume: inputs[ep.extractionSource.id] ? (isDelivered ? inputs[ep.extractionSource.id].actual : inputs[ep.extractionSource.id].forecast) : 0, price: minimumBidPrice } ];
    } else if (autoBid && wu && !wu.isRecurringDefaultBidder && !wu.isDemandOnly && !wu.isSystemActor) {
      const es = clearingOffersQuery.extractionSourcesAt;
      const esi = clearingOffersQuery.extractionSourceInputsForWaterDeliveryPeriodAt;
      if (es && es.length > 0 && esi) {
        const volume = getAllowance(wdp, wuep, es[0], esi, false);
        if (volume) {
          lines = [ { volume: volume.toFixed(2), price: maximumBidPrice } ];
        }
      }
    }
    return [...Array(maxOfferLines).keys()]
      .map(x => x >= lines.length ? { id: x, volume: null, price: null } : lines[x]);
  }, [ autoBid, clearingOffersQuery, inputs, isDelivered, maximumBidPrice, minimumBidPrice, wdp ]);

  const copy = () => {
    const getVolume = (x) => getFirstFourOfferLines(x).reduce((p, c) => {
      p = p + `\t${c.volume == null ? '' : c.volume}`;
      return p;
    }, "");
    const getPrice = (x) => getFirstFourOfferLines(x).reduce((p, c) => {
      p = p + `\t${c.price == null ? '' : c.price}`;
      return p;
    }, "");
    const base = inputs
      ? Object.keys(inputs).map(x => `0\t\t${[...Array(maxOfferLines).keys()].map(() => '\t').join('')}\t${inputs[x].forecast}\t${isDelivered ? inputs[x].actual : ''}${[...Array(maxOfferLines - 2).keys()].map(() => '\t').join('')}`)
      : [];
    const content = clearingOffersQuery.waterUserExtractionPointsAt.reduce((p, x) => {
      p.push(`${x.waterUser.publicId}\t${x.waterUser.name}\t${x.extractionPoint.name}${getPrice(x)}${getVolume(x)}`);
      return p;
    }, base);
    navigator.clipboard.writeText(content.join("\n"));
  };

  const columns = useMemo(() => {
    const cols = [
      { field: "waterUserExtractionPoint-waterUser-publicId", headerName: "Water User ID", flex: 0.05 },
      { field: "waterUserExtractionPoint-waterUser-name", headerName: "Water User Name", flex: 0.1 },
      { field: "waterUserExtractionPoint-extractionPoint-name", headerName: "Extraction Point", flex: 0.05 }
    ];

    [...Array(maxOfferLines).keys()].forEach(x => cols.push({ field: `offerLine-${x}-price`, headerName: `Price ${x+1}`, flex: 0.1 }));
    [...Array(maxOfferLines).keys()].forEach(x => cols.push({ field: `offerLine-${x}-volume`, headerName: `Volume ${x+1}`, flex: 0.1 }));

    cols.forEach(x => x.sortable = false);
    return cols;
  }, []);

  const rows = useMemo(() => {
    let id = 0;
    const base = inputs
      ? Object.keys(inputs).map(x => ({ id: id++, "waterUserExtractionPoint-waterUser-publicId": 0, "offerLine-0-volume": inputs[x].forecast, "offerLine-1-volume": isDelivered ? inputs[x].actual : null }))
      : [];
    return clearingOffersQuery.waterUserExtractionPointsAt.reduce((b, x) => {
      const offerLines = getFirstFourOfferLines(x);
      b.push({
        id: id++,
        "waterUserExtractionPoint-waterUser-publicId": x.waterUser.publicId,
        "waterUserExtractionPoint-waterUser-name": x.waterUser.name,
        "waterUserExtractionPoint-extractionPoint-name": x.extractionPoint.name,
        ...offerLines.reduce((p, c, i) => {
          p[`offerLine-${i}-price`] = c.price;
          return p;
        }, {}),
        ...offerLines.reduce((p, c, i) => {
          p[`offerLine-${i}-volume`] = c.volume;
          return p;
        }, {})
      });
      return b;
    }, base);
  }, [ clearingOffersQuery, getFirstFourOfferLines, isDelivered, inputs ]);

  return (
    <>
      {isMissingInputs && <Alert severity="warning">One or more extraction sources are missing forecast volumes for this water delivery period.</Alert>}
      <Button onClick={copy} size="small" startIcon={<CopyAllIcon />} sx={{ mb: 1, mt: 1 }}>
        Copy
      </Button>
      <div style={{ display: "flex", minHeight: "200px", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid autoHeight sx={{ ".MuiDataGrid-footerContainer": { display: "none" } }}
            columns={columns} rows={rows} disableColumnMenu />
        </div>
      </div>
    </>
  );
};