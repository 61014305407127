import React, { useContext, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation, usePreloadedQuery } from "react-relay";
import { Fab, Grid, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DeliveryPeriodsTable } from "./DeliveryPeriodsTable";
import { DeliveryPeriodDialog } from "./DeliveryPeriodDialog";
import { ProfileContext } from "../../../../common/contexts/profile";
import { NotificationContext } from "../../../../common/contexts/notification";

const CreateWaterDeliveryPeriodMutation = graphql`
  mutation DeliveryPeriodsResultsCreateWaterDeliveryPeriodMutation($input: CreateWaterDeliveryPeriodInput!) {
    createWaterDeliveryPeriod(input: $input) {
      waterDeliveryPeriod {
        id
        start
        end
        close
        isSeasonStart
      }
    }
  }
`;

const UpdateDeliveryPeriodMutation = graphql`
  mutation DeliveryPeriodsResultsUpdateDeliveryPeriodMutation($input: UpdateWaterDeliveryPeriodInput!) {
    updateWaterDeliveryPeriod(input: $input) {
      waterDeliveryPeriod {
        id
        start
        end
        close
        isSeasonStart
      }
    }
  }
`;

const DeleteDeliveryPeriodMutation = graphql`
  mutation DeliveryPeriodsResultsDeleteDeliveryPeriodMutation($input: DeleteWaterDeliveryPeriodInput!) {
    deleteWaterDeliveryPeriod(input: $input) {
      waterDeliveryPeriod {
        id @deleteRecord
      }
    }
  }
`;

const updateDeliveryPeriods = (store, catchmentId, start, end, toAdd, toRemove) => {
  const linkName = "waterDeliveryPeriods";
  const linkFilter = { where: { and: [ { start: { lte: end.format() } }, { end: { gte: start.format() } }, { catchment: { id: { eq: catchmentId } } } ] }, order: { start: "ASC" } };

  let wdps = store.getRoot().getLinkedRecords(linkName, linkFilter);
  if (toAdd) {
    wdps = [...wdps, toAdd];
  }
  if (toRemove) {
    wdps = wdps.filter(x => !!x);
  }
  wdps.sort((a, b) => a.getValue("start").localeCompare(b.getValue("start")));
  store.getRoot().setLinkedRecords(wdps, linkName, linkFilter);
};

export const DeliveryPeriodsResults = ({ deliveryPeriodsQueryDef, deliveryPeriodsQueryRef, catchmentId, start, end }) => {
  const deliveryPeriodsQuery = usePreloadedQuery(deliveryPeriodsQueryDef, deliveryPeriodsQueryRef);
  const profileContext = useContext(ProfileContext);
  const [ commitCreateWaterDeliveryPeriod ] = useMutation(CreateWaterDeliveryPeriodMutation);
  const [ commitUpdateDeliveryPeriod ] = useMutation(UpdateDeliveryPeriodMutation);
  const [ commitDeleteWaterDeliveryPeriod ] = useMutation(DeleteDeliveryPeriodMutation);
  const theme = useTheme();
  const { setSuccess, setError } = useContext(NotificationContext);

  const [ open, setOpen ] = useState(false);
  const [ target, setTarget ] = useState(null);

  const onCompleted = e => setSuccess("Water delivery period updated successfully");
  const onError = e => setError("Water delivery period failed to update", e);

  const handleAdd = () => {
    setTarget(null);
    setOpen(true);
  };

  const handleEdit = item => {
    setTarget(item);
    setOpen(true);
  };

  const handleSave = item => {
    setOpen(false);
    if (target) {
      commitUpdateDeliveryPeriod({
        variables: {
          input: {
            waterDeliveryPeriodId: target.id,
            start: item.start,
            end: item.end,
            close: item.close,
            isSeasonStart: item.isSeasonStart
          }
        },
        onCompleted,
        onError,
        updater: store => updateDeliveryPeriods(store, catchmentId, start, end)
      });
    } else {
      commitCreateWaterDeliveryPeriod({
        variables: {
          input: {
            catchmentId: profileContext.catchmentId,
            start: item.start,
            end: item.end,
            close: item.close,
            isSeasonStart: item.isSeasonStart
          }
        },
        onCompleted,
        onError,
        updater: store => {
          const wdp = store.getRootField("createWaterDeliveryPeriod")
            .getLinkedRecord("waterDeliveryPeriod");
          updateDeliveryPeriods(store, catchmentId, start, end, wdp);
        }
      });
    }
  };

  const handleDelete = () => {
    setOpen(false);
    commitDeleteWaterDeliveryPeriod({
      variables: { input: { waterDeliveryPeriodId: target.id } },
      onCompleted,
      onError,
      updater: store => updateDeliveryPeriods(store, catchmentId, start, end, null, target)
    });
  };

  const handleCancel = () => setOpen(false);

  return (
    <>
      <Grid container className="delivery-periods" sx={{ mb: 10, mt: 2 }}>
        <DeliveryPeriodsTable waterDeliveryPeriods={deliveryPeriodsQuery.waterDeliveryPeriods} onEdit={handleEdit} />
      </Grid>
      <Fab color="primary" style={{ bottom: theme.spacing(3), position: "fixed", right: theme.spacing(3) }} onClick={handleAdd}>
        <AddIcon />
      </Fab>
      <DeliveryPeriodDialog open={open} target={target} onSave={handleSave} onCancel={handleCancel} onDelete={handleDelete} />
    </>
  );
};