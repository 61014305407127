import React from "react";
import { Grid, Link, Paper, Typography } from "@mui/material";
import { LinkWrap } from "../../../../common/components/linkWrap";

export const Tile = ({ label, value, md, sm, sx, to }) => {
  return (
    <Grid item xs={sm} sm={sm} md={md} sx={sx}>
      <Paper sx={{ height: "100%", p: { xs: 1, sm: 2 } }}>
        <Typography component="div" sx={{ fontSize: "22px" }}>
          <Link component={LinkWrap} to={to}>{value}</Link>
        </Typography>
        <Typography component="div" variant="caption">
          {label}
        </Typography>
      </Paper>
    </Grid>
  );
};

Tile.defaultProps = {
  sm: 6,
  md: 5
};