/**
 * @generated SignedSource<<d5f1a0299dbe1616c5c174538bee0e2d>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateExtractionSourceInputInput = {|
  actualGross?: ?any,
  actualNet?: ?any,
  date: any,
  extractionSourceId: any,
  forecastGross?: ?any,
  forecastNet?: ?any,
|};
export type ExtractionSourceResultsCreateExtractionSourceInputMutation$variables = {|
  input: CreateExtractionSourceInputInput,
|};
export type ExtractionSourceResultsCreateExtractionSourceInputMutation$data = {|
  +createExtractionSourceInput: {|
    +extractionSourceInput: ?{|
      +actualGross: ?any,
      +actualNet: ?any,
      +date: any,
      +forecastGross: ?any,
      +forecastNet: ?any,
      +id: ?string,
    |},
  |},
|};
export type ExtractionSourceResultsCreateExtractionSourceInputMutation = {|
  response: ExtractionSourceResultsCreateExtractionSourceInputMutation$data,
  variables: ExtractionSourceResultsCreateExtractionSourceInputMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateExtractionSourceInputPayload",
    "kind": "LinkedField",
    "name": "createExtractionSourceInput",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExtractionSourceInput",
        "kind": "LinkedField",
        "name": "extractionSourceInput",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "forecastGross",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "forecastNet",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actualGross",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actualNet",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExtractionSourceResultsCreateExtractionSourceInputMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExtractionSourceResultsCreateExtractionSourceInputMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f615d951922cb035e31843cc944642e9",
    "id": null,
    "metadata": {},
    "name": "ExtractionSourceResultsCreateExtractionSourceInputMutation",
    "operationKind": "mutation",
    "text": "mutation ExtractionSourceResultsCreateExtractionSourceInputMutation(\n  $input: CreateExtractionSourceInputInput!\n) {\n  createExtractionSourceInput(input: $input) {\n    extractionSourceInput {\n      id\n      date\n      forecastGross\n      forecastNet\n      actualGross\n      actualNet\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "13a17e5c99ff8911f53ef31455398285";

module.exports = ((node/*: any*/)/*: Mutation<
  ExtractionSourceResultsCreateExtractionSourceInputMutation$variables,
  ExtractionSourceResultsCreateExtractionSourceInputMutation$data,
>*/);
