import React from "react";
import { Breadcrumbs, Box, Grid, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { usePreloadedQuery } from "react-relay";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from '@mui/icons-material/Search';
import { add, chain } from "../../../../common/utils/math";
import { Tile } from "./Tile";
import { formatCurrency, formatDate, formatVolume } from "../../../../common/utils/format";

export const Financials = ({ financialsQueryDef, financialsQueryRef }) => {
  const financialsQuery = usePreloadedQuery(financialsQueryDef, financialsQueryRef);

  const users = financialsQuery.waterUsers.map(x => ({ ...x }));
  if (financialsQuery.accounts) {
    financialsQuery.accounts
      .filter(x => x.accountBalance)
      .forEach(x => {
        var wu = users.find(u => u.id === x.referenceId);
        if (wu) {
          wu.accountId = x.id;
          wu.balance = x.accountBalance.value;
        }
      });
  }
  if (financialsQuery.transactionLineItemsForCurrentSeason) {
    financialsQuery.transactionLineItemsForCurrentSeason
      .forEach(x => {
        var wu = users.find(u => u.id === x.account.referenceId);
        if (wu) {
          if (!wu.transactions) {
            wu.transactions = [];
          }

          wu.transactions.push(x);
        }
      });
    users
      .filter(x => x.transactions)
      .forEach(x => {
        x.total = x.transactions
          .reduce((p, c) => p.add(c.value), chain(0))
          .done();
      });
  }
  if (financialsQuery.exchangeClearingsForCurrentSeason) {
    financialsQuery.exchangeClearingsForCurrentSeason
      .forEach(x => {
        x.allocations.forEach(y => {
          var wu = users.find(u => u.id === y.waterUserExtractionPoint.waterUser.id);
          if (wu) {
            if (!wu.clearings) {
              wu.clearings = [];
            }

            const total = (wu.transactions || [])
              .filter(t => t.transaction?.transactionMetadata?.find(y => true)?.value === x.waterDeliveryPeriod?.id)
              .reduce((p, c) => p.add(c.value), chain(0))
              .done();

            wu.clearings.push({
              allocation: y,
              waterDeliveryPeriod: x.waterDeliveryPeriod,
              total
            });
          }
        });
      });
  }

  return (
    <Box className="financials">
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">Financials</Typography>
        </Link>
      </Breadcrumbs>
      {users
        .filter(x => x.balance !== undefined)
        .map(x => (
          <Box sx={{ mb: 4 }} className="section">
            <Typography variant="h1" component="h2">{x.name}</Typography>
            <Grid container columnSpacing={2} alignItems="stretch" sx={{ mt: 2 }}>
              <Tile label="Balance" value={formatCurrency(x.balance)} />
              <Tile label="Season Charge" value={formatCurrency(x.total || 0)} />
              <Tile label="Browse" value={<SearchIcon />} to={`/transactions/${x.accountId}`} sx={{ display: { xs: "none", md: "block" } }} md={2} />
            </Grid>
            <TableContainer component={Paper} sx={{ mt: 1 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Delivery period</TableCell>
                    <TableCell>Impact Allowance purchased</TableCell>
                    <TableCell>Price at farm offtake</TableCell>
                    <TableCell>Race congestion cost</TableCell>
                    <TableCell>Water to be delivered</TableCell>
                    <TableCell>Final charge</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {x.clearings && x.clearings.map(y => (
                    <TableRow>
                      <TableCell>{formatDate(y.waterDeliveryPeriod.start)} - {formatDate(y.waterDeliveryPeriod.end)}</TableCell>
                      <TableCell>{formatVolume(y.allocation.changeQuantity)}</TableCell>
                      <TableCell>{formatCurrency(y.allocation.spotPrice)}</TableCell>
                      <TableCell>{formatCurrency(add(y.allocation.raceCongestionCharge, y.allocation.raceCongestionCredit))}</TableCell>
                      <TableCell>{formatVolume(y.allocation.totalQuantity)}</TableCell>
                      <TableCell>{formatCurrency(y.total)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
    </Box>
  );
};