/**
 * @generated SignedSource<<93f7a1e547843885521632148191640e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteWaterDeliveryPeriodInput = {|
  waterDeliveryPeriodId: any,
|};
export type DeliveryPeriodsResultsDeleteDeliveryPeriodMutation$variables = {|
  input: DeleteWaterDeliveryPeriodInput,
|};
export type DeliveryPeriodsResultsDeleteDeliveryPeriodMutation$data = {|
  +deleteWaterDeliveryPeriod: {|
    +waterDeliveryPeriod: ?{|
      +id: ?string,
    |},
  |},
|};
export type DeliveryPeriodsResultsDeleteDeliveryPeriodMutation = {|
  response: DeliveryPeriodsResultsDeleteDeliveryPeriodMutation$data,
  variables: DeliveryPeriodsResultsDeleteDeliveryPeriodMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeliveryPeriodsResultsDeleteDeliveryPeriodMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteWaterDeliveryPeriodPayload",
        "kind": "LinkedField",
        "name": "deleteWaterDeliveryPeriod",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WaterDeliveryPeriod",
            "kind": "LinkedField",
            "name": "waterDeliveryPeriod",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeliveryPeriodsResultsDeleteDeliveryPeriodMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteWaterDeliveryPeriodPayload",
        "kind": "LinkedField",
        "name": "deleteWaterDeliveryPeriod",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WaterDeliveryPeriod",
            "kind": "LinkedField",
            "name": "waterDeliveryPeriod",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "99d7a2e6184f30312c95a6c2d0c82c1e",
    "id": null,
    "metadata": {},
    "name": "DeliveryPeriodsResultsDeleteDeliveryPeriodMutation",
    "operationKind": "mutation",
    "text": "mutation DeliveryPeriodsResultsDeleteDeliveryPeriodMutation(\n  $input: DeleteWaterDeliveryPeriodInput!\n) {\n  deleteWaterDeliveryPeriod(input: $input) {\n    waterDeliveryPeriod {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "bf960667da9da9b24102d1bc20ea9363";

module.exports = ((node/*: any*/)/*: Mutation<
  DeliveryPeriodsResultsDeleteDeliveryPeriodMutation$variables,
  DeliveryPeriodsResultsDeleteDeliveryPeriodMutation$data,
>*/);
