import React, { Suspense, useContext, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { useParams } from "react-router-dom";
import { TransactionHeader } from "./components/TransactionHeader";
import { TransactionResults } from "./components/TransactionResults";
import { CenteredLoading, ResultsLoading } from "../../../common/components/loading";
import { ProfileContext } from "../../../common/contexts/profile";

const TransactionHeaderQuery = graphql`
  query transactionTransactionHeaderQuery($catchmentId: UUID!, $accountId: UUID!) {
    myAccounts(where: { and: [ { catchment: { id: { eq: $catchmentId } } }, { id: { eq: $accountId } } ] }) {
      id
      name
      accountBalance {
        value
      }
    }
  }
`;

const TransactionResultsQuery = graphql`
  query transactionTransactionResultsQuery($catchmentId: UUID!, $accountId: UUID!, $start: DateTime!, $end: DateTime!) {
    myAccounts(where: { and: [ { catchment: { id: { eq: $catchmentId } } }, { id: { eq: $accountId } } ] }) {
      id
      accountType {
        name
        code
      }
      transactionLineItems(where: { transaction: { and: [ { date: { gte: $start } }, { date: { lte: $end } } ] } }, order: { transaction: { date: DESC } }) {
        id
        value
        transaction {
          id
          date
          memo
          referenceTypeName
          referenceId
          transactionLineItems(where: { account: { referenceTypeName: { eq: "WaterDeliveryPeriods" } } }) {
            id
            account {
              id
              name
            }
          }
          transactionMetadata {
            name
            value
          }
        }
      }
    }
  }
`;

export const TransactionPage = () => {
  const { aId } = useParams();
  const [ transactionHeaderQueryRef, loadTransactionHeaderQuery ] = useQueryLoader(TransactionHeaderQuery);
  const [ transactionResultsQueryRef, loadTransactionResultsQuery ] = useQueryLoader(TransactionResultsQuery);
  const { catchmentId } = useContext(ProfileContext);

  const handleExecute = ({ end, start }) => {
    loadTransactionResultsQuery({
      catchmentId,
      accountId: aId,
      end: end.clone().add(1, "day").format(),
      start: start.format()
    }, { fetchPolicy: "network-only" });
  };

  useEffect(() => loadTransactionHeaderQuery(
    { accountId: aId, catchmentId }, { fetchPolicy: "network-only" }),
    [ loadTransactionHeaderQuery, aId, catchmentId ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {transactionHeaderQueryRef &&
        <TransactionHeader transactionHeaderQueryDef={TransactionHeaderQuery} transactionHeaderQueryRef={transactionHeaderQueryRef}
          onExecute={handleExecute} />}
      <Suspense fallback={<ResultsLoading />}>
        {transactionResultsQueryRef && <TransactionResults transactionResultsQueryDef={TransactionResultsQuery}
          transactionResultsQueryRef={transactionResultsQueryRef} />}
      </Suspense>
    </Suspense>
  );
};