import React, { useEffect, useState } from "react";
import { Button, Box, Dialog, DialogActions, DialogContent, DialogTitle, Step, StepLabel, Stepper } from "@mui/material";
import { WizardLoading } from "../../../common/components/loading";
import { ImportProperties } from "./ImportProperties";
import { ImportPreview } from "./ImportPreview";

const finalStep = 1;

export const ImportWizardDialog = ({ defaultOfftakeId, offtakes, defaultQualityTypeId, qualityTypes, open, onCancel, onSave }) => {
  const [ activeStep, setActiveStep ] = useState(0);
  const [ comment, setComment ] = useState("");
  const [ fileName, setFileName ] = useState("");
  const [ offtakeId, setOfftakeId ] = useState("");
  const [ qualityTypeId, setQualityTypeId ] = useState("");
  const [ measurements, setMeasurements ] = useState([]);
  const [ isSaving, setIsSaving ] = useState(false);

  const handleNext = () => {
    if (activeStep === finalStep) {
      onSave({ comment, fileName, measurements, offtakeId, qualityTypeId, isSaving, setIsSaving });
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  useEffect(() => {
    if (open) {
      setActiveStep(0);
      setComment("");
      setFileName("");
      setOfftakeId(defaultOfftakeId);
      setQualityTypeId(defaultQualityTypeId);
      setIsSaving(false);
      setMeasurements([]);
    }
  }, [ defaultOfftakeId, defaultQualityTypeId, open ]);

  return (
    <Dialog open={open} onClose={onCancel} fullWidth={activeStep > 0} maxWidth="xl">
      <DialogTitle>Import</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            <Step>
              <StepLabel>Properties</StepLabel>
            </Step>
            <Step>
              <StepLabel>Preview</StepLabel>
            </Step>
          </Stepper>
          <Box sx={{ mt: 2 }}>
            {!isSaving && activeStep === 0 &&
              <ImportProperties comment={comment} fileName={fileName} offtakeId={offtakeId} setOfftakeId={setOfftakeId} offtakes={offtakes} setMeasurements={setMeasurements}
                qualityTypes={qualityTypes} qualityTypeId={qualityTypeId} setComment={setComment} setFileName={setFileName} setQualityTypeId={setQualityTypeId} />}
            {!isSaving && activeStep === 1 &&
              <ImportPreview measurements={measurements} setMeasurements={setMeasurements} />}
            {isSaving && <WizardLoading />}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <Button color="inherit" disabled={activeStep === 0 || isSaving} onClick={handleBack}>Back</Button>
          <Button onClick={handleNext} disabled={(offtakes && offtakes.length > 0 && !offtakeId) || !qualityTypeId || !measurements || measurements.length === 0 || isSaving}>{activeStep === finalStep ? "Save" : "Next"}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};