import React, { useState } from "react";
import { Link as LinkReact } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import InfoIcon from "@mui/icons-material/Info";
import { formatDate } from "../../../../common/utils/format";
import { formatAccountingValue, getReason, isCredit, isDebit } from "../../../utils/accounts";

const getLink = (referenceTypeName, referenceId, metadata) => {
  if (!referenceTypeName || !referenceId) {
    return null;
  }

  switch (referenceTypeName) {
    case 'WaterUsers':
      return `/admin/water-users/${referenceId}`
    case 'WaterDeliveryPeriods':
      return `/admin/delivery-periods/${referenceId}`;
    case 'ExchangeClearings':
      const wdpId = metadata.find(x => x.name === 'WaterDeliveryPeriodId')?.value;
      return wdpId
        ? `/admin/delivery-periods/${wdpId}/clearings/${referenceId}`
        : null;
    default:
      return null;
  }
};

export const TransactionsTable = ({ account, transactionLineItems }) => {
  const [ open, setOpen ] = useState(null);
  const theme = useTheme();

  const records = transactionLineItems
    ? transactionLineItems.map(x => ({
      id: x.id,
      date: x.transaction.date,
      memo: x.transaction.memo,
      value: x.value,
      transaction: x.transaction,
      link: getLink(x.transaction.referenceTypeName, x.transaction.referenceId, x.transaction.transactionMetadata),
      account
    }))
    : [];

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Memo</TableCell>
              <TableCell>Debit</TableCell>
              <TableCell>Credit</TableCell>
              <TableCell width={50}>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map(x => {
              const wdp = (x.transaction.transactionLineItems || [])
                .find(x => x.account.id !== account.id && x.account.referenceTypeName === "WaterDeliveryPeriods");
              const suffix = wdp ? ` (${wdp.account.name})` : "";
              return (
                <TableRow key={x.id}>
                  <TableCell>{formatDate(x.date)}</TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center">
                      {x.memo}{suffix}
                      <IconButton onClick={() => setOpen(x.transaction)} size="small" sx={{ ml: 1 }}>
                        <InfoIcon fontSize="inherit" />
                      </IconButton>
                    </Stack>
                  </TableCell>
                  <TableCell>{isDebit(x, x.account) && formatAccountingValue(x.value)}</TableCell>
                  <TableCell>{isCredit(x, x.account) && formatAccountingValue(x.value)}</TableCell>
                  <TableCell>
                    {x.link && (
                      <Link component={LinkReact} to={x.link}>
                        <LinkIcon fontSize="small" sx={{ color: theme.palette.text.primary, mr: 1, mt: 1, opacity: 0.54 }} />
                      </Link>)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {open && <Dialog open={!!open} fullWidth={true} maxWidth="md" onClose={() => setOpen(null)}>
        <DialogTitle>Transaction</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>Details</Typography>
          <Typography variant="body2">Date: {formatDate(open.date)}</Typography>
          <Typography variant="body2">Memo: {open.memo}</Typography>
          {(() => {
            const reason = getReason(open);
            if (reason) {
              return <Typography variant="body2">Reason: {reason}</Typography>;
            }
          })()}
          <Typography variant="subtitle1" sx={{ mt: 2 }}>Lines</Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Account</TableCell>
                <TableCell>Debit</TableCell>
                <TableCell>Credit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {open.transactionLineItems
                .map(y => (
                  <TableRow key={y.id}>
                    <TableCell>{y.account.name}</TableCell>
                    <TableCell>{isDebit(y) && formatAccountingValue(y.value)}</TableCell>
                    <TableCell>{isCredit(y) && formatAccountingValue(y.value)}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(null)}>Close</Button>
        </DialogActions>
      </Dialog>}
    </>
  );
};