/**
 * @generated SignedSource<<d9aec425f23e20653c9f587b909bcca3>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateExtractionSourceMeasurementsInput = {|
  comment?: ?string,
  extractionSourceId: any,
  extractionSourceMeasurements?: ?$ReadOnlyArray<?ExtractionSourceMeasurementCreateInput>,
  fileName?: ?string,
|};
export type ExtractionSourceMeasurementCreateInput = {|
  date: any,
  flowRate: any,
  measurementQualityTypeId: any,
  total?: ?any,
|};
export type ExtractionSourceImportWizardDialogUpdateExtractionSourceMeasurementsMutation$variables = {|
  input: UpdateExtractionSourceMeasurementsInput,
|};
export type ExtractionSourceImportWizardDialogUpdateExtractionSourceMeasurementsMutation$data = {|
  +updateExtractionSourceMeasurements: {|
    +extractionSourceMeasurement: ?$ReadOnlyArray<?{|
      +date: any,
      +flowRate: any,
      +id: ?string,
      +importOperation: ?{|
        +id: ?string,
      |},
      +measurementQualityType: ?{|
        +id: ?string,
      |},
      +modifiedByUser: ?{|
        +id: ?string,
      |},
      +total: ?any,
    |}>,
  |},
|};
export type ExtractionSourceImportWizardDialogUpdateExtractionSourceMeasurementsMutation = {|
  response: ExtractionSourceImportWizardDialogUpdateExtractionSourceMeasurementsMutation$data,
  variables: ExtractionSourceImportWizardDialogUpdateExtractionSourceMeasurementsMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateExtractionSourceMeasurementsPayload",
    "kind": "LinkedField",
    "name": "updateExtractionSourceMeasurements",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExtractionSourceMeasurement",
        "kind": "LinkedField",
        "name": "extractionSourceMeasurement",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "flowRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedByUser",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ImportOperation",
            "kind": "LinkedField",
            "name": "importOperation",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MeasurementQualityType",
            "kind": "LinkedField",
            "name": "measurementQualityType",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExtractionSourceImportWizardDialogUpdateExtractionSourceMeasurementsMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExtractionSourceImportWizardDialogUpdateExtractionSourceMeasurementsMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "69e3809c4b5f27c82e6f168f6f910eb8",
    "id": null,
    "metadata": {},
    "name": "ExtractionSourceImportWizardDialogUpdateExtractionSourceMeasurementsMutation",
    "operationKind": "mutation",
    "text": "mutation ExtractionSourceImportWizardDialogUpdateExtractionSourceMeasurementsMutation(\n  $input: UpdateExtractionSourceMeasurementsInput!\n) {\n  updateExtractionSourceMeasurements(input: $input) {\n    extractionSourceMeasurement {\n      id\n      date\n      flowRate\n      total\n      modifiedByUser {\n        id\n      }\n      importOperation {\n        id\n      }\n      measurementQualityType {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "eeb5bae841d6199eadabf7aa0db1bd27";

module.exports = ((node/*: any*/)/*: Mutation<
  ExtractionSourceImportWizardDialogUpdateExtractionSourceMeasurementsMutation$variables,
  ExtractionSourceImportWizardDialogUpdateExtractionSourceMeasurementsMutation$data,
>*/);
