import React from "react";
import { Link as LinkReact } from "react-router-dom";
import { usePreloadedQuery } from "react-relay";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Subheader } from "../../../../../../common/components/subheader";
import { Query } from "./Query";

export const ForecastAccuracyHeader = ({ forecastAccuracyQueryDef, forecastAccuracyQueryRef, onExecute }) => {
  const results = usePreloadedQuery(forecastAccuracyQueryDef, forecastAccuracyQueryRef);
  const extractionSources = results.extractionSources || [];

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Administration</Typography>
        </Link>
        <Link component={LinkReact} underline="hover" color="inherit" to="/admin/reports">
          <Typography variant="body2">Reports</Typography>
        </Link>
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">Forecast Accuracy</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Forecast Accuracy</Typography>
      <Subheader name="Search" />
      <Query extractionSources={extractionSources} onExecute={onExecute} endOffset={28} />
    </>
  );
};