import React from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { formatDate } from "../../../../common/utils/format";
import { Stat } from "./Stat";
import { LinkWrap } from "../../../../common/components/linkWrap";
import { roundStartDate, roundEndDate } from "../../../../common/utils/date";

export const Tile = ({ waterDeliveryPeriod, waterUserExtractionPointId, stats }) => (
  <LinkWrap to={`/delivery-periods/${waterDeliveryPeriod.id}/water-users/${waterUserExtractionPointId}`}>
    <Paper component={Button} sx={{ display: { xs: "block", md: "none" }, mb: 1, mt: 1, p: 1, width: "100%" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography component="div" variant="subtitle1">{formatDate(roundStartDate(waterDeliveryPeriod.start))} - {formatDate(roundEndDate(waterDeliveryPeriod.end))}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Grid container>
            {stats.map(x => (
              <Grid item xs={(1 / stats.length) * 12} key={x.label}>
                <Stat num={x.num} label={x.label} direction={x.direction} />
              </Grid>))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  </LinkWrap>
);