import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { formatDate } from "../../../../common/utils/format";
import { StatIcon } from "./StatIcon";
import { LinkCell } from "../../../../common/components/linkCell";
import { Icon } from "./Icon";
import { roundStartDate, roundEndDate } from "../../../../common/utils/date";

export const Tbl = ({ items }) => (
  <TableContainer component={Paper} sx={{ display: { xs: "none", md: "block" }, mt: 1 }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="31%">Water Delivery Period</TableCell>
          {items && items.length > 0 && items[0].stats.map(x => <TableCell width="23%" key={x.label}>{x.label}</TableCell>)}
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map(x => {
          const link = `/delivery-periods/${x.waterDeliveryPeriod.id}/water-users/${x.waterUserExtractionPointId}`;
          return (
            <TableRow key={`${x.waterDeliveryPeriod.id}_${x.extractionPoint.id}`}>
              <LinkCell link={link}>
                <div>{formatDate(roundStartDate(x.waterDeliveryPeriod.start))} - {formatDate(roundEndDate(x.waterDeliveryPeriod.end))}</div>
              </LinkCell>
              {x.stats.map(y =>
                <LinkCell key={`${x.id}_${y.label}`} link={link}>
                  <div>
                    {y.num == null ? "-" : y.num}{y.num != null && <> k.m<sup>3</sup></>}
                    {y.direction && <StatIcon direction={y.direction} />}
                  </div>
                </LinkCell>)}
              <TableCell>
                {x.isMissingOffers && <Icon type="isMissingOffers" />}
                {!x.isMissingOffers && x.isInProgress && <Icon type="isInProgress" />}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
);

export default Tbl;