import React from "react";
import { usePreloadedQuery } from "react-relay";
import { Link as LinkReact, Navigate } from "react-router-dom";
import { Breadcrumbs, Grid, Link, Paper, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Subheader } from "../../../../common/components/subheader";
import { formatDate } from "../../../../common/utils/format";
import { AllocationsTable } from "./AllocationsTable";
import { Status } from "../../clearing/components/Status";
import { ErrorBoundary } from "../../../../common/components/errorBoundary";
import { ResultAggregateChart } from "./ResultAggregateChart";
import { getTotalEsiForWdp } from "../../../utils/stats";
import { ResultDetail } from "./ResultDetail";
import { roundStartDate, roundEndDate } from "../../../../common/utils/date";

export const Allocations = ({ allocationsQueryDef, allocationsQueryRef }) => {
  const allocationsQuery = usePreloadedQuery(allocationsQueryDef, allocationsQueryRef);

  const wdp = allocationsQuery.waterDeliveryPeriods && allocationsQuery.waterDeliveryPeriods.length > 0
    ? allocationsQuery.waterDeliveryPeriods[0]
    : null;
  const ecs = wdp
    ? wdp.exchangeClearings
    : [];
  const ec = ecs.length > 0
    ? ecs[0]
    : null;
  const allocations = ec
    ? (ec.allocations || [])
    : null;
  const offers = allocationsQuery.offerSummaries && allocationsQuery.offerSummaries.length > 0
    ? allocationsQuery.offerSummaries
    : null;
  const esiAtRunTime = allocationsQuery.extractionSourceInputsForWaterDeliveryPeriodAtLatest
    ? allocationsQuery.extractionSourceInputsForWaterDeliveryPeriodAtLatest
    : [];
  const es = allocationsQuery.extractionSources && allocationsQuery.extractionSources.length > 0
    ? allocationsQuery.extractionSources[0]
    : {};
  const totalInputAtClearingRunTime = es && wdp && ec
    ? getTotalEsiForWdp(es, esiAtRunTime, wdp, ec.exchangeClearingType.isDelivered)
    : null;

  if (!wdp) {
    return (<Navigate to="/" />);
  }

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Overview</Typography>
        </Link>
        <Link underline="hover" color="inherit">
          <Typography variant="body2">{`${formatDate(roundStartDate(wdp.start))} - ${formatDate(roundEndDate(wdp.end))}`}</Typography>
        </Link>
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">{es.name}</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Clearing for {es.name}</Typography>
      <Typography variant="body2" component="h2" sx={{ mb: 1, mt: 1 }}>for delivery period {formatDate(roundStartDate(wdp.start))} - {formatDate(roundEndDate(wdp.end))}</Typography>
      <Grid container className="clearing" sx={{ mb: 10, mt: 2 }}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Status type={ec ? ec.exchangeClearingType.name : null} />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }} className="results">
          <Subheader name="Results" />
          {!ec && <Paper sx={{ p: 2 }}>
            <Typography variant="body2">No clearing has been processed for this delivery period. Come back later to see the results.</Typography>
          </Paper>}
        </Grid>
        {offers && totalInputAtClearingRunTime !== null &&
          <>
            <ErrorBoundary>
              <ResultAggregateChart offerSummaries={offers} totalInput={totalInputAtClearingRunTime} />
            </ErrorBoundary>
            <ResultDetail spotPrice={allocations && allocations.length > 0 && allocations[0].spotPricePreLoss} total={totalInputAtClearingRunTime} run={ec.run} />
          </>}
        <Grid item xs={12} sx={{ mt: 2 }} className="breakdown">
          <Subheader name="Per-user Breakdown" />
          {(!ec || !ec.exchangeClearingType.isFinal) && <Paper sx={{ p: 2 }}>
            <Typography variant="body2">Clearing is not yet finalized. Come back later to see individual results.</Typography>
          </Paper>}
          {ec && ec.exchangeClearingType.isFinal && allocations && allocations.length > 0 && <Grid item xs={12} sx={{ mt: 2 }}>
            <AllocationsTable allocations={allocations} />
          </Grid>}
        </Grid>
      </Grid>
    </>
  );
};