import React from "react";
import { Link as LinkReact, Navigate } from "react-router-dom";
import { Breadcrumbs, Grid, Link, Paper, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { usePreloadedQuery } from "react-relay";
import { formatDate, formatDateTime, formatDateForFileName } from "../../../../common/utils/format";
import { Subheader } from "../../../../common/components/subheader";
import { GridNode } from "../../../../common/components/gridNode";
import { OffersTable } from "./OffersTable";
import { AllocationsTable } from "./AllocationsTable";
import { roundStartDate, roundEndDate } from "../../../../common/utils/date";
import { cap } from "../../../../common/utils/strings";

export const ExchangeClearing = ({ exchangeClearingQueryDef, exchangeClearingQueryRef }) => {
  const exchangeClearingQuery = usePreloadedQuery(exchangeClearingQueryDef, exchangeClearingQueryRef);
  const exchangeClearing = exchangeClearingQuery.exchangeClearings.length > 0
    ? exchangeClearingQuery.exchangeClearings[0]
    : null;

  if (!exchangeClearing) {
    return (<Navigate to="/admin/delivery-periods" />);
  }

  const download = () => {
    const start = formatDateForFileName(exchangeClearing.waterDeliveryPeriod.start);
    const end = formatDateForFileName(exchangeClearing.waterDeliveryPeriod.end); 

    const records = exchangeClearing.allocations
      .filter(x => !x.waterUserExtractionPoint.waterUser.isDemandOnly)
      .map(x => ({
        "WIL ID": x.waterUserExtractionPoint.publicId,
        "Farm ID": x.waterUserExtractionPoint.waterUser.publicId,
        "Farm name": `"${x.waterUserExtractionPoint.waterUser.name}"`,
        "Start date": formatDateTime(exchangeClearing.waterDeliveryPeriod.start),
        "End date": formatDateTime(exchangeClearing.waterDeliveryPeriod.end),
        "Total volume": x.totalQuantity,
        "Flow rate": ""
      }));

    const rows = records.reduce((p, c, i) => {
      if (i === 0) {
        p.push(Object.keys(c).join(","));
      }

      p.push(Object.values(c).join(","));
      return p;
    }, []);

    const a = document.createElement("a");
    const blob = new Blob([ rows.join("\n") ], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.setAttribute("download", `Allocations for ${start} to ${end}`);
    a.click();
  };

  const wdpTitle = `${formatDate(roundStartDate(exchangeClearing.waterDeliveryPeriod.start))} - ${formatDate(roundEndDate(exchangeClearing.waterDeliveryPeriod.end))}`;
  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Administration</Typography>
        </Link>
        <Link component={LinkReact} underline="hover" color="inherit" to="/admin/delivery-periods">
          <Typography variant="body2">Delivery Periods</Typography>
        </Link>
        <Link component={LinkReact} underline="hover" color="inherit" to={`/admin/delivery-periods/${exchangeClearing.waterDeliveryPeriod.id}`}>
          <Typography variant="body2">{wdpTitle}</Typography>
        </Link>
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">Exchange Clearing</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Exchange Clearing</Typography>
      <Grid container className="clearing" sx={{ mb: 10 }}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Subheader name="Overview" />
        </Grid>
        <Grid container item xs={12} component={Paper} sx={{ p: 2, pt: 1 }} rowSpacing={1}>
          <GridNode name="Type" value={exchangeClearing.exchangeClearingType.name} />
          <GridNode name="Run" value={formatDate(exchangeClearing.run)} />
        </Grid>
        {exchangeClearing.submissionState && <>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Subheader name="Operations" />
          </Grid>
          <Grid container item xs={12} component={Paper} sx={{ p: 2, pt: 1 }} rowSpacing={1}>
            <GridNode name="State" value={cap(exchangeClearing.submissionState)} />
            <GridNode name="Comment" value={exchangeClearing.submissionComment} />
          </Grid>
        </>}
        <Grid item xs={12}>
          <Subheader name="Offers" />
        </Grid>
        <Grid item xs={12}>
          <OffersTable offers={exchangeClearingQuery.waterUserExtractionPointsAtExchangeClearing} />
        </Grid>
        <Grid item xs={12}>
          <Subheader name="Cleared Quantities" linkName="Download as CSV" action={download} />
        </Grid>
        <Grid item xs={12}>
          <AllocationsTable allocations={exchangeClearing.allocations} />
        </Grid>
      </Grid>
    </>
  );
};