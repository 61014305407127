/**
 * @generated SignedSource<<a9cd0d7fde0acb5f1303ddca6d39d9b7>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteExchangeClearingInput = {|
  exchangeClearingId: any,
|};
export type ClearingsTableDeleteExchangeClearingMutation$variables = {|
  input: DeleteExchangeClearingInput,
|};
export type ClearingsTableDeleteExchangeClearingMutation$data = {|
  +deleteExchangeClearing: {|
    +exchangeClearing: ?{|
      +id: ?string,
    |},
  |},
|};
export type ClearingsTableDeleteExchangeClearingMutation = {|
  response: ClearingsTableDeleteExchangeClearingMutation$data,
  variables: ClearingsTableDeleteExchangeClearingMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClearingsTableDeleteExchangeClearingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteExchangeClearingPayload",
        "kind": "LinkedField",
        "name": "deleteExchangeClearing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExchangeClearing",
            "kind": "LinkedField",
            "name": "exchangeClearing",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClearingsTableDeleteExchangeClearingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteExchangeClearingPayload",
        "kind": "LinkedField",
        "name": "deleteExchangeClearing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExchangeClearing",
            "kind": "LinkedField",
            "name": "exchangeClearing",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "849938d8257c4c6bac99b73a94b02269",
    "id": null,
    "metadata": {},
    "name": "ClearingsTableDeleteExchangeClearingMutation",
    "operationKind": "mutation",
    "text": "mutation ClearingsTableDeleteExchangeClearingMutation(\n  $input: DeleteExchangeClearingInput!\n) {\n  deleteExchangeClearing(input: $input) {\n    exchangeClearing {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "f67cbecb85bd733a3966da6dbb79c50e";

module.exports = ((node/*: any*/)/*: Mutation<
  ClearingsTableDeleteExchangeClearingMutation$variables,
  ClearingsTableDeleteExchangeClearingMutation$data,
>*/);
