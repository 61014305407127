/**
 * @generated SignedSource<<5999cb13dac5b5d2a7883352a0ab01c9>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type deliveryPeriodsDeliveryPeriodsQuery$variables = {|
  catchmentId: any,
  end: any,
  start: any,
|};
export type deliveryPeriodsDeliveryPeriodsQuery$data = {|
  +waterDeliveryPeriods: ?$ReadOnlyArray<?{|
    +close: ?any,
    +end: any,
    +exchangeClearings: ?$ReadOnlyArray<?{|
      +exchangeClearingType: ?{|
        +id: ?string,
        +name: ?string,
      |},
      +id: ?string,
      +run: any,
    |}>,
    +id: ?string,
    +isSeasonStart: ?boolean,
    +start: any,
  |}>,
|};
export type deliveryPeriodsDeliveryPeriodsQuery = {|
  response: deliveryPeriodsDeliveryPeriodsQuery$data,
  variables: deliveryPeriodsDeliveryPeriodsQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "catchmentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order",
        "value": {
          "start": "ASC"
        }
      },
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "lte",
                        "variableName": "end"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "start"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.0"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "gte",
                        "variableName": "start"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "end"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.1"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "eq",
                            "variableName": "catchmentId"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "catchment"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.2"
              }
            ],
            "kind": "ListValue",
            "name": "and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "WaterDeliveryPeriod",
    "kind": "LinkedField",
    "name": "waterDeliveryPeriods",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "close",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSeasonStart",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "isLatest": {
                "eq": true
              }
            }
          }
        ],
        "concreteType": "ExchangeClearing",
        "kind": "LinkedField",
        "name": "exchangeClearings",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "run",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExchangeClearingType",
            "kind": "LinkedField",
            "name": "exchangeClearingType",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "exchangeClearings(where:{\"isLatest\":{\"eq\":true}})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "deliveryPeriodsDeliveryPeriodsQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "deliveryPeriodsDeliveryPeriodsQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "a955ac10543510dbde1b4327ae5a8e3b",
    "id": null,
    "metadata": {},
    "name": "deliveryPeriodsDeliveryPeriodsQuery",
    "operationKind": "query",
    "text": "query deliveryPeriodsDeliveryPeriodsQuery(\n  $catchmentId: UUID!\n  $start: DateTime!\n  $end: DateTime!\n) {\n  waterDeliveryPeriods(where: {and: [{start: {lte: $end}}, {end: {gte: $start}}, {catchment: {id: {eq: $catchmentId}}}]}, order: {start: ASC}) {\n    id\n    start\n    end\n    close\n    isSeasonStart\n    exchangeClearings(where: {isLatest: {eq: true}}) {\n      id\n      run\n      exchangeClearingType {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "23c95328ed74a2c64efb3d87945bc8ff";

module.exports = ((node/*: any*/)/*: Query<
  deliveryPeriodsDeliveryPeriodsQuery$variables,
  deliveryPeriodsDeliveryPeriodsQuery$data,
>*/);
