import React from "react";
import { Grid, IconButton, Stack, Switch, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import SettingsIcon from '@mui/icons-material/Settings';

export const ChartTitle = ({ checked, onChange, title, onDownload }) => (
  <Grid container item xs={12} direction="row" justifyContent="space-between">
    <Typography sx={{ ml: 2, mt: 2 }} variant="body1">{title}</Typography>
    <Stack sx={{ ml: 1, mr: 2, mt: 1 }} direction="row" alignItems="center">
      <Switch onChange={e => onChange(e.target.checked)} checked={checked} />
      <SettingsIcon />
      {onDownload && <IconButton onClick={onDownload}>
        <DownloadIcon />
      </IconButton>}
    </Stack>
  </Grid>
);

ChartTitle.defaultProps = {
  onDownload: null
};