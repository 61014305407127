import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormGroup, FormControlLabel, Switch, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import "moment-timezone";
import { dateTimeEditFormatString } from "../../../../common/utils/format";
import { ProfileContext } from "../../../../common/contexts/profile";

export const DeliveryPeriodDialog = ({ open, target, onCancel, onDelete, onSave }) => {
  const { timeZone } = useContext(ProfileContext);
  const [ start, setStart ] = useState(null);
  const [ startError, setStartError ] = useState(false);
  const [ end, setEnd ] = useState(null);
  const [ endError, setEndError ] = useState(false);
  const [ close, setClose ] = useState(null);
  const [ closeError, setCloseError ] = useState(false);
  const [ isSeasonStart, setIsSeasonStart ] = useState(false);
  const { now } = useContext(ProfileContext);

  useEffect(() => {
    if (start && end && start >= end) {
      setStartError("Start date must be before end date");
    } else if (!start) {
      setStartError("Start date is required");
    } else {
      setStartError(false);
    }

    if (!end) {
      setEndError("End date is required");
    } else {
      setEndError(false);
    }

    if (close && close > start) {
      setCloseError("Close date must be before start date");
    } else {
      setCloseError(false);
    }
  }, [ start, end, close ]);

  useEffect(() => {
    const baseline =  now().hour(5).minute(0).second(0).millisecond(0);
    setStart(target ? moment.parseZone(target.start) : baseline);
    setEnd(target ? moment.parseZone(target.end) : baseline.clone().add(1, "day"));
    setClose(target && target.close ? moment.parseZone(target.close) : null);
    setIsSeasonStart(target ? target.isSeasonStart : false);
  }, [ now, target, setStart, setEnd, timeZone ]);

  const handleSave = ({ start, end, close, isSeasonStart }) =>
    onSave({
      start: start.tz(timeZone, true).format(),
      end: end.tz(timeZone, true).format(),
      close: close ? close.tz(timeZone, true).format() : null,
      isSeasonStart
    });

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{target ? "Edit" : "Add"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Configure the water delivery period.
        </DialogContentText>
        <FormGroup>
          <DateTimePicker
            renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" error={!!startError} helperText={startError} {...props} />}
            label="Start"
            value={start}
            onChange={setStart}
            inputFormat={dateTimeEditFormatString}
            mask="__/__/__ __:__"
          />
          <DateTimePicker
            renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" error={!!endError} helperText={endError} {...props} />}
            label="End"
            value={end}
            onChange={setEnd}
            inputFormat={dateTimeEditFormatString}
            mask="__/__/__ __:__"
          />
          <DateTimePicker
            renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" error={!!closeError} helperText={closeError} {...props} />}
            label="Close"
            value={close}
            onChange={setClose}
            inputFormat={dateTimeEditFormatString}
            mask="__/__/__ __:__"
          />
          <FormControlLabel control={<Switch onChange={e => setIsSeasonStart(e.target.checked)} checked={isSeasonStart} />} label="Is season start?" sx={{ mt: 1 }} />
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        {target ? <Button onClick={onDelete} color="error">Delete</Button> : <div />}
        <Box>
          <Button onClick={onCancel}>Cancel</Button>
          {!target && <Button onClick={() => handleSave({ start, end, close, isSeasonStart })} disabled={!!startError || !!endError || !!closeError}>Add</Button>}
          {target && <Button onClick={() => handleSave({ start, end, close, isSeasonStart })} disabled={!!startError || !!endError || !!closeError}>Edit</Button>}
        </Box>
      </DialogActions>
    </Dialog>
  )
};