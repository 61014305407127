import React from "react";
import { Fab, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import { usePreloadedQuery } from "react-relay";
import DownloadIcon from '@mui/icons-material/Download';
import { formatDateTime } from "../../../../../../common/utils/format";

const newRow = c => ( { index: c.index, start: c.start, daysSinceStart: c.daysSinceStart, duration: c.duration, waterUserExtractionPoints: [], extractionSources: [] });

export const ActualDeliveriesResults = ({ actualDeliveriesResultsQueryDef, actualDeliveriesResultsQueryRef }) => {
  const actualDeliveriesResults = usePreloadedQuery(actualDeliveriesResultsQueryDef, actualDeliveriesResultsQueryRef);
  const theme = useTheme();

  const records = (actualDeliveriesResults.actualDeliveriesForWaterUserExtractionPoints || [])
    .reduce((p, c) => {
      let curr = p.find(x => x.index === c.index);
      if (!curr) {
        curr = newRow(c);
        p.push(curr);
      }

      curr.waterUserExtractionPoints.push({
        waterUserName: c.waterUserName,
        extractionPointName: c.extractionPointName,
        total: c.total
      });

      return p;
    }, []);

  (actualDeliveriesResults.actualDeliveriesForExtractionSources || [])
    .reduce((p, c) => {
      let curr = p.find(x => x.index === c.index);
      if (!curr) {
        curr = newRow(c);
        p.push(curr);
      }

      curr.extractionSources.push({
        extractionSourceName: c.extractionSourceName,
        total: c.total
      });

      return p;
    }, records);

  const download = () => {
    const rows = records
      .map(x => ({
        "Water Delivery Period": x.index,
        "Delivery Start Date": formatDateTime(x.start),
        "Days Since Start": x.daysSinceStart,
        "Days in Water Delivery Period": x.duration,
        ...x.waterUserExtractionPoints.reduce((p, c) => {
          p[c.waterUserName] = c.total;
          return p;
        }, {}),
        ...x.extractionSources.reduce((p, c) => {
          p[c.extractionSourceName] = c.total;
          return p;
        }, {})
      }))
      .reduce((p, c, i) => {
        if (i === 0) {
          p.push(Object.keys(c).map(x => x && x.indexOf(",") >= 0 ? `"${x}"` : x).join(","));
        }

        p.push(Object.values(c).join(","));
        return p;
      }, []);

    const a = document.createElement("a");
    const blob = new Blob([ rows.join("\n") ], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.setAttribute("download", "Actual Deliveries");
    a.click();
  };

  return (
    <>
      <Grid item xs={12} sx={{ mt: 2, mb: 10 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Water Delivery Period</TableCell>
                <TableCell>Delivery Start Date</TableCell>
                <TableCell>Days Since Start</TableCell>
                <TableCell>Days in Water Delivery Period</TableCell>
                {records.length > 0 && records[0].waterUserExtractionPoints.map(x => (<TableCell key={`${x.waterUserName}_${x.extractionPointName}`}>{x.waterUserName} (k.m<sup>3</sup>)</TableCell>))}
                {records.length > 0 && records[0].extractionSources.map(x => (<TableCell key={x.extractionSourceName}>{x.extractionSourceName} (k.m<sup>3</sup>)</TableCell>))}
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map(x => (
                <TableRow key={x.index}>
                  <TableCell>{x.index}</TableCell>
                  <TableCell>{formatDateTime(x.start)}</TableCell>
                  <TableCell>{x.daysSinceStart}</TableCell>
                  <TableCell>{x.duration}</TableCell>
                  {x.waterUserExtractionPoints.map(y => (<TableCell key={`${x.index}_${y.waterUserName}_${y.extractionPointName}`}>{y.total.toFixed(3)}</TableCell>))}
                  {x.extractionSources.map(y => (<TableCell key={`${x.index}_${y.extractionSourceName}`}>{y.total.toFixed(3)}</TableCell>))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Fab color="primary" sx={{ bottom: theme.spacing(3), position: "fixed", right: theme.spacing(3) }} onClick={download}>
        <DownloadIcon />
      </Fab>
    </>
  );
};