import React, { Suspense, useContext, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { DeliveryPeriodsHeader } from "./components/DeliveryPeriodsHeader";
import { DeliveryPeriodsResults } from "./components/DeliveryPeriodsResults";
import { ResultsLoading } from "../../../common/components/loading";
import { ProfileContext } from "../../../common/contexts/profile";

const DeliveryPeriodsQuery = graphql`
  query deliveryPeriodsDeliveryPeriodsQuery($catchmentId: UUID!, $start: DateTime!, $end: DateTime!) {
    waterDeliveryPeriods(where: { and: [ { start: { lte: $end } }, { end: { gte: $start } }, { catchment: { id: { eq: $catchmentId } } } ] }, order: { start: ASC }) {
      id
      start
      end
      close
      isSeasonStart
      exchangeClearings(where: { isLatest: { eq: true } }) {
        id
        run
        exchangeClearingType {
          id
          name
        }
      }
    }
  }
`;

export const DeliveryPeriodsPage = () => {
  const [ deliveryPeriodsQueryRef, loadDeliveryPeriodsQuery ] = useQueryLoader(DeliveryPeriodsQuery);
  const { catchmentId } = useContext(ProfileContext);
  const [ start, setStart ] = useState(null);
  const [ end, setEnd ] = useState(null);

  const handleExecute = ({ end, start }) => {
    setStart(start);
    setEnd(end);
    loadDeliveryPeriodsQuery({ catchmentId, end: end.format(), start: start.format() },
      { fetchPolicy: "network-only" });
  };

  return (
    <>
      <DeliveryPeriodsHeader onExecute={handleExecute} />
      <Suspense fallback={<ResultsLoading />}>
        {deliveryPeriodsQueryRef &&
          <DeliveryPeriodsResults deliveryPeriodsQueryDef={DeliveryPeriodsQuery} deliveryPeriodsQueryRef={deliveryPeriodsQueryRef}
            catchmentId={catchmentId} start={start} end={end} />}
      </Suspense>
    </>
  );
};