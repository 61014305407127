import React, { useContext, useState } from "react";
import { useMutation, usePreloadedQuery } from "react-relay";
import { Navigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Typography } from "@mui/material";
import moment from "moment";
import "moment-timezone";
import HelpIcon from "@mui/icons-material/Help";
import { Offers } from "./Offers";
import { Subheader } from "../../../../common/components/subheader";
import { ResultUserChart } from "./ResultUserChart";
import { ResultDetail } from "./ResultDetail";
import { getTotalEsiForWdp } from "../../../utils/stats";
import { NotificationContext } from "../../../../common/contexts/notification";
import { ErrorBoundary } from "../../../../common/components/errorBoundary";
import { SourceInputsByPeriodChart } from "./SourceInputsByPeriodChart";
import { SourceInputsByPeriodDetail } from "./SourceInputsByPeriodDetail";
import { Status } from "./Status";
import { chain } from "../../../../common/utils/math";
import { MeasurementsChart } from "./MeasurementsChart";
import { MeasurementsDetail } from "./MeasurementsDetail";
import { DeliveryDetail } from "./DeliveryDetail";
import { summarizeDeliveries } from "../../../utils/water";
import { ProfileContext } from "../../../../common/contexts/profile";
import { MagicHeader } from "./MagicHeader";
import { formatDateTimeLocal } from "../../../../common/utils/format";

const CreateOfferLineMutation = graphql`
  mutation ClearingCreateOfferLineMutation($input: CreateOfferLineInput!) {
    createOfferLine(input: $input) {
      offerLine {
        id
        price
        volume
        offer {
          id
        }
      }
    }
  }
`;

const UpdateOfferLineMutation = graphql`
  mutation ClearingUpdateOfferLineMutation($input: UpdateOfferLineInput!) {
    updateOfferLine(input: $input) {
      offerLine {
        id
        price
        volume
        offer {
          id
        }
      }
    }
  }
`;

const DeleteOfferLineMutation = graphql`
  mutation ClearingDeleteOfferLineMutation($input: DeleteOfferLineInput!) {
    deleteOfferLine(input: $input) {
      offerLine {
        id @deleteRecord
      }
    }
  }
`;

const DuplicateOfferMutation = graphql`
  mutation ClearingDuplicateOfferMutation($input: CreateOfferFromPreviousPeriodInput!) {
    createOfferFromPreviousPeriod(input: $input) {
      offer {
        id
        offerLines {
          id
          price
          volume
          offer {
            id
          }
        }
      }
    }
  }
`;

const updateOfferLines = (store, offerId, wdpId, wuepId, toAdd, toRemove) => {
  const offer = store.get(offerId);
  const linkName = "offerLines";
  const linkFilter = { order: { price: "DESC" } };
  let offerLines = offer.getLinkedRecords(linkName, linkFilter);
  if (!offerLines) {
    offerLines = [];
    
    const wuepLinkName = "offers";
    const wuepLinkFilter = { where: { waterDeliveryPeriod: { id: { eq: wdpId } } } };
    store.get(wuepId).setLinkedRecords([ offer ], wuepLinkName, wuepLinkFilter);
  }

  if (toAdd) {
    offerLines = [...offerLines, ...toAdd];
  }
  if (toRemove) {
    offerLines = offerLines.filter(x => !!x);
  }
  offerLines.sort((a, b) => b.getValue("price") - a.getValue("price"));
  offer.setLinkedRecords(offerLines, linkName, linkFilter);
};

const ExtendedHeader = ({ title, showIcon, onClick }) => (
  <>
    {title}
    {showIcon && <IconButton size="small" onClick={onClick} sx={{ p: 0, mt: "-2px" }}>
      <HelpIcon style={{ height: "16px", verticalAlign: "text-bottom" }} />
    </IconButton>}
  </>
);

export const Clearing = ({ clearingQueryDef, clearingQueryRef, wdpId, wuepId }) => {
  const clearingQuery = usePreloadedQuery(clearingQueryDef, clearingQueryRef);
  const [ commitCreateOfferLine ] = useMutation(CreateOfferLineMutation);
  const [ commitUpdateOfferLine ] = useMutation(UpdateOfferLineMutation);
  const [ commitDeleteOfferLine ] = useMutation(DeleteOfferLineMutation);
  const [ commitDuplicateOffer ] = useMutation(DuplicateOfferMutation);
  const { setSuccess, setError } = useContext(NotificationContext);
  const { now, nowOffset, timeZone } = useContext(ProfileContext);
  const [ showRunDialog, setShowRunDialog ] = useState(false);
  const [ showNowDialog, setShowNowDialog ] = useState(false);

  const invalidClearing = !clearingQuery.waterDeliveryPeriods || clearingQuery.waterDeliveryPeriods.length === 0
    || !clearingQuery.waterUserExtractionPoints || clearingQuery.waterUserExtractionPoints.length === 0
    || !clearingQuery.offerSummariesByWaterUserExtractionPoint
    || !clearingQuery.extractionSourceInputsForWaterDeliveryPeriodAtLatest
    || !clearingQuery.exchangeClearingConstraintForWaterUserExtractionPoint;
  const wdp = invalidClearing || clearingQuery.waterDeliveryPeriods[0];
  const wuep = invalidClearing || clearingQuery.waterUserExtractionPoints[0];
  const wuepAt = invalidClearing || clearingQuery.waterUserExtractionPointsAtLatest[0];
  const clearing = invalidClearing || (wdp.exchangeClearings.length > 0 ? wdp.exchangeClearings[0] : null);
  const constraint = invalidClearing || clearingQuery.exchangeClearingConstraintForWaterUserExtractionPoint[0];

  const onCompleted = () => setSuccess("Offer updated successfully");
  const onError = e => setError("Offer failed to update", e);

  let clearingDate = clearing ? moment.parseZone(clearing.run).tz(timeZone) : null;
  if (clearingDate && nowOffset) {
    clearingDate = clearingDate.add(moment.duration(nowOffset));
  }

  let totalInput = null;
  let isLive = false;
  if (!invalidClearing && clearing) {
    const esiAtClearing = clearingQuery.extractionSourceInputsForWaterDeliveryPeriodAtLatest;
    const esiLive = clearingQuery.extractionSourceInputsForWaterDeliveryPeriods
      && clearingQuery.extractionSourceInputsForWaterDeliveryPeriods.find(x => x.waterDeliveryPeriod.id === wdpId);

    isLive = !clearing.exchangeClearingType.isFinal && !clearingDate.isSame(now(), "day");
    const esi = isLive ? esiLive && esiLive.extractionSourceInputs : esiAtClearing;
    if (esi) {
      totalInput = getTotalEsiForWdp(wuep.extractionPoint.extractionSource, esi, wdp, clearing.exchangeClearingType.isDelivered);
    }
  }

  const handleOfferLineSave = offerLine => {
    const addLine = () => commitCreateOfferLine({
      variables: { input: { waterDeliveryPeriodId: wdp.id, waterUserExtractionPointId: wuep.id, volume: offerLine.volume, price: offerLine.price } },
      onCompleted,
      onError,
      updater: store => {
        const offerLine = store.getRootField("createOfferLine")
          .getLinkedRecord("offerLine");
        const offerId = offerLine
          .getLinkedRecord("offer")
          .getDataID();
        updateOfferLines(store, offerId, wdp.id, wuep.id, [ offerLine ]);
      }
    });

    const editLine = () => commitUpdateOfferLine({
      variables: { input: { offerLineId: offerLine.id, volume: offerLine.volume, price: offerLine.price } },
      onCompleted,
      onError,
      updater: store => updateOfferLines(store, offerLine.offer.id, wdp.id, wuep.id)
    });

    if (offerLine.id) {
      editLine();
    } else {
      addLine();
    }
  };

  const handleOfferLineDelete = offerLine => commitDeleteOfferLine({
    variables: { input: { offerLineId: offerLine.id } },
    onCompleted,
    onError,
    updater: store => updateOfferLines(store, offerLine.offer.id, wdp.id, wuep.id, null, offerLine.id)
  });

  const handleOfferDuplicate = () => commitDuplicateOffer({
    variables: { input: { currentWaterDeliveryPeriodId: wdp.id, waterUserExtractionPointId: wuep.id } },
    onCompleted,
    onError,
    updater: store => {
      const offer = store.getRootField("createOfferFromPreviousPeriod")
        .getLinkedRecord("offer");
      const offerLines = offer.getLinkedRecords("offerLines");
      updateOfferLines(store, offer.getDataID(), wdp.id, wuep.id, offerLines);
    }
  });

  if (invalidClearing) {
    return (<Navigate to="/" />);
  }

  const maxVolume = chain(wuep.maxOfftake)
    .multiply(moment.parseZone(wdp.end).diff(moment.parseZone(wdp.start), "seconds"))
    .divide(1000)
    .done();

  const isInDeliveryPhase = clearing
    && clearing.exchangeClearingType.isFinal
    && clearingQuery.waterUserExtractionPointOfftakeMeasurementsByUser
    && clearingQuery.waterUserExtractionPointOfftakeMeasurementsByUser.length > 0;

  const actions = [];
  if (clearing && !clearing.exchangeClearingType.isFinal) {
    actions.push("Review trial clearing results.");
  }
  if (!clearing || !clearing.exchangeClearingType.isFinal) {
    actions.push("Review water availability forecast to understand available volume.");
    actions.push(!wuep.offers || wuep.offers.length === 0
      ? "Place your initial offers."
      : "Adjust your initial offers.");
  }
  if (clearing && clearing.exchangeClearingType.isFinal) {
    actions.push("Review final clearing results.");
    if (isInDeliveryPhase) {
      actions.push("Review actual delivery volumes.");
    }
  }

  const clearingStatus = clearing
    ? (isInDeliveryPhase && clearing.exchangeClearingType.name === 'Final' ? 'Delivery' : clearing.exchangeClearingType.name)
    : null;

  const deliverySummary = isInDeliveryPhase
    ? summarizeDeliveries(wdp, clearing.allocations[0], clearingQuery.waterUserExtractionPointOfftakeMeasurementsByUser, clearing.exchangeClearingType.isFinal)
    : null;

  const isAfterClose = wdp.close && now().isAfter(moment.parseZone(wdp.close));

  return (
    <>
      <MagicHeader type={clearingStatus} waterDeliveryPeriod={wdp} waterUserExtractionPoint={wuep} />
      <Grid container className="clearing" sx={{ mb: 10 }}>
        <Grid item container xs={12} sx={{ mt: 2 }} className="overview">
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Status type={clearingStatus} />
          </Grid>
        </Grid>
        <Grid item xs={12} className="actions">
          <Subheader name="Instructions" />
          <Grid container item xs={12} component={Paper}>
            <Grid item xs={12}>
              <ol>
                {actions.map(x => <li key={x}><Typography variant="body2">{x}</Typography></li>)}
              </ol>
            </Grid>
          </Grid>
        </Grid>
        {isInDeliveryPhase && deliverySummary && <Grid item xs={12} sx={{ mt: 2 }} className="deliveries">
          <Subheader name={<ExtendedHeader title="Deliveries" showIcon={true} onClick={() => setShowNowDialog(true)} />} />
          {clearingQuery.waterUserExtractionPointOfftakeMeasurementsByUser && clearingQuery.waterUserExtractionPointOfftakeMeasurementsByUser.length > 0 &&
            <>
              {deliverySummary.isOver && <Alert severity="info">Water over-delivery detected. No additional charge is due.</Alert>}
              {deliverySummary.isUnder && <Alert severity="warning">Water under-delivery detected. A credit has been applied.</Alert>}
              <ErrorBoundary>
                <Paper>
                  <MeasurementsChart offtakes={wuep.waterUserExtractionPointOfftakes}
                    measurements={clearingQuery.waterUserExtractionPointOfftakeMeasurementsByUser} />
                  <MeasurementsDetail measurements={clearingQuery.waterUserExtractionPointOfftakeMeasurementsByUser} />
                </Paper>
              </ErrorBoundary>
              <DeliveryDetail allocation={clearing.allocations[0]} deliverySummary={deliverySummary} />
            </>}
        </Grid>}
        <Grid item xs={12} sx={{ mt: 2 }} className="results">
          <Subheader name={<ExtendedHeader title="Results" showIcon={!!clearing} onClick={() => setShowRunDialog(true)} />}
            link={`/delivery-periods/${wdp.id}/water-sources/${wuep.extractionPoint.extractionSource.id}`} linkName="View all results..." />
          {!clearing && <Paper sx={{ p: 2 }}>
            <Typography variant="body2">No clearing has been processed for this delivery period. Come back later to see the results.</Typography>
          </Paper>}
          {clearing && clearingQuery.offerSummariesByWaterUserExtractionPoint &&
            clearing.allocations && clearing.allocations.length > 0 && totalInput !== null &&
            <>
              <ErrorBoundary>
                <ResultUserChart offerSummariesByWaterUserExtractionPoint={clearingQuery.offerSummariesByWaterUserExtractionPoint}
                  totalInput={totalInput} lossFactor={wuepAt.lossFactor} allocation={clearing.allocations[0]}
                  wdpId={wdpId} wuepId={wuepId} constraint={constraint} showIntercept={!isLive} />
              </ErrorBoundary>
              <ResultDetail allocation={clearing.allocations[0]} clearingDate={clearingDate} />
            </>}
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }} className="river">
          <Subheader name={<ExtendedHeader title="Water Forecast" showIcon={true} onClick={() => setShowNowDialog(true)} />} />
          {clearingQuery.extractionSourceInputsForWaterDeliveryPeriods && clearingQuery.extractionSourceInputsForWaterDeliveryPeriods.length > 0 &&
            <ErrorBoundary>
              <Paper>
                <SourceInputsByPeriodChart inputs={clearingQuery.extractionSourceInputsForWaterDeliveryPeriods} waterUserExtractionPoint={wuep} />
                <SourceInputsByPeriodDetail inputs={clearingQuery.extractionSourceInputsForWaterDeliveryPeriods} waterUserExtractionPoint={wuep}
                  waterDeliveryPeriod={wdp} />
              </Paper>
            </ErrorBoundary>}
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }} className="offers">
          <Subheader name={<ExtendedHeader title="Offers" showIcon={true} onClick={() => setShowNowDialog(true)} />} />
          <Offers offers={wuep.offers} onOfferLineSave={handleOfferLineSave} onOfferLineDelete={handleOfferLineDelete}
            isFinal={clearing?.exchangeClearingType.isFinal || isAfterClose} onOfferDuplicate={handleOfferDuplicate}
            maxVolume={maxVolume} waterDeliveryPeriod={wdp} />
        </Grid>
      </Grid>
      <Dialog open={showRunDialog} onClose={() => setShowRunDialog(false)}>
        <DialogTitle>About</DialogTitle>
        <DialogContent>
          <DialogContentText>This section shows the market as of the time of the most recent clearing{clearing ? ` (${formatDateTimeLocal(clearing.run)})` : ''}.</DialogContentText>
          {isLive && <DialogContentText sx={{ mt: 1 }}>Available supply reflects the latest forecast.</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowRunDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showNowDialog} onClose={() => setShowNowDialog(false)}>
        <DialogTitle>About</DialogTitle>
        <DialogContent>
          <DialogContentText>This section always shows the latest information available.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNowDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};