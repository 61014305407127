import React from "react";
import { Grid, Link, Paper, Typography } from "@mui/material";
import { LinkWrap } from "../../../../common/components/linkWrap";
import { StatIcon } from "./StatIcon";
import { Icon } from "./Icon";

export const NowTile = ({ label, num, direction, hideUnits, sx, to, isMissingOffers, isInProgress }) => {
  return (
    <Grid item xs={6} sm={4} sx={sx}>
      <Paper sx={{ height: "100%", p: { xs: 1, sm: 2 } }}>
        <Typography component="div" sx={{ fontSize: "22px" }}>
          <Link component={LinkWrap} to={to}>{num == null ? "-" : num}</Link>
          {direction && <StatIcon direction={direction} />}
          {isMissingOffers && <Icon type="isMissingOffers" />}
          {!isMissingOffers && isInProgress && <Icon type="isInProgress" />}
        </Typography>
        <Typography component="div" variant="caption">
          {label}
        </Typography>
        {!hideUnits && <Typography component="div" variant="caption" sx={{ mt: "-5px" }}>
          k.m<sup>3</sup>
        </Typography>}
      </Paper>
    </Grid>
  );
};