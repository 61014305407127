/**
 * @generated SignedSource<<ba178ac786929d8ce7595f1b844b8355>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type actualDeliveriesActualDeliveriesResultsQuery$variables = {|
  waterDeliveryPeriodId: any,
|};
export type actualDeliveriesActualDeliveriesResultsQuery$data = {|
  +actualDeliveriesForExtractionSources: ?$ReadOnlyArray<?{|
    +daysSinceStart: number,
    +duration: number,
    +extractionSourceName: ?string,
    +index: number,
    +start: any,
    +total: any,
  |}>,
  +actualDeliveriesForWaterUserExtractionPoints: ?$ReadOnlyArray<?{|
    +daysSinceStart: number,
    +duration: number,
    +extractionPointName: ?string,
    +index: number,
    +start: any,
    +total: any,
    +waterUserName: ?string,
  |}>,
|};
export type actualDeliveriesActualDeliveriesResultsQuery = {|
  response: actualDeliveriesActualDeliveriesResultsQuery$data,
  variables: actualDeliveriesActualDeliveriesResultsQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "waterDeliveryPeriodId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "waterDeliveryPeriodId",
    "variableName": "waterDeliveryPeriodId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "daysSinceStart",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "ActualWaterUserExtractionPointDelivery",
    "kind": "LinkedField",
    "name": "actualDeliveriesForWaterUserExtractionPoints",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "waterUserName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "extractionPointName",
        "storageKey": null
      },
      (v6/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "ActualExtractionSourceDelivery",
    "kind": "LinkedField",
    "name": "actualDeliveriesForExtractionSources",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "extractionSourceName",
        "storageKey": null
      },
      (v6/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "actualDeliveriesActualDeliveriesResultsQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "actualDeliveriesActualDeliveriesResultsQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "f56d141a6c33a67932fa086f2229cf69",
    "id": null,
    "metadata": {},
    "name": "actualDeliveriesActualDeliveriesResultsQuery",
    "operationKind": "query",
    "text": "query actualDeliveriesActualDeliveriesResultsQuery(\n  $waterDeliveryPeriodId: UUID!\n) {\n  actualDeliveriesForWaterUserExtractionPoints(waterDeliveryPeriodId: $waterDeliveryPeriodId) {\n    index\n    start\n    daysSinceStart\n    duration\n    waterUserName\n    extractionPointName\n    total\n  }\n  actualDeliveriesForExtractionSources(waterDeliveryPeriodId: $waterDeliveryPeriodId) {\n    index\n    start\n    daysSinceStart\n    duration\n    extractionSourceName\n    total\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "d4b184b2becb25c6592455f90ee72e77";

module.exports = ((node/*: any*/)/*: Query<
  actualDeliveriesActualDeliveriesResultsQuery$variables,
  actualDeliveriesActualDeliveriesResultsQuery$data,
>*/);
