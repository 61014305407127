import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tab, TextField, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import "moment-timezone";
import { dateEditFormatString } from "../../../../common/utils/format";
import { ProfileContext } from "../../../../common/contexts/profile";
import { safeParse } from "../../../../common/utils/json";

const handleChange = (set, setError, label) => e => {
  if (e.target.value === "") {
    set(null);
    setError(false);
    return;
  }

  const p = parseFloat(e.target.value);
  const ok = !isNaN(p) && p >= 0;
  set(p);
  setError(ok ? false : `${label} must be empty or greater than or equal to 0.`);
};

const cleanValue = v => v === "" ? null : v;

export const ExtractionSourceInputDialog = ({ open, target, extractionSourceConfig, onCancel, onDelete, onSave }) => {
  const { now, timeZone } = useContext(ProfileContext);
  const [ date, setDate ] = useState(null);
  const [ dateError, setDateError ] = useState(false);
  const [ forecastGross, setForecastGross ] = useState(null);
  const [ forecastGrossError, setForecastGrossError ] = useState(false);
  const [ forecastNet, setForecastNet ] = useState(null);
  const [ forecastNetError, setForecastNetError ] = useState(false);
  const [ actualGross, setActualGross ] = useState(null);
  const [ actualGrossError, setActualGrossError ] = useState(false);
  const [ actualNet, setActualNet ] = useState(null);
  const [ actualNetError, setActualNetError ] = useState(false);
  const [ typeTab, setTypeTab ] = useState("0");
  const config = safeParse(extractionSourceConfig);

  useEffect(() => {
    setDateError(date ? false : "Date is required.");
  }, [ date, setDateError ]);

  useEffect(() => {
    setDate(target ? moment.parseZone(target.date) : now());
    setForecastGross(target ? target.forecastGross : "");
    setForecastNet(target ? target.forecastNet : "");
    setActualGross(target ? target.actualGross : "");
    setActualNet(target ? target.actualNet : "");
  }, [ now, target ]);

  const handleSave = () => onSave({
    date: date.tz(timeZone).startOf("day").format(),
    forecastGross: cleanValue(forecastGross),
    forecastNet: cleanValue(forecastNet),
    actualGross: cleanValue(actualGross),
    actualNet: cleanValue(actualNet)
  });

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{target ? "Edit" : "Add"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Configure the extraction source input.
        </DialogContentText>
        <DatePicker
          renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" error={!!dateError} helperText={dateError} {...props} />}
          label="Date"
          value={date}
          onChange={setDate}
          inputFormat={dateEditFormatString}
          mask="__/__/__"
        />
        <TabContext value={typeTab}>
          <Box sx={{ mt: 2, borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={(e, v) => setTypeTab(v)}>
              <Tab label="Forecast" value="0" />
              <Tab label="Actual" value="1" />
            </TabList>
          </Box>
          <TabPanel value="0" sx={{ pl: 0, pr: 0 }}>
            <TextField margin="dense" id="forecastGross" label={config.labels?.Gross} type="number" fullWidth variant="standard"
              value={forecastGross} onChange={handleChange(setForecastGross, setForecastGrossError, config.labels?.Gross)} error={!!forecastGrossError} helperText={forecastGrossError} />
            <TextField margin="dense" id="forecastNet" label={config.labels?.Net} type="number" fullWidth variant="standard"
              value={forecastNet} onChange={handleChange(setForecastNet, setForecastNetError, config.labels?.Net)} error={!!forecastNetError} helperText={forecastNetError} />
          </TabPanel>
          <TabPanel value="1" sx={{ pl: 0, pr: 0 }}>
            <TextField margin="dense" id="actualGross" label={config.labels?.Gross} type="number" fullWidth variant="standard"
              value={actualGross} onChange={handleChange(setActualGross, setActualGrossError, config.labels?.Gross)} error={!!actualGrossError} helperText={actualGrossError} />
            <TextField margin="dense" id="actualNet" label={config.labels?.Net} type="number" fullWidth variant="standard"
              value={actualNet} onChange={handleChange(setActualNet, setActualNetError, config.labels?.Net)} error={!!actualNetError} helperText={actualNetError} />
          </TabPanel>
        </TabContext>
        <Typography variant="body1" sx={{ mb: 3, mt: 1 }}>Leave {config.labels?.Net} empty to automatically calculate using extraction source rules.</Typography>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        {target ? <Button onClick={onDelete} color="error">Delete</Button> : <div />}
        <Box>
          <Button onClick={onCancel}>Cancel</Button>
          {!target && <Button onClick={handleSave} disabled={!!dateError || !!forecastGrossError || !!forecastNetError || !!actualGrossError || !!actualNetError}>Add</Button>}
          {target && <Button onClick={handleSave} disabled={!!dateError || !!forecastGrossError || !!forecastNetError || !!actualGrossError || !!actualNetError}>Edit</Button>}
        </Box>
      </DialogActions>
    </Dialog>
  )
};