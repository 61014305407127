import React from "react";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { formatCurrency, formatVolume } from "../../../../common/utils/format";

export const AllocationsTable = ({ allocations }) => (
  <Grid item xs={12}>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Water User</TableCell>
            <TableCell>Extraction Point</TableCell>
            <TableCell>Total Quantity</TableCell>
            <TableCell>Spot Price</TableCell>
            <TableCell>Total Charge</TableCell>
            <TableCell>Clearing Fee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allocations.map(x => (
            <TableRow key={x.id}>
              <TableCell>{x.waterUserExtractionPoint.waterUser.name}</TableCell>
              <TableCell>{x.waterUserExtractionPoint.extractionPoint.name}</TableCell>
              <TableCell>{formatVolume(x.totalQuantity)}</TableCell>
              <TableCell>{formatCurrency(x.spotPrice)}</TableCell>
              <TableCell>{formatCurrency(x.totalNetCharge)}</TableCell>
              <TableCell>{formatCurrency(x.clearingFee)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>
);