import React, { useContext, useRef } from "react";
import { FormControl, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import FileUpload from "@mui/icons-material/FileUpload";
import Papa from "papaparse";
import moment from "moment";
import { NotificationContext } from "../../../common/contexts/notification";

export const ImportProperties = ({ comment, fileName, offtakes, offtakeId, setOfftakeId, qualityTypes, qualityTypeId, setComment, setFileName, setQualityTypeId, setMeasurements }) => {
  const { setError } = useContext(NotificationContext);
  const uploadMeasurementsRef = useRef();

  const handleOfftakeChange = evt => setOfftakeId(evt.target.value);
  const handleQualityTypeChange = evt => setQualityTypeId(evt.target.value);
  const handleCommentChange = evt => setComment(evt.target.value);

  const handleUpload = evt => {
    var file = evt.target.files[0];
    Papa.parse(file, {
      complete: results => {
        if (uploadMeasurementsRef.current) {
          uploadMeasurementsRef.current.value = "";
        }

        if (results.errors && results.errors.length > 0) {
          setError(`Unable to parse file: ${results.errors[0].message}`);
          return;
        }

        if (results.data.length > 0
          && results.data[0].length === 3
          && results.data[0][0] === "Date" && results.data[0][1] === "Time" && results.data[0][2].endsWith("(l/s)")) {
          let i = 0;
          setMeasurements(results.data
            .slice(1) // skip header row
            .filter(x => x[0] !== "" && x[1] !== "" && x[2] !== "")
            .map(x => ({
              id: i++,
              date: moment.parseZone(`${x[0]} ${x[1]} +12:00`, "D/M/YYYY HH:mm:ss ZZ").format(),
              flowRate: parseFloat(x[2]),
              total: null
            })));
        } else if (results.data.length > 1
          && results.data[0].length > 1
          && results.data[0][1] !== "" // title row
          && results.data[1].length === 5
          && results.data[1][0] === "Period End Time (New Zealand Standard Time)" // header row
          && results.data[1][1] === ""
          && results.data[1][2] === "Values (l/s)"
          && results.data[1][3] === "Cumulative (m3)"
          && results.data[1][4] === "Period End Time (Daylight Saving Adjusted)") {
          let i = 0;
          setMeasurements(results.data
            .slice(2) // skip title + header row
            .filter(x => x[0] !== "" && x[1] !== "" && x[2] !== "" && x[3] !== "")
            .map(x => ({
              id: i++,
              date: moment.parseZone(`${x[0]}+12:00`).format(),
              flowRate: parseFloat(x[1]),
              total: parseFloat(x[2])
            })));
        } else {
          setError("Unrecognized file format");
          return;
        }

        setFileName(file.name);
      },
      delimiter: ",",
      header: false,
      skipEmptyLines: true
    });
  };

  return (
    <>
      {offtakes && <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="offtake-select-label" variant="standard">Offtake</InputLabel>
        <Select
          labelId="offtake-select-label"
          id="offtake-select"
          value={offtakeId}
          label="Offtake"
          variant="standard"
          onChange={handleOfftakeChange}
        >
          {offtakes.map(offtake => <MenuItem key={offtake.id} value={offtake.id}>{offtake.name}</MenuItem>)}
        </Select>
      </FormControl>}
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="qt-select-label" variant="standard">Quality</InputLabel>
        <Select
          labelId="qt-select-label"
          id="qt-select"
          value={qualityTypeId}
          label="Quality"
          variant="standard"
          onChange={handleQualityTypeChange}
        >
          {qualityTypes.map(qt => <MenuItem key={qt.id} value={qt.id}>{qt.name}</MenuItem>)}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <TextField
          id="comments"
          label="Comments"
          multiline
          rows={3}
          variant="standard"
          value={comment}
          onChange={handleCommentChange}
        />
      </FormControl>
      <FormControl disabled fullWidth sx={{ mt: 2 }}>
        <input
          style={{ display: 'none' }}
          id="upload-measurements-file"
          name="upload-measurements-file"
          type="file"
          ref={uploadMeasurementsRef}
          onChange={handleUpload}
        />
        <InputLabel htmlFor="upload-measurements" variant="standard">Upload measurements</InputLabel>
        <Input
          id="upload-measurements"
          value={fileName}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => uploadMeasurementsRef.current && uploadMeasurementsRef.current.click()}>
                <FileUpload />
              </IconButton>
            </InputAdornment>
          } />
      </FormControl>
    </>
  );
};