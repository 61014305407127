/**
 * @generated SignedSource<<f13bbb0a71f35733c0257a36288f16e2>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type transactionTransactionHeaderQuery$variables = {|
  accountId: any,
  catchmentId: any,
|};
export type transactionTransactionHeaderQuery$data = {|
  +myAccounts: ?$ReadOnlyArray<?{|
    +accountBalance: ?{|
      +value: any,
    |},
    +id: ?string,
    +name: ?string,
  |}>,
|};
export type transactionTransactionHeaderQuery = {|
  response: transactionTransactionHeaderQuery$data,
  variables: transactionTransactionHeaderQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "catchmentId"
},
v2 = [
  {
    "fields": [
      {
        "items": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "eq",
                        "variableName": "catchmentId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "catchment"
              }
            ],
            "kind": "ObjectValue",
            "name": "and.0"
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "eq",
                    "variableName": "accountId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "and.1"
          }
        ],
        "kind": "ListValue",
        "name": "and"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "transactionTransactionHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "myAccounts",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountBalance",
            "kind": "LinkedField",
            "name": "accountBalance",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "transactionTransactionHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "myAccounts",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountBalance",
            "kind": "LinkedField",
            "name": "accountBalance",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d988bbb6d2f20bcc01fa528e1ea0aab",
    "id": null,
    "metadata": {},
    "name": "transactionTransactionHeaderQuery",
    "operationKind": "query",
    "text": "query transactionTransactionHeaderQuery(\n  $catchmentId: UUID!\n  $accountId: UUID!\n) {\n  myAccounts(where: {and: [{catchment: {id: {eq: $catchmentId}}}, {id: {eq: $accountId}}]}) {\n    id\n    name\n    accountBalance {\n      value\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "15413fcdd92642c626a563605f1446c3";

module.exports = ((node/*: any*/)/*: Query<
  transactionTransactionHeaderQuery$variables,
  transactionTransactionHeaderQuery$data,
>*/);
