import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Paper, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { dateEditFormatString } from "../../utils/format";
import { ProfileContext } from "../../../common/contexts/profile";

export const QueryPanel = ({ onExecute, startOffset, endOffset }) => {
  const [ end, setEnd ] = useState(null);
  const [ start, setStart ] = useState(null);
  const [ isFirstLoad, setIsFirstLoad ] = useState(true);
  const { now } = useContext(ProfileContext);

  useEffect(() => {
    if (!start && !end) {
      const defaultStart = now({ ignoreTz: true }).add(startOffset, "day");
      setStart(defaultStart);

      const defaultEnd = now({ ignoreTz: true }).add(endOffset, "day");
      setEnd(defaultEnd);
    }
  }, [ end, endOffset, now, start, startOffset ]);

  useEffect(() => {
    if (isFirstLoad && start && end) {
      onExecute({ end, start });
      setIsFirstLoad(false);
    }
  }, [ end, isFirstLoad, onExecute, start ]);

  const handleQuery = () => onExecute({ end, start });

  return (
    <Grid container component={Paper}>
      <Grid container item sx={{ p: 2 }} spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <DatePicker
            renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" {...props} />}
            label="Start"
            value={start}
            onChange={setStart}
            inputFormat={dateEditFormatString}
            mask="__/__/__" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DatePicker
            renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" {...props} />}
            label="End"
            value={end}
            onChange={setEnd}
            inputFormat={dateEditFormatString}
            mask="__/__/__" />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleQuery}>Search</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

QueryPanel.defaultProps = {
  startOffset: -7,
  endOffset: 14
};