import React, { Suspense, useContext, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { useParams } from "react-router-dom";
import { DeliveryPeriod } from "./components/DeliveryPeriod";
import { CenteredLoading } from "../../../common/components/loading";
import { ProfileContext } from "../../../common/contexts/profile";

const DeliveryPeriodQuery = graphql`
  query deliveryPeriodDeliveryPeriodQuery($catchmentId: UUID!, $wdpId: UUID!) {
    waterDeliveryPeriods(where: { and: [ { id: { eq: $wdpId } }, { catchment: { id: { eq: $catchmentId } } } ] }) {
      id
      start
      end
      close
      catchment {
        id
        waterUsers {
          id
          name
          publicId
          waterUserExtractionPoints {
            id
            extractionPoint {
              id
              name
            }
          }
        }
      }
      exchangeClearings(order: { run: DESC }) {
        id
        isLatest
        run
        submissionState
        exchangeClearingType {
          id
          name
          isFinal
          order
        }
        allocations {
          id
          baseQuantityPreLoss
          baseQuantity
          totalQuantityPreLoss
          totalQuantity
          changeQuantity
          farmSurcharge
          spotPricePreLoss
          spotPrice
          farmCongestionPrice
          raceCongestionPrice
          effectiveGrossPrice
          effectiveNetPrice
          quantityDisposedCreditPreLoss
          quantityObtainedCharge
          raceCongestionCharge
          raceCongestionCredit
          totalGrossCharge
          totalNetCharge
          clearingFee
          constraint
          waterUserExtractionPoint {
            id
            extractionPoint {
              id
              name
            }
            waterUser {
              id
              name
              publicId
            }
          }
        }
      }
    }
    exchangeClearingTypes {
      id
      name
      isDelivered
      order
    }
  }
`;

export const DeliveryPeriodPage = () => {
  const [ deliveryPeriodQueryRef, loadDeliveryPeriodQuery ] = useQueryLoader(DeliveryPeriodQuery);
  const { wdpId } = useParams();
  const { catchmentId } = useContext(ProfileContext);
  useEffect(() => loadDeliveryPeriodQuery({ catchmentId, wdpId },
    { fetchPolicy: "network-only" }),
    [ catchmentId, loadDeliveryPeriodQuery, wdpId ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {deliveryPeriodQueryRef && <DeliveryPeriod deliveryPeriodQueryDef={DeliveryPeriodQuery} deliveryPeriodQueryRef={deliveryPeriodQueryRef} />}
    </Suspense>
  );
};