import React, { useContext } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation, usePreloadedQuery } from "react-relay";
import { NotificationContext } from "../../../../common/contexts/notification";
import { ImportWizardDialog } from "../../../components/import/ImportWizardDialog";

const UpdateExtractionSourceMeasurementsMutation = graphql`
  mutation ExtractionSourceImportWizardDialogUpdateExtractionSourceMeasurementsMutation($input: UpdateExtractionSourceMeasurementsInput!) {
    updateExtractionSourceMeasurements(input: $input) {
      extractionSourceMeasurement {
        id
        date
        flowRate
        total
        modifiedByUser {
          id
        }
        importOperation {
          id
        }
        measurementQualityType {
          id
        }
      }
    }
  }
`;

export const ExtractionSourceImportWizardDialog = ({ open, onCancel, onSave, extractionSourceId, extractionSourceQueryDef, extractionSourceQueryRef }) => {
  const extractionSourceQuery = usePreloadedQuery(extractionSourceQueryDef, extractionSourceQueryRef);

  const defaultQualityTypeId = extractionSourceQuery && extractionSourceQuery.measurementQualityTypes.length > 0
    ? extractionSourceQuery.measurementQualityTypes[0].id
    : "";

  const qualityTypes = extractionSourceQuery && extractionSourceQuery.measurementQualityTypes
    ? extractionSourceQuery.measurementQualityTypes
    : [];

  const [ commitMeasurements ] = useMutation(UpdateExtractionSourceMeasurementsMutation);
  const { setSuccess, setError } = useContext(NotificationContext);

  const handleSave = ({ comment, fileName, measurements, setIsSaving, qualityTypeId }) => {
    setIsSaving(true);

    commitMeasurements({
      variables: {
        input: {
          comment,
          fileName,
          extractionSourceId,
          extractionSourceMeasurements:
            measurements.map(x => ({
              date: x.date,
              flowRate: x.flowRate,
              total: x.total,
              measurementQualityTypeId: qualityTypeId
            }))
        }
      },
      onCompleted: () => {
        setIsSaving(false);
        setSuccess("Source measurements updated successfully");
        onSave();
      },
      onError: e => {
        setIsSaving(false);
        setError("Source measurements failed to update", e);
      }
    });
  };

  return (<ImportWizardDialog open={open} onCancel={onCancel} onSave={handleSave}
    defaultQualityTypeId={defaultQualityTypeId} qualityTypes={qualityTypes} />);
};