import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { GridNode } from "../../../../common/components/gridNode";
import { formatCurrency, formatDateTimeLocal, formatVolume } from "../../../../common/utils/format";

export const ResultDetail = ({ spotPrice, run, total }) => (
  <Grid container item component={Paper} sx={{ mt: 1, p: 2 }}>
    <Grid item xs={12}>
      <Typography sx={{ mb: 2 }} variant="body1">Outputs (as of {formatDateTimeLocal(run)})</Typography>
    </Grid>
    <Grid container item xs={12}>
      <GridNode name="Total" value={formatVolume(total)} isDense />
      {spotPrice && <GridNode name="Spot price" value={<>{formatCurrency(spotPrice, 3)}&#8203;/k.m<sup>3</sup></>} isDense />}
    </Grid>
  </Grid>
);