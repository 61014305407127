/**
 * @generated SignedSource<<7848d352ba4ce13bc2104626c431f941>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type extractionSourceExtractionSourceQuery$variables = {|
  catchmentId: any,
  extractionSourceId: any,
|};
export type extractionSourceExtractionSourceQuery$data = {|
  +extractionSources: ?$ReadOnlyArray<?{|
    +actualName: ?string,
    +actualSource: ?string,
    +config: ?string,
    +forecastName: ?string,
    +forecastSource: ?string,
    +id: ?string,
    +inputOffset: ?number,
    +name: ?string,
  |}>,
  +measurementQualityTypes: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
  |}>,
|};
export type extractionSourceExtractionSourceQuery = {|
  response: extractionSourceExtractionSourceQuery$data,
  variables: extractionSourceExtractionSourceQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "catchmentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "extractionSourceId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "catchmentId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "catchment"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "eq",
                        "variableName": "extractionSourceId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.0"
              },
              {
                "fields": (v1/*: any*/),
                "kind": "ObjectValue",
                "name": "and.1"
              }
            ],
            "kind": "ListValue",
            "name": "and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "ExtractionSource",
    "kind": "LinkedField",
    "name": "extractionSources",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "config",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "inputOffset",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "forecastSource",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "forecastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualSource",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualName",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order",
        "value": {
          "name": "ASC"
        }
      },
      {
        "fields": (v1/*: any*/),
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "MeasurementQualityType",
    "kind": "LinkedField",
    "name": "measurementQualityTypes",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "extractionSourceExtractionSourceQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "extractionSourceExtractionSourceQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "b847940c8febc93252f6a57aff39720a",
    "id": null,
    "metadata": {},
    "name": "extractionSourceExtractionSourceQuery",
    "operationKind": "query",
    "text": "query extractionSourceExtractionSourceQuery(\n  $catchmentId: UUID!\n  $extractionSourceId: UUID!\n) {\n  extractionSources(where: {and: [{id: {eq: $extractionSourceId}}, {catchment: {id: {eq: $catchmentId}}}]}) {\n    id\n    name\n    config\n    inputOffset\n    forecastSource\n    forecastName\n    actualSource\n    actualName\n  }\n  measurementQualityTypes(where: {catchment: {id: {eq: $catchmentId}}}, order: {name: ASC}) {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "e8b73b54a136d732a8af7c25450757f8";

module.exports = ((node/*: any*/)/*: Query<
  extractionSourceExtractionSourceQuery$variables,
  extractionSourceExtractionSourceQuery$data,
>*/);
