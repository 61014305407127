import React, { Suspense, useContext, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { useParams } from "react-router-dom";
import { CenteredLoading, ResultsLoading } from "../../../common/components/loading";
import { ProfileContext } from "../../../common/contexts/profile";
import { WaterUserExtractionPointHeader } from "./components/WaterUserExtractionPointHeader";
import { WaterUserExtractionPointResults } from "./components/WaterUserExtractionPointResults";

const WaterUserExtractionPointHeaderQuery = graphql`
  query waterUserExtractionPointWaterUserExtractionPointHeaderQuery($catchmentId: UUID!, $wuepId: UUID!) {
    waterUserExtractionPoints(where: { and: [ { id: { eq: $wuepId } }, { catchment: { id: { eq: $catchmentId } } } ] }) {
      id
      lossFactor
      maxOfftake
      extractionPoint {
        id
        name
      }
      waterUser {
        id
        name
      }
      waterUserExtractionPointOfftakes(order: { name: ASC }) {
        id
        name
      }
    }
    extractionPointOfftakeTypes(where: { catchment: { id: { eq: $catchmentId } } }) {
      id
      name
    }
    measurementQualityTypes(where: { catchment: { id: { eq: $catchmentId } } }, order: { name: ASC }) {
      id
      name
    }
  }
`;

const WaterUserExtractionPointResultsQuery = graphql`
  query waterUserExtractionPointWaterUserExtractionPointResultsQuery($catchmentId: UUID!, $wuepId: UUID!, $start: DateTime!, $end: DateTime!, $extractionPointOfftakeTypes: [UUID!]) {
    waterUserExtractionPointOfftakes(where: { and: [ { waterUserExtractionPoint: { id: { eq: $wuepId } } }, { catchment: { id: { eq: $catchmentId } } }, { extractionPointOfftakeType: { id: { in: $extractionPointOfftakeTypes } } } ] }) {
      id
      name
      waterUserExtractionPointOfftakeMeasurements(where: { date: { gte: $start, lte: $end } }, order: { date: ASC }) {
        id
        date
        flowRate
        total
        modifiedByUser {
          id
        }
        importOperation {
          id
        }
        measurementQualityType {
          id
        }
      }
    }
  }
`;

export const WaterUserExtractionPointPage = () => {
  const [ waterUserExtractionPointHeaderQueryRef, loadWaterUserExtractionPointHeaderQuery ] = useQueryLoader(WaterUserExtractionPointHeaderQuery);
  const [ waterUserExtractionPointResultsQueryRef, loadWaterUserExtractionPointResultsQuery ] = useQueryLoader(WaterUserExtractionPointResultsQuery);
  const { catchmentId } = useContext(ProfileContext);
  const { wuepId } = useParams();

  const handleExecute = ({ end, start, extractionPointOfftakeTypes }) =>
    loadWaterUserExtractionPointResultsQuery({
      catchmentId,
      end: end.format(),
      start: start.format(),
      extractionPointOfftakeTypes,
      wuepId
    }, { fetchPolicy: "network-only" });

  useEffect(() => loadWaterUserExtractionPointHeaderQuery({ catchmentId, wuepId },
    { fetchPolicy: "network-only" }),
    [ catchmentId, wuepId, loadWaterUserExtractionPointHeaderQuery ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {waterUserExtractionPointHeaderQueryRef &&
        <WaterUserExtractionPointHeader
          waterUserExtractionPointHeaderQueryDef={WaterUserExtractionPointHeaderQuery}
          waterUserExtractionPointHeaderQueryRef={waterUserExtractionPointHeaderQueryRef}
          onExecute={handleExecute} />}
      <Suspense fallback={<ResultsLoading />}>
        {waterUserExtractionPointResultsQueryRef &&
          <WaterUserExtractionPointResults
            waterUserExtractionPointHeaderQueryDef={WaterUserExtractionPointHeaderQuery}
            waterUserExtractionPointHeaderQueryRef={waterUserExtractionPointHeaderQueryRef}
            waterUserExtractionPointResultsQueryDef={WaterUserExtractionPointResultsQuery}
            waterUserExtractionPointResultsQueryRef={waterUserExtractionPointResultsQueryRef} />}
      </Suspense>
    </Suspense>
  );
};