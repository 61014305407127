import React from "react";
import { Typography } from "@mui/material";
import { StatIcon } from "./StatIcon";

export const Stat = ({ num, label, direction }) => (
  <>
    <Typography component="div" sx={{ fontSize: "22px" }}>
      {num == null ? "-" : num}
      {direction && <StatIcon direction={direction} />}
    </Typography>
    <Typography component="div" variant="caption">
      {label}
    </Typography>
    <Typography component="div" variant="caption" sx={{ mt: "-5px" }}>
      k.m<sup>3</sup>
    </Typography>
  </>
);