/**
 * @generated SignedSource<<3a82611ffe4929db15119c1e09a7ae33>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type ResultUserChartDownloadDownloadQuery$variables = {|
  wdpId: any,
|};
export type ResultUserChartDownloadDownloadQuery$data = {|
  +offerSummariesDetailed: ?$ReadOnlyArray<?{|
    +adjustedMaxOfftake: any,
    +adjustedPrice: any,
    +adjustedVolume: any,
    +lossFactor: any,
    +maxOfftake: any,
    +offer: ?{|
      +id: ?string,
      +waterUserExtractionPoint: ?{|
        +id: ?string,
        +publicId: ?string,
        +waterUser: ?{|
          +id: ?string,
          +name: ?string,
          +publicId: number,
        |},
      |},
    |},
    +offerLineId: any,
    +price: any,
    +volume: any,
  |}>,
|};
export type ResultUserChartDownloadDownloadQuery = {|
  response: ResultUserChartDownloadDownloadQuery$data,
  variables: ResultUserChartDownloadDownloadQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "wdpId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicId",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "waterDeliveryPeriodId",
        "variableName": "wdpId"
      }
    ],
    "concreteType": "NormalizedOfferLine",
    "kind": "LinkedField",
    "name": "offerSummariesDetailed",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "offerLineId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "volume",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "adjustedVolume",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "adjustedPrice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lossFactor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maxOfftake",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "adjustedMaxOfftake",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Offer",
        "kind": "LinkedField",
        "name": "offer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "WaterUserExtractionPoint",
            "kind": "LinkedField",
            "name": "waterUserExtractionPoint",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WaterUser",
                "kind": "LinkedField",
                "name": "waterUser",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResultUserChartDownloadDownloadQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResultUserChartDownloadDownloadQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "9be75e1c0cb4ad1f88c3f4a2b4e4db06",
    "id": null,
    "metadata": {},
    "name": "ResultUserChartDownloadDownloadQuery",
    "operationKind": "query",
    "text": "query ResultUserChartDownloadDownloadQuery(\n  $wdpId: UUID!\n) {\n  offerSummariesDetailed(waterDeliveryPeriodId: $wdpId) {\n    offerLineId\n    volume\n    price\n    adjustedVolume\n    adjustedPrice\n    lossFactor\n    maxOfftake\n    adjustedMaxOfftake\n    offer {\n      id\n      waterUserExtractionPoint {\n        id\n        publicId\n        waterUser {\n          id\n          name\n          publicId\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "efe861fb425468069a3858fc19bf7794";

module.exports = ((node/*: any*/)/*: Query<
  ResultUserChartDownloadDownloadQuery$variables,
  ResultUserChartDownloadDownloadQuery$data,
>*/);
