/**
 * @generated SignedSource<<8380420a19ad2cba470c99d672f8b305>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type accountAccountQuery$variables = {|
  accountId: any,
  catchmentId: any,
|};
export type accountAccountQuery$data = {|
  +accountHierarchy: ?$ReadOnlyArray<?{|
    +accountId: any,
    +fullPath: ?string,
  |}>,
  +accounts: ?$ReadOnlyArray<?{|
    +accountBalance: ?{|
      +value: any,
    |},
    +accountType: ?{|
      +code: ?string,
      +name: ?string,
    |},
    +id: ?string,
    +name: ?string,
    +transactionLineItems: ?$ReadOnlyArray<?{|
      +id: ?string,
      +transaction: ?{|
        +date: any,
        +id: ?string,
        +memo: ?string,
        +referenceId: ?any,
        +referenceTypeName: ?string,
        +transactionLineItems: ?$ReadOnlyArray<?{|
          +account: ?{|
            +accountType: ?{|
              +code: ?string,
              +name: ?string,
            |},
            +id: ?string,
            +name: ?string,
            +referenceTypeName: ?string,
          |},
          +id: ?string,
          +value: any,
        |}>,
        +transactionMetadata: ?$ReadOnlyArray<?{|
          +name: ?string,
          +value: ?string,
        |}>,
      |},
      +value: any,
    |}>,
  |}>,
  +allAccounts: ?$ReadOnlyArray<?{|
    +accountType: ?{|
      +code: ?string,
      +name: ?string,
    |},
    +id: ?string,
    +name: ?string,
    +parentId: ?any,
  |}>,
|};
export type accountAccountQuery = {|
  response: accountAccountQuery$data,
  variables: accountAccountQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "catchmentId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "eq",
    "variableName": "catchmentId"
  }
],
v3 = [
  {
    "fields": [
      {
        "fields": (v2/*: any*/),
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "catchment"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "eq",
    "variableName": "accountId"
  }
],
v5 = [
  {
    "fields": (v4/*: any*/),
    "kind": "ObjectValue",
    "name": "id"
  }
],
v6 = [
  {
    "kind": "Literal",
    "name": "order",
    "value": {
      "name": "ASC"
    }
  },
  {
    "fields": [
      {
        "items": [
          {
            "fields": (v3/*: any*/),
            "kind": "ObjectValue",
            "name": "and.0"
          },
          {
            "fields": [
              {
                "items": [
                  {
                    "fields": (v5/*: any*/),
                    "kind": "ObjectValue",
                    "name": "or.0"
                  },
                  {
                    "fields": [
                      {
                        "fields": (v4/*: any*/),
                        "kind": "ObjectValue",
                        "name": "parentId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "or.1"
                  }
                ],
                "kind": "ListValue",
                "name": "or"
              }
            ],
            "kind": "ObjectValue",
            "name": "and.1"
          }
        ],
        "kind": "ListValue",
        "name": "and"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountType",
  "kind": "LinkedField",
  "name": "accountType",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "order",
    "value": {
      "transaction": {
        "date": "DESC"
      }
    }
  },
  {
    "fields": [
      {
        "fields": (v5/*: any*/),
        "kind": "ObjectValue",
        "name": "account"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "memo",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referenceTypeName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referenceId",
  "storageKey": null
},
v17 = [
  {
    "fields": (v3/*: any*/),
    "kind": "ObjectValue",
    "name": "where"
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentId",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "items": [
            {
              "fields": [
                {
                  "fields": (v2/*: any*/),
                  "kind": "ObjectValue",
                  "name": "catchmentId"
                }
              ],
              "kind": "ObjectValue",
              "name": "and.0"
            },
            {
              "fields": [
                {
                  "fields": (v4/*: any*/),
                  "kind": "ObjectValue",
                  "name": "accountId"
                }
              ],
              "kind": "ObjectValue",
              "name": "and.1"
            }
          ],
          "kind": "ListValue",
          "name": "and"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "AccountHierarchy",
  "kind": "LinkedField",
  "name": "accountHierarchy",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullPath",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountType",
  "kind": "LinkedField",
  "name": "accountType",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v10/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "accountAccountQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": true,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountBalance",
            "kind": "LinkedField",
            "name": "accountBalance",
            "plural": false,
            "selections": [
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          {
            "alias": null,
            "args": (v12/*: any*/),
            "concreteType": "TransactionLineItem",
            "kind": "LinkedField",
            "name": "transactionLineItems",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Transaction",
                "kind": "LinkedField",
                "name": "transaction",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionMetadata",
                    "kind": "LinkedField",
                    "name": "transactionMetadata",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionLineItem",
                    "kind": "LinkedField",
                    "name": "transactionLineItems",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "account",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v15/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "allAccounts",
        "args": (v17/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": true,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          (v18/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      (v19/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "accountAccountQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": true,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountBalance",
            "kind": "LinkedField",
            "name": "accountBalance",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v20/*: any*/),
          {
            "alias": null,
            "args": (v12/*: any*/),
            "concreteType": "TransactionLineItem",
            "kind": "LinkedField",
            "name": "transactionLineItems",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Transaction",
                "kind": "LinkedField",
                "name": "transaction",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionMetadata",
                    "kind": "LinkedField",
                    "name": "transactionMetadata",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionLineItem",
                    "kind": "LinkedField",
                    "name": "transactionLineItems",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "account",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v15/*: any*/),
                          (v20/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "allAccounts",
        "args": (v17/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": true,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          (v18/*: any*/),
          (v20/*: any*/)
        ],
        "storageKey": null
      },
      (v19/*: any*/)
    ]
  },
  "params": {
    "cacheID": "abc9938f915ba99232f43910c3e1147d",
    "id": null,
    "metadata": {},
    "name": "accountAccountQuery",
    "operationKind": "query",
    "text": "query accountAccountQuery(\n  $catchmentId: UUID!\n  $accountId: UUID!\n) {\n  accounts(where: {and: [{catchment: {id: {eq: $catchmentId}}}, {or: [{id: {eq: $accountId}}, {parentId: {eq: $accountId}}]}]}, order: {name: ASC}) {\n    id\n    name\n    accountBalance {\n      value\n      id\n    }\n    accountType {\n      name\n      code\n      id\n    }\n    transactionLineItems(where: {account: {id: {eq: $accountId}}}, order: {transaction: {date: DESC}}) {\n      id\n      value\n      transaction {\n        id\n        date\n        memo\n        referenceTypeName\n        referenceId\n        transactionMetadata {\n          name\n          value\n          id\n        }\n        transactionLineItems {\n          id\n          value\n          account {\n            id\n            name\n            referenceTypeName\n            accountType {\n              name\n              code\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n  allAccounts: accounts(where: {catchment: {id: {eq: $catchmentId}}}) {\n    id\n    name\n    parentId\n    accountType {\n      name\n      code\n      id\n    }\n  }\n  accountHierarchy(where: {and: [{catchmentId: {eq: $catchmentId}}, {accountId: {eq: $accountId}}]}) {\n    accountId\n    fullPath\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "3482ecd3b2e2356aefb0725898919fd9";

module.exports = ((node/*: any*/)/*: Query<
  accountAccountQuery$variables,
  accountAccountQuery$data,
>*/);
