import React, { Suspense, useContext, useEffect, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { useParams } from "react-router-dom";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ExtractionSourceHeader } from "./components/ExtractionSourceHeader";
import { ExtractionSourceResults } from "./components/ExtractionSourceResults";
import { CenteredLoading, ResultsLoading } from "../../../common/components/loading";
import { ProfileContext } from "../../../common/contexts/profile";
import { QueryPanel } from "../../../common/components/queryPanel";
import { Subheader } from "../../../common/components/subheader";
import { ExtractionSourceMeasurementResults } from "./components/ExtractionSourceMeasurementResults";
import { ExtractionSourceImportWizardDialog } from "./components/ExtractionSourceImportWizardDialog";

const ExtractionSourceQuery = graphql`
  query extractionSourceExtractionSourceQuery($catchmentId: UUID!, $extractionSourceId: UUID!) {
    extractionSources(where: { and: [ { id: { eq: $extractionSourceId } }, { catchment: { id: { eq: $catchmentId } } } ] }) {
      id
      name
      config
      inputOffset
      forecastSource
      forecastName
      actualSource
      actualName
    }
    measurementQualityTypes(where: { catchment: { id: { eq: $catchmentId } } }, order: { name: ASC }) {
      id
      name
    }
  }
`;

const ExtractionSourceInputsQuery = graphql`
  query extractionSourceExtractionSourceInputsQuery($catchmentId: UUID!, $extractionSourceId: UUID!, $start: DateTime!, $end: DateTime!) {
    extractionSourceInputs(where: { and: [ { date: { lte: $end } }, { date: { gte: $start } }, { catchment: { id: { eq: $catchmentId } } }, { extractionSource: { id: { eq: $extractionSourceId } } } ] }, order: { date: ASC }) {
      id
      date
      forecastGross
      forecastNet
      forecastSync {
        id
        analysis
        sync
      }
      actualGross
      actualNet
      actualSync {
        id
        analysis
        sync
      }
    }
  }
`;

const ExtractionSourceMeasurementsQuery = graphql`
  query extractionSourceExtractionSourceMeasurementsQuery($catchmentId: UUID!, $extractionSourceId: UUID!, $start: DateTime!, $end: DateTime!) {
    extractionSourceMeasurements(where: { and: [ { extractionSource: { id: { eq: $extractionSourceId } } }, { catchment: { id: { eq: $catchmentId } } }, { date: { lte: $end } }, { date: { gte: $start } } ] }, order: { date: ASC }) {
      id
      date
      flowRate
      total
      modifiedByUser {
        id
      }
      importOperation {
        id
      }
      measurementQualityType {
        id
      }
    }
  }
`;

export const ExtractionSourcePage = () => {
  const [ extractionSourceQueryRef, loadExtractionSourceQuery ] = useQueryLoader(ExtractionSourceQuery);
  const [ extractionSourceInputsQueryRef, loadExtractionSourceInputsQuery ] = useQueryLoader(ExtractionSourceInputsQuery);
  const [ extractionSourceMeasurementsQueryRef, loadExtractionSourceMeasurementsQuery ] = useQueryLoader(ExtractionSourceMeasurementsQuery);
  const { catchmentId, timeZone } = useContext(ProfileContext);
  const { esId } = useParams();
  const [ inputsStart, setInputsStart ] = useState(null);
  const [ inputsEnd, setInputsEnd ] = useState(null);
  const [ measurementsStart, setMeasurementsStart ] = useState(null);
  const [ measurementsEnd, setMeasurementsEnd ] = useState(null);
  const [ open, setOpen ] = useState(false);
  const [ searchTab, setSearchTab ] = useState("0");

  useEffect(() => loadExtractionSourceQuery({ catchmentId, extractionSourceId: esId },
    { fetchPolicy: "network-only" }),
    [ catchmentId, esId, loadExtractionSourceQuery ]);

  const handleInputsExecute = ({ end, start }) => {
    setInputsStart(start);
    setInputsEnd(end);
    loadExtractionSourceInputsQuery(
      { catchmentId, extractionSourceId: esId, start: start.tz(timeZone, true).format(), end: end.tz(timeZone, true).format() },
      { fetchPolicy: "network-only" });
  };

  const handleMeasurementsExecute = ({ end, start }) => {
    setMeasurementsStart(start);
    setMeasurementsEnd(end);
    loadExtractionSourceMeasurementsQuery(
      { catchmentId, extractionSourceId: esId, start: start.tz(timeZone, true).format(), end: end.tz(timeZone, true).format() },
      { fetchPolicy: "network-only" });
  };

  const handleSave = () => {
    setOpen(false);
    handleMeasurementsExecute({ end: measurementsEnd, start: measurementsStart });
  };

  return (
    <Suspense fallback={<CenteredLoading />}>
      {extractionSourceQueryRef && <>
        <ExtractionSourceHeader extractionSourceQueryDef={ExtractionSourceQuery} extractionSourceQueryRef={extractionSourceQueryRef} />
        <Subheader name="Search" action={searchTab === "1" ? () => setOpen(true) : null} linkName={searchTab === "1" ? "Import..." : null} />
        <TabContext value={searchTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={(e, v) => setSearchTab(v)}>
              <Tab label="Forecast" value="0" />
              <Tab label="Measurements" value="1" />
            </TabList>
          </Box>
          <TabPanel value="0" sx={{ pl: 0, pr: 0 }}>
            <QueryPanel onExecute={handleInputsExecute} startOffset={0} endOffset={14} />
            <Suspense fallback={<ResultsLoading />}>
              {extractionSourceInputsQueryRef &&
                <ExtractionSourceResults extractionSourceInputsQueryDef={ExtractionSourceInputsQuery}
                  extractionSourceInputsQueryRef={extractionSourceInputsQueryRef} catchmentId={catchmentId}
                  extractionSourceQueryDef={ExtractionSourceQuery} extractionSourceQueryRef={extractionSourceQueryRef}
                  start={inputsStart} end={inputsEnd} />}
            </Suspense>
          </TabPanel>
          <TabPanel value="1" sx={{ pl: 0, pr: 0 }}>
            <QueryPanel onExecute={handleMeasurementsExecute} startOffset={-3} endOffset={0} />
            <Suspense fallback={<ResultsLoading />}>
              {extractionSourceMeasurementsQueryRef &&
                <ExtractionSourceMeasurementResults extractionSourceQueryDef={ExtractionSourceQuery} extractionSourceQueryRef={extractionSourceQueryRef}
                  extractionSourceMeasurementsQueryDef={ExtractionSourceMeasurementsQuery} extractionSourceMeasurementsQueryRef={extractionSourceMeasurementsQueryRef} />}
            </Suspense>
          </TabPanel>
          <ExtractionSourceImportWizardDialog open={open} onSave={handleSave} onCancel={() => setOpen(false)} extractionSourceId={esId}
            extractionSourceQueryDef={ExtractionSourceQuery} extractionSourceQueryRef={extractionSourceQueryRef} />
        </TabContext>
      </>}
    </Suspense>
  );
};