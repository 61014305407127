import React from "react";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { LinkCell } from "../../../../common/components/linkCell";
import { formatCurrency } from "../../../../common/utils/format";

export const AccountsTable = ({ accounts }) => (
  <Grid item xs={12}>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts.map(x => {
            const link = `/admin/accounts/${x.id}`;
            return (
              <TableRow key={x.id}>
                <LinkCell link={link}>{x.name}</LinkCell>
                <LinkCell link={link}>{formatCurrency(x.accountBalance ? x.accountBalance.value : 0)}</LinkCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell><Typography variant="subtitle2">Total</Typography></TableCell>
            <TableCell><Typography variant="subtitle2">{formatCurrency(accounts.reduce((p, c) => p + (c.accountBalance ? c.accountBalance.value : 0), 0))}</Typography></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>);