/**
 * @generated SignedSource<<bf0182b5dbf0ed9092dbc6745341459c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteOfferLineInput = {|
  offerLineId: any,
|};
export type ClearingDeleteOfferLineMutation$variables = {|
  input: DeleteOfferLineInput,
|};
export type ClearingDeleteOfferLineMutation$data = {|
  +deleteOfferLine: {|
    +offerLine: ?{|
      +id: ?string,
    |},
  |},
|};
export type ClearingDeleteOfferLineMutation = {|
  response: ClearingDeleteOfferLineMutation$data,
  variables: ClearingDeleteOfferLineMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClearingDeleteOfferLineMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteOfferLinePayload",
        "kind": "LinkedField",
        "name": "deleteOfferLine",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OfferLine",
            "kind": "LinkedField",
            "name": "offerLine",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClearingDeleteOfferLineMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteOfferLinePayload",
        "kind": "LinkedField",
        "name": "deleteOfferLine",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OfferLine",
            "kind": "LinkedField",
            "name": "offerLine",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a5c4177656aa8dfd6ac594a84728a8de",
    "id": null,
    "metadata": {},
    "name": "ClearingDeleteOfferLineMutation",
    "operationKind": "mutation",
    "text": "mutation ClearingDeleteOfferLineMutation(\n  $input: DeleteOfferLineInput!\n) {\n  deleteOfferLine(input: $input) {\n    offerLine {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "647701f08bc6eb7536a109f9537a2c77";

module.exports = ((node/*: any*/)/*: Mutation<
  ClearingDeleteOfferLineMutation$variables,
  ClearingDeleteOfferLineMutation$data,
>*/);
