/**
 * @generated SignedSource<<82997dcb2ef5f8be58c8128f712acee7>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type accountsAccountsQuery$variables = {|
  catchmentId: any,
|};
export type accountsAccountsQuery$data = {|
  +accounts: ?$ReadOnlyArray<?{|
    +accountBalance: ?{|
      +value: any,
    |},
    +accountType: ?{|
      +code: ?string,
      +name: ?string,
    |},
    +id: ?string,
    +name: ?string,
  |}>,
|};
export type accountsAccountsQuery = {|
  response: accountsAccountsQuery$data,
  variables: accountsAccountsQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "catchmentId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "order",
    "value": {
      "name": "ASC"
    }
  },
  {
    "fields": [
      {
        "items": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "eq",
                        "variableName": "catchmentId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "catchment"
              }
            ],
            "kind": "ObjectValue",
            "name": "and.0"
          },
          {
            "kind": "Literal",
            "name": "and.1",
            "value": {
              "parentId": {
                "eq": null
              }
            }
          }
        ],
        "kind": "ListValue",
        "name": "and"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountsAccountsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountBalance",
            "kind": "LinkedField",
            "name": "accountBalance",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountType",
            "kind": "LinkedField",
            "name": "accountType",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountsAccountsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountBalance",
            "kind": "LinkedField",
            "name": "accountBalance",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountType",
            "kind": "LinkedField",
            "name": "accountType",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5535a1c1980f0b9ea921a6d56172dcda",
    "id": null,
    "metadata": {},
    "name": "accountsAccountsQuery",
    "operationKind": "query",
    "text": "query accountsAccountsQuery(\n  $catchmentId: UUID!\n) {\n  accounts(where: {and: [{catchment: {id: {eq: $catchmentId}}}, {parentId: {eq: null}}]}, order: {name: ASC}) {\n    id\n    name\n    accountBalance {\n      value\n      id\n    }\n    accountType {\n      name\n      code\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "cdb0a99a4a9d5cbbba14d3d9b06e2b0f";

module.exports = ((node/*: any*/)/*: Query<
  accountsAccountsQuery$variables,
  accountsAccountsQuery$data,
>*/);
