import React, { useEffect, useMemo, useState } from "react";
import { Button, Stack, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";

const defaultEpName = "WIL";

export const ClearingAllocations = ({ allocations, setAllocations, onProgressChange }) => {
  const [ isImporting, setIsImporting ] = useState(false);
  const [ importedText, setImportedText ] = useState(false);
  const [ selectedAllocation, setSelectedAllocation ] = useState([]);

  useEffect(() => onProgressChange(isImporting), [ isImporting, onProgressChange ]);

  const handleImportStart = () => {
    setImportedText("");
    setIsImporting(true);
  };

  const handleImportEnd = () => {
    let id = getNextId();
    const clean = input => {
      input = input.trim();
      input = input.replace("$", "").replace(",", "");
      if (input === "-" || !input) {
        return 0;
      }
      return parseFloat(input);
    };
    const rows = importedText.split("\n")
      .reduce((p, r) => {
        const cols = r.split("\t").map(x => x.trim());
        const fullLength = 23;
        if (cols.length === fullLength - 1 || cols.length === fullLength) {
          const offset = cols.length === fullLength ? 2 : 1;
          p.push({
            "id": id++,
            "waterUserExtractionPoint-waterUser-publicid": cols[0],
            "waterUserExtractionPoint-waterUser-name": cols[1],
            "waterUserExtractionPoint-extractionPoint-name": cols.length === fullLength ? cols[offset] : defaultEpName,
            "baseQuantityPreLoss": clean(cols[offset + 1]),
            "baseQuantity": clean(cols[offset + 2]),
            "totalQuantityPreLoss": clean(cols[offset + 3]),
            "totalQuantity": clean(cols[offset + 4]),
            "changeQuantity": clean(cols[offset + 5]),
            "farmSurcharge": cols[offset + 6],
            "quantityDisposedCreditPreLoss": clean(cols[offset + 7]),
            "quantityObtainedCharge": clean(cols[offset + 8]),
            "raceCongestionCharge": clean(cols[offset + 9]),
            "raceCongestionCredit": clean(cols[offset + 10]),
            "totalGrossCharge": clean(cols[offset + 11]),
            "totalNetCharge": clean(cols[offset + 12]),
            "spotPricePreLoss": clean(cols[offset + 13]),
            "spotPrice": clean(cols[offset + 14]),
            "farmCongestionPrice": clean(cols[offset + 15]),
            "raceCongestionPrice": clean(cols[offset + 16]),
            "effectiveGrossPrice": clean(cols[offset + 17]),
            "effectiveNetPrice": clean(cols[offset + 18]),
            "clearingFee": clean(cols[offset + 19]),
            "constraint": cols[offset + 20]
          });
        }
        return p;
      }, []);
    setAllocations(rows);
    setIsImporting(false);
  };

  const handleCancel = () => setIsImporting(false);

  const handleImportedTextChange = evt => setImportedText(evt.target.value);

  const handleAdd = () => setAllocations([ ...allocations,
    {
      id: getNextId(),
      "waterUserExtractionPoint-waterUser-publicid": "",
      "waterUserExtractionPoint-waterUser-name": "",
      "waterUserExtractionPoint-extractionPoint-name": defaultEpName,
      baseQuantityPreLoss: 0,
      baseQuantity: 0,
      totalQuantityPreLoss: 0,
      totalQuantity: 0,
      changeQuantity: 0,
      farmSurcharge: "",
      quantityDisposedCreditPreLoss: 0,
      quantityObtainedCharge: 0,
      raceCongestionCharge: 0,
      raceCongestionCredit: 0,
      totalGrossCharge: 0,
      totalNetCharge: 0,
      spotPricePreLoss: 0,
      spotPrice: 0,
      farmCongestionPrice: 0,
      raceCongestionPrice: 0,
      effectiveGrossPrice: 0,
      effectiveNetPrice: 0,
      clearingFee: 0,
      constraint: ""
    }
  ]);

  const handleEdit = newRow => {
    const newAllocations = allocations.map(x => {
      if (x.id === newRow.id) {
        return newRow;
      }
      return x;
    });
    setAllocations(newAllocations);
    return newRow;
  };

  const handleDelete = () =>
    setAllocations(p => {
      const rowToDeleteIndex = p.findIndex(x => x.id === selectedAllocation[0]);
      return rowToDeleteIndex >= 0
        ? [
          ...p.slice(0, rowToDeleteIndex),
          ...p.slice(rowToDeleteIndex + 1),
        ]
        : p;
    });

  const getNextId = () => allocations.length > 0 ? Math.max.apply(null, allocations.map(x => x.id)) + 1 : 0;

  const columns = useMemo(() => {
    const cols = [
      { field: "waterUserExtractionPoint-waterUser-publicid", headerName: "Water User ID", editable: true, flex: 0.035 },
      { field: "waterUserExtractionPoint-waterUser-name", headerName: "Water User", editable: true, flex: 0.18 },
      { field: "waterUserExtractionPoint-extractionPoint-name", headerName: "Extraction Point", editable: true, flex: 0.085 },
      { field: "baseQuantityPreLoss", headerName: "Base Quantity (pre-loss)", editable: true, type: "number", flex: 0.035 },
      { field: "baseQuantity", headerName: "Base Quantity", editable: true, type: "number", flex: 0.035 },
      { field: "totalQuantityPreLoss", headerName: "Total Quantity (pre-loss)", editable: true, type: "number", flex: 0.035 },
      { field: "totalQuantity", headerName: "Total Quantity", editable: true, type: "number", flex: 0.035 },
      { field: "changeQuantity", headerName: "Change Quantity", editable: true, type: "number", flex: 0.035 },
      { field: "farmSurcharge", headerName: "Farm Surcharge", editable: true, flex: 0.035 },
      { field: "quantityDisposedCreditPreLoss", headerName: "Quantity Disposed Credit (pre-loss)", editable: true, type: "number", flex: 0.035 },
      { field: "quantityObtainedCharge", headerName: "Quantity Obtained Charge", editable: true, type: "number", flex: 0.035 },
      { field: "raceCongestionCharge", headerName: "Race Congestion Charge", editable: true, type: "number", flex: 0.035 },
      { field: "raceCongestionCredit", headerName: "Race Congestion Credit", editable: true, type: "number", flex: 0.035 },
      { field: "totalGrossCharge", headerName: "Total Gross Charge", editable: true, type: "number", flex: 0.035 },
      { field: "totalNetCharge", headerName: "Total Net Charge", editable: true, type: "number", flex: 0.035 },
      { field: "spotPricePreLoss", headerName: "Spot Price (pre-loss)", editable: true, type: "number", flex: 0.035 },
      { field: "spotPrice", headerName: "Spot Price", editable: true, type: "number", flex: 0.035 },
      { field: "farmCongestionPrice", headerName: "Farm Congestion Price", editable: true, type: "number", flex: 0.035 },
      { field: "raceCongestionPrice", headerName: "Race Congestion Price", editable: true, type: "number", flex: 0.035 },
      { field: "effectiveGrossPrice", headerName: "Effective Gross Price", editable: true, type: "number", flex: 0.035 },
      { field: "effectiveNetPrice", headerName: "Effective Net Price", editable: true, type: "number", flex: 0.035 },
      { field: "clearingFee", headerName: "Clearing Fee", editable: true, type: "number", flex: 0.035 },
      { field: "constraint", headerName: "Constraint", editable: true, flex: 0.035 }
    ];
    cols.forEach(x => x.sortable = false);
    return cols;
  }, []);

  return (
    <>
      <Stack sx={{ mb: 1, pt: 1 }} direction="row">
        {!isImporting && <Button onClick={handleImportStart} size="small" startIcon={<ContentPasteIcon />}>Import</Button>}
        {!isImporting && <Button onClick={handleAdd} size="small" startIcon={<AddIcon />}>Add</Button>}
        {!isImporting && <Button onClick={handleDelete} size="small" startIcon={<DeleteIcon />} disabled={selectedAllocation.length !== 1}>Delete</Button>}
        {isImporting && <Button onClick={handleImportEnd} size="small" startIcon={<DoneIcon />} sx={{ mr: 1 }}>Done</Button>}
        {isImporting && <Button onClick={handleCancel} startIcon={<CancelIcon />} size="small">Cancel</Button>}
      </Stack>
      {isImporting && <TextField multiline variant="standard" label="Allocations to import" sx={{ minHeight: "200px", width: "100%" }}
        value={importedText} onChange={handleImportedTextChange} minRows={7} />}
      {!isImporting && <div style={{ display: "flex", minHeight: "200px", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid autoHeight sx={{ ".MuiDataGrid-footerContainer": { display: "none" } }}
            columns={columns} rows={allocations} selectionModel={selectedAllocation} onSelectionModelChange={setSelectedAllocation}
            disableColumnMenu experimentalFeatures={{ newEditingApi: true }} processRowUpdate={handleEdit} />
        </div>
      </div>}
    </>
  );
};