/**
 * @generated SignedSource<<de39738a4b1dcdf225f22e7cc73e5e98>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type waterUsersWaterUsersQuery$variables = {|
  catchmentId: any,
|};
export type waterUsersWaterUsersQuery$data = {|
  +waterUsers: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
    +publicId: number,
  |}>,
|};
export type waterUsersWaterUsersQuery = {|
  response: waterUsersWaterUsersQuery$data,
  variables: waterUsersWaterUsersQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "catchmentId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order",
        "value": {
          "publicId": "ASC"
        }
      },
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "eq",
                    "variableName": "catchmentId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "catchment"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "WaterUser",
    "kind": "LinkedField",
    "name": "waterUsers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "publicId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "waterUsersWaterUsersQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "waterUsersWaterUsersQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "80c582673eadb33cfb7b53c8a4f4bc98",
    "id": null,
    "metadata": {},
    "name": "waterUsersWaterUsersQuery",
    "operationKind": "query",
    "text": "query waterUsersWaterUsersQuery(\n  $catchmentId: UUID!\n) {\n  waterUsers(where: {catchment: {id: {eq: $catchmentId}}}, order: {publicId: ASC}) {\n    id\n    name\n    publicId\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "9cdc41d06e0edf7bce9906d64e98ebc0";

module.exports = ((node/*: any*/)/*: Query<
  waterUsersWaterUsersQuery$variables,
  waterUsersWaterUsersQuery$data,
>*/);
