import React from "react";
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { formatDateTime, formatDateTimeLocal } from "../../../../common/utils/format";
import { LinkCell } from "../../../../common/components/linkCell";

export const DeliveryPeriodsTable = ({ waterDeliveryPeriods, onEdit }) => {
  const clearingStatus = wdp => wdp.exchangeClearings && wdp.exchangeClearings.length > 0
    ? `${wdp.exchangeClearings[0].exchangeClearingType.name} (${formatDateTimeLocal(wdp.exchangeClearings[0].run)})` : "-";

  return (
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              <TableCell>Clearing Status</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {waterDeliveryPeriods.map(x => {
              const link = `/admin/delivery-periods/${x.id}`;
              return (
                <TableRow key={x.id}>
                  <LinkCell link={link}>{formatDateTime(x.start)}</LinkCell>
                  <LinkCell link={link}>{formatDateTime(x.end)}</LinkCell>
                  <LinkCell link={link}>{clearingStatus(x)}</LinkCell>
                  <TableCell>
                    <IconButton size="small" onClick={() => onEdit(x)} sx={{ mr: 1 }}>
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};