/**
 * @generated SignedSource<<1a98f5c8263f4878d226e9124273b57a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateOfferLineInput = {|
  offerLineId: any,
  price: any,
  volume: any,
|};
export type ClearingUpdateOfferLineMutation$variables = {|
  input: UpdateOfferLineInput,
|};
export type ClearingUpdateOfferLineMutation$data = {|
  +updateOfferLine: {|
    +offerLine: ?{|
      +id: ?string,
      +offer: ?{|
        +id: ?string,
      |},
      +price: any,
      +volume: any,
    |},
  |},
|};
export type ClearingUpdateOfferLineMutation = {|
  response: ClearingUpdateOfferLineMutation$data,
  variables: ClearingUpdateOfferLineMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateOfferLinePayload",
    "kind": "LinkedField",
    "name": "updateOfferLine",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OfferLine",
        "kind": "LinkedField",
        "name": "offerLine",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "volume",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Offer",
            "kind": "LinkedField",
            "name": "offer",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClearingUpdateOfferLineMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClearingUpdateOfferLineMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4eafa88eb128300c02078fd101447e27",
    "id": null,
    "metadata": {},
    "name": "ClearingUpdateOfferLineMutation",
    "operationKind": "mutation",
    "text": "mutation ClearingUpdateOfferLineMutation(\n  $input: UpdateOfferLineInput!\n) {\n  updateOfferLine(input: $input) {\n    offerLine {\n      id\n      price\n      volume\n      offer {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ddb44bcbab4439e70c98580b83ea4c38";

module.exports = ((node/*: any*/)/*: Mutation<
  ClearingUpdateOfferLineMutation$variables,
  ClearingUpdateOfferLineMutation$data,
>*/);
