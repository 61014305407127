import React, { useContext, useMemo, useState } from "react";
import { Button, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProfileContext } from "../../../common/contexts/profile";

export const ImportPreview = ({ measurements, setMeasurements }) => {
  const { now } = useContext(ProfileContext);
  const [ selectedMeasurement, setSelectedMeasurement ] = useState([]);

  const handleAdd = () => setMeasurements([ ...measurements,
    {
      id: getNextId(),
      date: now(),
      flowRate: 0,
      total: 0
    }
  ]);

  const handleEdit = newRow => {
    const newMeasurements = measurements.map(x => {
      if (x.id === newRow.id) {
        return newRow;
      }
      return x;
    });
    setMeasurements(newMeasurements);
    return newRow;
  };

  const handleDelete = () =>
    setMeasurements(p => {
      const rowToDeleteIndex = p.findIndex(x => x.id === selectedMeasurement[0]);
      return rowToDeleteIndex >= 0
        ? [
          ...p.slice(0, rowToDeleteIndex),
          ...p.slice(rowToDeleteIndex + 1),
        ]
        : p;
    });

  const getNextId = () => measurements.length > 0 ? Math.max.apply(null, measurements.map(x => x.id)) + 1 : 0;

  const columns = useMemo(() => {
    const cols = [
      { field: "date", headerName: "Date", editable: true, flex: 0.33 },
      { field: "flowRate", headerName: "Flow rate (l/s)", editable: true, flex: 0.33, type: "number" },
      { field: "total", headerName: "Total (m³)", editable: true, flex: 0.34, type: "number" }
    ];
    cols.forEach(x => x.sortable = false);
    return cols;
  }, []);

  return (
    <>
      <Stack sx={{ mb: 1, pt: 1 }} direction="row">
        <Button onClick={handleAdd} size="small" startIcon={<AddIcon />}>Add</Button>
        <Button onClick={handleDelete} size="small" startIcon={<DeleteIcon />} disabled={selectedMeasurement.length !== 1}>Delete</Button>
      </Stack>
      <div style={{ display: "flex", minHeight: "200px", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid autoHeight columns={columns} rows={measurements} selectionModel={selectedMeasurement}
            onSelectionModelChange={setSelectedMeasurement} disableColumnMenu experimentalFeatures={{ newEditingApi: true }}
            processRowUpdate={handleEdit} />
        </div>
      </div>
    </>
  );
};