import React from "react";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { LinkCell } from "../../../../common/components/linkCell";

export const ExtractionSourcesTable = ({ extractionSources }) => (
  <Grid item xs={12}>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {extractionSources.map(x => {
            const link = `/admin/sources/${x.id}`;
            return (
              <TableRow key={x.id}>
                <LinkCell link={link}>{x.name}</LinkCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>);