import React, { useContext, useEffect, useState } from "react";
import { Autocomplete, Button, Grid, Paper, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { dateEditFormatString } from "../../../../../../common/utils/format";
import { ProfileContext } from "../../../../../../common/contexts/profile";

export const Query = ({ onExecute, extractionSources }) => {
  const [ end, setEnd ] = useState(null);
  const [ start, setStart ] = useState(null);
  const [ selectedExtractionSources, setSelectedExtractionSources ] = useState([]);
  const [ isFirstLoad, setIsFirstLoad ] = useState(true);
  const { now } = useContext(ProfileContext);

  useEffect(() => {
    if (!start && !end) {
      const defaultEnd = now({ ignoreTz: true });
      setEnd(defaultEnd);

      const defaultStart = defaultEnd.clone().add(-14, "day");
      setStart(defaultStart);
    }
  }, [ end, now, start ]);

  useEffect(() => {
    if (isFirstLoad && start && end && extractionSources && extractionSources.length > 0) {
      onExecute({ end, start, extractionSources: extractionSources.map(x => x.id) });
      setIsFirstLoad(false);
    }
  }, [ end, extractionSources, isFirstLoad, onExecute, start ]);

  const handleSelectedExtractionSourcesChange = (e, v) => setSelectedExtractionSources(v);
  const handleQuery = () => onExecute({ end, start,
    extractionSources: (selectedExtractionSources.length > 0 ? selectedExtractionSources : extractionSources).map(x => x.id) });

  return (
    <Grid container component={Paper}>
      <Grid container item sx={{ p: 2 }} spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <DatePicker
            renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" {...props} />}
            label="Start"
            value={start}
            onChange={setStart}
            inputFormat={dateEditFormatString}
            mask="__/__/__" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DatePicker
            renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" {...props} />}
            label="End"
            value={end}
            onChange={setEnd}
            inputFormat={dateEditFormatString}
            mask="__/__/__" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            multiple
            id="es-select"
            options={extractionSources}
            getOptionLabel={option => option.name}
            renderInput={props => <TextField fullWidth variant="standard" margin="dense" label="Extraction Sources" {...props} />}
            value={selectedExtractionSources}
            onChange={handleSelectedExtractionSourcesChange} />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleQuery}>Search</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};