import React, { useContext } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { NotificationContext } from "../../../../common/contexts/notification";
import { ImportWizardDialog } from "../../../components/import/ImportWizardDialog";

const UpdateOfftakeMeasurementsMutation = graphql`
  mutation OfftakeImportWizardDialogUpdateOfftakeMeasurementsMutation($input: UpdateWaterUserExtractionPointOfftakeMeasurementsInput!) {
    updateWaterUserExtractionPointOfftakeMeasurements(input: $input) {
      waterUserExtractionPointOfftakeMeasurement {
        id
        date
        flowRate
        total
        measurementQualityType {
          id
        }
      }
    }
  }
`;

export const OfftakeImportWizardDialog = ({ open, onCancel, onSave, waterUserExtractionPoint, qualityTypes }) => {
  const [ commitOfftakeMeasurements ] = useMutation(UpdateOfftakeMeasurementsMutation);
  const { setSuccess, setError } = useContext(NotificationContext);

  const defaultOfftakeId = waterUserExtractionPoint && waterUserExtractionPoint.waterUserExtractionPointOfftakes.length > 0
    ? waterUserExtractionPoint.waterUserExtractionPointOfftakes[0].id
    : "";

  const offtakes = waterUserExtractionPoint && waterUserExtractionPoint.waterUserExtractionPointOfftakes
    ? waterUserExtractionPoint.waterUserExtractionPointOfftakes
    : [];

  const defaultQualityTypeId = qualityTypes.length > 0
    ? qualityTypes[0].id
    : "";

  const handleSave = ({ comment, fileName, offtakeId, measurements, setIsSaving, qualityTypeId }) => {
    setIsSaving(true);

    commitOfftakeMeasurements({
      variables: {
        input: {
          comment: comment,
          fileName: fileName,
          waterUserExtractionPointOfftakeId: offtakeId,
          waterUserExtractionPointOfftakeMeasurements:
            measurements.map(x => ({
              date: x.date,
              flowRate: x.flowRate,
              total: x.total,
              measurementQualityTypeId: qualityTypeId
            }))
        }
      },
      onCompleted: () => {
        setIsSaving(false);
        setSuccess("Offtake measurements updated successfully");
        onSave();
      },
      onError: e => {
        setIsSaving(false);
        setError("Offtake measurements failed to update", e);
      }
    });
  };

  return (<ImportWizardDialog defaultOfftakeId={defaultOfftakeId} offtakes={offtakes} defaultQualityTypeId={defaultQualityTypeId}
    qualityTypes={qualityTypes} open={open} onCancel={onCancel} onSave={handleSave} />);
};