import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { formatCurrency, formatVolume } from "../../../../common/utils/format";

const maxOfferLines = 4;
const getOfferLines = offers => offers && offers.length > 0 ? offers[0].offerLines : [];

export const OffersTable = ({ offers }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Water User ID</TableCell>
          <TableCell>Water User Name</TableCell>
          <TableCell>Extraction Point</TableCell>
          {[...Array(maxOfferLines).keys()].map(x => (
            <React.Fragment key={x}>
              <TableCell>Volume {x+1}</TableCell>
              <TableCell>Price {x+1}</TableCell>
            </React.Fragment>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {offers && offers.map(x => (
          <TableRow key={x.id}>
            <TableCell>{x.waterUser.publicId}</TableCell>
            <TableCell>{x.waterUser.name}</TableCell>
            <TableCell>{x.extractionPoint.name}</TableCell>
            {[...Array(maxOfferLines).keys()]
              .map((y, i) => y >= getOfferLines(x.offers).length ? { id: i } : getOfferLines(x.offers)[y])
              .map(y => (
                <React.Fragment key={y.id}>
                  <TableCell>{y ? formatVolume(y.volume) : ""}</TableCell>
                  <TableCell>{y ? formatCurrency(y.price) : ""}</TableCell>
                </React.Fragment>
              ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);