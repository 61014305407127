import React, { Suspense, useContext, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { useParams } from "react-router-dom";
import { WaterUser } from "./components/WaterUser";
import { CenteredLoading } from "../../../common/components/loading";
import { ProfileContext } from "../../../common/contexts/profile";

const WaterUserQuery = graphql`
  query waterUserWaterUserQuery($catchmentId: UUID!, $waterUserId: UUID!) {
    waterUsers(where: { and: [ { id: { eq: $waterUserId } }, { catchment: { id: { eq: $catchmentId } } } ] }) {
      id
      name
      publicId
      waterUserExtractionPoints {
        id
        lossFactor
        maxOfftake
        extractionPoint {
          id
          name
        }
      }
    }
  }
`;

export const WaterUserPage = () => {
  const [ waterUserQueryRef, loadWaterUserQuery ] = useQueryLoader(WaterUserQuery);
  const { catchmentId } = useContext(ProfileContext);
  const { wuId } = useParams();

  useEffect(() => loadWaterUserQuery({ catchmentId, waterUserId: wuId },
    { fetchPolicy: "network-only" }),
    [ catchmentId, wuId, loadWaterUserQuery ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {waterUserQueryRef && <WaterUser waterUserQueryDef={WaterUserQuery} waterUserQueryRef={waterUserQueryRef} />}
    </Suspense>
  );
};