/**
 * @generated SignedSource<<d93014fc43f9c61bb8bd63062feb3376>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type homeHomeWaterUsersQuery$variables = {|
  catchmentId: any,
  end: any,
  esiEnd: any,
  esiStart: any,
  start: any,
|};
export type homeHomeWaterUsersQuery$data = {|
  +extractionSources: ?$ReadOnlyArray<?{|
    +extractionSourceInputs: ?$ReadOnlyArray<?{|
      +actualGross: ?any,
      +actualNet: ?any,
      +date: any,
      +forecastGross: ?any,
      +forecastNet: ?any,
      +id: ?string,
    |}>,
    +id: ?string,
    +inputOffset: ?number,
  |}>,
  +waterDeliveryPeriods: ?$ReadOnlyArray<?{|
    +end: any,
    +id: ?string,
    +start: any,
  |}>,
  +waterUsers: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
    +waterUserExtractionPoints: ?$ReadOnlyArray<?{|
      +allocations: ?$ReadOnlyArray<?{|
        +baseQuantity: any,
        +exchangeClearing: ?{|
          +exchangeClearingType: ?{|
            +id: ?string,
            +isDelivered: ?boolean,
            +isFinal: boolean,
          |},
          +id: ?string,
          +waterDeliveryPeriod: ?{|
            +id: ?string,
          |},
        |},
        +totalQuantity: any,
      |}>,
      +extractionPoint: ?{|
        +extractionSource: ?{|
          +config: ?string,
          +id: ?string,
          +inputOffset: ?number,
        |},
        +id: ?string,
        +name: ?string,
      |},
      +id: ?string,
      +lossFactor: ?any,
      +offers: ?$ReadOnlyArray<?{|
        +id: ?string,
        +offerLines: ?$ReadOnlyArray<?{|
          +id: ?string,
          +volume: any,
        |}>,
        +waterDeliveryPeriod: ?{|
          +id: ?string,
        |},
      |}>,
      +shares: ?$ReadOnlyArray<?{|
        +apportionment: ?{|
          +id: ?string,
          +value: any,
        |},
        +id: ?string,
        +value: any,
      |}>,
    |}>,
  |}>,
|};
export type homeHomeWaterUsersQuery = {|
  response: homeHomeWaterUsersQuery$data,
  variables: homeHomeWaterUsersQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "catchmentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "esiEnd"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "esiStart"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start"
},
v5 = {
  "fields": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "eq",
          "variableName": "catchmentId"
        }
      ],
      "kind": "ObjectValue",
      "name": "id"
    }
  ],
  "kind": "ObjectValue",
  "name": "catchment"
},
v6 = {
  "fields": [
    (v5/*: any*/)
  ],
  "kind": "ObjectValue",
  "name": "where"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inputOffset",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    (v6/*: any*/)
  ],
  "concreteType": "ExtractionSource",
  "kind": "LinkedField",
  "name": "extractionSources",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "gte",
                  "variableName": "esiStart"
                },
                {
                  "kind": "Variable",
                  "name": "lte",
                  "variableName": "esiEnd"
                }
              ],
              "kind": "ObjectValue",
              "name": "date"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "ExtractionSourceInput",
      "kind": "LinkedField",
      "name": "extractionSourceInputs",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastGross",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastNet",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualGross",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualNet",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "gte",
      "variableName": "start"
    }
  ],
  "kind": "ObjectValue",
  "name": "end"
},
v11 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "lte",
      "variableName": "end"
    }
  ],
  "kind": "ObjectValue",
  "name": "start"
},
v12 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "order",
      "value": {
        "start": "ASC"
      }
    },
    {
      "fields": [
        (v5/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/)
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "WaterDeliveryPeriod",
  "kind": "LinkedField",
  "name": "waterDeliveryPeriods",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "start",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "end",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "filterByCurrentUser",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": {
      "publicId": "ASC"
    }
  },
  (v6/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lossFactor",
  "storageKey": null
},
v16 = [
  {
    "fields": [
      (v10/*: any*/),
      (v11/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "waterDeliveryPeriod"
  }
],
v17 = [
  {
    "kind": "Literal",
    "name": "order",
    "value": {
      "exchangeClearing": {
        "run": "DESC"
      }
    }
  },
  {
    "fields": [
      {
        "fields": [
          {
            "items": [
              {
                "kind": "Literal",
                "name": "and.0",
                "value": {
                  "isLatest": {
                    "eq": true
                  }
                }
              },
              {
                "fields": (v16/*: any*/),
                "kind": "ObjectValue",
                "name": "and.1"
              }
            ],
            "kind": "ListValue",
            "name": "and"
          }
        ],
        "kind": "ObjectValue",
        "name": "exchangeClearing"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "baseQuantity",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalQuantity",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "WaterDeliveryPeriod",
  "kind": "LinkedField",
  "name": "waterDeliveryPeriod",
  "plural": false,
  "selections": [
    (v7/*: any*/)
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "ExchangeClearing",
  "kind": "LinkedField",
  "name": "exchangeClearing",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ExchangeClearingType",
      "kind": "LinkedField",
      "name": "exchangeClearingType",
      "plural": false,
      "selections": [
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFinal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDelivered",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v20/*: any*/)
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "ExtractionPoint",
  "kind": "LinkedField",
  "name": "extractionPoint",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ExtractionSource",
      "kind": "LinkedField",
      "name": "extractionSource",
      "plural": false,
      "selections": [
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "config",
          "storageKey": null
        },
        (v8/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": [
    {
      "fields": (v16/*: any*/),
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "Offer",
  "kind": "LinkedField",
  "name": "offers",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OfferLine",
      "kind": "LinkedField",
      "name": "offerLines",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "volume",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v20/*: any*/)
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "Share",
  "kind": "LinkedField",
  "name": "shares",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    (v24/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Apportionment",
      "kind": "LinkedField",
      "name": "apportionment",
      "plural": false,
      "selections": [
        (v7/*: any*/),
        (v24/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "homeHomeWaterUsersQuery",
    "selections": [
      (v9/*: any*/),
      (v12/*: any*/),
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "WaterUser",
        "kind": "LinkedField",
        "name": "waterUsers",
        "plural": true,
        "selections": [
          (v7/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "WaterUserExtractionPoint",
            "kind": "LinkedField",
            "name": "waterUserExtractionPoints",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": (v17/*: any*/),
                "concreteType": "Allocation",
                "kind": "LinkedField",
                "name": "allocations",
                "plural": true,
                "selections": [
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v21/*: any*/)
                ],
                "storageKey": null
              },
              (v22/*: any*/),
              (v23/*: any*/),
              (v25/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "homeHomeWaterUsersQuery",
    "selections": [
      (v9/*: any*/),
      (v12/*: any*/),
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "WaterUser",
        "kind": "LinkedField",
        "name": "waterUsers",
        "plural": true,
        "selections": [
          (v7/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "WaterUserExtractionPoint",
            "kind": "LinkedField",
            "name": "waterUserExtractionPoints",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": (v17/*: any*/),
                "concreteType": "Allocation",
                "kind": "LinkedField",
                "name": "allocations",
                "plural": true,
                "selections": [
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v21/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v22/*: any*/),
              (v23/*: any*/),
              (v25/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "44428d01c4c4cd6d9c61e5b5a73e7a97",
    "id": null,
    "metadata": {},
    "name": "homeHomeWaterUsersQuery",
    "operationKind": "query",
    "text": "query homeHomeWaterUsersQuery(\n  $catchmentId: UUID!\n  $start: DateTime!\n  $end: DateTime!\n  $esiStart: DateTime!\n  $esiEnd: DateTime!\n) {\n  extractionSources(where: {catchment: {id: {eq: $catchmentId}}}) {\n    id\n    inputOffset\n    extractionSourceInputs(where: {date: {gte: $esiStart, lte: $esiEnd}}) {\n      id\n      date\n      forecastGross\n      forecastNet\n      actualGross\n      actualNet\n    }\n  }\n  waterDeliveryPeriods(where: {catchment: {id: {eq: $catchmentId}}, start: {lte: $end}, end: {gte: $start}}, order: {start: ASC}) {\n    id\n    start\n    end\n  }\n  waterUsers(filterByCurrentUser: true, where: {catchment: {id: {eq: $catchmentId}}}, order: {publicId: ASC}) {\n    id\n    name\n    waterUserExtractionPoints {\n      id\n      lossFactor\n      allocations(where: {exchangeClearing: {and: [{isLatest: {eq: true}}, {waterDeliveryPeriod: {start: {lte: $end}, end: {gte: $start}}}]}}, order: {exchangeClearing: {run: DESC}}) {\n        baseQuantity\n        totalQuantity\n        exchangeClearing {\n          id\n          exchangeClearingType {\n            id\n            isFinal\n            isDelivered\n          }\n          waterDeliveryPeriod {\n            id\n          }\n        }\n        id\n      }\n      extractionPoint {\n        id\n        name\n        extractionSource {\n          id\n          config\n          inputOffset\n        }\n      }\n      offers(where: {waterDeliveryPeriod: {start: {lte: $end}, end: {gte: $start}}}) {\n        id\n        offerLines {\n          id\n          volume\n        }\n        waterDeliveryPeriod {\n          id\n        }\n      }\n      shares {\n        id\n        value\n        apportionment {\n          id\n          value\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "1cee851c2ed2aa2f012fe6cb45286962";

module.exports = ((node/*: any*/)/*: Query<
  homeHomeWaterUsersQuery$variables,
  homeHomeWaterUsersQuery$data,
>*/);
