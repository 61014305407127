/**
 * @generated SignedSource<<7ef2520e923723c2441375a54395ae93>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type ExchangeClearingSubmissionState = "APPROVED" | "CANCELED" | "DRAFT" | "FAILED" | "PENDING" | "REJECTED" | "%future added value";
export type exchangeClearingExchangeClearingQuery$variables = {|
  catchmentId: any,
  ecId: any,
  wdpId: any,
|};
export type exchangeClearingExchangeClearingQuery$data = {|
  +exchangeClearings: ?$ReadOnlyArray<?{|
    +allocations: ?$ReadOnlyArray<?{|
      +baseQuantity: any,
      +baseQuantityPreLoss: any,
      +changeQuantity: any,
      +clearingFee: any,
      +constraint: ?string,
      +effectiveGrossPrice: any,
      +effectiveNetPrice: any,
      +farmCongestionPrice: any,
      +farmSurcharge: ?string,
      +id: ?string,
      +quantityDisposedCreditPreLoss: any,
      +quantityObtainedCharge: any,
      +raceCongestionCharge: any,
      +raceCongestionCredit: any,
      +raceCongestionPrice: any,
      +spotPrice: any,
      +spotPricePreLoss: any,
      +totalGrossCharge: any,
      +totalNetCharge: any,
      +totalQuantity: any,
      +totalQuantityPreLoss: any,
      +waterUserExtractionPoint: ?{|
        +extractionPoint: ?{|
          +id: ?string,
          +name: ?string,
        |},
        +id: ?string,
        +publicId: ?string,
        +waterUser: ?{|
          +id: ?string,
          +isDemandOnly: boolean,
          +name: ?string,
          +publicId: number,
        |},
      |},
    |}>,
    +exchangeClearingType: ?{|
      +id: ?string,
      +name: ?string,
    |},
    +id: ?string,
    +isLatest: boolean,
    +run: any,
    +submissionComment: ?string,
    +submissionState: ?ExchangeClearingSubmissionState,
    +waterDeliveryPeriod: ?{|
      +end: any,
      +id: ?string,
      +start: any,
    |},
  |}>,
  +waterUserExtractionPointsAtExchangeClearing: ?$ReadOnlyArray<?{|
    +extractionPoint: ?{|
      +id: ?string,
      +name: ?string,
    |},
    +id: ?string,
    +offers: ?$ReadOnlyArray<?{|
      +id: ?string,
      +offerLines: ?$ReadOnlyArray<?{|
        +id: ?string,
        +price: any,
        +volume: any,
      |}>,
    |}>,
    +waterUser: ?{|
      +id: ?string,
      +name: ?string,
      +publicId: number,
    |},
  |}>,
|};
export type exchangeClearingExchangeClearingQuery = {|
  response: exchangeClearingExchangeClearingQuery$data,
  variables: exchangeClearingExchangeClearingQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "catchmentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ecId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "wdpId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "catchmentId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "catchment"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "waterUser": {
    "publicId": "ASC"
  }
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ExtractionPoint",
  "kind": "LinkedField",
  "name": "extractionPoint",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "eq",
                        "variableName": "ecId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.0"
              },
              {
                "fields": (v1/*: any*/),
                "kind": "ObjectValue",
                "name": "and.1"
              }
            ],
            "kind": "ListValue",
            "name": "and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "ExchangeClearing",
    "kind": "LinkedField",
    "name": "exchangeClearings",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isLatest",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "run",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submissionState",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submissionComment",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ExchangeClearingType",
        "kind": "LinkedField",
        "name": "exchangeClearingType",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "order",
            "value": {
              "waterUserExtractionPoint": (v5/*: any*/)
            }
          }
        ],
        "concreteType": "Allocation",
        "kind": "LinkedField",
        "name": "allocations",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "baseQuantityPreLoss",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "baseQuantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalQuantityPreLoss",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalQuantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "changeQuantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "farmSurcharge",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "spotPricePreLoss",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "spotPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "farmCongestionPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "raceCongestionPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "effectiveGrossPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "effectiveNetPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quantityDisposedCreditPreLoss",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quantityObtainedCharge",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "raceCongestionCharge",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "raceCongestionCredit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalGrossCharge",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalNetCharge",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clearingFee",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "constraint",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WaterUserExtractionPoint",
            "kind": "LinkedField",
            "name": "waterUserExtractionPoint",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WaterUser",
                "kind": "LinkedField",
                "name": "waterUser",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isDemandOnly",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allocations(order:{\"waterUserExtractionPoint\":{\"waterUser\":{\"publicId\":\"ASC\"}}})"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WaterDeliveryPeriod",
        "kind": "LinkedField",
        "name": "waterDeliveryPeriod",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "start",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "end",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "exchangeClearingId",
        "variableName": "ecId"
      },
      {
        "kind": "Literal",
        "name": "order",
        "value": (v5/*: any*/)
      },
      {
        "fields": (v1/*: any*/),
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "TemporalWaterUserExtractionPoint",
    "kind": "LinkedField",
    "name": "waterUserExtractionPointsAtExchangeClearing",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "eq",
                        "variableName": "wdpId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "waterDeliveryPeriod"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "Offer",
        "kind": "LinkedField",
        "name": "offers",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "order",
                "value": {
                  "price": "ASC"
                }
              }
            ],
            "concreteType": "OfferLine",
            "kind": "LinkedField",
            "name": "offerLines",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "volume",
                "storageKey": null
              }
            ],
            "storageKey": "offerLines(order:{\"price\":\"ASC\"})"
          }
        ],
        "storageKey": null
      },
      (v7/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "WaterUser",
        "kind": "LinkedField",
        "name": "waterUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "exchangeClearingExchangeClearingQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "exchangeClearingExchangeClearingQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "3e7d4cea457ee61cd8be31a2a0d95bed",
    "id": null,
    "metadata": {},
    "name": "exchangeClearingExchangeClearingQuery",
    "operationKind": "query",
    "text": "query exchangeClearingExchangeClearingQuery(\n  $catchmentId: UUID!\n  $ecId: UUID!\n  $wdpId: UUID!\n) {\n  exchangeClearings(where: {and: [{id: {eq: $ecId}}, {catchment: {id: {eq: $catchmentId}}}]}) {\n    id\n    isLatest\n    run\n    submissionState\n    submissionComment\n    exchangeClearingType {\n      id\n      name\n    }\n    allocations(order: {waterUserExtractionPoint: {waterUser: {publicId: ASC}}}) {\n      id\n      baseQuantityPreLoss\n      baseQuantity\n      totalQuantityPreLoss\n      totalQuantity\n      changeQuantity\n      farmSurcharge\n      spotPricePreLoss\n      spotPrice\n      farmCongestionPrice\n      raceCongestionPrice\n      effectiveGrossPrice\n      effectiveNetPrice\n      quantityDisposedCreditPreLoss\n      quantityObtainedCharge\n      raceCongestionCharge\n      raceCongestionCredit\n      totalGrossCharge\n      totalNetCharge\n      clearingFee\n      constraint\n      waterUserExtractionPoint {\n        id\n        publicId\n        extractionPoint {\n          id\n          name\n        }\n        waterUser {\n          id\n          name\n          publicId\n          isDemandOnly\n        }\n      }\n    }\n    waterDeliveryPeriod {\n      id\n      start\n      end\n    }\n  }\n  waterUserExtractionPointsAtExchangeClearing(exchangeClearingId: $ecId, where: {catchment: {id: {eq: $catchmentId}}}, order: {waterUser: {publicId: ASC}}) {\n    id\n    offers(where: {waterDeliveryPeriod: {id: {eq: $wdpId}}}) {\n      id\n      offerLines(order: {price: ASC}) {\n        id\n        price\n        volume\n      }\n    }\n    extractionPoint {\n      id\n      name\n    }\n    waterUser {\n      id\n      name\n      publicId\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "7669a45fd1ce1472ca3339042798245c";

module.exports = ((node/*: any*/)/*: Query<
  exchangeClearingExchangeClearingQuery$variables,
  exchangeClearingExchangeClearingQuery$data,
>*/);
