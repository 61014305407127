import React, { useState } from "react";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { usePreloadedQuery } from "react-relay";
import InfoIcon from "@mui/icons-material/Info";
import { formatDateTime } from "../../../../common/utils/format";
import { MeasurementsChart } from "./MeasurementsChart";
import { ImportDetail } from "../../../components/import/ImportDetail";

export const WaterUserExtractionPointResults = ({ waterUserExtractionPointHeaderQueryDef, waterUserExtractionPointHeaderQueryRef, waterUserExtractionPointResultsQueryDef, waterUserExtractionPointResultsQueryRef }) => {
  const waterUserExtractionPointQuery = usePreloadedQuery(waterUserExtractionPointHeaderQueryDef, waterUserExtractionPointHeaderQueryRef);
  const waterUserExtractionPointResultsQuery = usePreloadedQuery(waterUserExtractionPointResultsQueryDef, waterUserExtractionPointResultsQueryRef);
  const results = waterUserExtractionPointResultsQuery.waterUserExtractionPointOfftakes || [];
  const [ detail, setDetail ] = useState(null);

  const lookup = waterUserExtractionPointQuery && waterUserExtractionPointQuery.measurementQualityTypes
    ? waterUserExtractionPointQuery.measurementQualityTypes.reduce((acc, x) => ({ ...acc, [x.id]: x.name }), {})
    : {};

  return (
    <>
      {results.length > 0
        && results.some(x =>
          x.waterUserExtractionPointOfftakeMeasurements
          && x.waterUserExtractionPointOfftakeMeasurements.length > 0)
        && <MeasurementsChart offtakes={results} />}
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Flow Rate</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Quality</TableCell>
              <TableCell width={50}>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.reduce((p, c) => {
              const measurements = c.waterUserExtractionPointOfftakeMeasurements || [];
              for (const m of measurements) {
                p.push(
                  <TableRow key={m.id}>
                    <TableCell>{c.name}</TableCell>
                    <TableCell>{formatDateTime(m.date)}</TableCell>
                    <TableCell>{m.flowRate.toFixed(3)} l/s</TableCell>
                    <TableCell>{m.total ? (<>{(m.total / 1000).toFixed(3)} k.m<sup>3</sup></>): ""}</TableCell>
                    <TableCell>{m.measurementQualityType ? lookup[m.measurementQualityType.id] : ""}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => setDetail(m)}>
                        <InfoIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              }
              return p;
            }, [])}
          </TableBody>
        </Table>
      </TableContainer>
      {detail && <ImportDetail importOperationId={detail.importOperation ? detail.importOperation.id : '00000000-0000-0000-0000-000000000000'}
        userId={detail.modifiedByUser ? detail.modifiedByUser.id : '00000000-0000-0000-0000-000000000000'}
        isOpen={!!detail} onClose={() => setDetail(null)} />}
    </>
  );
};