/**
 * @generated SignedSource<<65109b4470cbb714cd113d801623dcaf>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExchangeClearingSubmissionState = "APPROVED" | "CANCELED" | "DRAFT" | "FAILED" | "PENDING" | "REJECTED" | "%future added value";
export type UpdateExchangeClearingInput = {|
  exchangeClearingId: any,
  exchangeClearingTypeId: any,
|};
export type ClearingsTableUpdateExchangeClearingMutation$variables = {|
  input: UpdateExchangeClearingInput,
|};
export type ClearingsTableUpdateExchangeClearingMutation$data = {|
  +updateExchangeClearing: {|
    +exchangeClearing: ?{|
      +exchangeClearingType: ?{|
        +id: ?string,
        +isFinal: boolean,
        +name: ?string,
      |},
      +id: ?string,
      +isLatest: boolean,
      +run: any,
      +submissionState: ?ExchangeClearingSubmissionState,
    |},
  |},
|};
export type ClearingsTableUpdateExchangeClearingMutation = {|
  response: ClearingsTableUpdateExchangeClearingMutation$data,
  variables: ClearingsTableUpdateExchangeClearingMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateExchangeClearingPayload",
    "kind": "LinkedField",
    "name": "updateExchangeClearing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExchangeClearing",
        "kind": "LinkedField",
        "name": "exchangeClearing",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLatest",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submissionState",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "run",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExchangeClearingType",
            "kind": "LinkedField",
            "name": "exchangeClearingType",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isFinal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClearingsTableUpdateExchangeClearingMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClearingsTableUpdateExchangeClearingMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "28c6607461160fa800d68a4d99d58117",
    "id": null,
    "metadata": {},
    "name": "ClearingsTableUpdateExchangeClearingMutation",
    "operationKind": "mutation",
    "text": "mutation ClearingsTableUpdateExchangeClearingMutation(\n  $input: UpdateExchangeClearingInput!\n) {\n  updateExchangeClearing(input: $input) {\n    exchangeClearing {\n      id\n      isLatest\n      submissionState\n      run\n      exchangeClearingType {\n        id\n        isFinal\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "b292b1505cf4279363a577970ac25072";

module.exports = ((node/*: any*/)/*: Mutation<
  ClearingsTableUpdateExchangeClearingMutation$variables,
  ClearingsTableUpdateExchangeClearingMutation$data,
>*/);
