import React from "react";
import { Link as LinkReact } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { usePreloadedQuery } from "react-relay";
import { Subheader } from "../../../../common/components/subheader";
import { Query } from "./Query";

export const ClearingsHeader = ({ clearingsHeaderQueryDef, clearingsHeaderQueryRef, onExecute }) => {
  const clearingsHeaderQuery = usePreloadedQuery(clearingsHeaderQueryDef, clearingsHeaderQueryRef);
  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Overview</Typography>
        </Link>
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">Clearings</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Clearings</Typography>
      {clearingsHeaderQuery && <>
        <Subheader name="Search" />
        <Query onExecute={onExecute} waterUsers={clearingsHeaderQuery.waterUsers} />
      </>}
    </>
  );
};