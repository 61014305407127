/**
 * @generated SignedSource<<64c14055aac50302c26353bcd74ca116>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateWaterUserExtractionPointOfftakeMeasurementsInput = {|
  comment?: ?string,
  fileName?: ?string,
  waterUserExtractionPointOfftakeId: any,
  waterUserExtractionPointOfftakeMeasurements?: ?$ReadOnlyArray<?WaterUserExtractionPointOfftakeMeasurementCreateInput>,
|};
export type WaterUserExtractionPointOfftakeMeasurementCreateInput = {|
  date: any,
  flowRate: any,
  measurementQualityTypeId: any,
  total?: ?any,
|};
export type OfftakeImportWizardDialogUpdateOfftakeMeasurementsMutation$variables = {|
  input: UpdateWaterUserExtractionPointOfftakeMeasurementsInput,
|};
export type OfftakeImportWizardDialogUpdateOfftakeMeasurementsMutation$data = {|
  +updateWaterUserExtractionPointOfftakeMeasurements: {|
    +waterUserExtractionPointOfftakeMeasurement: ?$ReadOnlyArray<?{|
      +date: any,
      +flowRate: any,
      +id: ?string,
      +measurementQualityType: ?{|
        +id: ?string,
      |},
      +total: ?any,
    |}>,
  |},
|};
export type OfftakeImportWizardDialogUpdateOfftakeMeasurementsMutation = {|
  response: OfftakeImportWizardDialogUpdateOfftakeMeasurementsMutation$data,
  variables: OfftakeImportWizardDialogUpdateOfftakeMeasurementsMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateWaterUserExtractionPointOfftakeMeasurementsPayload",
    "kind": "LinkedField",
    "name": "updateWaterUserExtractionPointOfftakeMeasurements",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WaterUserExtractionPointOfftakeMeasurement",
        "kind": "LinkedField",
        "name": "waterUserExtractionPointOfftakeMeasurement",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "flowRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MeasurementQualityType",
            "kind": "LinkedField",
            "name": "measurementQualityType",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OfftakeImportWizardDialogUpdateOfftakeMeasurementsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OfftakeImportWizardDialogUpdateOfftakeMeasurementsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "bfd6c52d610d7a249a7dfd8c4cd1c7d4",
    "id": null,
    "metadata": {},
    "name": "OfftakeImportWizardDialogUpdateOfftakeMeasurementsMutation",
    "operationKind": "mutation",
    "text": "mutation OfftakeImportWizardDialogUpdateOfftakeMeasurementsMutation(\n  $input: UpdateWaterUserExtractionPointOfftakeMeasurementsInput!\n) {\n  updateWaterUserExtractionPointOfftakeMeasurements(input: $input) {\n    waterUserExtractionPointOfftakeMeasurement {\n      id\n      date\n      flowRate\n      total\n      measurementQualityType {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "d911c9ce26af80310b1e1f5885bea872";

module.exports = ((node/*: any*/)/*: Mutation<
  OfftakeImportWizardDialogUpdateOfftakeMeasurementsMutation$variables,
  OfftakeImportWizardDialogUpdateOfftakeMeasurementsMutation$data,
>*/);
