import React from "react";
import { IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { formatDate, formatVolume } from "../../../../common/utils/format";
import { calculateNet } from "../../../utils/water";
import { n } from "../../../../common/utils/math";
import { safeParse } from "../../../../common/utils/json";

export const ExtractionSourceInputsTable = ({ extractionSourceInputs, extractionSourceConfig, onEdit, onView }) => {
  const config = safeParse(extractionSourceConfig);
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell colSpan={2}>Forecast</TableCell>
            <TableCell colSpan={3}>Actual</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>{config.labels?.Gross}</TableCell>
            <TableCell>{config.labels?.Net}</TableCell>
            <TableCell>{config.labels?.Gross}</TableCell>
            <TableCell>{config.labels?.Net}</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {extractionSourceInputs && extractionSourceInputs.map(x => (
            <TableRow key={x.id}>
              <TableCell>{formatDate(x.date)}</TableCell>
              <TableCell>{formatVolume(x.forecastGross, true)}</TableCell>
              <TableCell>{formatVolume(n(calculateNet(extractionSourceConfig, x.forecastGross, x.forecastNet)), true)}</TableCell>
              <TableCell>{formatVolume(x.actualGross, true)}</TableCell>
              <TableCell>{formatVolume(n(calculateNet(extractionSourceConfig, x.actualGross, x.actualNet)), true)}</TableCell>
              <TableCell>
                <Stack direction="row">
                  <IconButton size="small" onClick={() => onEdit(x)} sx={{ mr: 1 }}>
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                  {(x.forecastSync || x.actualSync) &&
                    <IconButton size="small" onClick={() => onView(x)} sx={{ mr: 1 }}>
                      <InfoIcon fontSize="inherit" />
                    </IconButton>}
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};