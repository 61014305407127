import React, { useState } from "react";
import { Link as LinkReact, Navigate } from "react-router-dom";
import { Breadcrumbs, Grid, Link, Paper, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { usePreloadedQuery } from "react-relay";
import { Subheader } from "../../../../common/components/subheader";
import { GridNode } from "../../../../common/components/gridNode";
import { Query } from "./Query";
import { OfftakeImportWizardDialog } from "./OfftakeImportWizardDialog";

const formatOfftakes = (waterUserExtractionPointOfftakes) => {
  const types = waterUserExtractionPointOfftakes || [];
  return types.map((t) => t.name).sort().join(", ");
};

export const WaterUserExtractionPointHeader = ({ waterUserExtractionPointHeaderQueryDef, waterUserExtractionPointHeaderQueryRef, onExecute }) => {
  const waterUserExtractionPointQuery = usePreloadedQuery(waterUserExtractionPointHeaderQueryDef, waterUserExtractionPointHeaderQueryRef);
  const waterUserExtractionPoint = waterUserExtractionPointQuery.waterUserExtractionPoints.length > 0
    ? waterUserExtractionPointQuery.waterUserExtractionPoints[0]
    : null;
  const qualityTypes = waterUserExtractionPointQuery.measurementQualityTypes || [];
  const [ open, setOpen ] = useState(false);
  const [ isFirstLoad, setIsFirstLoad ] = useState(true);

  const handleSave = () => {
    setOpen(false);
    setIsFirstLoad(true);
  };

  if (!waterUserExtractionPoint) {
    return (<Navigate to="/admin/water-users" />);
  }

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Administration</Typography>
        </Link>
        <Link component={LinkReact} underline="hover" color="inherit" to="/admin/water-users">
          <Typography variant="body2">Water Users</Typography>
        </Link>
        <Link component={LinkReact} underline="hover" color="inherit" to={`/admin/water-users/${waterUserExtractionPoint.waterUser.id}`}>
          <Typography variant="body2">{waterUserExtractionPoint.waterUser.name}</Typography>
        </Link>
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">{waterUserExtractionPoint.extractionPoint.name}</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">{waterUserExtractionPoint.extractionPoint.name}</Typography>
      <Grid container className="water-user-extraction-point">
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Subheader name="Overview" />
        </Grid>
        <Grid container item xs={12} component={Paper} sx={{ p: 2, pt: 1 }} rowSpacing={1}>
          <GridNode name="Loss Factor" value={waterUserExtractionPoint.lossFactor} />
          <GridNode name="Max Offtake" value={waterUserExtractionPoint.maxOfftake} />
          <GridNode name="Offtakes" value={formatOfftakes(waterUserExtractionPoint.waterUserExtractionPointOfftakes)} />
        </Grid>
      </Grid>
      <Subheader name="Search" action={() => setOpen(true)} linkName={"Import..."} />
      <Query isFirstLoad={isFirstLoad} setIsFirstLoad={setIsFirstLoad} onExecute={onExecute}
        extractionPointOfftakeTypes={waterUserExtractionPointQuery.extractionPointOfftakeTypes} />
      <OfftakeImportWizardDialog open={open} onSave={handleSave} onCancel={() => setOpen(false)} waterUserExtractionPoint={waterUserExtractionPoint}
        qualityTypes={qualityTypes} />
    </>
  );
};