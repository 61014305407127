/**
 * @generated SignedSource<<4ee8487328b9057d0e3803a1c6d0454a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type ClearingOffersClearingOffersQuery$variables = {|
  catchmentId: any,
  run: any,
  wdpId: any,
|};
export type ClearingOffersClearingOffersQuery$data = {|
  +catchments: ?$ReadOnlyArray<?{|
    +minimumBidPrice: any,
  |}>,
  +extractionSourceInputsForWaterDeliveryPeriodAt: ?$ReadOnlyArray<?{|
    +actualGross: ?any,
    +actualNet: ?any,
    +date: any,
    +extractionSource: ?{|
      +id: ?string,
    |},
    +forecastGross: ?any,
    +forecastNet: ?any,
    +id: ?string,
  |}>,
  +extractionSourcesAt: ?$ReadOnlyArray<?{|
    +config: ?string,
    +id: ?string,
    +inputOffset: ?number,
    +name: ?string,
  |}>,
  +waterDeliveryPeriods: ?$ReadOnlyArray<?{|
    +end: any,
    +id: ?string,
    +start: any,
  |}>,
  +waterUserExtractionPointsAt: ?$ReadOnlyArray<?{|
    +extractionPoint: ?{|
      +extractionSource: ?{|
        +id: ?string,
      |},
      +id: ?string,
      +name: ?string,
    |},
    +id: ?string,
    +lossFactor: ?any,
    +offers: ?$ReadOnlyArray<?{|
      +id: ?string,
      +offerLines: ?$ReadOnlyArray<?{|
        +id: ?string,
        +price: any,
        +volume: any,
      |}>,
    |}>,
    +shares: ?$ReadOnlyArray<?{|
      +apportionment: ?{|
        +id: ?string,
        +value: any,
      |},
      +id: ?string,
      +value: any,
    |}>,
    +waterUser: ?{|
      +id: ?string,
      +isDemandOnly: boolean,
      +isRecurringDefaultBidder: boolean,
      +isSystemActor: boolean,
      +name: ?string,
      +publicId: number,
    |},
  |}>,
|};
export type ClearingOffersClearingOffersQuery = {|
  response: ClearingOffersClearingOffersQuery$data,
  variables: ClearingOffersClearingOffersQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "catchmentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "run"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wdpId"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "eq",
        "variableName": "catchmentId"
      }
    ],
    "kind": "ObjectValue",
    "name": "id"
  }
],
v4 = [
  {
    "fields": (v3/*: any*/),
    "kind": "ObjectValue",
    "name": "where"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minimumBidPrice",
  "storageKey": null
},
v6 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "eq",
        "variableName": "wdpId"
      }
    ],
    "kind": "ObjectValue",
    "name": "id"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "fields": (v6/*: any*/),
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "WaterDeliveryPeriod",
  "kind": "LinkedField",
  "name": "waterDeliveryPeriods",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "start",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "end",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "kind": "Variable",
  "name": "run",
  "variableName": "run"
},
v10 = {
  "fields": [
    {
      "fields": (v3/*: any*/),
      "kind": "ObjectValue",
      "name": "catchment"
    }
  ],
  "kind": "ObjectValue",
  "name": "where"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": [
    (v9/*: any*/),
    (v10/*: any*/)
  ],
  "concreteType": "TemporalExtractionSource",
  "kind": "LinkedField",
  "name": "extractionSourcesAt",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "config",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inputOffset",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ExtractionSource",
  "kind": "LinkedField",
  "name": "extractionSource",
  "plural": false,
  "selections": [
    (v7/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": [
    (v9/*: any*/),
    {
      "kind": "Variable",
      "name": "waterDeliveryPeriodId",
      "variableName": "wdpId"
    },
    (v10/*: any*/)
  ],
  "concreteType": "TemporalExtractionSourceInput",
  "kind": "LinkedField",
  "name": "extractionSourceInputsForWaterDeliveryPeriodAt",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "forecastGross",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "forecastNet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualGross",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualNet",
      "storageKey": null
    },
    (v13/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "order",
      "value": {
        "waterUser": {
          "publicId": "ASC"
        }
      }
    },
    (v9/*: any*/),
    (v10/*: any*/)
  ],
  "concreteType": "TemporalWaterUserExtractionPoint",
  "kind": "LinkedField",
  "name": "waterUserExtractionPointsAt",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lossFactor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExtractionPoint",
      "kind": "LinkedField",
      "name": "extractionPoint",
      "plural": false,
      "selections": [
        (v7/*: any*/),
        (v11/*: any*/),
        (v13/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WaterUser",
      "kind": "LinkedField",
      "name": "waterUser",
      "plural": false,
      "selections": [
        (v7/*: any*/),
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publicId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isRecurringDefaultBidder",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDemandOnly",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isSystemActor",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "fields": (v6/*: any*/),
              "kind": "ObjectValue",
              "name": "waterDeliveryPeriod"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "Offer",
      "kind": "LinkedField",
      "name": "offers",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "order",
              "value": {
                "price": "ASC"
              }
            }
          ],
          "concreteType": "OfferLine",
          "kind": "LinkedField",
          "name": "offerLines",
          "plural": true,
          "selections": [
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "volume",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            }
          ],
          "storageKey": "offerLines(order:{\"price\":\"ASC\"})"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Share",
      "kind": "LinkedField",
      "name": "shares",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        (v15/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Apportionment",
          "kind": "LinkedField",
          "name": "apportionment",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            (v15/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClearingOffersClearingOffersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Catchment",
        "kind": "LinkedField",
        "name": "catchments",
        "plural": true,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/),
      (v12/*: any*/),
      (v14/*: any*/),
      (v16/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClearingOffersClearingOffersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Catchment",
        "kind": "LinkedField",
        "name": "catchments",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/),
      (v12/*: any*/),
      (v14/*: any*/),
      (v16/*: any*/)
    ]
  },
  "params": {
    "cacheID": "ebc6efe1f0e5067790ec4bfebb756d1a",
    "id": null,
    "metadata": {},
    "name": "ClearingOffersClearingOffersQuery",
    "operationKind": "query",
    "text": "query ClearingOffersClearingOffersQuery(\n  $catchmentId: UUID!\n  $wdpId: UUID!\n  $run: DateTime!\n) {\n  catchments(where: {id: {eq: $catchmentId}}) {\n    minimumBidPrice\n    id\n  }\n  waterDeliveryPeriods(where: {id: {eq: $wdpId}}) {\n    id\n    start\n    end\n  }\n  extractionSourcesAt(run: $run, where: {catchment: {id: {eq: $catchmentId}}}) {\n    id\n    name\n    config\n    inputOffset\n  }\n  extractionSourceInputsForWaterDeliveryPeriodAt(run: $run, waterDeliveryPeriodId: $wdpId, where: {catchment: {id: {eq: $catchmentId}}}) {\n    id\n    date\n    forecastGross\n    forecastNet\n    actualGross\n    actualNet\n    extractionSource {\n      id\n    }\n  }\n  waterUserExtractionPointsAt(run: $run, where: {catchment: {id: {eq: $catchmentId}}}, order: {waterUser: {publicId: ASC}}) {\n    id\n    lossFactor\n    extractionPoint {\n      id\n      name\n      extractionSource {\n        id\n      }\n    }\n    waterUser {\n      id\n      name\n      publicId\n      isRecurringDefaultBidder\n      isDemandOnly\n      isSystemActor\n    }\n    offers(where: {waterDeliveryPeriod: {id: {eq: $wdpId}}}) {\n      id\n      offerLines(order: {price: ASC}) {\n        id\n        volume\n        price\n      }\n    }\n    shares {\n      id\n      value\n      apportionment {\n        id\n        value\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "7094946855c85d0e6a4e3fd30820348e";

module.exports = ((node/*: any*/)/*: Query<
  ClearingOffersClearingOffersQuery$variables,
  ClearingOffersClearingOffersQuery$data,
>*/);
