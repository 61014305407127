import React, { useContext, useState } from "react";
import { Grid, useTheme } from "@mui/material";
import Plot from "react-plotly.js";
import moment from "moment";
import "moment-timezone";
import { useDefaultConfig, useDefaultLayout, useDefaultLabelStyle, useIsLightTheme, useConvert } from "../../../utils/charts";
import { ChartTitle } from "../../../components/chartTitle";
import { calculateDeliveriesByDay } from "../../../utils/water";
import { ProfileContext } from "../../../../common/contexts/profile";

export const MeasurementsChart = ({ offtakes, measurements }) => {
  const { timeZone } = useContext(ProfileContext);
  const [ dynamicPlot, setDynamicPlot ] = useState(false);
  const theme = useTheme();
  const isLightTheme = useIsLightTheme();
  const defaultConfig = useDefaultConfig({ staticPlot: !dynamicPlot });
  const defaultLayout = useDefaultLayout();
  const defaultLabelStyle = useDefaultLabelStyle();
  const convert = useConvert();

  const createVolume = (name, color) => ({
    x: [],
    y: [],
    name,
    type: "bar",
    marker: {
      color
    },
    hovertemplate: "%{x}: <b>%{y:.2f}k.m³</b><extra></extra>",
    hoverlabel: defaultLabelStyle
  });

  const createFlowRate = (name, color) => ({
    x: [],
    y: [],
    name,
    type: "line",
    marker: {
      color
    },
    hovertemplate: "%{x}: <b>%{y:.2f}l/s</b><extra></extra>",
    hoverlabel: defaultLabelStyle,
    yaxis: "y2"
  });

  const colors = [
    theme.palette.primary.light,
    theme.palette.grey[400],
    theme.palette.grey[700]
  ];

  // Ensure measurements, which are in standard time, are adjusted to the current TZ if required
  measurements = measurements.map(x => ({ ...x, date: moment.parseZone(x.date).tz(timeZone).format() }));

  const volume = createVolume("Volume", theme.palette.primary.main);
  const deliveries = calculateDeliveriesByDay(measurements);
  Object.keys(deliveries).forEach(d => {
    volume.x.push(d);
    volume.y.push(deliveries[d].done());
  });

  const rates = offtakes.map((o, i) => ({
    id: o.id,
    s: createFlowRate(`Flow rate (${o.name})`, colors[i % colors.length])
  }));

  for (const measurement of measurements) {
    const s = rates.find(x => x.id === measurement.waterUserExtractionPointOfftake.id);
    s.s.x.push(measurement.date);
    s.s.y.push(measurement.flowRate);
  }

  const data = [volume, ...rates.map(s => s.s)];

  return (
    <Grid container item sx={{ mt: 1 }} xs={12}>
      <ChartTitle checked={dynamicPlot} onChange={setDynamicPlot} title="Water deliveries over time" />
      <Grid item xs={12} style={{ height: 400 }}>
        <Plot
          config={defaultConfig}
          data={data}
          layout={{
            ...defaultLayout,
            margin: {
              ...defaultLayout.margin,
              b: 115,
              r: 65
            },
            legend: {...defaultLayout.legend, traceorder: "normal"},
            xaxis: {
              gridcolor: isLightTheme ? undefined : theme.palette.grey[800],
              rangemode: "nonnegative",
              ticks: "outside",
              tickformat: "%d/%m %H:%M",
              title: {
                font: {
                  size: convert(theme.typography.body2.fontSize)
                },
                text: "date"
              }
            },
            yaxis: {
              gridcolor: isLightTheme ? undefined : theme.palette.grey[800],
              rangemode: "tozero",
              ticks: "outside",
              title: {
                font: {
                  size: convert(theme.typography.body2.fontSize)
                },
                text: "volume (k.m³)"
              }
            },
            yaxis2: {
              gridcolor: isLightTheme ? undefined : theme.palette.grey[800],
              rangemode: "tozero",
              ticks: "outside",
              title: {
                font: {
                  size: convert(theme.typography.body2.fontSize)
                },
                text: "flow rate (l/s)"
              },
              side: "right",
              overlaying: "y"
            }
          }}
          style={{ display: "block" }}
          useResizeHandler={true}
        />
      </Grid>
    </Grid>
  );
};