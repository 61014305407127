import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import { Subheader } from "../../../../common/components/subheader";
import { Tbl } from "./Tbl";
import { Tile } from "./Tile";
import { NowTile } from "./NowTile";
import { formatDateShort } from "../../../../common/utils/format";
import { roundStartDate, roundEndDate } from "../../../../common/utils/date";

const nowOffsetInDays = 1;

export const Section = ({ name, items, now, sx }) => {
  const nowWithOffset = now.clone().startOf("day").add(nowOffsetInDays, "days");
  const nowItem = items.find(x => moment(x.waterDeliveryPeriod.start) <= nowWithOffset && moment(x.waterDeliveryPeriod.end) >= nowWithOffset);
  const nowLink = nowItem ? `/delivery-periods/${nowItem.waterDeliveryPeriod.id}/water-users/${nowItem.waterUserExtractionPointId}` : null;
  const nextItems = items.filter(x => moment(x.waterDeliveryPeriod.start) > nowWithOffset);

  return (
    <Box sx={sx} className="section">
      <Typography variant="h1" component="h2">{name}</Typography>
      <Box className="now">
        <Subheader name="Now" />
        {nowItem && <>
          <Grid container columnSpacing={2} alignItems="stretch">
            <NowTile label="Water Delivery Period" num={`${formatDateShort(roundStartDate(nowItem.waterDeliveryPeriod.start))} - ${formatDateShort(roundEndDate(nowItem.waterDeliveryPeriod.end))}`} hideUnits to={nowLink} />
            <NowTile label={nowItem.stats[0].label} num={nowItem.stats[0].num} sx={{ display: { xs: "none", sm: "block" } }} to={nowLink} />
            <NowTile label={nowItem.stats[2].label} num={nowItem.stats[2].num} to={nowLink} direction={nowItem.stats[2].direction}
              isMissingOffers={nowItem.isMissingOffers} isInProgress={nowItem.isInProgress} />
          </Grid>
        </>}
        {!nowItem && <Paper sx={{ p: 2 }}>
          <Typography variant="body2">No water delivery period is active for today's date.</Typography>
        </Paper>}
      </Box>
      <Box className="next">
        <Subheader name="Next" />
        <Box className="records">
          {nextItems.length > 0 && <>
            {nextItems.map(x => (
              <Tile key={`${x.waterDeliveryPeriod.id}_${x.waterUserExtractionPointId}`}
                waterDeliveryPeriod={x.waterDeliveryPeriod}
                waterUserExtractionPointId={x.waterUserExtractionPointId}
                stats={x.stats} />))}
            <Tbl items={nextItems} />
          </>}
          {nextItems.length === 0 && <Paper sx={{ p: 2 }}>
            <Typography variant="body2">No water delivery periods are available within the next fourteen days.</Typography>
          </Paper>}
        </Box>
      </Box>
    </Box>
  );
};