import React, { Suspense, useContext, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { useParams } from "react-router-dom";
import { ExchangeClearing } from "./components/ExchangeClearing";
import { CenteredLoading } from "../../../common/components/loading";
import { ProfileContext } from "../../../common/contexts/profile";

const ExchangeClearingQuery = graphql`
  query exchangeClearingExchangeClearingQuery($catchmentId: UUID!, $ecId: UUID!, $wdpId: UUID!) {
    exchangeClearings(where: { and: [ { id: { eq: $ecId } }, { catchment: { id: { eq: $catchmentId } } } ] }) {
      id
      isLatest
      run
      submissionState
      submissionComment
      exchangeClearingType {
        id
        name
      }
      allocations(order: { waterUserExtractionPoint: { waterUser: { publicId: ASC } } }) {
        id
        baseQuantityPreLoss
        baseQuantity
        totalQuantityPreLoss
        totalQuantity
        changeQuantity
        farmSurcharge
        spotPricePreLoss
        spotPrice
        farmCongestionPrice
        raceCongestionPrice
        effectiveGrossPrice
        effectiveNetPrice
        quantityDisposedCreditPreLoss
        quantityObtainedCharge
        raceCongestionCharge
        raceCongestionCredit
        totalGrossCharge
        totalNetCharge
        clearingFee
        constraint
        waterUserExtractionPoint {
          id
          publicId
          extractionPoint {
            id
            name
          }
          waterUser {
            id
            name
            publicId
            isDemandOnly
          }
        }
      }
      waterDeliveryPeriod {
        id
        start
        end
      }
    }
    waterUserExtractionPointsAtExchangeClearing(exchangeClearingId: $ecId, where: { catchment: { id: { eq: $catchmentId } } }, order: { waterUser: { publicId: ASC } }) {
      id
      offers(where: { waterDeliveryPeriod: { id: { eq: $wdpId } } }) {
        id
        offerLines(order: { price: ASC }) {
          id
          price
          volume
        }
      }
      extractionPoint {
        id
        name
      }
      waterUser {
        id
        name
        publicId
      }
    }
  }
`;

export const ExchangeClearingPage = () => {
  const [ exchangeClearingQueryRef, loadExchangeClearingQuery ] = useQueryLoader(ExchangeClearingQuery);
  const { wdpId, ecId } = useParams();
  const { catchmentId } = useContext(ProfileContext);
  useEffect(() => loadExchangeClearingQuery({ catchmentId, ecId, wdpId },
    { fetchPolicy: "network-only" }),
    [ catchmentId, ecId, loadExchangeClearingQuery, wdpId ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {exchangeClearingQueryRef &&
        <ExchangeClearing exchangeClearingQueryDef={ExchangeClearingQuery} exchangeClearingQueryRef={exchangeClearingQueryRef} />}
    </Suspense>
  );
};