import React, { useEffect } from "react";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { divide, multiply } from "../../../../common/utils/math";

const DownloadQuery = graphql`
  query ResultUserChartDownloadDownloadQuery($wdpId: UUID!) {
    offerSummariesDetailed(waterDeliveryPeriodId: $wdpId) {
      offerLineId
      volume
      price
      adjustedVolume
      adjustedPrice
      lossFactor
      maxOfftake
      adjustedMaxOfftake
      offer {
        id
        waterUserExtractionPoint {
          id
          publicId
          waterUser {
            id
            name
            publicId
          }
        }
      }
    }
  }
`

export const ResultUserChartDownload = ({ wdpId, wuepId, totalInput, onComplete }) => {
  const data = useLazyLoadQuery(DownloadQuery, { wdpId }, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (data && data.offerSummariesDetailed) {
      const current = data.offerSummariesDetailed.find(x => x.offer.waterUserExtractionPoint.id === wuepId);
      const ti = current ? divide(totalInput, current.lossFactor) : 0;

      const records = data.offerSummariesDetailed.map(x => ({
        "Type": x.offer.waterUserExtractionPoint.id === wuepId ? "Demand" : "Supply",
        "Water User ID": x.offer.waterUserExtractionPoint.waterUser.id,
        "Water User Public ID": x.offer.waterUserExtractionPoint.waterUser.publicId,
        "Water User Name": `"${x.offer.waterUserExtractionPoint.waterUser.name}"`,
        "Water User Extraction Point ID": x.offer.waterUserExtractionPoint.id,
        "Water User Extraction Point Public ID": x.offer.waterUserExtractionPoint.publicId,
        "Water User Extraction Point Max Offtake": x.maxOfftake,
        "Water User Extraction Point Loss Factor": x.lossFactor,
        "Water User Extraction Point Adjusted Max Offtake": x.adjustedMaxOfftake,
        "Offer ID": x.offer.id,
        "Offer Line ID": x.offerLineId,
        "Volume": x.volume,
        "Price": x.price,
        "Adjusted Volume (at river)": x.adjustedVolume,
        "Adjusted Price (at river)": x.adjustedPrice,
        "Adjusted Volume (at farm)": current ? divide(x.adjustedVolume, current.lossFactor) : "",
        "Cumulative Adjusted Volume (at farm)": 0,
        "Adjusted Price (at farm)": current ? multiply(x.adjustedPrice, current.lossFactor) : "",
      }));

      records.sort((a, b) => a["Type"].localeCompare(b["Type"]) || b["Adjusted Price (at farm)"] - a["Adjusted Price (at farm)"]);
      
      records
        .filter(x => x["Type"] === "Demand")
        .forEach((x, i, a) => {
          x["Cumulative Adjusted Volume (at farm)"] = (i === 0 ? 0 : a[i - 1]["Cumulative Adjusted Volume (at farm)"]) + x["Adjusted Volume (at farm)"];
        });

      records
        .filter(x => x["Type"] === "Supply")
        .forEach((x, i, a) => {
          x["Cumulative Adjusted Volume (at farm)"] = (i === 0 ? ti : a[i - 1]["Cumulative Adjusted Volume (at farm)"]) - x["Adjusted Volume (at farm)"];
        });

      const rows = records.reduce((p, c, i) => {
        if (i === 0) {
          p.push(Object.keys(c).join(","));
        }
  
        p.push(Object.values(c).join(","));
        return p;
      }, []);

      const a = document.createElement("a");
      const blob = new Blob([ rows.join("\n") ], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      a.href = url;
      a.setAttribute("download", `S&D_${wdpId}_${wuepId}.csv`);
      a.click();

      onComplete && onComplete();
    }
  }, [ data, onComplete, totalInput, wdpId, wuepId ]);

  return (<></>);
};