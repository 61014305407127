import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Box, Dialog, DialogActions, DialogContent, DialogTitle, Step, StepLabel, Stepper } from "@mui/material";
import moment from "moment";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { ClearingProperties } from "./ClearingProperties";
import { ClearingOffers } from "./ClearingOffers";
import { ClearingAllocations } from "./ClearingAllocations";
import { WizardLoading } from "../../../../common/components/loading";
import { NotificationContext } from "../../../../common/contexts/notification";
import { updateClearings } from "./ClearingsTable";

const CreateExchangeClearingMutation = graphql`
  mutation ClearingWizardDialogCreateExchangeClearingMutation($input: CreateExchangeClearingInput!) {
    createExchangeClearing(input: $input) {
      exchangeClearing {
        id
        isLatest
        run
        exchangeClearingType {
          id
          isFinal
          name
        }
        waterDeliveryPeriod {
          id
        }
        allocations {
          id
          baseQuantityPreLoss
          baseQuantity
          totalQuantityPreLoss
          totalQuantity
          changeQuantity
          farmSurcharge
          spotPricePreLoss
          spotPrice
          farmCongestionPrice
          raceCongestionPrice
          effectiveGrossPrice
          effectiveNetPrice
          quantityDisposedCreditPreLoss
          quantityObtainedCharge
          raceCongestionCharge
          raceCongestionCredit
          totalGrossCharge
          totalNetCharge
          clearingFee
          constraint
          waterUserExtractionPointId
        }
      }
    }
  }
`;

const finalStep = 2;

export const ClearingWizardDialog = ({ catchment, open, onCancel, onSave, types, waterDeliveryPeriodId }) => {
  const [ activeStep, setActiveStep ] = useState(0);
  const [ run, setRun ] = useState(null);
  const [ type, setType ] = useState("");
  const [ autoBid, setAutoBid ] = useState(true);
  const [ isInProgress, setIsInProgress ] = useState(false);
  const [ allocations, setAllocations ] = useState([]);
  const [ commitExchangeClearing ] = useMutation(CreateExchangeClearingMutation);
  const [ isSaving, setIsSaving ] = useState(false);
  const { setSuccess, setError } = useContext(NotificationContext);

  const handleNext = () => {
    if (activeStep === finalStep) {
      handleSave();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  const handleProgressChange = useCallback(p => setIsInProgress(p), [ setIsInProgress ]);

  const handleSave = () => {
    setIsSaving(true);

    const mappedAllocations = mapAllocations();
    if (!mappedAllocations) {
      setIsSaving(false);
      return;
    }

    commitExchangeClearing({
      variables: {
        input: {
          catchmentId: catchment.id,
          waterDeliveryPeriodId,
          run: run.format(),
          exchangeClearingTypeId: type,
          allocations: mappedAllocations
        }
      },
      onCompleted: () => {
        setIsSaving(false);
        setSuccess("Exchange clearing updated successfully");
        onSave();
      },
      onError: e => {
        setIsSaving(false);
        setError("Exchange clearing failed to update", e);
      },
      updater: store => {
        const exchangeClearing = store.getRootField("createExchangeClearing")
          .getLinkedRecord("exchangeClearing");
        updateClearings(store, waterDeliveryPeriodId, exchangeClearing);
      }
    });
  };

  const mapAllocations = () => {
    const mappedAllocations = [];
    for (const allocation of allocations) {
      const wuName = allocation["waterUserExtractionPoint-waterUser-name"];
      const epName = allocation["waterUserExtractionPoint-extractionPoint-name"];
      const wu = catchment.waterUsers.find(x => x.name === wuName);
      const wuEp = wu
        ? wu.waterUserExtractionPoints.find(x => x.extractionPoint.name === epName)
        : null;

      if (!wuEp) {
        setError(`Unable to find ${wuName}`);
        return null;
      }

      mappedAllocations.push({
        waterUserExtractionPointId: wuEp.id,
        baseQuantityPreLoss: allocation.baseQuantityPreLoss || 0,
        baseQuantity: allocation.baseQuantity || 0,
        totalQuantityPreLoss: allocation.totalQuantityPreLoss || 0,
        totalQuantity: allocation.totalQuantity || 0,
        changeQuantity: allocation.changeQuantity || 0,
        farmSurcharge: allocation.farmSurcharge || "",
        spotPricePreLoss: allocation.spotPricePreLoss || 0,
        spotPrice: allocation.spotPrice || 0,
        farmCongestionPrice: allocation.farmCongestionPrice || 0,
        raceCongestionPrice: allocation.raceCongestionPrice || 0,
        effectiveGrossPrice: allocation.effectiveGrossPrice || 0,
        effectiveNetPrice: allocation.effectiveNetPrice || 0,
        quantityDisposedCreditPreLoss: allocation.quantityDisposedCreditPreLoss || 0,
        quantityObtainedCharge: allocation.quantityObtainedCharge || 0,
        raceCongestionCharge: allocation.raceCongestionCharge || 0,
        raceCongestionCredit: allocation.raceCongestionCredit || 0,
        totalGrossCharge: allocation.totalGrossCharge || 0,
        totalNetCharge: allocation.totalNetCharge || 0,
        clearingFee: allocation.clearingFee || 0,
        constraint: allocation.constraint || ""
      });
    }

    return mappedAllocations;
  };

  useEffect(() => {
    if (open) {
      setActiveStep(0);
      setRun(moment()); // Don't use now() as this date is used for temporal tables
      setType("");
      setIsInProgress(false);
      setIsSaving(false);
      setAllocations([]);
    }
  }, [ open, setActiveStep, setRun, setType ]);

  const trialId = types.find(type => type.name === 'Trial')?.id;

  return (
    <Dialog open={open} onClose={onCancel} fullWidth={activeStep > 0} maxWidth="xl">
      <DialogTitle>Add</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            <Step>
              <StepLabel>Properties</StepLabel>
            </Step>
            <Step>
              <StepLabel>Offers</StepLabel>
            </Step>
            <Step>
              <StepLabel>Results</StepLabel>
            </Step>
          </Stepper>
          <Box sx={{ mt: 2 }}>
            {!isSaving && activeStep === 0 &&
              <ClearingProperties run={run} setRun={setRun} type={type} setType={setType} types={types} autoBid={autoBid} setAutoBid={setAutoBid} />}
            {!isSaving && activeStep === 1 &&
              <ClearingOffers run={run} waterDeliveryPeriodId={waterDeliveryPeriodId} onProgressChange={handleProgressChange} type={type} types={types}
                autoBid={autoBid && type === trialId} />}
            {!isSaving && activeStep === 2 &&
              <ClearingAllocations allocations={allocations} setAllocations={setAllocations} catchment={catchment}
                onProgressChange={handleProgressChange} />}
            {isSaving && <WizardLoading />}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <Button color="inherit" disabled={activeStep === 0 || isInProgress || isSaving} onClick={handleBack}>Back</Button>
          <Button onClick={handleNext} disabled={!type || isInProgress || isSaving}>{activeStep === finalStep ? "Save" : "Next"}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};