import React, { useState } from "react";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, IconButton, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { formatCurrency, formatDate, formatVolume } from "../../../../common/utils/format";
import { chain } from "../../../../common/utils/math";
import { roundEndDate, roundStartDate } from "../../../../common/utils/date";

export const Offers = ({ isFinal, offers, onOfferLineSave, onOfferLineDelete, onOfferDuplicate, maxVolume, waterDeliveryPeriod }) => {
  const [ open, setOpen ] = useState(false);
  const [ target, setTarget ] = useState(null);
  const [ price, setPrice ] = useState(1);
  const [ volume, setVolume ] = useState(1);
  const [ priceError, setPriceError ] = useState(false);
  const [ volumeError, setVolumeError ] = useState(false);
  const [ isDuplicated, setIsDuplicated ] = useState(false);
  const theme = useTheme();

  const offer = offers && offers.length > 0 ? offers[0] : null;
  const lines = offer && offer.offerLines ? offer.offerLines : [];
  const totalVolume = lines.reduce((p, c) => p.add(c.volume), chain(0)).done();

  const handleAdd = () => {
    setTarget(null);
    setPrice(1);
    setPriceError(false);
    setVolume(1);
    setVolumeError(false);
    setOpen(true);
  };

  const handleEdit = line => {
    if (isFinal) {
      return;
    }

    setTarget(line);
    setPrice(line.price);
    setPriceError(false);
    setVolume(line.volume);
    setVolumeError(false);
    setOpen(true);
  };

  const handleCancel = () => setOpen(false);

  const handleSave = () => {
    onOfferLineSave({ ...target, offer: offer ? { id: offer.id } : null, price, volume });
    setOpen(false);
  };

  const handleDelete = () => {
    onOfferLineDelete({ ...target, offer: { id: offer.id } });
    setOpen(false);
  };

  const handlePriceChange = e => {
    const p = parseFloat(e.target.value);
    const ok = !isNaN(p) && p > 0 && p < 500;
    setPrice(p);
    setPriceError(ok ? false : "Price must be greater than $0 and less than $500.");
  };

  const handleVolumeChange = e => {
    const v = parseFloat(e.target.value);
    const ok = !isNaN(v) && v > 0;
    setVolume(v);
    setVolumeError(ok ? false : "Volume must be greater than 0.");
  };

  const handleDuplicate = e => {
    if (!isDuplicated) {
      e.preventDefault();
      onOfferDuplicate();
      setIsDuplicated(true);
    }
  };

  return (
    <>
      {lines.length === 0 && <Paper sx={{ p: 2 }} className="offers-summary">
        <Typography variant="body2">
          No offers have been made yet. Click the + icon in the bottom right to get started
          {!isDuplicated && !isFinal && <> or click <Link href="" onClick={handleDuplicate}>here</Link> to duplicate the offer from the last period</>}.
        </Typography>
      </Paper>}
      {lines.length > 0 && <>
        {totalVolume > maxVolume && <Alert severity="warning">Total offers are {formatVolume(totalVolume)} but your maximum offtake for the period is {formatVolume(maxVolume)}.</Alert>}
        <TableContainer component={Paper} sx={{ mt: 1 }} className="offers-summary">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Step</TableCell>
                <TableCell>Volume</TableCell>
                <TableCell>Cumulative Volume</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lines && lines.map((x, i) => (
                <TableRow className="offers-row" key={x.id}>
                  <TableCell>{i+1}</TableCell>
                  <TableCell>{formatVolume(x.volume || 0)}</TableCell>
                  <TableCell>{formatVolume(lines.reduce((p, y, j) => p.add(j <= i ? y.volume : 0), chain(0)).done())}</TableCell>
                  <TableCell>{formatCurrency(x.price)}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(x)} disabled={isFinal} size="small">
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>}
      <Fab color="primary" sx={{ bottom: theme.spacing(3), position: "fixed", right: theme.spacing(3) }}
        onClick={handleAdd} disabled={isFinal || lines.length >= 4} className="add-offer">
        <AddIcon />
      </Fab>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{target ? "Edit" : "Add"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Specify your offer for {formatDate(roundStartDate(waterDeliveryPeriod.start))} - {formatDate(roundEndDate(waterDeliveryPeriod.end))}.
          </DialogContentText>
          <TextField margin="dense" id="volume" label="Volume" type="number" fullWidth variant="standard"
            value={volume} onChange={handleVolumeChange} error={!!volumeError} helperText={volumeError} />
          <TextField margin="dense" id="price" label="Price" type="number" fullWidth variant="standard"
            value={price} onChange={handlePriceChange} error={!!priceError} helperText={priceError} />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
          {target ? <Button onClick={handleDelete} color="error">Delete</Button> : <div />}
          <Box>
            <Button onClick={handleCancel}>Cancel</Button>
            {!target && <Button onClick={handleSave} disabled={!!volumeError || !!priceError}>Add</Button>}
            {target && <Button onClick={handleSave} disabled={!!volumeError || !!priceError}>Edit</Button>}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
