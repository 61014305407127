import React from "react";
import { usePreloadedQuery } from "react-relay";
import { Link as LinkReact } from "react-router-dom";
import { Breadcrumbs, Grid, Link, Paper, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { GridNode } from "../../../../common/components/gridNode";

export const Profile = ({ profileQueryDef, profileQueryRef }) => {
  const profileQuery = usePreloadedQuery(profileQueryDef, profileQueryRef);
  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Overview</Typography>
        </Link>
        <Link underline="hover" color="inherit">
          <Typography variant="body2">Profile</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Profile</Typography>
      <Grid container sx={{ mt: 3 }}>
        <Grid container item xs={12} component={Paper} sx={{ p: 2, pt: 1 }} rowSpacing={1}>
          <GridNode name="Name" isSparse value={profileQuery.me.name} />
          <GridNode name="Account" isSparse isComplex value={profileQuery.me.userIdentities.map(x => <Typography key={x.upn} variant="body1">{x.upn} ({x.provider})</Typography>)} />
          <GridNode name="Roles" isSparse isComplex value={profileQuery.me.userRoles
            .filter(x => x.role.code === "WaterUsers" && x.role.level === "W")
            .map(x => {
              for (const catchment of profileQuery.catchments) {
                for (const waterUser of catchment.waterUsers) {
                  if (waterUser.id === x.target) {
                    return waterUser.name;
                  }
                }
              }

              return null;
            })
            .filter(x => !!x)
            .map(x => <Typography key={x} variant="body1">{x}</Typography>)} />
        </Grid>
      </Grid>
    </>
  );
};