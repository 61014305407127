import { useCallback, useMemo } from "react";
import { useTheme } from "@mui/material";

export const useConvert = () => {
  const theme = useTheme();

  return useCallback(rem => {
    const input = parseFloat(rem);
    const coef = theme.typography.fontSize / 14;
    const htmlFontSize = theme.typography.htmlFontSize;
    return input / coef * htmlFontSize;
  }, [ theme ]);
};

export const useDefaultConfig = ({ staticPlot }) => ({
  displaylogo: false,
  modeBarButtonsToRemove: [ "lasso2d", "toImage" ],
  responsive: true,
  scrollZoom: true,
  staticPlot
});

export const useIsLightTheme = () => {
  const theme = useTheme();
  return theme.palette.mode === "light";
};

export const useDefaultLayout = () => {
  const theme = useTheme();
  const convert = useConvert();

  return useMemo(() => ({
    autosize: true,
    font: {
      color: theme.palette.text.primary,
      family: theme.typography.body1.fontFamily,
      size: convert(theme.typography.body1.fontSize)
    },
    height: 400,
    legend: {
      font: {
        size: convert(theme.typography.body2.fontSize)
      },
      orientation: "h",
      traceorder: "reversed",
      xanchor: "center",
      x: 0.5,
      yanchor: "bottom",
      y: 1
    },
    margin: {
      t: 5,
      l: 65,
      r: 15
    },
    modebar: {
      activecolor: theme.palette.text.primary,
      bgcolor: "transparent",
      color: theme.palette.grey[400]
    },
    paper_bgcolor: "transparent",
    plot_bgcolor: "transparent",
    showlegend: true
  }), [ convert, theme ]);
};

export const useDefaultLabelStyle = () => {
  const convert = useConvert();
  const isLightTheme = useIsLightTheme();
  const theme = useTheme();

  return useMemo(() => ({
    bgcolor: isLightTheme ? 'white' : 'black',
    bordercolor: isLightTheme ? 'white' : 'black',
    font: {
      color: theme.palette.text.primary,
      family: theme.typography.body1.fontFamily,
      size: convert(theme.typography.body2.fontSize)
    }
  }), [ convert, isLightTheme, theme ]);
};