import React, { Suspense, useEffect, useMemo, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Grid, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { useLazyLoadQuery, usePreloadedQuery } from "react-relay";
import moment from "moment";
import "moment-timezone";
import { formatDate, formatVolume } from "../../../../../../common/utils/format";

const LookBack = 10;

const ForecastRawDataQuery = graphql`
  query ForecastAccuracyResultsForecastRawDataQuery($syncId: UUID!) {
    extractionSourceSyncs(where: { id: { eq: $syncId } }) {
      id
      data
    }
  }
`;

const ForecastAccuracyResultDownload = ({ id, docRef, onComplete }) => {
  const results = useLazyLoadQuery(ForecastRawDataQuery, { syncId: id }, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (results) {
      if (results.extractionSourceSyncs.length > 0 && results.extractionSourceSyncs[0].data) {
        const blob = new Blob([ results.extractionSourceSyncs[0].data ], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", `Forecast ${docRef.replace('/', '_')}`);
        a.click();
      }

      onComplete();
    }
  }, [ id, onComplete, docRef, results ]);

  return (<></>);
};

export const ForecastAccuracyResults = ({ forecastAccuracyResultsQueryDef, forecastAccuracyResultsQueryRef }) => {
  const results = usePreloadedQuery(forecastAccuracyResultsQueryDef, forecastAccuracyResultsQueryRef);
  const theme = useTheme();
  const [ target, setTarget ] = useState(null);
  const [ download, setDownload ] = useState(false);

  const forecast = useMemo(() => {
    const records = results.extractionSourceInputHistories || [];
    const actual = results.extractionSourceInputs || [];

    const g = records.reduce((acc, curr) => {
      const date = curr.date;
      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(curr);
      return acc;
    }, {});

    const o = Object.keys(g)
      .map(x => {
        const d = moment.parseZone(x);

        const forecasts = [];
        for (const y of g[x]) {
          const yd = moment.parseZone(y.forecastSync.sync);
          const offset = d.diff(yd, "days");
          if (offset < 10) {
            forecasts.push({
              date: x,
              forecast: y.forecastGross,
              analysis: y.forecastSync.analysis,
              sync: y.forecastSync.sync,
              sId: y.forecastSync.id,
              ref: y.forecastSync.reference,
              offset
            });
          }
        }

        return { date: x, forecasts };
      });

    return o.map(x => {
      const a = actual.find(y => y.date === x.date);
      return {
        date: formatDate(x.date),
        forecasts: [...Array(LookBack).keys()].map(y => {
          const m = x.forecasts.find(z => z.offset === (LookBack - y - 1));
          return { id: LookBack - y, forecast: m?.forecast, data: m };
        }),
        actual: a?.actualGross
      };
    });
  }, [ results ]);

  const handleDownload = () => {
    const rows = forecast
      .map(x => ({
        "Date": x.date,
        ...x.forecasts.reduce((p, c) => {
          p[`N-${c.id}`] = c.forecast;
          return p;
        }, {}),
        "Actual": x.actual
      }))
      .reduce((p, c, i) => {
        if (i === 0) {
          p.push(Object.keys(c).join(","));
        }

        p.push(Object.values(c).join(","));
        return p;
      }, []);

    const a = document.createElement("a");
    const blob = new Blob([ rows.join("\n") ], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.setAttribute("download", "Forecast Accuracy");
    a.click();
  };

  const handleClick = (e, m) => {
    e.preventDefault();
    setTarget(m);
  };

  const handleDownloadRef = (e) => {
    e.preventDefault();
    setDownload(true);
  };

  return (
    <>
      <Grid item xs={12} sx={{ mt: 2, mb: 10 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                {[...Array(LookBack).keys()].map(x => (
                  <TableCell key={x}>N-{LookBack - x} (k.m<sup>3</sup>)</TableCell>
                ))}
                <TableCell>Actual (k.m<sup>3</sup>)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {forecast.map(x => (
                <TableRow key={x.date}>
                  <TableCell>{x.date}</TableCell>
                  {x.forecasts.map(y => (
                    <TableCell key={y.id}>
                      {y.forecast && <Link href="" color="inherit" underline="hover" onClick={e => handleClick(e, y.data)}>{formatVolume(y.forecast, true)}</Link>}
                    </TableCell>
                  ))}
                  <TableCell>{x.actual && formatVolume(x.actual, true)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Dialog open={!!target} onClose={() => setTarget(null)}>
        <DialogTitle>Forecast Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Forecast sync details.
          </DialogContentText>
          {target && <>
            <Typography sx={{ mt: 1 }} variant="body1">{formatDate(target.date)}</Typography>
            <Typography variant="caption">Target date</Typography>
            {target.analysis && <>
              <Typography sx={{ mt: 1 }} variant="body1">{formatDate(target.analysis)}</Typography>
              <Typography variant="caption">Analysis date</Typography>
            </>}
            <Typography sx={{ mt: 1 }} variant="body1">{formatDate(target.sync)}</Typography>
            <Typography variant="caption">Sync date</Typography>
            {target.ref && <>
              <Typography sx={{ mt: 1 }} variant="body1">
                <Link href="" color="inherit" underline="hover" onClick={handleDownloadRef}>{target.ref}</Link>
                {download && <Suspense fallback={<></>}>
                  <ForecastAccuracyResultDownload id={target.sId} docRef={target.ref} onComplete={() => setDownload(false)} />
                </Suspense>}
              </Typography>
              <Typography variant="caption">Reference</Typography>
            </>}
          </>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTarget(null)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Fab color="primary" sx={{ bottom: theme.spacing(3), position: "fixed", right: theme.spacing(3) }} onClick={handleDownload}>
        <DownloadIcon />
      </Fab>
    </>
  );
};