/**
 * @generated SignedSource<<f8d7da29d38f57e7f7fba5420a05645b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type configurationConfigurationQuery$variables = {|
  catchmentId: any,
|};
export type configurationConfigurationQuery$data = {|
  +catchments: ?$ReadOnlyArray<?{|
    +minimumBidPrice: any,
    +name: ?string,
    +nowOffset: ?any,
    +nowOverride: ?any,
  |}>,
|};
export type configurationConfigurationQuery = {|
  response: configurationConfigurationQuery$data,
  variables: configurationConfigurationQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "catchmentId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "catchmentId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minimumBidPrice",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nowOffset",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nowOverride",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "configurationConfigurationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Catchment",
        "kind": "LinkedField",
        "name": "catchments",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "configurationConfigurationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Catchment",
        "kind": "LinkedField",
        "name": "catchments",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4d27aae65334f215fda945d9fc41fa0b",
    "id": null,
    "metadata": {},
    "name": "configurationConfigurationQuery",
    "operationKind": "query",
    "text": "query configurationConfigurationQuery(\n  $catchmentId: UUID!\n) {\n  catchments(where: {id: {eq: $catchmentId}}) {\n    name\n    minimumBidPrice\n    nowOffset\n    nowOverride\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "9b3749954c98a499c73b2507d03a77d0";

module.exports = ((node/*: any*/)/*: Query<
  configurationConfigurationQuery$variables,
  configurationConfigurationQuery$data,
>*/);
