/**
 * @generated SignedSource<<8d907cc1e6030bca3a867f344f56dafd>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type extractionSourcesExtractionSourcesQuery$variables = {|
  catchmentId: any,
|};
export type extractionSourcesExtractionSourcesQuery$data = {|
  +extractionSources: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
  |}>,
|};
export type extractionSourcesExtractionSourcesQuery = {|
  response: extractionSourcesExtractionSourcesQuery$data,
  variables: extractionSourcesExtractionSourcesQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "catchmentId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order",
        "value": {
          "name": "ASC"
        }
      },
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "eq",
                    "variableName": "catchmentId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "catchment"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "ExtractionSource",
    "kind": "LinkedField",
    "name": "extractionSources",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "extractionSourcesExtractionSourcesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "extractionSourcesExtractionSourcesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1f5a75242f20f6d9e7093f15f77918fd",
    "id": null,
    "metadata": {},
    "name": "extractionSourcesExtractionSourcesQuery",
    "operationKind": "query",
    "text": "query extractionSourcesExtractionSourcesQuery(\n  $catchmentId: UUID!\n) {\n  extractionSources(where: {catchment: {id: {eq: $catchmentId}}}, order: {name: ASC}) {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "83f7675c83f6c19779264f829d50476f";

module.exports = ((node/*: any*/)/*: Query<
  extractionSourcesExtractionSourcesQuery$variables,
  extractionSourcesExtractionSourcesQuery$data,
>*/);
