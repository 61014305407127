import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { usePreloadedQuery } from "react-relay";
import { formatDate } from "../../../../common/utils/format";
import { getStatsItems } from "../../../utils/stats";
import { LinkCell } from "../../../../common/components/linkCell";
import { roundStartDate, roundEndDate } from "../../../../common/utils/date";

export const ClearingsResults = ({ clearingsResultsQueryDef, clearingsResultsQueryRef }) => {
  const clearingsResultsQuery = usePreloadedQuery(clearingsResultsQueryDef, clearingsResultsQueryRef);
  const results = (clearingsResultsQuery.waterDeliveryPeriods || []).reduce((p, wdp) => {
    (clearingsResultsQuery.waterUsers || []).forEach(wu => {
      wu.waterUserExtractionPoints.forEach(wuep => {
        const a = wuep.allocations.find(a => a.exchangeClearing.waterDeliveryPeriod.id === wdp.id);
        const o = wuep.offers.find(o => o.waterDeliveryPeriod.id === wdp.id);
        p.push({
          waterDeliveryPeriod: wdp,
          waterUser: wu,
          waterUserExtractionPoint: wuep,
          extractionPoint: wuep.extractionPoint,
          stats: getStatsItems(a, o, wdp, wuep,
            wuep.extractionPoint.extractionSource,
            clearingsResultsQuery.extractionSources.find(e => e.id === wuep.extractionPoint.extractionSource.id)
              .extractionSourceInputs,
            a && a.exchangeClearing.exchangeClearingType.isDelivered)
        });
      });
    });
    return p;
  }, []);

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Delivery Period</TableCell>
              <TableCell>Water User</TableCell>
              <TableCell>Extraction Point</TableCell>
              {results && results.length > 0 && <>
                <TableCell>{results[0].stats[0].label}</TableCell>
                <TableCell>{results[0].stats[1].label}</TableCell>
                <TableCell>{results[0].stats[2].label}</TableCell>
              </>}
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map(x => {
              const link = `/delivery-periods/${x.waterDeliveryPeriod.id}/water-users/${x.waterUserExtractionPoint.id}`;
              return (
                <TableRow key={`${x.waterDeliveryPeriod.id}_${x.waterUserExtractionPoint.id}`}>
                  <LinkCell link={link}>{`${formatDate(roundStartDate(x.waterDeliveryPeriod.start))} - ${formatDate(roundEndDate(x.waterDeliveryPeriod.end))}`}</LinkCell>
                  <LinkCell link={link}>{x.waterUser.name}</LinkCell>
                  <LinkCell link={link}>{x.extractionPoint.name}</LinkCell>
                  {x.stats.map(y => <LinkCell key={`${x.waterDeliveryPeriod.id}_${x.waterUserExtractionPoint.id}_${y.label}`}
                    link={link}>{y.num == null ? "-" : y.num}{y.num != null && <> k.m<sup>3</sup></>}</LinkCell>)}
                </TableRow>);
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};