import React, { useContext, useState } from "react";
import { Link as LinkReact } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { Breadcrumbs, Fab, Grid, Link, Paper, Typography, useTheme } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useMutation, usePreloadedQuery } from "react-relay";
import { Subheader } from "../../../../common/components/subheader";
import { AccountsTable } from "../../accounts/components/AccountsTable";
import { GridNode } from "../../../../common/components/gridNode";
import { formatCurrency } from "../../../../common/utils/format";
import { TransactionsTable } from "./TransactionsTable";
import { CreateTransactionDialog } from "./CreateTransactionDialog";
import { NotificationContext } from "../../../../common/contexts/notification";
import { ProfileContext } from "../../../../common/contexts/profile";
import AddIcon from "@mui/icons-material/Add";

const CreateTransactionMutation = graphql`
  mutation AccountCreateTransactionMutation($input: CreateTransactionInput!, $accountId: UUID!) {
    createTransaction(input: $input) {
      transaction {
        id
        transactionLineItems(where: { account: { id: { eq: $accountId } } }) {
          id
          value
          transaction {
            id
            date
            memo
            referenceTypeName
            referenceId
            transactionMetadata {
              name
              value
            }
            transactionLineItems {
              id
              value
              account {
                id
                name
                accountType {
                  name
                  code
                }
              }
            }
          }
        }
      }
    }
  }
`;

const updateTransactionLineItems = (store, cId, aId, toAdd) => {
  const linkName = "accounts";
  const linkFilter = { where: { and: [{ catchment: { id: { eq: cId } } }, { or: [ { id: { eq: aId } }, { parentId: { eq: aId } } ] } ] }, order: { name: "ASC" } };

  let accounts = store.getRoot().getLinkedRecords(linkName, linkFilter);
  for (const a of accounts) {
    if (a.getValue("id") === aId) {
      const txnLinkName = "transactionLineItems";
      const existingLinkFilter = { where: { account: { id: { eq: aId } } }, order: { transaction: { date: "DESC" } } };
      const existingLineItems = a.getLinkedRecords(txnLinkName, existingLinkFilter);
      const newLinkFilter = { where: { account: { id: { eq: aId } } } };
      const newLineItems = toAdd.getLinkedRecords(txnLinkName, newLinkFilter);
      a.setLinkedRecords([ ...newLineItems, ...existingLineItems, ], txnLinkName, existingLinkFilter);
    }
  }
  store.getRoot().setLinkedRecords(accounts, linkName, linkFilter);
};

export const Account = ({ accountId, accountQueryDef, accountQueryRef }) => {
  const theme = useTheme();
  const [ open, setOpen ] = useState(false);
  const { setSuccess, setError } = useContext(NotificationContext);
  const { catchmentId } = useContext(ProfileContext);
  
  const accountQuery = usePreloadedQuery(accountQueryDef, accountQueryRef);
  const [ commitTransactionLine ] = useMutation(CreateTransactionMutation);

  const account = accountQuery.accounts.length > 0
    ? accountQuery.accounts.find(x => x.id === accountId)
    : null;
  const children = account
    ? accountQuery.accounts.filter(x => x.id !== accountId)
    : [];
  const path = accountQuery.accountHierarchy.length > 0
    ? accountQuery.accountHierarchy[0].fullPath.split("\\")
      .map(x => {
        var components = x.split('|');
        return { id: components[0], name: components[1] };
      })
      .filter(x => x.id !== accountId)
    : [];
  const lineItems = account
    ? account.transactionLineItems
    : [];
  const allAccounts = accountQuery.allAccounts;

  const handleSave = txn => {
    setOpen(false);
    commitTransactionLine({
      variables: {
        input: {
          catchmentId,
          date: txn.date.format(),
          memo: txn.memo,
          transactionLineItems: txn.lines.map(x => ({
            value: x.value,
            accountId: x.accountId
          }))
        },
        accountId
      },
      onCompleted: () => setSuccess("Transaction recorded successfully"),
      onError: e => setError("Transaction failed to save", e),
      updater: store => {
        const transaction = store.getRootField("createTransaction")
          .getLinkedRecord("transaction");
        updateTransactionLineItems(store, catchmentId, accountId, transaction);
      }
    });
  };

  return (
    <>
      {account &&
        <>
          <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
            <Link component={LinkReact} underline="hover" color="inherit" to="/">
              <Typography variant="body2">Administration</Typography>
            </Link>
            <Link component={LinkReact} underline="hover" color="inherit" to="/admin/accounts">
              <Typography variant="body2">Accounts</Typography>
            </Link>
            {path.map(x => (
              <Link key={x.id} component={LinkReact} underline="hover" color="inherit" to={`/admin/accounts/${x.id}`}>
                <Typography variant="body2">{x.name}</Typography>
              </Link>))}
            <Link underline="hover" color="text.primary">
              <Typography variant="body2">{account.name}</Typography>
            </Link>
          </Breadcrumbs>
          <Typography variant="h1">{account.name}</Typography>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Subheader name="Overview" />
          </Grid>
          <Grid container item xs={12} component={Paper} sx={{ p: 2, pt: 1 }} rowSpacing={1}>
            <GridNode name="Type" value={account.accountType.name} />
            <GridNode name="Balance" value={formatCurrency(account.accountBalance ? account.accountBalance.value : 0)} />
          </Grid>
          {children.length > 0 &&
            <>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Subheader name="Child Accounts" />
              </Grid>
              <Grid container className="accounts" sx={{ mt: 2 }}>
                <AccountsTable accounts={children} />
              </Grid>
            </>}
          {lineItems.length > 0 &&
            <>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Subheader name="Transactions" />
              </Grid>
              <Grid container className="transactions" sx={{ mt: 2 }}>
                <TransactionsTable account={account} transactionLineItems={lineItems} />
              </Grid>
            </>}
          {allAccounts &&
            <>
              <Fab color="primary" sx={{ bottom: theme.spacing(3), position: "fixed", right: theme.spacing(3) }} onClick={() => setOpen(true)}>
                <AddIcon />
              </Fab>
              <CreateTransactionDialog open={open} onCancel={() => setOpen(false)} onSave={handleSave}
                accounts={allAccounts} currentAccount={account} />
            </>}
      </>}
    </>
  );
};