import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { dateEditFormatString, formatCurrency } from "../../../../common/utils/format";
import { chain } from "../../../../common/utils/math";
import { ProfileContext } from "../../../../common/contexts/profile";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const mapAccounts = (accounts, parentId, level) =>
  accounts
    .filter(a => a.parentId === parentId)
    .reduce((p, c) => {
      const children = mapAccounts(accounts, c.id, level + 1);
      return [ ...p, { label: ". ".repeat(level) + c.name, value: c.id }, ...children ];
    }, []);

export const CreateTransactionDialog = ({ accounts, currentAccount, open, onCancel, onSave }) => {
  const [ date, setDate ] = useState(null);
  const [ dateError, setDateError ] = useState(false);
  const [ memo, setMemo ] = useState("");
  const [ lines, setLines ] = useState([]);
  const [ selectedLine, setSelectedLine ] = useState([]);
  const [ index, setIndex ] = useState(0);
  const { now } = useContext(ProfileContext);

  const balance = useMemo(() => {
    const bal = lines.reduce((p, c) => {
      if (c.debit) {
        p = p.add(c.debit);
      }
      if (c.credit) {
        p = p.subtract(c.credit);
      }
      return p;
    }, chain(0));
    return bal.done();
  }, [ lines ]);

  const isValid = useMemo(() =>
    lines.length > 0
      && !dateError
      && balance === 0
      && lines.every(l => !!l.accountId && ((!l.debit && !!l.credit) || (!!l.debit && !l.credit))),
    [ balance, dateError, lines ]);

  useEffect(() => {
    if (open) {
      setDate(now({ ignoreTz: true }));
      setDateError(false);
      setMemo("");
      setLines(currentAccount ? [ { id: 0, accountId: currentAccount.id, debit: 0, credit: 0 } ] : []);
      setSelectedLine([]);
      setIndex(currentAccount ? 1 : 0);
    }
  }, [ currentAccount, now, open ]);

  const columns = useMemo(() => {
    const acctList = mapAccounts(accounts, null, 0);
    return [
      { field: "accountId", headerName: "Account", editable: true, flex: 0.5, type: "singleSelect", valueOptions: acctList, valueFormatter: ({ value }) => acctList.find(a => a.value === value)?.label || "" },
      { field: "debit", headerName: "Debit", editable: true, flex: 0.2, type: "number" },
      { field: "credit", headerName: "Credit", editable: true, flex: 0.2, type: "number" },
      { field: "accounts", type: "actions", flex: 0.1,getActions: params => [ <GridActionsCellItem icon={<DeleteIcon />} onClick={e => handleDelete(e, params.id)} label="Delete" /> ] }
    ];
  }, [ accounts ]);

  const handleAdd = () => {
    const line = {
      id: index,
      accountId: "",
      debit: 0,
      credit: 0
    };

    setIndex(i => i + 1);
    setLines(l => [ ...l, line ]);
  };

  const handleEdit = newRow => {
    setLines(lines.map(x => x.id === newRow.id ? newRow : x));
    return newRow;
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setLines((prevLines) => prevLines.filter(x => x.id !== id));
  };

  const handleSave = () => {
    const newLines = lines.map(l => {
      const account = accounts.find(x => x.id === l.accountId);

      let value = 0;
      for (const t of ['Debit', 'Credit']) {
        const current = l[t.toLowerCase()];
        if (current > 0) {
          value = t === account.accountType.name ? current : -current;
          break;
        }
      }

      return {
        value,
        accountId: l.accountId,
      };
    });
    onSave({ date, memo, lines: newLines });
  };

  const handleDateChange = newDate => {
    setDateError(newDate ? false : "Date is required");
    setDate(newDate);
  };

  return (
    <Dialog open={open} onClose={onCancel} fullWidth={true} maxWidth="md">
      <DialogTitle>Create</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Configure your transaction.
        </DialogContentText>
        <Typography variant="subtitle1" sx={{ mt: 2 }}>Details</Typography>
        <DatePicker
          renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" error={!!dateError} helperText={dateError} {...props} />}
          label="Date"
          value={date}
          onChange={handleDateChange}
          inputFormat={dateEditFormatString}
          mask="__/__/__" />
        <TextField fullWidth variant="standard" margin="dense" label="Memo" value={memo} onChange={e => setMemo(e.target.value)} />
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }} startIcon={<AddIcon />}>
          <Typography variant="subtitle1">Lines</Typography>
          <Button onClick={handleAdd}>+ Add Line</Button>
        </Box>
        <DataGrid autoHeight sx={{ mt: 1, ".MuiDataGrid-footerContainer": { display: "none" } }}
          columns={columns} rows={lines} selectionModel={selectedLine} onSelectionModelChange={setSelectedLine}
          experimentalFeatures={{ newEditingApi: true }} processRowUpdate={handleEdit} />
        <Typography variant="body1" sx={{ float: "right", mt: 2 }}>Balance: {formatCurrency(Math.abs(balance))} {balance >= 0 ? "DR" : "CR"}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={handleSave} disabled={!isValid}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};