import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { LinkCell } from "../../../../common/components/linkCell";

export const ExtractionPoints = ({ waterUserExtractionPoints }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Extraction Point</TableCell>
          <TableCell>Loss Factor</TableCell>
          <TableCell>Max Offtake</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {waterUserExtractionPoints.map(x => (
          <TableRow key={x.id}>
            <LinkCell link={`/admin/water-user-extraction-points/${x.id}`}>{x.extractionPoint.name}</LinkCell>
            <TableCell>{x.lossFactor}</TableCell>
            <TableCell>{x.maxOfftake}{x.maxOfftake !== null && (<> m<sup>3</sup>/s</>)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>);