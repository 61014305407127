import React, { Suspense, useContext, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { ActualDeliveriesHeader } from "./components/ActualDeliveriesHeader";
import { CenteredLoading, ResultsLoading } from "../../../../../common/components/loading";
import { ProfileContext } from "../../../../../common/contexts/profile";
import { ActualDeliveriesResults } from "./components/ActualDeliveriesResults";

const ActualDeliveriesQuery = graphql`
  query actualDeliveriesActualDeliveriesQuery($catchmentId: UUID!) {
    waterDeliveryPeriods(where: { and: [ { catchment: { id: { eq: $catchmentId } } }, { isSeasonStart: { eq: true } } ] }, order: { start: ASC }) {
      id
      start
      end
    }
  }
`;

const ActualDeliveriesResultsQuery = graphql`
  query actualDeliveriesActualDeliveriesResultsQuery($waterDeliveryPeriodId: UUID!) {
    actualDeliveriesForWaterUserExtractionPoints(waterDeliveryPeriodId: $waterDeliveryPeriodId) {
      index
      start
      daysSinceStart
      duration
      waterUserName
      extractionPointName
      total
    }
    actualDeliveriesForExtractionSources(waterDeliveryPeriodId: $waterDeliveryPeriodId) {
      index
      start
      daysSinceStart
      duration
      extractionSourceName
      total
    }
  }
`;

export const ActualDeliveriesReportPage = () => {
  const [ actualDeliveriesQueryRef, loadActualDeliveriesQuery ] = useQueryLoader(ActualDeliveriesQuery);
  const [ actualDeliveriesResultsQueryRef, loadActualDeliveriesResultsQuery ] = useQueryLoader(ActualDeliveriesResultsQuery);
  const { catchmentId } = useContext(ProfileContext);

  useEffect(() => loadActualDeliveriesQuery({ catchmentId },
    { fetchPolicy: "network-only" }),
    [ catchmentId, loadActualDeliveriesQuery ]);

  const handleExecute = ({ wdpId }) =>
    loadActualDeliveriesResultsQuery(
      { waterDeliveryPeriodId: wdpId },
      { fetchPolicy: "network-only" });

  return (
    <Suspense fallback={<CenteredLoading />}>
      {actualDeliveriesQueryRef && <>
        <ActualDeliveriesHeader actualDeliveriesQueryDef={ActualDeliveriesQuery} actualDeliveriesQueryRef={actualDeliveriesQueryRef}
          onExecute={handleExecute} />
        <Suspense fallback={<ResultsLoading />}>
          {actualDeliveriesResultsQueryRef &&
            <ActualDeliveriesResults actualDeliveriesResultsQueryDef={ActualDeliveriesResultsQuery} actualDeliveriesResultsQueryRef={actualDeliveriesResultsQueryRef} />}
        </Suspense>
      </>}
    </Suspense>
  );
};