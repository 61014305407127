/**
 * @generated SignedSource<<8d77b8ce25ce72be783125b068c4e1bc>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type extractionSourceExtractionSourceMeasurementsQuery$variables = {|
  catchmentId: any,
  end: any,
  extractionSourceId: any,
  start: any,
|};
export type extractionSourceExtractionSourceMeasurementsQuery$data = {|
  +extractionSourceMeasurements: ?$ReadOnlyArray<?{|
    +date: any,
    +flowRate: any,
    +id: ?string,
    +importOperation: ?{|
      +id: ?string,
    |},
    +measurementQualityType: ?{|
      +id: ?string,
    |},
    +modifiedByUser: ?{|
      +id: ?string,
    |},
    +total: ?any,
  |}>,
|};
export type extractionSourceExtractionSourceMeasurementsQuery = {|
  response: extractionSourceExtractionSourceMeasurementsQuery$data,
  variables: extractionSourceExtractionSourceMeasurementsQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "catchmentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "extractionSourceId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order",
        "value": {
          "date": "ASC"
        }
      },
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "eq",
                            "variableName": "extractionSourceId"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "extractionSource"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.0"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "eq",
                            "variableName": "catchmentId"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "catchment"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.1"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "lte",
                        "variableName": "end"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "date"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.2"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "gte",
                        "variableName": "start"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "date"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.3"
              }
            ],
            "kind": "ListValue",
            "name": "and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "ExtractionSourceMeasurement",
    "kind": "LinkedField",
    "name": "extractionSourceMeasurements",
    "plural": true,
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "flowRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "modifiedByUser",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ImportOperation",
        "kind": "LinkedField",
        "name": "importOperation",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MeasurementQualityType",
        "kind": "LinkedField",
        "name": "measurementQualityType",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "extractionSourceExtractionSourceMeasurementsQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "extractionSourceExtractionSourceMeasurementsQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "08bd2b7e29a921c4d8a099beebf063b4",
    "id": null,
    "metadata": {},
    "name": "extractionSourceExtractionSourceMeasurementsQuery",
    "operationKind": "query",
    "text": "query extractionSourceExtractionSourceMeasurementsQuery(\n  $catchmentId: UUID!\n  $extractionSourceId: UUID!\n  $start: DateTime!\n  $end: DateTime!\n) {\n  extractionSourceMeasurements(where: {and: [{extractionSource: {id: {eq: $extractionSourceId}}}, {catchment: {id: {eq: $catchmentId}}}, {date: {lte: $end}}, {date: {gte: $start}}]}, order: {date: ASC}) {\n    id\n    date\n    flowRate\n    total\n    modifiedByUser {\n      id\n    }\n    importOperation {\n      id\n    }\n    measurementQualityType {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "c892dd04eda6b5f4b0f5de9895a636af";

module.exports = ((node/*: any*/)/*: Query<
  extractionSourceExtractionSourceMeasurementsQuery$variables,
  extractionSourceExtractionSourceMeasurementsQuery$data,
>*/);
