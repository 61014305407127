import React, { Suspense, useContext, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { WaterUsers } from "./components/WaterUsers";
import { CenteredLoading } from "../../../common/components/loading";
import { ProfileContext } from "../../../common/contexts/profile";

const WaterUsersQuery = graphql`
  query waterUsersWaterUsersQuery($catchmentId: UUID!) {
    waterUsers(where: { catchment: { id: { eq: $catchmentId } } }, order: { publicId: ASC }) {
      id
      name
      publicId
    }
  }
`;

export const WaterUsersPage = () => {
  const [ waterUsersQueryRef, loadWaterUsersQuery ] = useQueryLoader(WaterUsersQuery);
  const { catchmentId } = useContext(ProfileContext);
  useEffect(() => loadWaterUsersQuery({ catchmentId },
    { fetchPolicy: "network-only" }),
    [ catchmentId, loadWaterUsersQuery ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {waterUsersQueryRef &&
        <WaterUsers waterUsersQueryDef={WaterUsersQuery} waterUsersQueryRef={waterUsersQueryRef} />}
    </Suspense>
  );
};