import React, { Suspense, useContext, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { ExtractionSources } from "./components/ExtractionSources";
import { CenteredLoading } from "../../../common/components/loading";
import { ProfileContext } from "../../../common/contexts/profile";

const ExtractionSourcesQuery = graphql`
  query extractionSourcesExtractionSourcesQuery($catchmentId: UUID!) {
    extractionSources(where: { catchment: { id: { eq: $catchmentId } } }, order: { name: ASC }) {
      id
      name
    }
  }
`;

export const ExtractionSourcesPage = () => {
  const [ extractionSourcesQueryRef, loadExtractionSourcesQuery ] = useQueryLoader(ExtractionSourcesQuery);
  const { catchmentId } = useContext(ProfileContext);
  useEffect(() => loadExtractionSourcesQuery({ catchmentId },
    { fetchPolicy: "network-only" }),
    [ catchmentId, loadExtractionSourcesQuery ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {extractionSourcesQueryRef &&
        <ExtractionSources extractionSourcesQueryDef={ExtractionSourcesQuery} extractionSourcesQueryRef={extractionSourcesQueryRef} />}
    </Suspense>
  );
};