import moment from "moment";
import "moment-timezone";
import { b, chain, math } from "../../common/utils/math";

// The initial implementation used regex; however, due to some limitations in
// Mobile Safari, a more explicit approach is implemented.
// See: https://stackoverflow.com/questions/70860187/safari-regular-expression-error-invalid-group-specifier-name-innerhtml-replace
const encodeSpecialChars = config => {
  // For each "'" in the *name* of a constant, variable, or output, encode it
  // to a "_". Keep a record of the changes.
  const changes = {};
  const newConfig = Object.keys(config)
    .reduce((p, c) => {
      p[c] = Object.keys(config[c])
        .reduce((p1, c1) => {
          let encodedC1 = c1;
          if (c1.indexOf("'") >= 0) {
            encodedC1 = c1.replaceAll("'", "_");

            // Store a record of the changes in the format "nameofcollection['nameofvariable']"
            changes[`${c}['${c1}']`] = `${c}['${encodedC1}']`;
          }
          p1[encodedC1] = config[c][c1];
          return p1;
        }, {});
      return p;
    }, {});

  // Apply the name updates in the constant, variable, or output values.
  Object.keys(changes)
    .forEach(c =>
      Object.keys(newConfig).forEach(col =>
        Object.keys(newConfig[col])
          .filter(prop => typeof newConfig[col][prop] === 'string')
          .forEach(prop => {
            newConfig[col][prop] = newConfig[col][prop].replaceAll(c, changes[c]);
          })
      )
    );

  return newConfig;
};

export const calculateNet = (config, gross, net) => {
  if (net) {
    return net;
  }

  if (!gross) {
    return 0;
  }

  if (config && typeof config === 'string') {
    try {
      config = JSON.parse(config);
    } catch {
      config = null;
    }
  }

  if (!config) {
    return gross;
  }

  config = encodeSpecialChars(config);

  try {
    const scope = {
      constants: Object.keys(config.constants).reduce((p, c) => {
        p[c] = b(config.constants[c]);
        return p;
      }, {}),
      variables: {},
      outputs: {},
      gross: b(gross)
    };

    for (const variable of Object.keys(config.variables)) {
      scope.variables[variable] = math.evaluate(config.variables[variable], scope);
    }

    for (const output of Object.keys(config.outputs)) {
      scope.outputs[output] = math.evaluate(config.outputs[output], scope);
    }

    return scope.outputs.Net ? scope.outputs.Net : gross;
  } catch (e) {
    console.error(`Net calculation failed: ${e}`);
    return gross;
  }
};

const groupMeasurementsByOfftake = measurements =>
  (measurements || [])
    .reduce((p, c) => {
      if (!p[c.waterUserExtractionPointOfftake.id]) {
        p[c.waterUserExtractionPointOfftake.id] = [];
      }

      p[c.waterUserExtractionPointOfftake.id].push(c);
      return p;
    }, {});

export const calculateDelivery = measurements => {
  let total = chain(0);

  const measurementsByOfftake = groupMeasurementsByOfftake(measurements);
  for (const offtakeId of Object.keys(measurementsByOfftake)) {
    const offtakeMeasurements = measurementsByOfftake[offtakeId];
    for (let i = 1; i < offtakeMeasurements.length; i++) {
      const prev = offtakeMeasurements[i - 1];
      const curr = offtakeMeasurements[i];

      let result = null;
      if (prev.total && curr.total) {
        result = chain(curr.total)
          .subtract(prev.total)
          .multiply(0.001)
          .done();
      } else {
        const pDate = moment.parseZone(prev.date);
        const cDate = moment.parseZone(curr.date);
        const diff = cDate.diff(pDate, "seconds");
        result = chain(diff)
          .multiply(prev.flowRate)
          .multiply(0.001)
          .multiply(0.001)
          .done();
      }

      total = total.add(result);
    }
  }

  return total.done();
};

export const calculateDeliveriesByDay = measurements => {
  const deliveries = {};

  const measurementsByOfftake = groupMeasurementsByOfftake(measurements);
  for (const offtakeId of Object.keys(measurementsByOfftake)) {
    const offtakeMeasurements = measurementsByOfftake[offtakeId];
    for (let i = 1; i < offtakeMeasurements.length; i++) {
      const prev = offtakeMeasurements[i - 1];
      const curr = offtakeMeasurements[i];

      const d = moment.parseZone(prev.date).format("YYYY-MM-DD 12:00:00");
      if (!deliveries[d]) {
        deliveries[d] = chain(0);
      }

      let result = null;
      if (prev.total && curr.total) {
        result = chain(curr.total)
          .subtract(prev.total)
          .multiply(0.001)
          .done();
      } else {
        const pDate = moment.parseZone(prev.date);
        const cDate = moment.parseZone(curr.date);
        const diff = cDate.diff(pDate, "seconds");
        result = chain(diff)
          .multiply(prev.flowRate)
          .multiply(0.001)
          .multiply(0.001)
          .done();
      }

      deliveries[d] = deliveries[d].add(result);
    }
  }

  return deliveries;
};

export const summarizeDeliveries = (waterDeliveryPeriod, allocation, measurements, isFinal) => {
  let totalQuantity = allocation.totalQuantity;
  let totalCharge = allocation.totalNetCharge;
  let isOver = false;
  let isUnder = false;
  if (isFinal
    && measurements
    && measurements.some(x => moment.parseZone(x.date).isSame(moment.parseZone(waterDeliveryPeriod.start)))
    && measurements.some(x => moment.parseZone(x.date).isSame(moment.parseZone(waterDeliveryPeriod.end)))) {
    const delivery = calculateDelivery(measurements);
    if (delivery < totalQuantity) {
      totalQuantity = delivery;

      const underCharge = chain(allocation.totalQuantity).subtract(delivery).multiply(allocation.spotPrice).done();
      totalCharge = chain(totalCharge).subtract(underCharge).done();

      isUnder = true;
    } else if (delivery > totalQuantity) {
      isOver = true;
    }
  }

  return { totalQuantity, totalCharge, isOver, isUnder };
};