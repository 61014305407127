import React from "react";
import { Link as LinkReact } from "react-router-dom";
import { Breadcrumbs, Grid, Link, Paper, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Subheader } from "../../../common/components/subheader";

const reportsByCategory = [
  {
    name: "Deliveries",
    reports: [
      {
        name: "Actual Deliveries by Water User",
        description: "Total actual deliveries by water user for the selected season.",
        path: "/admin/reports/actual-deliveries"
      }
    ]
  },
  {
    name: "Forecasts",
    reports: [
      {
        name: "Forecast Accuracy",
        description: "Extraction source forecasts vs actuals over time.",
        path: "/admin/reports/forecast-accuracy"
      }
    ]
  }
];

export const ReportsPage = () => {
  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Administration</Typography>
        </Link>
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">Reports</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Reports</Typography>
      {reportsByCategory.map(category => (
        <React.Fragment key={category.name}>
          <Subheader name={category.name} />
          <Grid container>
            {category.reports.map(report => (
              <Grid item component={Paper} xs={12} sm={6} md={6} lg={4} sx={{ p: 2 }} key={report.name}>
                <Link component={LinkReact} to={report.path} color="inherit" underline="hover">
                  <Typography variant="h3" sx={{ mb: 1 }}>{report.name}</Typography>
                </Link>
                <Typography variant="body2">{report.description}</Typography>
              </Grid>
            ))}
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
};