/**
 * @generated SignedSource<<d4a86e34d92fdec22d6a3f63571d9727>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateTransactionInput = {|
  catchmentId: any,
  date: any,
  memo?: ?string,
  transactionLineItems?: ?$ReadOnlyArray<?TransactionLineItemCreateInput>,
|};
export type TransactionLineItemCreateInput = {|
  accountId: any,
  value: any,
|};
export type AccountCreateTransactionMutation$variables = {|
  accountId: any,
  input: CreateTransactionInput,
|};
export type AccountCreateTransactionMutation$data = {|
  +createTransaction: {|
    +transaction: ?{|
      +id: ?string,
      +transactionLineItems: ?$ReadOnlyArray<?{|
        +id: ?string,
        +transaction: ?{|
          +date: any,
          +id: ?string,
          +memo: ?string,
          +referenceId: ?any,
          +referenceTypeName: ?string,
          +transactionLineItems: ?$ReadOnlyArray<?{|
            +account: ?{|
              +accountType: ?{|
                +code: ?string,
                +name: ?string,
              |},
              +id: ?string,
              +name: ?string,
            |},
            +id: ?string,
            +value: any,
          |}>,
          +transactionMetadata: ?$ReadOnlyArray<?{|
            +name: ?string,
            +value: ?string,
          |}>,
        |},
        +value: any,
      |}>,
    |},
  |},
|};
export type AccountCreateTransactionMutation = {|
  response: AccountCreateTransactionMutation$data,
  variables: AccountCreateTransactionMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "fields": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "eq",
                "variableName": "accountId"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "account"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "memo",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referenceTypeName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referenceId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountCreateTransactionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateTransactionPayload",
        "kind": "LinkedField",
        "name": "createTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Transaction",
            "kind": "LinkedField",
            "name": "transaction",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "TransactionLineItem",
                "kind": "LinkedField",
                "name": "transactionLineItems",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Transaction",
                    "kind": "LinkedField",
                    "name": "transaction",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TransactionMetadata",
                        "kind": "LinkedField",
                        "name": "transactionMetadata",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TransactionLineItem",
                        "kind": "LinkedField",
                        "name": "transactionLineItems",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Account",
                            "kind": "LinkedField",
                            "name": "account",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AccountType",
                                "kind": "LinkedField",
                                "name": "accountType",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v11/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AccountCreateTransactionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateTransactionPayload",
        "kind": "LinkedField",
        "name": "createTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Transaction",
            "kind": "LinkedField",
            "name": "transaction",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "TransactionLineItem",
                "kind": "LinkedField",
                "name": "transactionLineItems",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Transaction",
                    "kind": "LinkedField",
                    "name": "transaction",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TransactionMetadata",
                        "kind": "LinkedField",
                        "name": "transactionMetadata",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          (v5/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TransactionLineItem",
                        "kind": "LinkedField",
                        "name": "transactionLineItems",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Account",
                            "kind": "LinkedField",
                            "name": "account",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AccountType",
                                "kind": "LinkedField",
                                "name": "accountType",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bdbb96c899d298440afe44656d0d100a",
    "id": null,
    "metadata": {},
    "name": "AccountCreateTransactionMutation",
    "operationKind": "mutation",
    "text": "mutation AccountCreateTransactionMutation(\n  $input: CreateTransactionInput!\n  $accountId: UUID!\n) {\n  createTransaction(input: $input) {\n    transaction {\n      id\n      transactionLineItems(where: {account: {id: {eq: $accountId}}}) {\n        id\n        value\n        transaction {\n          id\n          date\n          memo\n          referenceTypeName\n          referenceId\n          transactionMetadata {\n            name\n            value\n            id\n          }\n          transactionLineItems {\n            id\n            value\n            account {\n              id\n              name\n              accountType {\n                name\n                code\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "e4869e5edf9d360254b3d0a24495ff3d";

module.exports = ((node/*: any*/)/*: Mutation<
  AccountCreateTransactionMutation$variables,
  AccountCreateTransactionMutation$data,
>*/);
