import React, { useEffect, useState } from "react";
import { Link as LinkReact } from "react-router-dom";
import { usePreloadedQuery } from "react-relay";
import { Breadcrumbs, Button, FormControl, Grid, InputLabel, Link, MenuItem, Paper, Select, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Subheader } from "../../../../../../common/components/subheader";
import { formatDate } from "../../../../../../common/utils/format";
import { roundEndDate, roundStartDate } from "../../../../../../common/utils/date";

export const ActualDeliveriesHeader = ({ actualDeliveriesQueryDef, actualDeliveriesQueryRef, onExecute }) => {
  const actualDeliveries = usePreloadedQuery(actualDeliveriesQueryDef, actualDeliveriesQueryRef);
  const [ wdpId, setWdpId ] = useState("");
  const [ isFirstLoad, setIsFirstLoad ] = useState(true);

  useEffect(() => {
    if (!wdpId && actualDeliveries && actualDeliveries.waterDeliveryPeriods && actualDeliveries.waterDeliveryPeriods.length > 0) {
      setWdpId(actualDeliveries.waterDeliveryPeriods[0].id);
    }
  }, [ actualDeliveries, wdpId ]);

  useEffect(() => {
    if (isFirstLoad && wdpId) {
      onExecute({ wdpId });
      setIsFirstLoad(false);
    }
  }, [ isFirstLoad, onExecute, wdpId ]);

  const handleWdpChange = e => setWdpId(e.target.value);
  const handleQuery = () => onExecute({ wdpId });

  const wdps = actualDeliveries.waterDeliveryPeriods || [];

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Administration</Typography>
        </Link>
        <Link component={LinkReact} underline="hover" color="inherit" to="/admin/reports">
          <Typography variant="body2">Reports</Typography>
        </Link>
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">Actual Deliveries by Water User</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Actual Deliveries by Water User</Typography>
      <Subheader name="Search" />
      <Grid container component={Paper}>
        <Grid container item sx={{ p: 2 }} spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="wdp-select-label" variant="standard">Water Season Start</InputLabel>
              <Select
                labelId="wdp-select-label"
                id="wdp-select"
                value={wdpId}
                label="Water Season Start"
                variant="standard"
                onChange={handleWdpChange}
              >
                {wdps.map(wdp => <MenuItem key={wdp.id} value={wdp.id}>{formatDate(roundStartDate(wdp.start))} - {formatDate(roundEndDate(wdp.end))}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleQuery} disabled={!wdpId}>Search</Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};