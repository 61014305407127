import React from "react";
import { usePreloadedQuery } from "react-relay";
import { Link as LinkReact, Navigate } from "react-router-dom";
import { Breadcrumbs, Grid, Link, Paper, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import moment from "moment";
import { Subheader } from "../../../../common/components/subheader";
import { GridNode } from "../../../../common/components/gridNode";

export const ExtractionSourceHeader = ({ extractionSourceQueryDef, extractionSourceQueryRef, onExecute }) => {
  const extractionSourceQuery = usePreloadedQuery(extractionSourceQueryDef, extractionSourceQueryRef);

  const es = extractionSourceQuery.extractionSources.length > 0
    ? extractionSourceQuery.extractionSources[0]
    : null;
  
  if (!es) {
    return (<Navigate to="/admin/sources" />);
  }

  let config = null;
  try {
    config = JSON.parse(es.config);
  } catch {}

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Administration</Typography>
        </Link>
        <Link component={LinkReact} underline="hover" color="inherit" to="/admin/sources">
          <Typography variant="body2">Extraction Sources</Typography>
        </Link>
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">{es.name}</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Extraction Source</Typography>
      <Subheader name="Overview" />
      <Grid container item xs={12} component={Paper} sx={{ p: 2, pt: 1 }} rowSpacing={1}>
        <GridNode name="Name" value={es.name} />
        <GridNode name="Offset (hours)" value={moment.duration(es.inputOffset, 'seconds').asHours()} />
        {es.forecastSource && <GridNode name="Forecast Source" value={es.forecastSource} />}
        {es.forecastName && <GridNode name="Forecast Name" value={es.forecastName} />}
        {es.actualSource && <GridNode name="Actual Source" value={es.actualSource} />}
        {es.actualName && <GridNode name="Actual Name" value={es.actualName} />}
      </Grid>
      {config && <>
        <Subheader name="Configuration" />
        <Grid container item xs={12} component={Paper} sx={{ p: 2, pt: 1 }} rowSpacing={1}>
          {Object.keys(config.constants).map(key =>
            <GridNode key={key} name={key} value={config.constants[key]} />
          )}
        </Grid>
      </>}
    </>
  );
};