/**
 * @generated SignedSource<<927c53890fb91dfd4a14814cb94f3a08>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type ForecastAccuracyResultsForecastRawDataQuery$variables = {|
  syncId: any,
|};
export type ForecastAccuracyResultsForecastRawDataQuery$data = {|
  +extractionSourceSyncs: ?$ReadOnlyArray<?{|
    +data: ?string,
    +id: ?string,
  |}>,
|};
export type ForecastAccuracyResultsForecastRawDataQuery = {|
  response: ForecastAccuracyResultsForecastRawDataQuery$data,
  variables: ForecastAccuracyResultsForecastRawDataQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "syncId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "eq",
                "variableName": "syncId"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "ExtractionSourceSync",
    "kind": "LinkedField",
    "name": "extractionSourceSyncs",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ForecastAccuracyResultsForecastRawDataQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ForecastAccuracyResultsForecastRawDataQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b3fc410c1b98d0c404e7acaebf4f7e1c",
    "id": null,
    "metadata": {},
    "name": "ForecastAccuracyResultsForecastRawDataQuery",
    "operationKind": "query",
    "text": "query ForecastAccuracyResultsForecastRawDataQuery(\n  $syncId: UUID!\n) {\n  extractionSourceSyncs(where: {id: {eq: $syncId}}) {\n    id\n    data\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "3b534392f0b89f7ad5f15940b28cd2e3";

module.exports = ((node/*: any*/)/*: Query<
  ForecastAccuracyResultsForecastRawDataQuery$variables,
  ForecastAccuracyResultsForecastRawDataQuery$data,
>*/);
