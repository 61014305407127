import React, { useContext, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation, usePreloadedQuery } from "react-relay";
import { Link as LinkReact } from "react-router-dom";
import { Breadcrumbs, Button, Grid, Link, Paper, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import moment from "moment";
import "moment-timezone";
import { Subheader } from "../../../../common/components/subheader";
import { dateEditFormatString } from "../../../../common/utils/format";
import { NotificationContext } from "../../../../common/contexts/notification";
import { ProfileContext } from "../../../../common/contexts/profile";

const UpdateCatchmentMutation = graphql`
  mutation ConfigurationUpdateCatchmentMutation($input: UpdateCatchmentInput!) {
    updateCatchment(input: $input) {
      catchment {
        id
        minimumBidPrice
        nowOffset
        nowOverride
      }
    }
  }
`;

export const Configuration = ({ configurationQueryDef, configurationQueryRef }) => {
  const configurationQuery = usePreloadedQuery(configurationQueryDef, configurationQueryRef);
  const { catchmentId, nowOffsetDisabled, nowOverrideDisabled, setNowOffset, setNowOverride, timeZone } = useContext(ProfileContext);
  const [ commitUpdateCatchmentMutation ] = useMutation(UpdateCatchmentMutation);
  const [ nowOverrideEdit, setNowOverrideEdit ] = useState(
    configurationQuery.catchments[0].nowOverride
      ? moment.parseZone(configurationQuery.catchments[0].nowOverride)
      : null);
  const [ nowOffsetEdit, setNowOffsetEdit ] = useState(configurationQuery.catchments[0].nowOffset);
  const [ nowOffsetError, setNowOffsetError ] = useState(null);
  const [ minimumBidPrice, setMinimumBidPrice ] = useState(configurationQuery.catchments[0].minimumBidPrice);
  const [ minimumBidPriceError, setMinimumBidPriceError ] = useState(null);
  const { setSuccess, setError } = useContext(NotificationContext);

  const onCompleted = () => setSuccess("Catchment updated successfully");
  const onError = e => setError("Catchment failed to update", e);

  const handleMinimumBidPriceChange = e => {
    const p = parseFloat(e.target.value);
    const ok = !isNaN(p) && p >= 0;
    setMinimumBidPrice(p);
    setMinimumBidPriceError(ok ? false : 'Minimum bid price must be greater than or equal to 0.');
  };

  const handleNowOffsetChange = e => {
    const d = moment.duration(e.target.value);
    setNowOffsetEdit(e.target.value);
    setNowOffsetError(!e.target.value || e.target.value === d.toISOString() ? false : 'Now offset must be a valid ISO-8601 duration.');
  };

  const saveCatchment = () => {
    const now = nowOverrideEdit ? nowOverrideEdit.clone().tz(timeZone, true).startOf("day") : null;
    commitUpdateCatchmentMutation({
      variables: {
        input: {
          catchmentId,
          minimumBidPrice,
          nowOffset: nowOffsetEdit ? nowOffsetEdit : null,
          nowOverride: now ? now.format() : null
        }
      },
      onCompleted: () => {
        setNowOffset(nowOffsetEdit ? nowOffsetEdit : null);
        setNowOverride(now ? now.format() : null);
        onCompleted();
      },
      onError
    });
  };

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Administration</Typography>
        </Link>
        <Link underline="hover" color="inherit">
          <Typography variant="body2">Configuration</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Configuration</Typography>
      <Subheader name="Catchment" />
      <Grid container component={Paper} sx={{ mt: 3, p: 2, pt: 1 }} rowSpacing={2}>
        <Grid item xs={12} lg={6} xl={4} sx={{ pr: 2 }}>
          <DatePicker
            renderInput={(props) => <TextField fullWidth variant="standard" {...props} />}
            label="Now override"
            value={nowOverrideEdit}
            onChange={setNowOverrideEdit}
            inputFormat={dateEditFormatString}
            mask="__/__/__"
            disabled={nowOverrideDisabled} />
        </Grid>
        <Grid item xs={12} lg={6} xl={4} sx={{ pr: 2 }}>
          <TextField id="nowOffset" label="Now offset" fullWidth variant="standard" disabled={nowOffsetDisabled}
            value={nowOffsetEdit} onChange={handleNowOffsetChange} error={!!nowOffsetError} helperText={nowOffsetError} />
        </Grid>
        <Grid item xs={12} lg={6} xl={4} sx={{ pr: 2 }}>
          <TextField id="minimumBidPrice" label="Minimum bid price" type="number" fullWidth variant="standard"
            value={minimumBidPrice} onChange={handleMinimumBidPriceChange} error={!!minimumBidPriceError} helperText={minimumBidPriceError} />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button variant="contained" onClick={saveCatchment} disabled={minimumBidPriceError || nowOffsetError}>Save</Button>
        </Grid>
      </Grid>
    </>
  );
};