import React from "react";
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { dateTimeEditFormatString } from "../../../../common/utils/format";

export const ClearingProperties = ({ type, setType, types, run, setRun, autoBid, setAutoBid }) => {
  const handleTypeChange = evt => setType(evt.target.value);
  const handleAutoBidChange = evt => setAutoBid(evt.target.checked);
  const trialId = types.find(type => type.name === 'Trial')?.id;
  return (
    <>
      {setRun && <DateTimePicker
        renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" {...props} />}
        label="Run"
        value={run}
        onChange={setRun}
        inputFormat={dateTimeEditFormatString}
      />}
      {setType && <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="type-select-label" variant="standard">Clearing Type</InputLabel>
        <Select
          labelId="type-select-label"
          id="type-select"
          value={type}
          label="Clearing Type"
          variant="standard"
          onChange={handleTypeChange}
        >
          {types.map(type => <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>)}
        </Select>
      </FormControl>}
      {setAutoBid && type === trialId && <FormControlLabel sx={{ mt: 2 }} control={
        <Checkbox
          checked={autoBid}
          onChange={handleAutoBidChange}
          inputProps={{ 'aria-label': 'controlled' }}/>}
        label="Auto-bid for inactive users" />}
    </>
  );
};