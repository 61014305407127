/**
 * @generated SignedSource<<45b234dc7c3d5a3f8e87358f442a989f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type waterUserExtractionPointWaterUserExtractionPointHeaderQuery$variables = {|
  catchmentId: any,
  wuepId: any,
|};
export type waterUserExtractionPointWaterUserExtractionPointHeaderQuery$data = {|
  +extractionPointOfftakeTypes: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
  |}>,
  +measurementQualityTypes: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
  |}>,
  +waterUserExtractionPoints: ?$ReadOnlyArray<?{|
    +extractionPoint: ?{|
      +id: ?string,
      +name: ?string,
    |},
    +id: ?string,
    +lossFactor: ?any,
    +maxOfftake: ?any,
    +waterUser: ?{|
      +id: ?string,
      +name: ?string,
    |},
    +waterUserExtractionPointOfftakes: ?$ReadOnlyArray<?{|
      +id: ?string,
      +name: ?string,
    |}>,
  |}>,
|};
export type waterUserExtractionPointWaterUserExtractionPointHeaderQuery = {|
  response: waterUserExtractionPointWaterUserExtractionPointHeaderQuery$data,
  variables: waterUserExtractionPointWaterUserExtractionPointHeaderQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "catchmentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "wuepId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "catchmentId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "catchment"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v4 = {
  "kind": "Literal",
  "name": "order",
  "value": {
    "name": "ASC"
  }
},
v5 = {
  "fields": (v1/*: any*/),
  "kind": "ObjectValue",
  "name": "where"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "eq",
                        "variableName": "wuepId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.0"
              },
              {
                "fields": (v1/*: any*/),
                "kind": "ObjectValue",
                "name": "and.1"
              }
            ],
            "kind": "ListValue",
            "name": "and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "WaterUserExtractionPoint",
    "kind": "LinkedField",
    "name": "waterUserExtractionPoints",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lossFactor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maxOfftake",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ExtractionPoint",
        "kind": "LinkedField",
        "name": "extractionPoint",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WaterUser",
        "kind": "LinkedField",
        "name": "waterUser",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          (v4/*: any*/)
        ],
        "concreteType": "WaterUserExtractionPointOfftake",
        "kind": "LinkedField",
        "name": "waterUserExtractionPointOfftakes",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": "waterUserExtractionPointOfftakes(order:{\"name\":\"ASC\"})"
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      (v5/*: any*/)
    ],
    "concreteType": "ExtractionPointOfftakeType",
    "kind": "LinkedField",
    "name": "extractionPointOfftakeTypes",
    "plural": true,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "concreteType": "MeasurementQualityType",
    "kind": "LinkedField",
    "name": "measurementQualityTypes",
    "plural": true,
    "selections": (v3/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "waterUserExtractionPointWaterUserExtractionPointHeaderQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "waterUserExtractionPointWaterUserExtractionPointHeaderQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "2cfef30e64929adaad571d03bba7d484",
    "id": null,
    "metadata": {},
    "name": "waterUserExtractionPointWaterUserExtractionPointHeaderQuery",
    "operationKind": "query",
    "text": "query waterUserExtractionPointWaterUserExtractionPointHeaderQuery(\n  $catchmentId: UUID!\n  $wuepId: UUID!\n) {\n  waterUserExtractionPoints(where: {and: [{id: {eq: $wuepId}}, {catchment: {id: {eq: $catchmentId}}}]}) {\n    id\n    lossFactor\n    maxOfftake\n    extractionPoint {\n      id\n      name\n    }\n    waterUser {\n      id\n      name\n    }\n    waterUserExtractionPointOfftakes(order: {name: ASC}) {\n      id\n      name\n    }\n  }\n  extractionPointOfftakeTypes(where: {catchment: {id: {eq: $catchmentId}}}) {\n    id\n    name\n  }\n  measurementQualityTypes(where: {catchment: {id: {eq: $catchmentId}}}, order: {name: ASC}) {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "e99e41f2aa3aa55a712236bf07b4d04c";

module.exports = ((node/*: any*/)/*: Query<
  waterUserExtractionPointWaterUserExtractionPointHeaderQuery$variables,
  waterUserExtractionPointWaterUserExtractionPointHeaderQuery$data,
>*/);
