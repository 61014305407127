import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import "./Status.scss";

const states = [
  { type: null, label: "Open", description: "This water delivery period is open for offers. No trial clearing has been performed yet so the best way to understand prices is by looking at previous clearings." },
  { type: 'Trial' , label: "Trial", description: "This water delivery period is open for offers. A trial clearing has been performed and is the best way to understand the likely result when the allocations are finalized." },
  { type: 'Final' , label: "Final", description: "This water delivery period has been finalized. No further offers can be made. Deliveries will commence based on the final results." },
  { type: 'Delivery', label: "Delivery", description: "This water delivery period has been finalized and deliveries are underway. Once complete, the total charge will be updated if needed based on actual measurements." },
  { type: 'Reconciliation #1' , label: "Reconciliation", description: "This water delivery period has been finalized and the clearing has been adjusted to reflect the actual water deliveries made." },
  { type: 'Reconciliation #2' , label: "Reconciliation" }
];

export const Status = ({ type }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [ showDialog, setShowDialog ] = useState(false);

  const state = states.find(s => s.type === type);
  const labels = states
    .reduce((p, c) => {
      if (!p.includes(c.label)) {
        p.push(c.label);
      }
      return p;
    }, []);
  const labelIndex = state ? labels.indexOf(state.label) : -1;

  const getColor = i => {
    if (i < labelIndex) {
      return theme.palette.primary.light;
    }

    if (i === labelIndex) {
      return theme.palette.primary.main;
    }

    return theme.palette.background.default;
  };

  const getBorder = (i, closing) => {
    if (i >= labelIndex) {
      const borderWidth = '2px';
      return {
        borderColor: i === labelIndex ? theme.palette.primary.main : theme.palette.primary.light,
        borderStyle: 'solid',
        borderWidth: `${borderWidth} ${i === labels.length - 1 && closing ? borderWidth : '0'} ${borderWidth} 0`
      };
    }

    return {};
  };

  return (
    <>
      {state &&
        <div className="clearing-status">
          {labels.map((l, i) =>
            <React.Fragment key={l}>
              <div key={l} className="status-item" style={{
                backgroundColor: getColor(i),
                ...getBorder(i, true),
                width: isMobile && i !== labelIndex ? '15px' : 'auto'
              }}>
                <Typography className="content" variant="body1" component="div" sx={{
                  color: theme.palette.getContrastText(getColor(i)),
                  m: 1
                }}>{l}</Typography>
                {(!isMobile || i === labelIndex) && <HelpIcon sx={{
                    color: theme.palette.getContrastText(getColor(i)),
                    cursor: 'pointer',
                    height: '14px'
                  }} onClick={() => setShowDialog(l)} />}
              </div>
              {i < labels.length - 1 &&
                <svg className="status-arrow" viewBox="0 0 50 100"
                  preserveAspectRatio="none" style={i >= labelIndex ? getBorder(i + 1) : {}}>
                  <polygon points="0,100 50,50 0,0" style={{ fill: getColor(i) }} />
                  <polygon points="0,0 50,0 50,50" style={{ fill: getColor(i + 1) }} />
                  <polygon points="0,100 50,100 50,50" style={{ fill: getColor(i + 1) }} />
                  {i > labelIndex && <path d="M0,-2 L48,50 L0,102" style={{
                    fill: 'transparent',
                    stroke: theme.palette.primary.light,
                    strokeWidth: 5,
                    strokeLinecap: "round",
                    strokeLinejoin: "round"
                  }} />}
                </svg>}
            </React.Fragment>
          )}
        </div>}
      <Dialog open={!!showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>{showDialog}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" component="div">
            {(states.find(x => x.label === showDialog) || {}).description}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};