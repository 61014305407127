import React, { useState } from "react";
import { Grid, Paper, useTheme } from "@mui/material";
import Plot from "react-plotly.js";
import { useDefaultConfig, useDefaultLayout, useDefaultLabelStyle, useIsLightTheme, useConvert } from "../../../utils/charts";
import { ChartTitle } from "../../../components/chartTitle";

export const MeasurementsChart = ({ offtakes }) => {
  const [ dynamicPlot, setDynamicPlot ] = useState(false);
  const theme = useTheme();
  const isLightTheme = useIsLightTheme();
  const defaultConfig = useDefaultConfig({ staticPlot: !dynamicPlot });
  const defaultLayout = useDefaultLayout();
  const defaultLabelStyle = useDefaultLabelStyle();
  const convert = useConvert();

  const createFlowRate = (name, color) => ({
    x: [],
    y: [],
    name,
    type: "line",
    marker: {
      color
    },
    hovertemplate: "%{x}: <b>%{y:.2f}l/s</b><extra></extra>",
    hoverlabel: defaultLabelStyle
  });

  const createTotal = (name, color) => ({
    x: [],
    y: [],
    name,
    type: "line",
    marker: {
      color
    },
    hovertemplate: "%{x}: <b>%{y:.2f}k.m³</b><extra></extra>",
    hoverlabel: defaultLabelStyle,
    yaxis: "y2"
  });

  const colors = [
    theme.palette.primary.light,
    theme.palette.primary.dark,
    theme.palette.grey[400],
    theme.palette.grey[700]
  ];

  const data = [];
  for (const offtake of offtakes) {
    const measurements = offtake.waterUserExtractionPointOfftakeMeasurements;
    if (measurements && measurements.length > 0) {
      const flowRate = createFlowRate(`Flow rate (${offtake.name})`, colors[data.length]);
      const total = createTotal(`Total (${offtake.name})`, colors[data.length + 1]);
      for (const measurement of measurements) {
        flowRate.x.push(measurement.date);
        flowRate.y.push(measurement.flowRate);
        if (measurement.total !== null) {
          total.x.push(measurement.date);
          total.y.push(measurement.total / 1000);
        }
      }

      data.push(flowRate);
      if (total.x.length > 0) {
        data.push(total);
      }
    }
  }

  return (
    <Grid container item component={Paper} xs={12} sx={{ mt: 2 }}>
      <ChartTitle checked={dynamicPlot} onChange={setDynamicPlot} title="Water deliveries over time" />
      <Grid item xs={12} style={{ height: 400 }}>
        <Plot
          config={defaultConfig}
          data={data}
          layout={{
            ...defaultLayout,
            margin: {
              ...defaultLayout.margin,
              b: 115,
              r: 65
            },
            legend: {...defaultLayout.legend, traceorder: "normal"},
            xaxis: {
              gridcolor: isLightTheme ? undefined : theme.palette.grey[800],
              rangemode: "nonnegative",
              ticks: "outside",
              tickformat: "%d/%m %H:%M",
              title: {
                font: {
                  size: convert(theme.typography.body2.fontSize)
                },
                text: "date"
              }
            },
            yaxis: {
              gridcolor: isLightTheme ? undefined : theme.palette.grey[800],
              rangemode: "tozero",
              ticks: "outside",
              title: {
                font: {
                  size: convert(theme.typography.body2.fontSize)
                },
                text: "flow rate (l/s)"
              }
            },
            yaxis2: {
              gridcolor: isLightTheme ? undefined : theme.palette.grey[800],
              rangemode: "tozero",
              ticks: "outside",
              title: {
                font: {
                  size: convert(theme.typography.body2.fontSize)
                },
                text: "volume (k.m³)"
              },
              side: "right",
              overlaying: "y"
            }
          }}
          style={{ display: "block" }}
          useResizeHandler={true}
        />
      </Grid>
    </Grid>
  );
};