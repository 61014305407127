import React, { useState } from "react";
import { Breadcrumbs, Fab, Grid, Link, Paper, Typography, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link as LinkReact, Navigate } from "react-router-dom";
import { usePreloadedQuery } from "react-relay";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { formatDate, formatDateTime } from "../../../../common/utils/format";
import { Subheader } from "../../../../common/components/subheader";
import { GridNode } from "../../../../common/components/gridNode";
import { ClearingsTable } from "./ClearingsTable";
import { ClearingWizardDialog } from "./ClearingWizardDialog";
import { roundStartDate, roundEndDate } from "../../../../common/utils/date";

export const DeliveryPeriod = ({ deliveryPeriodQueryDef, deliveryPeriodQueryRef }) => {
  const deliveryPeriodQuery = usePreloadedQuery(deliveryPeriodQueryDef, deliveryPeriodQueryRef);
  const theme = useTheme();
  const [ showWizard, setShowWizard ] = useState(false);

  const wdp = deliveryPeriodQuery.waterDeliveryPeriods.length > 0
    ? deliveryPeriodQuery.waterDeliveryPeriods[0]
    : null;
  const title = wdp ? `${formatDate(roundStartDate(wdp.start))} - ${formatDate(roundEndDate(wdp.end))}` : null;
  
  const types = deliveryPeriodQuery.exchangeClearingTypes || [];
  const latest = (wdp?.exchangeClearings || [])
    .find(x => x.isLatest);
  const availableTypes = types.filter(x => !latest || x.order >= latest.exchangeClearingType.order);

  const handleAdd = () => setShowWizard(true);
  const handleSave = () => setShowWizard(false);

  if (!wdp) {
    return (<Navigate to="/admin/delivery-periods" />);
  }

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Administration</Typography>
        </Link>
        <Link component={LinkReact} underline="hover" color="inherit" to="/admin/delivery-periods">
          <Typography variant="body2">Delivery Periods</Typography>
        </Link>
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">{title}</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Delivery Period</Typography>
      <Grid container className="clearing" sx={{ mb: 10 }}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Subheader name="Overview" />
        </Grid>
        <Grid container item xs={12} component={Paper} sx={{ p: 2, pt: 1 }} rowSpacing={1}>
          <GridNode name="Start" value={formatDateTime(wdp.start)} />
          <GridNode name="End" value={formatDateTime(wdp.end)} />
          <GridNode name="Close" value={wdp.close ? formatDateTime(wdp.close) : ''} />
        </Grid>
        <Grid item xs={12}>
          <Subheader name="Clearings" />
        </Grid>
        <Grid item xs={12}>
          <ClearingsTable clearings={wdp.exchangeClearings} waterDeliveryPeriod={wdp} types={types} />
        </Grid>
      </Grid>
      <Fab color="primary" style={{ bottom: theme.spacing(3), position: "fixed", right: theme.spacing(3) }} onClick={handleAdd}>
        <AddIcon />
      </Fab>
      <ClearingWizardDialog open={showWizard} onCancel={handleSave} onSave={handleSave}
        waterDeliveryPeriodId={wdp.id} catchment={wdp.catchment} types={availableTypes} />
    </>
  );
};