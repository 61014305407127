import React from "react";
import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { formatDateShort, formatVolume } from "../../../../common/utils/format";
import { divide, multiply } from "../../../../common/utils/math";
import { roundStartDate, roundEndDate, parseDateWithZone } from "../../../../common/utils/date";
import { getApportionment, getTotalEsiForWdp } from "../../../utils/stats";

export const SourceInputsByPeriodDetail = ({ inputs, waterDeliveryPeriod, waterUserExtractionPoint }) => {
  if (!inputs || inputs.length === 0 || !waterUserExtractionPoint.extractionPoint.extractionSource) {
    return <></>;
  }

  const data = inputs
    .map(x => {
      const a = getApportionment(waterUserExtractionPoint);
      const i = getTotalEsiForWdp(
        waterUserExtractionPoint.extractionPoint.extractionSource,
        x.extractionSourceInputs,
        x.waterDeliveryPeriod,
        x.waterDeliveryPeriod.exchangeClearings.some(y => y.exchangeClearingType.isDelivered));
      if (i === null) {
        return null;
      }

      const t = divide(i, waterUserExtractionPoint.lossFactor);
      const u = multiply(t, a);
      return {
        label: `${formatDateShort(roundStartDate(x.waterDeliveryPeriod.start))} - ${formatDateShort(roundEndDate(x.waterDeliveryPeriod.end))}`,
        user: formatVolume(u),
        total: formatVolume(t),
        isFinal: x.waterDeliveryPeriod.exchangeClearings.some(y => y.exchangeClearingType.isFinal),
        wdpId: x.waterDeliveryPeriod.id,
        duration: parseDateWithZone(x.waterDeliveryPeriod.end).diff(parseDateWithZone(x.waterDeliveryPeriod.start), 'days')
      };
    })
    .filter(x => !!x);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            {data.map((x, i) => (<TableCell key={i}>{x.label} ({x.duration} days) {waterDeliveryPeriod.id === x.wdpId && <Chip size="small" label="selected" sx={{ fontWeight: "normal" }} />}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ width: { xs: "10%", sm: "10%", md: "15%", xl: "20%" } }}>User volume</TableCell>
            {data.map((x, i) => (<TableCell key={i}>{x.user}{x.isFinal ? <CheckIcon sx={{ fontSize: "1em", marginLeft: "4px", verticalAlign: "text-top" }} />: null}</TableCell>))}
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: { xs: "10%", sm: "10%", md: "15%", xl: "20%" } }}>Total volume</TableCell>
            {data.map((x, i) => (<TableCell key={i}>{x.total}{x.isFinal ? <CheckIcon sx={{ fontSize: "1em", marginLeft: "4px", verticalAlign: "text-top" }} />: null}</TableCell>))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};