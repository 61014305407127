/**
 * @generated SignedSource<<be1ad5c93c08e6d5ec90abb0897fe542>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type transactionTransactionResultsQuery$variables = {|
  accountId: any,
  catchmentId: any,
  end: any,
  start: any,
|};
export type transactionTransactionResultsQuery$data = {|
  +myAccounts: ?$ReadOnlyArray<?{|
    +accountType: ?{|
      +code: ?string,
      +name: ?string,
    |},
    +id: ?string,
    +transactionLineItems: ?$ReadOnlyArray<?{|
      +id: ?string,
      +transaction: ?{|
        +date: any,
        +id: ?string,
        +memo: ?string,
        +referenceId: ?any,
        +referenceTypeName: ?string,
        +transactionLineItems: ?$ReadOnlyArray<?{|
          +account: ?{|
            +id: ?string,
            +name: ?string,
          |},
          +id: ?string,
        |}>,
        +transactionMetadata: ?$ReadOnlyArray<?{|
          +name: ?string,
          +value: ?string,
        |}>,
      |},
      +value: any,
    |}>,
  |}>,
|};
export type transactionTransactionResultsQuery = {|
  response: transactionTransactionResultsQuery$data,
  variables: transactionTransactionResultsQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "catchmentId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start"
},
v4 = [
  {
    "fields": [
      {
        "items": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "eq",
                        "variableName": "catchmentId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "catchment"
              }
            ],
            "kind": "ObjectValue",
            "name": "and.0"
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "eq",
                    "variableName": "accountId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "and.1"
          }
        ],
        "kind": "ListValue",
        "name": "and"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "order",
    "value": {
      "transaction": {
        "date": "DESC"
      }
    }
  },
  {
    "fields": [
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "gte",
                        "variableName": "start"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "date"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.0"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "lte",
                        "variableName": "end"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "date"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.1"
              }
            ],
            "kind": "ListValue",
            "name": "and"
          }
        ],
        "kind": "ObjectValue",
        "name": "transaction"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "memo",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referenceTypeName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referenceId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "account": {
          "referenceTypeName": {
            "eq": "WaterDeliveryPeriods"
          }
        }
      }
    }
  ],
  "concreteType": "TransactionLineItem",
  "kind": "LinkedField",
  "name": "transactionLineItems",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": "transactionLineItems(where:{\"account\":{\"referenceTypeName\":{\"eq\":\"WaterDeliveryPeriods\"}}})"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "transactionTransactionResultsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "myAccounts",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountType",
            "kind": "LinkedField",
            "name": "accountType",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "TransactionLineItem",
            "kind": "LinkedField",
            "name": "transactionLineItems",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Transaction",
                "kind": "LinkedField",
                "name": "transaction",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionMetadata",
                    "kind": "LinkedField",
                    "name": "transactionMetadata",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "transactionTransactionResultsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "myAccounts",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountType",
            "kind": "LinkedField",
            "name": "accountType",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "TransactionLineItem",
            "kind": "LinkedField",
            "name": "transactionLineItems",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Transaction",
                "kind": "LinkedField",
                "name": "transaction",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionMetadata",
                    "kind": "LinkedField",
                    "name": "transactionMetadata",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v9/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "90060aa0586a0d21f124e2b85b35abbb",
    "id": null,
    "metadata": {},
    "name": "transactionTransactionResultsQuery",
    "operationKind": "query",
    "text": "query transactionTransactionResultsQuery(\n  $catchmentId: UUID!\n  $accountId: UUID!\n  $start: DateTime!\n  $end: DateTime!\n) {\n  myAccounts(where: {and: [{catchment: {id: {eq: $catchmentId}}}, {id: {eq: $accountId}}]}) {\n    id\n    accountType {\n      name\n      code\n      id\n    }\n    transactionLineItems(where: {transaction: {and: [{date: {gte: $start}}, {date: {lte: $end}}]}}, order: {transaction: {date: DESC}}) {\n      id\n      value\n      transaction {\n        id\n        date\n        memo\n        referenceTypeName\n        referenceId\n        transactionLineItems(where: {account: {referenceTypeName: {eq: \"WaterDeliveryPeriods\"}}}) {\n          id\n          account {\n            id\n            name\n          }\n        }\n        transactionMetadata {\n          name\n          value\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "f9de1adfc667839bc8b49c0c4eb77cd2";

module.exports = ((node/*: any*/)/*: Query<
  transactionTransactionResultsQuery$variables,
  transactionTransactionResultsQuery$data,
>*/);
