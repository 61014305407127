/**
 * @generated SignedSource<<aa4c720461f0c3601c81bc5b46088639>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type ExchangeClearingSubmissionState = "APPROVED" | "CANCELED" | "DRAFT" | "FAILED" | "PENDING" | "REJECTED" | "%future added value";
export type deliveryPeriodDeliveryPeriodQuery$variables = {|
  catchmentId: any,
  wdpId: any,
|};
export type deliveryPeriodDeliveryPeriodQuery$data = {|
  +exchangeClearingTypes: ?$ReadOnlyArray<?{|
    +id: ?string,
    +isDelivered: ?boolean,
    +name: ?string,
    +order: ?number,
  |}>,
  +waterDeliveryPeriods: ?$ReadOnlyArray<?{|
    +catchment: ?{|
      +id: ?string,
      +waterUsers: ?$ReadOnlyArray<?{|
        +id: ?string,
        +name: ?string,
        +publicId: number,
        +waterUserExtractionPoints: ?$ReadOnlyArray<?{|
          +extractionPoint: ?{|
            +id: ?string,
            +name: ?string,
          |},
          +id: ?string,
        |}>,
      |}>,
    |},
    +close: ?any,
    +end: any,
    +exchangeClearings: ?$ReadOnlyArray<?{|
      +allocations: ?$ReadOnlyArray<?{|
        +baseQuantity: any,
        +baseQuantityPreLoss: any,
        +changeQuantity: any,
        +clearingFee: any,
        +constraint: ?string,
        +effectiveGrossPrice: any,
        +effectiveNetPrice: any,
        +farmCongestionPrice: any,
        +farmSurcharge: ?string,
        +id: ?string,
        +quantityDisposedCreditPreLoss: any,
        +quantityObtainedCharge: any,
        +raceCongestionCharge: any,
        +raceCongestionCredit: any,
        +raceCongestionPrice: any,
        +spotPrice: any,
        +spotPricePreLoss: any,
        +totalGrossCharge: any,
        +totalNetCharge: any,
        +totalQuantity: any,
        +totalQuantityPreLoss: any,
        +waterUserExtractionPoint: ?{|
          +extractionPoint: ?{|
            +id: ?string,
            +name: ?string,
          |},
          +id: ?string,
          +waterUser: ?{|
            +id: ?string,
            +name: ?string,
            +publicId: number,
          |},
        |},
      |}>,
      +exchangeClearingType: ?{|
        +id: ?string,
        +isFinal: boolean,
        +name: ?string,
        +order: ?number,
      |},
      +id: ?string,
      +isLatest: boolean,
      +run: any,
      +submissionState: ?ExchangeClearingSubmissionState,
    |}>,
    +id: ?string,
    +start: any,
  |}>,
|};
export type deliveryPeriodDeliveryPeriodQuery = {|
  response: deliveryPeriodDeliveryPeriodQuery$data,
  variables: deliveryPeriodDeliveryPeriodQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "catchmentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "wdpId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ExtractionPoint",
  "kind": "LinkedField",
  "name": "extractionPoint",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "eq",
                        "variableName": "wdpId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.0"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "eq",
                            "variableName": "catchmentId"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "catchment"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.1"
              }
            ],
            "kind": "ListValue",
            "name": "and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "WaterDeliveryPeriod",
    "kind": "LinkedField",
    "name": "waterDeliveryPeriods",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "close",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Catchment",
        "kind": "LinkedField",
        "name": "catchment",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "WaterUser",
            "kind": "LinkedField",
            "name": "waterUsers",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WaterUserExtractionPoint",
                "kind": "LinkedField",
                "name": "waterUserExtractionPoints",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "order",
            "value": {
              "run": "DESC"
            }
          }
        ],
        "concreteType": "ExchangeClearing",
        "kind": "LinkedField",
        "name": "exchangeClearings",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLatest",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "run",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submissionState",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExchangeClearingType",
            "kind": "LinkedField",
            "name": "exchangeClearingType",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isFinal",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Allocation",
            "kind": "LinkedField",
            "name": "allocations",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "baseQuantityPreLoss",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "baseQuantity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalQuantityPreLoss",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalQuantity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "changeQuantity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "farmSurcharge",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "spotPricePreLoss",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "spotPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "farmCongestionPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "raceCongestionPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveGrossPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveNetPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantityDisposedCreditPreLoss",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantityObtainedCharge",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "raceCongestionCharge",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "raceCongestionCredit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalGrossCharge",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalNetCharge",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clearingFee",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "constraint",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WaterUserExtractionPoint",
                "kind": "LinkedField",
                "name": "waterUserExtractionPoint",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WaterUser",
                    "kind": "LinkedField",
                    "name": "waterUser",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "exchangeClearings(order:{\"run\":\"DESC\"})"
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ExchangeClearingType",
    "kind": "LinkedField",
    "name": "exchangeClearingTypes",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isDelivered",
        "storageKey": null
      },
      (v5/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deliveryPeriodDeliveryPeriodQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deliveryPeriodDeliveryPeriodQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "3dffdc752b00b24848202bd664747af6",
    "id": null,
    "metadata": {},
    "name": "deliveryPeriodDeliveryPeriodQuery",
    "operationKind": "query",
    "text": "query deliveryPeriodDeliveryPeriodQuery(\n  $catchmentId: UUID!\n  $wdpId: UUID!\n) {\n  waterDeliveryPeriods(where: {and: [{id: {eq: $wdpId}}, {catchment: {id: {eq: $catchmentId}}}]}) {\n    id\n    start\n    end\n    close\n    catchment {\n      id\n      waterUsers {\n        id\n        name\n        publicId\n        waterUserExtractionPoints {\n          id\n          extractionPoint {\n            id\n            name\n          }\n        }\n      }\n    }\n    exchangeClearings(order: {run: DESC}) {\n      id\n      isLatest\n      run\n      submissionState\n      exchangeClearingType {\n        id\n        name\n        isFinal\n        order\n      }\n      allocations {\n        id\n        baseQuantityPreLoss\n        baseQuantity\n        totalQuantityPreLoss\n        totalQuantity\n        changeQuantity\n        farmSurcharge\n        spotPricePreLoss\n        spotPrice\n        farmCongestionPrice\n        raceCongestionPrice\n        effectiveGrossPrice\n        effectiveNetPrice\n        quantityDisposedCreditPreLoss\n        quantityObtainedCharge\n        raceCongestionCharge\n        raceCongestionCredit\n        totalGrossCharge\n        totalNetCharge\n        clearingFee\n        constraint\n        waterUserExtractionPoint {\n          id\n          extractionPoint {\n            id\n            name\n          }\n          waterUser {\n            id\n            name\n            publicId\n          }\n        }\n      }\n    }\n  }\n  exchangeClearingTypes {\n    id\n    name\n    isDelivered\n    order\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "9b4a91ce073ba6d1aba198a25f9a8da9";

module.exports = ((node/*: any*/)/*: Query<
  deliveryPeriodDeliveryPeriodQuery$variables,
  deliveryPeriodDeliveryPeriodQuery$data,
>*/);
