import React, { Suspense, useContext, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { useParams } from "react-router-dom";
import { Account } from "./components/Account";
import { CenteredLoading } from "../../../common/components/loading";
import { ProfileContext } from "../../../common/contexts/profile";

const AccountQuery = graphql`
  query accountAccountQuery($catchmentId: UUID!, $accountId: UUID!) {
    accounts(where: { and: [ { catchment: { id: { eq: $catchmentId } } }, { or: [ { id: { eq: $accountId } }, { parentId: { eq: $accountId } } ] } ] }, order: { name: ASC }) {
      id
      name
      accountBalance {
        value
      }
      accountType {
        name
        code
      }
      transactionLineItems(where: { account: { id: { eq: $accountId } } }, order: { transaction: { date: DESC } }) {
        id
        value
        transaction {
          id
          date
          memo
          referenceTypeName
          referenceId
          transactionMetadata {
            name
            value
          }
          transactionLineItems {
            id
            value
            account {
              id
              name
              referenceTypeName
              accountType {
                name
                code
              }
            }
          }
        }
      }
    }
    allAccounts: accounts(where: { catchment: { id: { eq: $catchmentId } } }) {
      id
      name
      parentId
      accountType {
        name
        code
      }
    }
    accountHierarchy(where: { and: [ { catchmentId: { eq: $catchmentId } }, { accountId: { eq: $accountId } } ] }) {
      accountId
      fullPath
    }
  }
`;

export const AccountPage = () => {
  const [ accountQueryRef, loadAccountQuery ] = useQueryLoader(AccountQuery);
  const { catchmentId } = useContext(ProfileContext);
  const { aId } = useParams();

  useEffect(() => loadAccountQuery({ catchmentId, accountId: aId },
    { fetchPolicy: "network-only" }),
    [ catchmentId, aId, loadAccountQuery ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {accountQueryRef && <Account accountId={aId} accountQueryDef={AccountQuery} accountQueryRef={accountQueryRef} />}
    </Suspense>
  );
};