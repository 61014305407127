/**
 * @generated SignedSource<<ea26e245a259a607a8a8adcaf01b553a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type waterUserExtractionPointWaterUserExtractionPointResultsQuery$variables = {|
  catchmentId: any,
  end: any,
  extractionPointOfftakeTypes?: ?$ReadOnlyArray<any>,
  start: any,
  wuepId: any,
|};
export type waterUserExtractionPointWaterUserExtractionPointResultsQuery$data = {|
  +waterUserExtractionPointOfftakes: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
    +waterUserExtractionPointOfftakeMeasurements: ?$ReadOnlyArray<?{|
      +date: any,
      +flowRate: any,
      +id: ?string,
      +importOperation: ?{|
        +id: ?string,
      |},
      +measurementQualityType: ?{|
        +id: ?string,
      |},
      +modifiedByUser: ?{|
        +id: ?string,
      |},
      +total: ?any,
    |}>,
  |}>,
|};
export type waterUserExtractionPointWaterUserExtractionPointResultsQuery = {|
  response: waterUserExtractionPointWaterUserExtractionPointResultsQuery$data,
  variables: waterUserExtractionPointWaterUserExtractionPointResultsQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "catchmentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "extractionPointOfftakeTypes"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wuepId"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "eq",
                            "variableName": "wuepId"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "waterUserExtractionPoint"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.0"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "eq",
                            "variableName": "catchmentId"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "catchment"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.1"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "in",
                            "variableName": "extractionPointOfftakeTypes"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "extractionPointOfftakeType"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.2"
              }
            ],
            "kind": "ListValue",
            "name": "and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "WaterUserExtractionPointOfftake",
    "kind": "LinkedField",
    "name": "waterUserExtractionPointOfftakes",
    "plural": true,
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "order",
            "value": {
              "date": "ASC"
            }
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "gte",
                    "variableName": "start"
                  },
                  {
                    "kind": "Variable",
                    "name": "lte",
                    "variableName": "end"
                  }
                ],
                "kind": "ObjectValue",
                "name": "date"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "WaterUserExtractionPointOfftakeMeasurement",
        "kind": "LinkedField",
        "name": "waterUserExtractionPointOfftakeMeasurements",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "flowRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedByUser",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ImportOperation",
            "kind": "LinkedField",
            "name": "importOperation",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MeasurementQualityType",
            "kind": "LinkedField",
            "name": "measurementQualityType",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "waterUserExtractionPointWaterUserExtractionPointResultsQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "waterUserExtractionPointWaterUserExtractionPointResultsQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "08a03d23a7015df88322d0aaf605439c",
    "id": null,
    "metadata": {},
    "name": "waterUserExtractionPointWaterUserExtractionPointResultsQuery",
    "operationKind": "query",
    "text": "query waterUserExtractionPointWaterUserExtractionPointResultsQuery(\n  $catchmentId: UUID!\n  $wuepId: UUID!\n  $start: DateTime!\n  $end: DateTime!\n  $extractionPointOfftakeTypes: [UUID!]\n) {\n  waterUserExtractionPointOfftakes(where: {and: [{waterUserExtractionPoint: {id: {eq: $wuepId}}}, {catchment: {id: {eq: $catchmentId}}}, {extractionPointOfftakeType: {id: {in: $extractionPointOfftakeTypes}}}]}) {\n    id\n    name\n    waterUserExtractionPointOfftakeMeasurements(where: {date: {gte: $start, lte: $end}}, order: {date: ASC}) {\n      id\n      date\n      flowRate\n      total\n      modifiedByUser {\n        id\n      }\n      importOperation {\n        id\n      }\n      measurementQualityType {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "0ea0098d9f9a4820584b629dfdab07b8";

module.exports = ((node/*: any*/)/*: Query<
  waterUserExtractionPointWaterUserExtractionPointResultsQuery$variables,
  waterUserExtractionPointWaterUserExtractionPointResultsQuery$data,
>*/);
