import React from "react";
import { usePreloadedQuery } from "react-relay";
import { Link as LinkReact } from "react-router-dom";
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { AccountsTable } from "./AccountsTable";

export const Accounts = ({ accountsQueryDef, accountsQueryRef }) => {
  const accountsQuery = usePreloadedQuery(accountsQueryDef, accountsQueryRef);
  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Administration</Typography>
        </Link>
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">Accounts</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Accounts</Typography>
      <Typography variant="h2" sx={{ mt: 3 }}>Debit</Typography>
      <Grid container className="debit-accounts" sx={{ mt: 2 }}>
        <AccountsTable accounts={accountsQuery.accounts.filter(x => x.accountType.code === 'DR')} />
      </Grid>
      <Typography variant="h2" sx={{ mt: 3 }}>Credit</Typography>
      <Grid container className="credit-accounts" sx={{ mt: 2 }}>
        <AccountsTable accounts={accountsQuery.accounts.filter(x => x.accountType.code === 'CR')} />
      </Grid>
    </>
  );
};