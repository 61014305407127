import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { GridNode } from "../../../../common/components/gridNode";
import { formatCurrency, formatVolume } from "../../../../common/utils/format";
import { chain } from "../../../../common/utils/math";

export const DeliveryDetail = ({ allocation, deliverySummary }) => {
  const { totalQuantity, totalCharge } = deliverySummary;
  return (
    <Grid container item component={Paper} sx={{ mt: 1, p: 2 }}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 2 }} variant="body1">Results</Typography>
      </Grid>
      <Grid container item xs={12}>
        <GridNode name="Planned quantity" value={formatVolume(allocation.totalQuantity)} isDense />
        <GridNode name="Actual quantity" value={formatVolume(totalQuantity)} isDense />
        <GridNode name="Difference" value={formatVolume(chain(totalQuantity).subtract(allocation.totalQuantity).done())} />
      </Grid>
      <Grid container item xs={12} sx={{ mt: 1 }}>
        <GridNode name="Planned charge" value={formatCurrency(allocation.totalNetCharge)} isDense />
        <GridNode name="Actual charge" value={formatCurrency(totalCharge)} isDense />
        <GridNode name="Difference" value={formatCurrency(chain(totalCharge).subtract(allocation.totalNetCharge).done())} />
      </Grid>
    </Grid>
  );
};