/**
 * @generated SignedSource<<bbaabca02a181a2c7237410d6ca0647f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type financialsFinancialsQuery$variables = {|
  catchmentId: any,
  now: any,
|};
export type financialsFinancialsQuery$data = {|
  +accounts: ?$ReadOnlyArray<?{|
    +accountBalance: ?{|
      +value: any,
    |},
    +id: ?string,
    +name: ?string,
    +referenceId: ?any,
  |}>,
  +exchangeClearingsForCurrentSeason: ?$ReadOnlyArray<?{|
    +allocations: ?$ReadOnlyArray<?{|
      +changeQuantity: any,
      +id: ?string,
      +raceCongestionCharge: any,
      +raceCongestionCredit: any,
      +spotPrice: any,
      +totalNetCharge: any,
      +totalQuantity: any,
      +waterUserExtractionPoint: ?{|
        +id: ?string,
        +waterUser: ?{|
          +id: ?string,
        |},
      |},
    |}>,
    +id: ?string,
    +waterDeliveryPeriod: ?{|
      +end: any,
      +id: ?string,
      +start: any,
    |},
  |}>,
  +transactionLineItemsForCurrentSeason: ?$ReadOnlyArray<?{|
    +account: ?{|
      +id: ?string,
      +referenceId: ?any,
    |},
    +id: ?string,
    +transaction: ?{|
      +id: ?string,
      +referenceId: ?any,
      +transactionMetadata: ?$ReadOnlyArray<?{|
        +id: ?string,
        +name: ?string,
        +value: ?string,
      |}>,
    |},
    +value: any,
  |}>,
  +waterUsers: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
  |}>,
|};
export type financialsFinancialsQuery = {|
  response: financialsFinancialsQuery$data,
  variables: financialsFinancialsQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "catchmentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "now"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "catchmentId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "catchment"
  }
],
v2 = {
  "referenceTypeName": {
    "eq": "WaterUsers"
  }
},
v3 = [
  {
    "fields": [
      {
        "items": [
          {
            "fields": (v1/*: any*/),
            "kind": "ObjectValue",
            "name": "and.0"
          },
          {
            "kind": "Literal",
            "name": "and.1",
            "value": (v2/*: any*/)
          }
        ],
        "kind": "ListValue",
        "name": "and"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referenceId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v8 = {
  "kind": "Variable",
  "name": "catchmentId",
  "variableName": "catchmentId"
},
v9 = {
  "kind": "Variable",
  "name": "now",
  "variableName": "now"
},
v10 = {
  "eq": true
},
v11 = {
  "alias": null,
  "args": [
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "kind": "Literal",
      "name": "order",
      "value": {
        "waterDeliveryPeriod": {
          "start": "ASC"
        }
      }
    },
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "and": [
          {
            "exchangeClearingType": {
              "isFinal": (v10/*: any*/)
            }
          },
          {
            "isLatest": (v10/*: any*/)
          }
        ]
      }
    }
  ],
  "concreteType": "ExchangeClearing",
  "kind": "LinkedField",
  "name": "exchangeClearingsForCurrentSeason",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Allocation",
      "kind": "LinkedField",
      "name": "allocations",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "changeQuantity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalQuantity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "spotPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "raceCongestionCharge",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "raceCongestionCredit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalNetCharge",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WaterUserExtractionPoint",
          "kind": "LinkedField",
          "name": "waterUserExtractionPoint",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "WaterUser",
              "kind": "LinkedField",
              "name": "waterUser",
              "plural": false,
              "selections": [
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WaterDeliveryPeriod",
      "kind": "LinkedField",
      "name": "waterDeliveryPeriod",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "start",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "end",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": [
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "and": [
          {
            "account": (v2/*: any*/)
          },
          {
            "transaction": {
              "referenceTypeName": {
                "eq": "ExchangeClearings"
              }
            }
          }
        ]
      }
    }
  ],
  "concreteType": "TransactionLineItem",
  "kind": "LinkedField",
  "name": "transactionLineItemsForCurrentSeason",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Transaction",
      "kind": "LinkedField",
      "name": "transaction",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "where",
              "value": {
                "name": {
                  "eq": "WaterDeliveryPeriodId"
                }
              }
            }
          ],
          "concreteType": "TransactionMetadata",
          "kind": "LinkedField",
          "name": "transactionMetadata",
          "plural": true,
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/),
            (v7/*: any*/)
          ],
          "storageKey": "transactionMetadata(where:{\"name\":{\"eq\":\"WaterDeliveryPeriodId\"}})"
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "order",
      "value": {
        "publicId": "ASC"
      }
    },
    {
      "fields": (v1/*: any*/),
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "WaterUser",
  "kind": "LinkedField",
  "name": "waterUsers",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "financialsFinancialsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountBalance",
            "kind": "LinkedField",
            "name": "accountBalance",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "financialsFinancialsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountBalance",
            "kind": "LinkedField",
            "name": "accountBalance",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ]
  },
  "params": {
    "cacheID": "11ad7cde518f8d6fa0d9a9e1677cbb14",
    "id": null,
    "metadata": {},
    "name": "financialsFinancialsQuery",
    "operationKind": "query",
    "text": "query financialsFinancialsQuery(\n  $catchmentId: UUID!\n  $now: DateTime!\n) {\n  accounts(where: {and: [{catchment: {id: {eq: $catchmentId}}}, {referenceTypeName: {eq: \"WaterUsers\"}}]}) {\n    id\n    name\n    referenceId\n    accountBalance {\n      value\n      id\n    }\n  }\n  exchangeClearingsForCurrentSeason(catchmentId: $catchmentId, now: $now, where: {and: [{exchangeClearingType: {isFinal: {eq: true}}}, {isLatest: {eq: true}}]}, order: {waterDeliveryPeriod: {start: ASC}}) {\n    id\n    allocations {\n      id\n      changeQuantity\n      totalQuantity\n      spotPrice\n      raceCongestionCharge\n      raceCongestionCredit\n      totalNetCharge\n      waterUserExtractionPoint {\n        id\n        waterUser {\n          id\n        }\n      }\n    }\n    waterDeliveryPeriod {\n      id\n      start\n      end\n    }\n  }\n  transactionLineItemsForCurrentSeason(catchmentId: $catchmentId, now: $now, where: {and: [{account: {referenceTypeName: {eq: \"WaterUsers\"}}}, {transaction: {referenceTypeName: {eq: \"ExchangeClearings\"}}}]}) {\n    id\n    value\n    account {\n      id\n      referenceId\n    }\n    transaction {\n      id\n      referenceId\n      transactionMetadata(where: {name: {eq: \"WaterDeliveryPeriodId\"}}) {\n        id\n        name\n        value\n      }\n    }\n  }\n  waterUsers(where: {catchment: {id: {eq: $catchmentId}}}, order: {publicId: ASC}) {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "58702e7b6709759ee89ac1ae9c040672";

module.exports = ((node/*: any*/)/*: Query<
  financialsFinancialsQuery$variables,
  financialsFinancialsQuery$data,
>*/);
