import React, { Suspense, useContext, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { ClearingsHeader } from "./components/ClearingsHeader";
import { ClearingsResults } from "./components/ClearingsResults";
import { CenteredLoading, ResultsLoading } from "../../../common/components/loading";
import { ProfileContext } from "../../../common/contexts/profile";

const ClearingsHeaderQuery = graphql`
  query clearingsClearingsHeaderQuery($catchmentId: UUID!) {
    waterUsers(filterByCurrentUser: true, where: { catchment: { id: { eq: $catchmentId } } }, order: { publicId: ASC }) {
      id
      name
    }
  }
`;

const ClearingsResultsQuery = graphql`
  query clearingsClearingResultsQuery($catchmentId: UUID!, $start: DateTime!, $end: DateTime!, $esiStart: DateTime!, $esiEnd: DateTime!, $waterUsers: [UUID!]) {
    extractionSources(where: { catchment: { id: { eq: $catchmentId } } }) {
      id
      inputOffset
      extractionSourceInputs(where: { date: { gte: $esiStart, lte: $esiEnd } }) {
        id
        date
        forecastGross
        forecastNet
        actualGross
        actualNet
      }
    }
    waterDeliveryPeriods(where: { catchment: { id: { eq: $catchmentId } }, start: { lte: $end }, end: { gte: $start } }, order: { start: ASC }) {
      id
      start
      end
    }
    waterUsers(where: { and: [ { id: { in: $waterUsers } }, { catchment: { id: { eq: $catchmentId } } } ] }, order: { publicId: ASC }) {
      id
      name
      waterUserExtractionPoints {
        id
        lossFactor
        allocations(where: { exchangeClearing: { and: [ { isLatest: { eq: true } }, { waterDeliveryPeriod: { start: { lte: $end }, end: { gte: $start } } } ] } }, order: { exchangeClearing: { run: DESC } }) {
          baseQuantity
          totalQuantity
          exchangeClearing {
            id
            exchangeClearingType {
              id
              isDelivered
            }
            waterDeliveryPeriod {
              id
            }
          }
        }
        extractionPoint {
          id
          name
          extractionSource {
            id
            config
            inputOffset
          }
        }
        offers(where: { waterDeliveryPeriod: { start: { lte: $end }, end: { gte: $start } } }) {
          id
          offerLines {
            id
            volume
          }
          waterDeliveryPeriod {
            id
          }
        }
        shares {
          id
          value
          apportionment {
            id
            value
          }
        }
      }
    }
  }
`;

export const ClearingsPage = () => {
  const [ clearingsHeaderQueryRef, loadClearingsHeaderQuery ] = useQueryLoader(ClearingsHeaderQuery);
  const [ clearingsResultsQueryRef, loadClearingsResultsQuery ] = useQueryLoader(ClearingsResultsQuery);
  const { catchmentId } = useContext(ProfileContext);

  const handleExecute = ({ end, start, waterUsers }) => {
    const esiStart = start.clone().add(-7, "days");
    const esiEnd = end.clone().add(7, "days");
    loadClearingsResultsQuery({
      catchmentId,
      end: end.format(),
      esiEnd: esiEnd.format(),
      esiStart: esiStart.format(),
      start: start.format(),
      waterUsers
    }, { fetchPolicy: "network-only" });
  };

  useEffect(() => loadClearingsHeaderQuery({ catchmentId },
    { fetchPolicy: "network-only" }),
    [ loadClearingsHeaderQuery, catchmentId ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {clearingsHeaderQueryRef &&
        <ClearingsHeader clearingsHeaderQueryDef={ClearingsHeaderQuery} clearingsHeaderQueryRef={clearingsHeaderQueryRef}
          onExecute={handleExecute} />}
      <Suspense fallback={<ResultsLoading />}>
        {clearingsResultsQueryRef && <ClearingsResults clearingsResultsQueryDef={ClearingsResultsQuery}
          clearingsResultsQueryRef={clearingsResultsQueryRef} />}
      </Suspense>
    </Suspense>
  );
};