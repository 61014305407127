/**
 * @generated SignedSource<<fb03ab5cf6986892d87c4236444eaa45>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type profileProfileQuery$variables = {|
  catchmentId: any,
|};
export type profileProfileQuery$data = {|
  +catchments: ?$ReadOnlyArray<?{|
    +name: ?string,
    +waterUsers: ?$ReadOnlyArray<?{|
      +id: ?string,
      +name: ?string,
    |}>,
  |}>,
  +me: ?{|
    +name: ?string,
    +userIdentities: ?$ReadOnlyArray<?{|
      +provider: ?string,
      +upn: ?string,
    |}>,
    +userRoles: ?$ReadOnlyArray<?{|
      +role: ?{|
        +code: ?string,
        +level: ?string,
      |},
      +target: any,
    |}>,
  |},
|};
export type profileProfileQuery = {|
  response: profileProfileQuery$data,
  variables: profileProfileQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "catchmentId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upn",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provider",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "target",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "level",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "order",
    "value": {
      "name": "ASC"
    }
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "catchmentId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "WaterUser",
  "kind": "LinkedField",
  "name": "waterUsers",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "profileProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserIdentity",
            "kind": "LinkedField",
            "name": "userIdentities",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserRole",
            "kind": "LinkedField",
            "name": "userRoles",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Catchment",
        "kind": "LinkedField",
        "name": "catchments",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "profileProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserIdentity",
            "kind": "LinkedField",
            "name": "userIdentities",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserRole",
            "kind": "LinkedField",
            "name": "userRoles",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Catchment",
        "kind": "LinkedField",
        "name": "catchments",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v9/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "90ef3f2bfde8b03a74133d53b7a9c0f6",
    "id": null,
    "metadata": {},
    "name": "profileProfileQuery",
    "operationKind": "query",
    "text": "query profileProfileQuery(\n  $catchmentId: UUID!\n) {\n  me {\n    name\n    userIdentities {\n      upn\n      provider\n      id\n    }\n    userRoles {\n      target\n      role {\n        code\n        level\n        id\n      }\n      id\n    }\n    id\n  }\n  catchments(where: {id: {eq: $catchmentId}}, order: {name: ASC}) {\n    name\n    waterUsers {\n      id\n      name\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "3a6db50d80a635281f751246b37dc4fc";

module.exports = ((node/*: any*/)/*: Query<
  profileProfileQuery$variables,
  profileProfileQuery$data,
>*/);
