import React from "react";
import { usePreloadedQuery } from "react-relay";
import { Breadcrumbs, Box, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Section } from "./Section";
import { getStatsItems } from "../../../utils/stats";

export const Home = ({ homeQueryDef, homeQueryRef, now }) => {
  const homeQuery = usePreloadedQuery(homeQueryDef, homeQueryRef);

  const waterUsers = homeQuery.waterUsers
    .reduce((p, x) => {
      x.waterUserExtractionPoints.forEach(y => {
        const items = homeQuery.waterDeliveryPeriods
          .reduce((q, z) => {
            const a = y.allocations.find(a => a.exchangeClearing.waterDeliveryPeriod.id === z.id);
            const o = y.offers.find(o => o.waterDeliveryPeriod.id === z.id);
            q.push({
              waterDeliveryPeriod: z,
              waterUserExtractionPointId: y.id,
              extractionPoint: y.extractionPoint,
              stats: getStatsItems(a, o, z, y,
                y.extractionPoint.extractionSource,
                homeQuery.extractionSources.find(e => e.id === y.extractionPoint.extractionSource.id)
                  .extractionSourceInputs,
                a && a.exchangeClearing.exchangeClearingType.isDelivered),
              isMissingOffers: !o || !o.offerLines || o.offerLines.length === 0,
              isInProgress: a && a.exchangeClearing && !a.exchangeClearing.exchangeClearingType.isFinal
            });
            return q;
          }, []);

        p.push({
          name: `${x.name} (${y.extractionPoint.name})`,
          items
        });
      });
      return p;
    }, []);

  return (
    <Box className="home">
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">Overview</Typography>
        </Link>
      </Breadcrumbs>
      {waterUsers.map(x => (<Section key={x.name} name={x.name} now={now} items={x.items} sx={{ mb: 4 }} />))}
    </Box>
  );
};