/**
 * @generated SignedSource<<967d7f805a28dc9a11540516696a036a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateWaterDeliveryPeriodInput = {|
  close?: ?any,
  end: any,
  isSeasonStart?: ?boolean,
  start: any,
  waterDeliveryPeriodId: any,
|};
export type DeliveryPeriodsResultsUpdateDeliveryPeriodMutation$variables = {|
  input: UpdateWaterDeliveryPeriodInput,
|};
export type DeliveryPeriodsResultsUpdateDeliveryPeriodMutation$data = {|
  +updateWaterDeliveryPeriod: {|
    +waterDeliveryPeriod: ?{|
      +close: ?any,
      +end: any,
      +id: ?string,
      +isSeasonStart: ?boolean,
      +start: any,
    |},
  |},
|};
export type DeliveryPeriodsResultsUpdateDeliveryPeriodMutation = {|
  response: DeliveryPeriodsResultsUpdateDeliveryPeriodMutation$data,
  variables: DeliveryPeriodsResultsUpdateDeliveryPeriodMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateWaterDeliveryPeriodPayload",
    "kind": "LinkedField",
    "name": "updateWaterDeliveryPeriod",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WaterDeliveryPeriod",
        "kind": "LinkedField",
        "name": "waterDeliveryPeriod",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "start",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "end",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "close",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSeasonStart",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeliveryPeriodsResultsUpdateDeliveryPeriodMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeliveryPeriodsResultsUpdateDeliveryPeriodMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5ef8ec34e4191853ffcf6de3f4178523",
    "id": null,
    "metadata": {},
    "name": "DeliveryPeriodsResultsUpdateDeliveryPeriodMutation",
    "operationKind": "mutation",
    "text": "mutation DeliveryPeriodsResultsUpdateDeliveryPeriodMutation(\n  $input: UpdateWaterDeliveryPeriodInput!\n) {\n  updateWaterDeliveryPeriod(input: $input) {\n    waterDeliveryPeriod {\n      id\n      start\n      end\n      close\n      isSeasonStart\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "eaa077855f4002bb739fde0e67c71dfd";

module.exports = ((node/*: any*/)/*: Mutation<
  DeliveryPeriodsResultsUpdateDeliveryPeriodMutation$variables,
  DeliveryPeriodsResultsUpdateDeliveryPeriodMutation$data,
>*/);
