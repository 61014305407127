import React from "react";
import { Link as LinkReact, Navigate } from "react-router-dom";
import { Breadcrumbs, Grid, Link, Paper, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { usePreloadedQuery } from "react-relay";
import { Subheader } from "../../../../common/components/subheader";
import { GridNode } from "../../../../common/components/gridNode";
import { ExtractionPoints } from "./ExtractionPoints";

export const WaterUser = ({ waterUserQueryDef, waterUserQueryRef }) => {
  const waterUserQuery = usePreloadedQuery(waterUserQueryDef, waterUserQueryRef);
  const waterUser = waterUserQuery.waterUsers.length > 0
    ? waterUserQuery.waterUsers[0]
    : null;

  if (!waterUser) {
    return (<Navigate to="/admin/water-users" />);
  }

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Administration</Typography>
        </Link>
        <Link component={LinkReact} underline="hover" color="inherit" to="/admin/water-users">
          <Typography variant="body2">Water Users</Typography>
        </Link>
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">{waterUser.name}</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">{waterUser.name}</Typography>
      <Grid container className="water-user" sx={{ mb: 10 }}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Subheader name="Overview" />
        </Grid>
        <Grid container item xs={12} component={Paper} sx={{ p: 2, pt: 1 }} rowSpacing={1}>
          <GridNode name="Id" value={waterUser.publicId} />
          <GridNode name="Name" value={waterUser.name} />
        </Grid>
        <Grid item xs={12}>
          <Subheader name="Extraction Points" />
        </Grid>
        <Grid item xs={12}>
          <ExtractionPoints waterUserExtractionPoints={waterUser.waterUserExtractionPoints} />
        </Grid>
      </Grid>
    </>
  );
};