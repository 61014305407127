import React, { Suspense, useContext, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { ClearingOffersTable } from "./ClearingOffersTable";
import { WizardLoading } from "../../../../common/components/loading";
import { ProfileContext } from "../../../../common/contexts/profile";

const ClearingOffersQuery = graphql`
  query ClearingOffersClearingOffersQuery($catchmentId: UUID!, $wdpId: UUID!, $run: DateTime!) {
    catchments(where: { id: { eq: $catchmentId } }) {
      minimumBidPrice
    }
    waterDeliveryPeriods(where: { id: { eq: $wdpId } }) {
      id
      start
      end
    }
    extractionSourcesAt(run: $run, where: { catchment: { id: { eq: $catchmentId } } }) {
      id
      name
      config
      inputOffset
    }
    extractionSourceInputsForWaterDeliveryPeriodAt(run: $run, waterDeliveryPeriodId: $wdpId, where: { catchment: { id: { eq: $catchmentId } } }) {
      id
      date
      forecastGross
      forecastNet
      actualGross
      actualNet
      extractionSource {
        id
      }
    }
    waterUserExtractionPointsAt(run: $run, where: { catchment: { id: { eq: $catchmentId } } }, order: { waterUser: { publicId: ASC } }) {
      id
      lossFactor
      extractionPoint {
        id
        name
        extractionSource {
          id
        }
      }
      waterUser {
        id
        name
        publicId
        isRecurringDefaultBidder
        isDemandOnly
        isSystemActor
      }
      offers(where: { waterDeliveryPeriod: { id: { eq: $wdpId } } }) {
        id
        offerLines(order: { price: ASC }) {
          id
          volume
          price
        }
      }
      shares {
        id
        value
        apportionment {
          id
          value
        }
      }
    }
  }
`;

export const ClearingOffers = ({ autoBid, onProgressChange, run, type, types, waterDeliveryPeriodId }) => {
  const { catchmentId } = useContext(ProfileContext);
  const [ clearingOffersQueryRef, loadClearingOffersQuery ] = useQueryLoader(ClearingOffersQuery);
  useEffect(() => {
    loadClearingOffersQuery({ catchmentId, run, wdpId: waterDeliveryPeriodId }, { fetchPolicy: "network-only" });
    onProgressChange(true);
  },[ catchmentId, loadClearingOffersQuery, onProgressChange, run, waterDeliveryPeriodId ]);

  return (
    <Suspense fallback={<WizardLoading />}>
      {clearingOffersQueryRef && <ClearingOffersTable clearingOffersQueryDef={ClearingOffersQuery}
        clearingOffersQueryRef={clearingOffersQueryRef} onProgressChange={onProgressChange}
        autoBid={autoBid} type={type} types={types} />}
    </Suspense>
  );
};