/**
 * @generated SignedSource<<d25839c1eac37bef4902150cc8441740>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type forecastAccuracyForecastAccuracyResultsQuery$variables = {|
  catchmentId: any,
  end: any,
  extractionSources?: ?$ReadOnlyArray<any>,
  start: any,
|};
export type forecastAccuracyForecastAccuracyResultsQuery$data = {|
  +extractionSourceInputHistories: ?$ReadOnlyArray<?{|
    +date: any,
    +extractionSourceId: ?any,
    +forecastGross: ?any,
    +forecastSync: ?{|
      +analysis: ?any,
      +id: ?string,
      +reference: ?string,
      +sync: any,
    |},
    +id: ?string,
    +originalId: any,
    +periodEnd: any,
    +periodStart: any,
  |}>,
  +extractionSourceInputs: ?$ReadOnlyArray<?{|
    +actualGross: ?any,
    +actualSync: ?{|
      +analysis: ?any,
      +id: ?string,
      +reference: ?string,
      +sync: any,
    |},
    +date: any,
    +extractionSourceId: ?any,
    +id: ?string,
  |}>,
|};
export type forecastAccuracyForecastAccuracyResultsQuery = {|
  response: forecastAccuracyForecastAccuracyResultsQuery$data,
  variables: forecastAccuracyForecastAccuracyResultsQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "catchmentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "extractionSources"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start"
},
v4 = {
  "fields": [
    {
      "items": [
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "eq",
                  "variableName": "catchmentId"
                }
              ],
              "kind": "ObjectValue",
              "name": "catchmentId"
            }
          ],
          "kind": "ObjectValue",
          "name": "and.0"
        },
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "in",
                  "variableName": "extractionSources"
                }
              ],
              "kind": "ObjectValue",
              "name": "extractionSourceId"
            }
          ],
          "kind": "ObjectValue",
          "name": "and.1"
        },
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "gte",
                  "variableName": "start"
                }
              ],
              "kind": "ObjectValue",
              "name": "date"
            }
          ],
          "kind": "ObjectValue",
          "name": "and.2"
        },
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "lte",
                  "variableName": "end"
                }
              ],
              "kind": "ObjectValue",
              "name": "date"
            }
          ],
          "kind": "ObjectValue",
          "name": "and.3"
        }
      ],
      "kind": "ListValue",
      "name": "and"
    }
  ],
  "kind": "ObjectValue",
  "name": "where"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extractionSourceId",
  "storageKey": null
},
v8 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "analysis",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sync",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "reference",
    "storageKey": null
  }
],
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order",
        "value": {
          "date": "ASC",
          "periodStart": "ASC"
        }
      },
      (v4/*: any*/)
    ],
    "concreteType": "TemporalExtractionSourceInput",
    "kind": "LinkedField",
    "name": "extractionSourceInputHistories",
    "plural": true,
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "originalId",
        "storageKey": null
      },
      (v6/*: any*/),
      (v7/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "forecastGross",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ExtractionSourceSync",
        "kind": "LinkedField",
        "name": "forecastSync",
        "plural": false,
        "selections": (v8/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "periodStart",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "periodEnd",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order",
        "value": {
          "date": "ASC"
        }
      },
      (v4/*: any*/)
    ],
    "concreteType": "ExtractionSourceInput",
    "kind": "LinkedField",
    "name": "extractionSourceInputs",
    "plural": true,
    "selections": [
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualGross",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ExtractionSourceSync",
        "kind": "LinkedField",
        "name": "actualSync",
        "plural": false,
        "selections": (v8/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "forecastAccuracyForecastAccuracyResultsQuery",
    "selections": (v9/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "forecastAccuracyForecastAccuracyResultsQuery",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "b9bae29e2a3808e3aa9b75e571b46096",
    "id": null,
    "metadata": {},
    "name": "forecastAccuracyForecastAccuracyResultsQuery",
    "operationKind": "query",
    "text": "query forecastAccuracyForecastAccuracyResultsQuery(\n  $catchmentId: UUID!\n  $extractionSources: [UUID!]\n  $end: DateTime!\n  $start: DateTime!\n) {\n  extractionSourceInputHistories(where: {and: [{catchmentId: {eq: $catchmentId}}, {extractionSourceId: {in: $extractionSources}}, {date: {gte: $start}}, {date: {lte: $end}}]}, order: {date: ASC, periodStart: ASC}) {\n    id\n    originalId\n    date\n    extractionSourceId\n    forecastGross\n    forecastSync {\n      id\n      analysis\n      sync\n      reference\n    }\n    periodStart\n    periodEnd\n  }\n  extractionSourceInputs(where: {and: [{catchmentId: {eq: $catchmentId}}, {extractionSourceId: {in: $extractionSources}}, {date: {gte: $start}}, {date: {lte: $end}}]}, order: {date: ASC}) {\n    id\n    date\n    extractionSourceId\n    actualGross\n    actualSync {\n      id\n      analysis\n      sync\n      reference\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "a5f006c72926b845c09abdda1601819f";

module.exports = ((node/*: any*/)/*: Query<
  forecastAccuracyForecastAccuracyResultsQuery$variables,
  forecastAccuracyForecastAccuracyResultsQuery$data,
>*/);
