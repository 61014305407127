import React, { Suspense, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQueryLoader } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { CenteredLoading } from "../../../common/components/loading";
import { Clearing } from "./components/Clearing";
import { ProfileContext } from "../../../common/contexts/profile";

const ClearingQuery = graphql`
  query clearingClearingQuery($catchmentId: UUID!, $wdpId: UUID!, $wuepId: UUID!) {
    waterDeliveryPeriods(where: { and: [ { id: { eq: $wdpId } }, { catchment: { id: { eq: $catchmentId } } } ] }) {
      id
      start
      end
      close
      exchangeClearings(where: { isLatest: { eq: true } }) {
        id
        run
        exchangeClearingType {
          id
          name
          isFinal
          isDelivered
        }
        allocations(where: { waterUserExtractionPoint: { id: { eq: $wuepId } }}) {
          baseQuantityPreLoss
          baseQuantity
          totalQuantityPreLoss
          totalQuantity
          changeQuantity
          farmSurcharge
          spotPricePreLoss
          spotPrice
          farmCongestionPrice
          raceCongestionPrice
          effectiveGrossPrice
          effectiveNetPrice
          quantityDisposedCreditPreLoss
          quantityObtainedCharge
          raceCongestionCharge
          raceCongestionCredit
          totalGrossCharge
          totalNetCharge
          clearingFee
          constraint
        }
      }
    }
    exchangeClearingConstraintForWaterUserExtractionPoint(waterDeliveryPeriodId: $wdpId, waterUserExtractionPointId: $wuepId, isLatest: true) {
      id
      exchangeClearingId
      waterDeliveryPeriodId
      waterUserExtractionPointId
      isLatest
      isAnyConstrained
      isTargetConstrained
    }
    waterUserExtractionPoints(where: { and: [ { id: { eq: $wuepId } }, { catchment: { id: { eq: $catchmentId } } } ] }) {
      id
      lossFactor
      maxOfftake
      offers(where: { waterDeliveryPeriod: { id: { eq: $wdpId } } }) {
        id
        offerLines(order: { price: DESC }) {
          id
          volume
          price
        }
      }
      extractionPoint {
        id
        name
        extractionSource {
          id
          config
          inputOffset
        }
      }
      shares {
        value
        apportionment {
          value
        }
      }
      waterUser {
        name
        publicId
      }
      waterUserExtractionPointOfftakes {
        id
        name
      }
    }
    waterUserExtractionPointsAtLatest(waterDeliveryPeriodId: $wdpId, where: { and: [ { originalId: { eq: $wuepId } }, { catchment: { id: { eq: $catchmentId } } } ] }) {
      id
      lossFactor
      maxOfftake
    }
    offerSummariesByWaterUserExtractionPoint(waterDeliveryPeriodId: $wdpId, waterUserExtractionPointId: $wuepId) {
      isUser
      price
      volume
    }
    extractionSourceInputsForWaterDeliveryPeriodAtLatest(waterDeliveryPeriodId: $wdpId) {
      id
      date
      forecastGross
      forecastNet
      actualGross
      actualNet
    }
    extractionSourceInputsForWaterDeliveryPeriods(waterDeliveryPeriodId: $wdpId, waterUserExtractionPointId: $wuepId) {
      waterDeliveryPeriod {
        id
        start
        end
        exchangeClearings {
          id
          exchangeClearingType {
            id
            isFinal
            isDelivered
          }
        }
      }
      extractionSourceInputs {
        id
        date
        forecastGross
        forecastNet
        actualGross
        actualNet
      }
    }
    waterUserExtractionPointOfftakeMeasurementsByUser(waterDeliveryPeriodId: $wdpId, waterUserExtractionPointId: $wuepId, order: { date: ASC }) {
      id
      date
      flowRate
      total
      waterUserExtractionPointOfftake {
        id
      }
    }
  }
`;

export const ClearingPage = () => {
  const { wdpId, wuepId } = useParams();
  const [ clearingQueryRef, loadClearingQuery ] = useQueryLoader(ClearingQuery);
  const { catchmentId } = useContext(ProfileContext);
  useEffect(() => loadClearingQuery({ catchmentId, wdpId, wuepId }, { fetchPolicy: "network-only" }),
    [ catchmentId, loadClearingQuery, wdpId, wuepId ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {clearingQueryRef && <Clearing clearingQueryDef={ClearingQuery} clearingQueryRef={clearingQueryRef} wdpId={wdpId} wuepId={wuepId} />}
    </Suspense>
  );
};