import React, { useState } from "react";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { GridNode } from "../../../../common/components/gridNode";
import { formatCurrency, formatDateTime, formatVolume } from "../../../../common/utils/format";
import { add, multiply, divide } from "../../../../common/utils/math";

export const ResultDetail = ({ allocation, clearingDate }) => {
  const [ showPriceDialog, setShowPriceDialog ] = useState(false);
  const isPurchase = allocation.totalCharge >= 0;

  return (
    <>
      {allocation.farmCongestion && <Alert severity="warning">Farm off-take congestion detected ({allocation.farmCongestion}).</Alert>}
      <Grid container item component={Paper} sx={{ mt: 1, p: 2 }}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography variant="body1">Outputs</Typography>
          <Typography variant="caption">As at last clearing at {formatDateTime(clearingDate)}</Typography>
        </Grid>
        <Grid container item xs={12}>
          <GridNode name="Water allowance" value={formatVolume(allocation.baseQuantity)} isDense />
          <GridNode name="Purchased (or disposed)" value={formatVolume(allocation.changeQuantity)} isDense />
          <GridNode name="Net clearing water to arrive at farm" value={formatVolume(allocation.totalQuantity)} isDense />
        </Grid>
        <Grid container item xs={12} sx={{ mt: 1 }}>
          <GridNode name="Farm water price/unit" value={<>{formatCurrency(allocation.spotPrice, 3)}&#8203;/k.m<sup>3</sup></>} isDense />
          {/* <GridNode name="Purchased (or disposed)" value={formatVolume(allocation.changeQuantity)} isDense /> */}
          <GridNode name="&nbsp;" isDense />
          <GridNode name="Total farm water cost" value={formatCurrency(multiply(allocation.spotPrice, allocation.changeQuantity))} isDense />
        </Grid>
        <Grid container item xs={12} sx={{ mt: 1 }}>
          <GridNode name="Congestion cost/unit" value={formatCurrency(divide(allocation.raceCongestionCharge, allocation.totalQuantity), 3)} isDense />
          <GridNode name="Congestion allowance/unit" value={formatCurrency(divide(allocation.raceCongestionCredit, allocation.totalQuantity), 3)} isDense />
          <GridNode name="Net congestion charge" value={formatCurrency(add(allocation.raceCongestionCharge, allocation.raceCongestionCredit))} isDense />
        </Grid>
        <Grid container item xs={12} sx={{ mt: 1 }}>
          <GridNode name="&nbsp;" isDense />
          <GridNode name="&nbsp;" isDense />
          <GridNode name={
            <>
              Total clearing charge
              <IconButton size="small" onClick={() => setShowPriceDialog(true)} sx={{ p: 0 }}>
                <HelpIcon style={{ height: "16px", verticalAlign: "text-bottom" }} />
              </IconButton>
            </>}
            value={<>
              {formatCurrency(allocation.totalNetCharge, 2)}
              {isPurchase
                ? <ArrowDropDownIcon sx={{ mb: -1, mt: -1, mr: -1 }} color="error" />
                : <ArrowDropUpIcon sx={{ mb: -1, mt: -1, mr: -1 }} color="primary" />}
            </>} isDense isBold />
        </Grid>
      </Grid>
      <Dialog open={showPriceDialog} onClose={() => showPriceDialog(false)}>
        <DialogTitle>Total cost breakdown</DialogTitle>
        <DialogContent>
          <TableContainer sx={{ mt: 1 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Component</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Quantity Disposed</TableCell>
                  <TableCell>{formatCurrency(allocation.quantityDisposedCreditPreLoss)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Quantity Obtained</TableCell>
                  <TableCell>{formatCurrency(allocation.quantityObtainedCharge)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Race Congestion Charge</TableCell>
                  <TableCell>{formatCurrency(allocation.raceCongestionCharge)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Race Congestion Credit</TableCell>
                  <TableCell>{formatCurrency(allocation.raceCongestionCredit)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Charge</TableCell>
                  <TableCell>{formatCurrency(allocation.totalNetCharge)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPriceDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};