import React, { Suspense, useContext, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { Accounts } from "./components/Accounts";
import { CenteredLoading } from "../../../common/components/loading";
import { ProfileContext } from "../../../common/contexts/profile";

const AccountsQuery = graphql`
  query accountsAccountsQuery($catchmentId: UUID!) {
    accounts(where: { and: [ { catchment: { id: { eq: $catchmentId } } }, { parentId: { eq: null } } ] }, order: { name: ASC }) {
      id
      name
      accountBalance {
        value
      }
      accountType {
        name
        code
      }
    }
  }
`;

export const AccountsPage = () => {
  const [ accountsQueryRef, loadAccountsQuery ] = useQueryLoader(AccountsQuery);
  const { catchmentId } = useContext(ProfileContext);

  useEffect(() => loadAccountsQuery(
    { catchmentId }, { fetchPolicy: "network-only" }),
    [ loadAccountsQuery, catchmentId ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {accountsQueryRef && <Accounts accountsQueryDef={AccountsQuery} accountsQueryRef={accountsQueryRef} />}
    </Suspense>
  );
};