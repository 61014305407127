import React from "react";
import { usePreloadedQuery } from "react-relay";
import { Link as LinkReact } from "react-router-dom";
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ExtractionSourcesTable } from "./ExtractionSourcesTable";

export const ExtractionSources = ({ extractionSourcesQueryDef, extractionSourcesQueryRef }) => {
  const extractionSourcesQuery = usePreloadedQuery(extractionSourcesQueryDef, extractionSourcesQueryRef);
  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Administration</Typography>
        </Link>
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">Extraction Sources</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Extraction Sources</Typography>
      <Grid container className="delivery-periods" sx={{ mt: 2 }}>
        <ExtractionSourcesTable extractionSources={extractionSourcesQuery.extractionSources} />
      </Grid>
    </>
  );
};