import React, { useMemo, useState } from "react";
import { Grid, Paper, useTheme } from "@mui/material";
import Plot from "react-plotly.js";
import { add } from "../../../../common/utils/math";
import { GridNode } from "../../../../common/components/gridNode";
import { formatCurrency, formatVolume } from "../../../../common/utils/format";
import { useDefaultConfig, useDefaultLayout, useDefaultLabelStyle, useIsLightTheme, useConvert } from "../../../utils/charts";
import { ChartTitle } from "../../../components/chartTitle";

export const ResultAggregateChart = ({ offerSummaries, totalInput }) => {
  const [ dynamicPlot, setDynamicPlot ] = useState(false);
  const theme = useTheme();
  const isLightTheme = useIsLightTheme();
  const defaultConfig = useDefaultConfig({ staticPlot: !dynamicPlot });
  const defaultLayout = useDefaultLayout();
  const defaultLabelStyle = useDefaultLabelStyle();
  const convert = useConvert();

  const [ data, price, volume ] = useMemo(() => {
    const baseline = totalInput;

    const offersSeries = (offerSummaries || []).reduce((result, { price, volume }) => {
      result.x.push(result.x.length === 0 ? 0 : result.x[result.x.length - 1]);
      result.x.push(add(result.x[result.x.length - 1], volume));
      result.y.push(price);
      result.y.push(price);
      return result;
    }, {
      x: [],
      y: [],
      mode: "lines+markers",
      name: "Aggregate demand",
      line:  {
        color: theme.palette.primary.main
      },
      hovertemplate: "Volume: <b>%{x:.2f}k.m³</b>, Price: <b>%{y:$.3f}</b><extra></extra>",
      hoverlabel: defaultLabelStyle
    });
    if (offersSeries.x.length > 0) {
      offersSeries.x.push(offersSeries.x[offersSeries.x.length - 1]);
      offersSeries.y.push(0);
    }

    const availableSeries = {
      x: [ baseline, baseline ],
      y: [ 0, offersSeries.y.reduce((a, b) => Math.max(a, b), 0) ],
      mode: "lines+markers",
      name: "Available supply",
      line: {
        color: isLightTheme ? theme.palette.grey[400] : theme.palette.grey[100]
      },
      hovertemplate: "Volume: <b>%{x:.2f}k.m³</b><extra></extra>",
      hoverlabel: defaultLabelStyle
    };
  
    const data = [
      availableSeries,
      offersSeries
    ];

    let volume = null;
    let price = null;
    if (offersSeries.x.length > 0 && availableSeries.x.length > 0) {
      volume = baseline;
      price = offersSeries.x.reduce((p, c, i) => {
        if (p) {
          return p;
        }

        if (i % 2 === 0) {
          const next = offersSeries.x.length >= (i + 1) ? offersSeries.x[i + 1] : null;
          if (next) {
            if (c <= volume && next >= volume) {
              return offersSeries.y[i];
            }
          }
        }

        return p;
      }, null);

      if (volume && price) {
        const interceptSeries = {
          x: [ volume ],
          y: [ price ],
          marker: {
            color: theme.palette.error.main,
            size: 15,
            symbol: "star"
          },
          mode: "markers",
          name: "Clearing",
          showlegend: false,
          hovertemplate: "Exchange clearing point (volume: <b>%{x:.2f}k.m³</b>, price: <b>%{y:$.3f}</b>)<extra></extra>",
          hoverlabel: defaultLabelStyle
        };

        data.push(interceptSeries);
      }
    }

    return [ data, price, volume ];
  }, [ defaultLabelStyle, isLightTheme, offerSummaries, theme, totalInput ]);

  return (
    <Grid container item component={Paper} xs={12}>
      <ChartTitle checked={dynamicPlot} onChange={setDynamicPlot} title="Pre-loss aggregate demand" />
      <Grid item xs={12}>
        <Plot
          config={defaultConfig}
          data={data}
          layout={{
            ...defaultLayout,
            xaxis: {
              gridcolor: isLightTheme ? undefined : theme.palette.grey[800],
              rangemode: "tozero",
              ticks: "outside",
              title: {
                font: {
                  size: convert(theme.typography.body2.fontSize)
                },
                text: "volume (k.m³)"
              }
            },
            yaxis: {
              gridcolor: isLightTheme ? undefined : theme.palette.grey[800],
              rangemode: "tozero",
              ticks: "outside",
              title: {
                font: {
                  size: convert(theme.typography.body2.fontSize)
                },
                text: "price ($)"
              }
            }
          }}
          style={{ display: "block" }}
          useResizeHandler={true}
        />
      </Grid>
      {price && volume &&
        <Grid container item xs={12} sx={{ m: 2, mt: 1 }}>
          <GridNode name="Total" value={formatVolume(volume)} isDense />
          <GridNode name="Spot price" value={<>{formatCurrency(price, 3)}/k.m<sup>3</sup></>} isDense />
        </Grid>}
    </Grid>
  );
};