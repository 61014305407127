import React, { useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import moment from "moment";
import "moment-timezone";
import { ProfileContext } from "../../../../common/contexts/profile";
import { formatDateTime } from "../../../../common/utils/format";

const prepDate = (d, tz) => formatDateTime(moment.parseZone(d).tz(tz))

const SyncView = ({ label, sync, timeZone }) => (
  <>
    {sync &&
      <>
        {sync.analysis &&
          <>
            <Typography sx={{ mt: 1 }} variant="body1">{prepDate(sync.analysis, timeZone)}</Typography>
            <Typography variant="caption">{label} generated</Typography>
          </>}
        <Typography sx={{ mt: 1 }} variant="body1">{prepDate(sync.run, timeZone)}</Typography>
        <Typography variant="caption">{label} retrieved</Typography>
      </>}
  </>);

export const ExtractionSourceSyncDialog = ({ open, target, onCancel }) => {
  const { timeZone } = useContext(ProfileContext);
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Sync Details</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Sync details for forecast and actual value.
        </DialogContentText>
        <SyncView label="Forecast" sync={target.forecastSync} timeZone={timeZone} />
        <SyncView label="Actual" sync={target.actualSync} timeZone={timeZone} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Close</Button>
      </DialogActions>
    </Dialog>
  )
};